import React, { useEffect } from 'react';
import { bool, func, number, object, shape, string, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/Auth.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import Topbar from '../../components/Topbar/Topbar';
import { searchByKeyword } from '../SearchPage/SearchPage.duck';
import { loadData } from './TopbarContainer.duck';
import { useScrollDirection } from '../../util/contextHelpers';

export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    history,
    isAuthenticated,
    location,
    notificationCount,
    unreadMessagesCount,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onSearchByKeyword,
    onLoadTotalArtworks,
    totalArtworks,
    ...rest
  } = props;

  const scrollDirection = useScrollDirection();

  useEffect(() => {
    if (totalArtworks === 0) {
      onLoadTotalArtworks();
    }
  }, [totalArtworks]);

  return (
    <Topbar
      authInProgress={authInProgress}
      currentPage={currentPage}
      currentSearchParams={currentSearchParams}
      currentUser={currentUser}
      currentUserHasListings={currentUserHasListings}
      currentUserHasOrders={currentUserHasOrders}
      history={history}
      isAuthenticated={isAuthenticated}
      location={location}
      notificationCount={notificationCount}
      unreadMessagesCount={unreadMessagesCount}
      onLogout={onLogout}
      onManageDisableScrolling={onManageDisableScrolling}
      onResendVerificationEmail={onResendVerificationEmail}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
      onSearchByKeyword={onSearchByKeyword}
      totalArtworks={totalArtworks}
      scrollDirection={scrollDirection}
      {...rest}
    />
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  authScopes: [],
  fetchCurrentUserInProgress: false,
  announcementContent: null,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onSearchByKeyword: func.isRequired,
  authScopes: array,
  onLoadTotalArtworks: func.isRequired,
  totalArtworks: number.isRequired,
  fetchCurrentUserInProgress: bool,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
  announcementContent: string,
  bannerStateKey: string,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, authScopes } = state.Auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    unreadMessagesCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    fetchCurrentUserInProgress,
  } = state.user;
  const { totalArtworks, queryInProgress: queryTotalArtworksInProgress } = state.TopbarContainer;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    notificationCount,
    unreadMessagesCount,
    isAuthenticated,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    authScopes,
    totalArtworks,
    fetchCurrentUserInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onSearchByKeyword: keyword => dispatch(searchByKeyword(keyword)),
  onLoadTotalArtworks: () => dispatch(loadData()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TopbarContainerComponent);

export default TopbarContainer;
