import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
const PAGE_SIZE = 12;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageFavoriteArtistsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageFavoriteArtistsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageFavoriteArtistsPage/FETCH_LISTINGS_ERROR';

export const LOAD_MORE_REQUEST = 'app/ManageFavoriteArtistsPage/LOAD_MORE_REQUEST';
export const LOAD_MORE_SUCCESS = 'app/ManageFavoriteArtistsPage/LOAD_MORE_SUCCESS';
export const LOAD_MORE_ERROR = 'app/ManageFavoriteArtistsPage/LOAD_MORE_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  loadMoreInProgress: false,
  loadMoreError: null,
};

const ManageFavoriteArtistsPageReducer =
  (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
      case FETCH_LISTINGS_REQUEST:
        return {
          ...state,
          queryParams: payload.queryParams,
          queryInProgress: true,
          queryListingsError: null,
        };
      case FETCH_LISTINGS_SUCCESS:
        return {
          ...state,
          listings: payload.listings,
          pagination: payload.pagination,
          queryInProgress: false,
        };
      case FETCH_LISTINGS_ERROR:
        // eslint-disable-next-line no-console
        console.error(payload);
        return { ...state, queryInProgress: false, queryListingsError: payload };
      case LOAD_MORE_REQUEST:
        return {
          ...state,
          loadMoreInProgress: true,
          searchParams: payload.queryParams,
          loadMoreError: null,
        };
      case LOAD_MORE_SUCCESS:
        return {
          ...state,
          listings: [...state.listings, ...payload.listings],
          pagination: payload.pagination,
          loadMoreInProgress: false,
        };
      case LOAD_MORE_ERROR:
        console.error(payload);
        return { ...state, loadMoreInProgress: false, loadMoreError: payload };

      default:
        return state;
    }
  };

export default ManageFavoriteArtistsPageReducer;

// ================ Action creators ================ //

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = ({listings, pagination}) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { listings, pagination },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const loadMoreRequest = queryParams => ({
  type: LOAD_MORE_REQUEST,
  payload: { queryParams },
});

export const loadMoreSuccess = ({ listings, pagination }) => ({
  type: LOAD_MORE_SUCCESS,
  payload: { listings, pagination },
});

export const loadMoreError = error => ({ type: LOAD_MORE_ERROR, error: true, payload: error });

// ================ Thunks ================ //

export const queryRandomArtwork = artists => async (dispatch, getState, sdk) => {
  try {
    const artworks = artists.map(artist => {
      const artParams = {
        pub_listingType: 'art',
        author_id: artist.author.id.uuid,
        include: ['images'],
        'limit.images': 1,
        page: 1,
        per_page: 5,
      };

      return sdk.listings.query(artParams);
    });

    const artworksValue = await Promise.all(artworks);

    const listings = artists.map((artist, index) => {
      const arts = denormalisedResponseEntities(artworksValue[index]);
      const randomIndex = Math.floor(Math.random() * arts.length);
      const randomArtwork = arts.length === 0 ? null : arts[randomIndex];
      return { ...artist, images: randomArtwork?.images };
    });

    return listings;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const loadData = () => (dispatch, getState, sdk) => {
  let pagination;
  dispatch(queryListingsRequest({}));

  return dispatch(fetchCurrentUser())
    .then(() => {
      const currentUser = getState().user.currentUser;

      if (!currentUser) {
        return null;
      }

      const params = {
        pub_listingType: 'artist',
        meta_likers: currentUser && currentUser.id.uuid,
        per_page: PAGE_SIZE,
        page: 1,
        'fields.listing': [
          'description',
          'geolocation',
          'price',
          'title',
          'publicData',
          'metadata',
        ],
        include: ['author', 'author.profileImage'],
      };

      dispatch(queryListingsRequest(params));

      return sdk.listings
        .query(params)
        .then(response => {
          dispatch(addMarketplaceEntities(response));
          pagination = response.data.meta;
          const artists = denormalisedResponseEntities(response);
          return dispatch(queryRandomArtwork(artists));
        })
        .then(listings => {
          dispatch(queryListingsSuccess({ listings, pagination}));
        })
        .catch(e => {
          dispatch(queryListingsError(storableError(e)));
          throw e;
        });
    })
};

const canLoadMore = (pagination = {}) => {
  const { page, totalPages } = pagination || {};
  return page < totalPages;
};

const isLoadingMore = state => {
  const { loadMoreInProgress } = state.ManageFavoriteArtistsPage;
  return loadMoreInProgress;
};

export const loadMore = () => (dispatch, getState, sdk) => {
  const { pagination, queryParams } = getState().ManageFavoriteArtistsPage;
  let updatedPagination;
  if (isLoadingMore(getState()) || !canLoadMore(pagination)) return;

  const params = {
    ...queryParams,
    page: pagination.page + 1,
  };

  dispatch(loadMoreRequest(params));

  return sdk.listings
    .query(params)
    .then(response => {
      const artists = denormalisedResponseEntities(response);
      updatedPagination = response.data.meta;
      return dispatch(queryRandomArtwork(artists));
    })
    .then(listings => {
      dispatch(loadMoreSuccess({ listings, pagination: updatedPagination }));
    })
    .catch(e => dispatch(loadMoreError(storableError(e))));
};
