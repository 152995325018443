import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const QUERY_COPYRIGHT_REQUEST = 'app/CopyrightPage/QUERY_COPYRIGHT_REQUEST';
export const QUERY_COPYRIGHT_SUCCESS = 'app/CopyrightPage/QUERY_COPYRIGHT_SUCCESS';
export const QUERY_COPYRIGHT_ERROR = 'app/CopyrightPage/QUERY_COPYRIGHT_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryCopyrightError: null,
  copyright: null,
};

export default function CopyrightReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_COPYRIGHT_REQUEST:
      return { ...state, queryCopyrightError: null };
    case QUERY_COPYRIGHT_SUCCESS:
      return { ...state, copyright: payload };
    case QUERY_COPYRIGHT_ERROR:
      return { ...state, queryCopyrightError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryCopyrightRequest = () => ({
  type: QUERY_COPYRIGHT_REQUEST,
});

export const queryCopyrightSuccess = copyright => ({
  type: QUERY_COPYRIGHT_SUCCESS,
  payload: copyright,
});

export const queryCopyrightError = e => ({
  type: QUERY_COPYRIGHT_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(queryCopyrightRequest());

  return strapi
    .get('/api/copyright')
    .then(res => dispatch(queryCopyrightSuccess(res.data.attributes)))
    .catch(e => dispatch(queryCopyrightError(storableError(e.response))));
};
