import React, { useState, useEffect } from 'react';
import { getInfoFromAddress, reverseGeocoding } from '../../util/maps';

const CityState = props => {
  const { className, location } = props;
  const { address, origin } = location;
  const [info, setInfo] = useState(null);

  const fetchInfo = async (origin, address) => {
    const res = origin ? await reverseGeocoding(origin) : await getInfoFromAddress(address);
    setInfo(res);
  };

  useEffect(() => {
    fetchInfo(origin, address);
  }, [origin, address]);

  if (!info) {
    return null;
  } else {
    const city = info.address_components && info.address_components.find(ct => ct.types.includes('locality'));
    const state = info.address_components && info.address_components.find(ct => ct.types.includes('administrative_area_level_1'));
    if (state && city)
      return <span className={className}>{`${city.long_name}, ${state.long_name}`}</span>;
    else if (state)
      return <span className={className}>{`${state.long_name}`}</span>;
    return null;
  }
};

export default CityState;
