import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCloseHeader.module.css';

const IconCloseHeader = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.928894"
        y="13.657"
        width="18"
        height="2"
        transform="rotate(-45 0.928894 13.657)"
        fill="#050707"
      />
      <rect
        x="2.34308"
        y="0.928955"
        width="18"
        height="2"
        transform="rotate(45 2.34308 0.928955)"
        fill="#050707"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconCloseHeader.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCloseHeader.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCloseHeader;
