import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { getCurrentFavoriteListings } from '../ManageFavoriteListingsPage/ManageFavoriteListingsPage.duck';
import * as log from '../../util/log';
import config from '../../config';
import { PER_PAGE_COLLECTION_PAGE_DEFAULT, ARTIST_TRAFFIC, DEFAULT_SORT_BY_FEATURED } from '../../marketplace-custom-config';
import { parse } from '../../util/urlHelpers';
import { LISTING_TYPE_ART, LISTING_TYPE_ARTIST, LISTING_TYPE_ARTWORK_COLLECTION } from '../../util/types';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/CollectionPage/SET_INITIAL_STATE';

export const FETCH_COLLECTION_LISTING_REQUEST = 'app/CollectionPage/FETCH_COLLECTION_LISTING_REQUEST';
export const FETCH_COLLECTION_LISTING_SUCCESS = 'app/CollectionPage/FETCH_COLLECTION_LISTING_SUCCESS';
export const FETCH_COLLECTION_LISTING_ERROR = 'app/CollectionPage/FETCH_COLLECTION_LISTING_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/CollectionPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/CollectionPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/CollectionPage/QUERY_LISTINGS_ERROR';

export const LOAD_MORE_REQUEST = 'app/CollectionPage/LOAD_MORE_REQUEST';
export const LOAD_MORE_SUCCESS = 'app/CollectionPage/LOAD_MORE_SUCCESS';
export const LOAD_MORE_ERROR = 'app/CollectionPage/LOAD_MORE_ERROR';

// ================ Reducer ================ //

const initialState = {
  listingRefs: [],
  queryInProgress: false,
  queryParams: null,
  searchParams: null,
  queryListingsError: null,
  pagination: null,
  loadMoreInProgress: false,
  loadMoreError: null,
  collectionType: null,
  fetchCollectionInProgress: false,
  currentCollection: null,
  fetchCollectionListingError: null,
};

export default function collectionPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        queryParams: payload.queryParams,
        queryListingsError: null,
        queryInProgress: true,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        listingRefs: payload.listingRefs,
        pagination: payload.pagination,
        queryInProgress: false,
      };
    case QUERY_LISTINGS_ERROR:
      return { ...state, listingRefs: [], queryListingsError: payload, queryInProgress: false, };

    case FETCH_COLLECTION_LISTING_REQUEST:
      return { ...state, fetchCollectionInProgress: true, fetchCollectionListingError: null };
    case FETCH_COLLECTION_LISTING_SUCCESS:
      return { ...state, fetchCollectionInProgress: false, currentCollection: payload };
    case FETCH_COLLECTION_LISTING_ERROR:
      return { ...state, fetchCollectionInProgress: false, fetchCollectionListingError: payload };

    case LOAD_MORE_REQUEST:
      return { ...state, queryParams: payload, loadMoreInProgress: true, loadMoreError: null };
    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMoreInProgress: false,
        listingRefs: [...state.listingRefs, ...payload.listingRefs],
        pagination: payload.pagination,
      };
    case LOAD_MORE_ERROR:
      return { ...state, loadMoreInProgress: false, loadMoreError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryListingsRequest = (searchParams, queryParams) => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { searchParams, queryParams },
});

export const queryListingsSuccess = ({ listingRefs, pagination }) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs, pagination },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchCollectionRequest = () => ({
  type: FETCH_COLLECTION_LISTING_REQUEST,
});

export const fetchCollectionSuccess = currentCollection => ({
  type: FETCH_COLLECTION_LISTING_SUCCESS,
  payload: currentCollection,
});

export const fetchCollectionError = e => ({
  type: FETCH_COLLECTION_LISTING_ERROR,
  error: true,
  payload: e,
});

export const loadMoreRequest = queryParams => ({
  type: LOAD_MORE_REQUEST,
  payload: queryParams,
});

export const loadMoreSuccess = ({ listingRefs, pagination }) => ({
  type: LOAD_MORE_SUCCESS,
  payload: { listingRefs, pagination },
});

export const loadMoreError = error => ({ type: LOAD_MORE_ERROR, error: true, payload: error });


// ================ Thunks ================ //

const canLoadMore = (pagination = {}) => {
  const { page, totalPages } = pagination || {};
  return page < totalPages;
};

const isLoadingMore = state => {
  const { loadMoreInProgress } = state.CollectionPage;
  return loadMoreInProgress;
};

export const loadMore = () => (dispatch, getState, sdk) => {
  const { pagination, queryParams } = getState().CollectionPage;

  if (isLoadingMore(getState()) || !canLoadMore(pagination)) return;

  const params = {
    ...queryParams,
    page: pagination.page + 1,
  };

  dispatch(loadMoreRequest(params));

  return sdk.listings
    .query(params)
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(loadMoreSuccess({ listingRefs, pagination: response.data.meta }));
    })
    .catch(e => {
      dispatch(loadMoreError(storableError(e)))
    });
};

export const fetchCollection = collectionSlug => (dispatch, getState, sdk) => {
  dispatch(fetchCollectionRequest());

  const queryParams = {
    populate: {
      heroImg: true,
    },
  };

  return strapi
    .get(`/api/collections/${collectionSlug}`, queryParams)
    .then(response => {
      const collection = response.data.attributes;
      dispatch(fetchCollectionSuccess(collection));
    })
    .catch(e => {
      dispatch(fetchCollectionError(storableError(e.response)));
      log.error(e, 'fetch-collection-failed');
    });
};

export const queryListings = (searchParams, collectionType) => (dispatch, getState, sdk) => {
  const { currentCollection = {} } = getState().CollectionPage;
  const { listingIds = '' } = currentCollection;

  const isArtworkCollection = collectionType === LISTING_TYPE_ARTWORK_COLLECTION;
  const listingType = isArtworkCollection
    ? LISTING_TYPE_ART
    : LISTING_TYPE_ARTIST;

  const listOfCollectionId = listingIds.replace(/\n/g, ',');

  const {
    perPage,
    sort,
    ...rest
  } = searchParams;

  const sortDefault = isArtworkCollection ? DEFAULT_SORT_BY_FEATURED : ARTIST_TRAFFIC;

  const queryParams = {
    ...rest,
    ids: listOfCollectionId,
    pub_listingType: listingType,
    per_page: perPage || PER_PAGE_COLLECTION_PAGE_DEFAULT,
    sort: sort || sortDefault,
    include: ['author', 'images', 'author.profileImage'],
    'limit.images': 1,
    'fields.image': isArtworkCollection ? ['variants.scaled-small', 'variants.scaled-medium'] : null,
  };

  dispatch(queryListingsRequest(searchParams, queryParams));

  return sdk.listings.query(queryParams)
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess({ listingRefs, pagination: response.data.meta }));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
}

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(setInitialState());

  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // eslint-disable-next-line no-unused-vars
  const { page = 1, address, origin, perPage, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const { collectionType, slug: collectionSlug } = params;
  const searchParams = {
    ...rest,
    ...originMaybe,
    page,
    perPage,
  };

  return dispatch(fetchCollection(collectionSlug))
    .then(() => {
      return Promise.all([
        dispatch(queryListings(searchParams, collectionType)),
        dispatch(getCurrentFavoriteListings()),
      ]);
    })
};
