import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const QUERY_FAQ_REQUEST = 'app/FaqPage/QUERY_FAQ_REQUEST';
export const QUERY_FAQ_SUCCESS = 'app/FaqPage/QUERY_FAQ_SUCCESS';
export const QUERY_FAQ_ERROR = 'app/FaqPage/QUERY_FAQ_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryFaqError: null,
  faq: null,
};

export default function FaqReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_FAQ_REQUEST:
      return { ...state, queryFaqError: null };
    case QUERY_FAQ_SUCCESS:
      return { ...state, faq: payload };
    case QUERY_FAQ_ERROR:
      return { ...state, queryFaqError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryFaqRequest = () => ({
  type: QUERY_FAQ_REQUEST,
});

export const queryFaqSuccess = faq => ({
  type: QUERY_FAQ_SUCCESS,
  payload: faq,
});

export const queryFaqError = e => ({
  type: QUERY_FAQ_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(queryFaqRequest());

  return strapi
    .get('/api/faq')
    .then(res => dispatch(queryFaqSuccess(res.data.attributes)))
    .catch(e => dispatch(queryFaqError(storableError(e.response))));
};
