import moment from 'moment';
import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';
import { STATE_ACCEPTED } from '../../util/transaction';

// ================ Action types ================ //

export const INITIAL_LOAD_PAGE = 'app/ExhibitionsNavigation/INITIAL_LOAD_PAGE';
export const QUERY_EXHIBITIONS_SUCCESS = 'app/ExhibitionsNavigation/QUERY_EXHIBITIONS_SUCCESS';
export const QUERY_EXHIBITIONS_REQUEST = 'app/ExhibitionsNavigation/QUERY_EXHIBITIONS_REQUEST';
export const QUERY_EXHIBITIONS_ERROR = 'app/ExhibitionsNavigation/QUERY_EXHIBITIONS_ERROR';

export const LOAD_MORE_REQUEST = 'app/ExhibitionsNavigation/LOAD_MORE_REQUEST';
export const LOAD_MORE_SUCCESS = 'app/ExhibitionsNavigation/LOAD_MORE_SUCCESS';
export const LOAD_MORE_ERROR = 'app/ExhibitionsNavigation/LOAD_MORE_ERROR';

// ================ Constant variables ================ //

export const CURRENT_EXHIBITION = 'exhibition/current';
export const PAST_EXHIBITION = 'exhibition/past';
export const UPCOMING_EXHIBITION = 'exhibition/upcoming';
export const PAGINATION_PAGE_SIZE = 9;

// ================ Util functions ================ //

export const mapExhibitionTypeToQuery = exhibitionType => {
  const today = moment().format(strapi.DATE_FORMAT);
  switch (exhibitionType) {
    case CURRENT_EXHIBITION:
      return {
        populate: '*',
        filters: {
          $and: [{ startDate: { $lte: today } }, { endDate: { $gte: today } }],
          showOnExhibitionsPage: true,
        },
        sort: { startDate: 'desc' },
      };
    case PAST_EXHIBITION:
      return {
        populate: '*',
        filters: {
          endDate: { $lt: today },
          showOnExhibitionsPage: true,
        },
        sort: { startDate: 'desc' },
      };
    case UPCOMING_EXHIBITION:
      return {
        populate: '*',
        filters: {
          startDate: { $gt: today },
          showOnExhibitionsPage: true,
        },
        sort: { startDate: 'asc' },
      };
    default:
      return {
        populate: '*',
        showOnExhibitionsPage: true,
      };
  }
};

// ================ Reducer ================ //

const initialState = {
  isInitialLoadPage: false,
  queryExhibitionError: {},
  loadMoreInProgress: false,
  [CURRENT_EXHIBITION]: {
    data: [],
    meta: null,
  },
  [PAST_EXHIBITION]: {
    data: [],
    meta: null,
  },
  [UPCOMING_EXHIBITION]: {
    data: [],
    meta: null,
  },
};

export default function exhibitionsNavigationReducer(state = initialState, action = {}) {
  const { type, payload, exhibitionType } = action;
  switch (type) {
    case INITIAL_LOAD_PAGE:
      return {
        ...state,
        isInitialLoadPage: true,
      };
    case QUERY_EXHIBITIONS_REQUEST:
      return {
        ...state,
        queryExhibitionError: {
          ...state.queryExhibitionError,
          [exhibitionType]: null,
        },
        [exhibitionType]: {
          ...state[exhibitionType],
          meta: null,
        },
      };
    case QUERY_EXHIBITIONS_ERROR:
      return {
        ...state,
        queryExhibitionError: {
          ...state.queryExhibitionError,
          [exhibitionType]: payload,
        },
      };
    case QUERY_EXHIBITIONS_SUCCESS: {
      const previousExhibitions = state[exhibitionType];
      return {
        ...state,
        [exhibitionType]: {
          data:
            payload.meta?.pagination?.page === 1
              ? payload.data
              : [...previousExhibitions.data, ...payload.data],
          meta: payload.meta,
        },
        isInitialLoadPage: false,
        queryExhibitionError: {
          ...state.queryExhibitionError,
          [exhibitionType]: null,
        },
      };
    }
    case LOAD_MORE_REQUEST: {
      return {
        ...state,
        loadMoreInProgress: true,
      };
    }
    case LOAD_MORE_SUCCESS:
    case LOAD_MORE_ERROR: {
      return {
        ...state,
        loadMoreInProgress: false,
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const isInitialLoadPage = () => ({
  type: INITIAL_LOAD_PAGE,
});

export const queryExhibitionsRequest = exhibitionType => ({
  type: QUERY_EXHIBITIONS_REQUEST,
  exhibitionType,
});

export const queryExhibitionsError = (exhibitionType, error) => ({
  type: QUERY_EXHIBITIONS_ERROR,
  exhibitionType,
  payload: error,
});

export const queryExhibitionsSuccess = (exhibitionType, exhibitions) => ({
  type: QUERY_EXHIBITIONS_SUCCESS,
  exhibitionType,
  payload: exhibitions,
});

export const loadMoreRequest = () => ({
  type: LOAD_MORE_REQUEST,
});

export const loadMoreSuccess = () => ({
  type: LOAD_MORE_SUCCESS,
});

export const loadMoreError = () => ({
  type: LOAD_MORE_ERROR,
});

// ================ Thunks ================ //

const canLoadMore = (pagination = {}) => {
  const { page, pageCount } = pagination.pagination || {};
  return page < pageCount;
};

const isLoadingMore = state => {
  const { loadMoreInProgress } = state.ExhibitionsNavigationPage;
  return loadMoreInProgress;
};

export const queryExhibitions = (exhibitionType, query, config) => (
  dispatch,
  getState,
  sdk
) => {
  const queryParams = Object.assign(mapExhibitionTypeToQuery(exhibitionType), query);
  dispatch(queryExhibitionsRequest(exhibitionType));
  return strapi
    .get(`/api/exhibitions`, queryParams)
    .then(data => {
      dispatch(queryExhibitionsSuccess(exhibitionType, data));
      config && config.isPastExhibitionLoadMore && dispatch(loadMoreSuccess());
    })
    .catch(e => {
      dispatch(queryExhibitionsError(exhibitionType, storableError(e)));
      config && config.isPastExhibitionLoadMore && dispatch(loadMoreError());
    });
};

export const loadMore = query => (dispatch, getState, sdk) => {
  const { [PAST_EXHIBITION]: pastExhibitions } = getState().ExhibitionsNavigationPage;
  if (isLoadingMore(getState()) || !canLoadMore(pastExhibitions.meta)) return;

  dispatch(loadMoreRequest());
  dispatch(queryExhibitions(PAST_EXHIBITION, query, { isPastExhibitionLoadMore: true }));
};

export const loadData = () => dispatch => {
  dispatch(isInitialLoadPage());
  return Promise.all(
    [CURRENT_EXHIBITION, PAST_EXHIBITION, UPCOMING_EXHIBITION].map(type => {
      dispatch(
        queryExhibitions(type, {
          pagination: {
            page: 1,
            pageSize: PAGINATION_PAGE_SIZE,
          },
        })
      );
    })
  );
};
