import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { intlShape, injectIntl } from 'react-intl';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import Button from '../Button/Button';
import Logo from '../Logo/Logo';
import Modal from '../Modal/Modal';
import ModalMissingInformation from '../ModalMissingInformation/ModalMissingInformation';
import NamedLink from '../NamedLink/NamedLink';
import TopbarDesktop from '../TopbarDesktop/TopbarDesktop';
import TopbarMobileMenu from '../TopbarMobileMenu/TopbarMobileMenu';
import ModalArtistMissingInformation from '../ModalArtistMissingInformation/ModalArtistMissingInformation';
import LimitedAccessBanner from '../LimitedAccessBanner/LimitedAccessBanner';
import TopbarSearchForm from '../../forms/TopbarSearchForm/TopbarSearchForm';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import UserIcon from './UserIcon';
import { ensureCurrentUser } from '../../util/data';
import { currentUserIsArtist } from '../../util/user';
import ProfileDropdown from '../TopbarDesktop/ProfileDropdown';
import IconCloseHeader from '../IconCloseHeader/IconCloseHeader';
import { getRedirectRouteFromSearchItem } from '../../util/searchUtil';
import IconArrowBack from '../IconArrowBack/IconArrowBack';
import AnnouncementBanner from '../AnnouncementBanner/AnnouncementBanner';
import { SCROLL_DOWN, SCROLL_UP, SCROLL_DEFAULT_DIRECTION } from '../../marketplace-custom-config';

import css from './Topbar.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const SINGLE_MESSAGE_PAGE = 'SingleMessagePage';
const EXCLUSIVE_TOPBAR_BEHAVIOR_PAGES = [
  'SearchPage',
  'MyArtPage',
  'LandingPage',
  'ExhibitionsNavigationPage',
  'NewGalleryPage',
  'PressPage',
  'ProjectPage',
  'ArticleNavigationPage',
  'ArtistsCollectionPage',
  'ArtworksCollectionPage',
  'FavoriteArtistPage',
  'FavoriteArtPage',
];

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleSelectSearchItem = this.handleSelectSearchItem.bind(this);
  }

  handleMobileMenuOpen(isMobileMenuOpen) {
    if (isMobileMenuOpen) {
      redirectToURLWithoutModalState(this.props, 'mobilemenu');
    } else {
      redirectToURLWithModalState(this.props, 'mobilemenu');
    }
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams, currentPage } = this.props;
    const { search: keywords } = values;
    const { history } = this.props;
    const searchParams =
      currentPage === 'SearchPage'
        ? {
            ...currentSearchParams,
            keywords,
          }
        : { keywords };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleSelectSearchItem(values) {
    const { search: keywords, searchItem } = values;
    const { history } = this.props;

    const path = getRedirectRouteFromSearchItem(searchItem);
    history.push(path, { keywords });
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('GalleryPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      navItemClassName,
      logoClassName,
      searchClassName,
      loginButtonClassName,
      isAuthenticated,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      unreadMessagesCount,
      viewport,
      intl,
      location,
      history,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onSearchByKeyword,
      authScopes,
      totalArtworks,
      fetchCurrentUserInProgress,
      announcementContent,
      bannerStateKey,
      scrollDirection,
    } = this.props;

    const user = ensureCurrentUser(currentUser);

    const isArtist = currentUserIsArtist(user);

    const { mobilemenu, mobilesearch, address, origin, bounds, keywords } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
      keywords: ['keywords'],
    });

    const persistKeyword = location?.state?.keywords;

    const offersDot = notificationCount > 0 && <div className={css.notificationDot} />;
    const messagesDot = unreadMessagesCount > 0 && <div className={css.notificationDot} />;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const shouldShowBanner = !isMobileMenuOpen && currentPage === 'GalleryPage';
    const mobileMenu = (
      <TopbarMobileMenu
        notificationCount={notificationCount}
        currentPage={currentPage}
        isAuthenticated={isAuthenticated}
      />
    );
    const classes = classNames(rootClassName || css.root, className);

    const isTopbarHide = scrollDirection === SCROLL_DOWN;
    const isTopbarAppear = scrollDirection === SCROLL_UP;
    const isTopbarTransparent =
      scrollDirection === SCROLL_DEFAULT_DIRECTION &&
      !isAuthenticated &&
      currentPage === 'GalleryPage';

    const topbarBehaviorClassNames = !EXCLUSIVE_TOPBAR_BEHAVIOR_PAGES.includes(currentPage)
      ? {
          banner: {
            [css.hide]: isTopbarHide,
            [css.appear]: isTopbarAppear,
          },
          mobile: {
            [css.hide]: isTopbarHide,
            [css.galleryPage]: isTopbarTransparent,
            [css.mobileMenuOpen]: isMobileMenuOpen,
          },
          desktop: {
            [css.hide]: isTopbarHide,
            [css.galleryPage]: isTopbarTransparent,
          },
          search: {
            [css.searchBar]: isTopbarTransparent,
          },
        }
      : {};

    return (
      <div className={classes}>
        {shouldShowBanner && (
          <AnnouncementBanner
            className={classNames(css.banner, topbarBehaviorClassNames.banner)}
            announcementContent={announcementContent}
            bannerStateKey={bannerStateKey}
          />
        )}
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        {/*--------Top bar mobile---------*/}
        <div
          className={classNames(
            mobileRootClassName || css.container,
            mobileClassName,
            topbarBehaviorClassNames.mobile
          )}
        >
          {currentPage !== SINGLE_MESSAGE_PAGE ? (
            <>
              <Button
                rootClassName={css.menu}
                onClick={() => this.handleMobileMenuOpen(isMobileMenuOpen)}
                title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
              >
                {isMobileMenuOpen ? (
                  <IconCloseHeader className={css.menuIcon} />
                ) : (
                  <MenuIcon className={css.menuIcon} />
                )}
              </Button>
              <NamedLink
                className={css.home}
                title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
                name="GalleryPage"
              >
                <Logo format="mobile" />
              </NamedLink>
            </>
          ) : (
            <IconArrowBack
              className={css.backIcon}
              onClick={() => {
                history.push(createResourceLocatorString('MessagesPage', routeConfiguration(), {}));
              }}
            />
          )}
          <div className={css.iconsWrapper}>
            <Button
              rootClassName={css.searchMenu}
              onClick={this.handleMobileSearchOpen}
              title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
            >
              <SearchIcon className={css.searchMenuIcon} />
            </Button>
            <ProfileDropdown
              currentUserHasListings={currentUserHasListings}
              isAuthenticated={isAuthenticated}
              isArtist={isArtist}
              currentUser={currentUser}
              currentPage={currentPage}
              offersDot={offersDot}
              messagesDot={messagesDot}
              onLogout={this.handleLogout}
              isMobile
            >
              <div
                className={css.userMenu}
                title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
                role="button"
              >
                <UserIcon className={css.searchMenuIcon} />
              </div>
            </ProfileDropdown>
          </div>
        </div>
        {/*--------Top bar desktop---------*/}
        <div className={classNames(css.desktop, topbarBehaviorClassNames.desktop)}>
          <TopbarDesktop
            className={desktopClassName}
            navItemClassName={navItemClassName}
            logoClassName={logoClassName}
            searchClassName={classNames(searchClassName, topbarBehaviorClassNames.search)}
            loginButtonClassName={loginButtonClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={{ search: keywords || persistKeyword }}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            unreadMessagesCount={unreadMessagesCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            isArtist={isArtist}
            onSearchByKeyword={onSearchByKeyword}
            handleSelectSearchItem={this.handleSelectSearchItem}
            totalArtworks={totalArtworks}
            fetchCurrentUserInProgress={fetchCurrentUserInProgress}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          containerClassName={classNames(css.topbarMobileMenuModal, css.modalContainer)}
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          onManageDisableScrolling={onManageDisableScrolling}
          hideTopbar={false}
          isTopbarMobileMenu
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          onManageDisableScrolling={onManageDisableScrolling}
          isDisplayCloseButtonMessage={false}
          closeButtonClassName={css.close}
        >
          <TopbarSearchForm
            form="TopbarSearchForm"
            onSubmit={this.handleSubmit}
            initialValues={{ search: keywords || persistKeyword }}
            onSearchByKeyword={onSearchByKeyword}
            isMobile
            handleSelectSearchItem={this.handleSelectSearchItem}
          />
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />
        <ModalArtistMissingInformation
          currentUser={currentUser}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  navItemClassName: null,
  logoClassName: null,
  searchClassName: null,
  loginButtonClassName: null,
  notificationCount: 0,
  unreadMessagesCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  fetchCurrentUserInProgress: false,
  announcementContent: null,
};

const { array, func, number, shape, string, bool } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  navItemClassName: string,
  logoClassName: string,
  searchClassName: string,
  loginButtonClassName: string,
  isAuthenticated: bool.isRequired,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  unreadMessagesCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onSearchByKeyword: func.isRequired,
  authScopes: array,
  totalArtworks: number.isRequired,
  fetchCurrentUserInProgress: bool,
  scrollDirection: propTypes.scrollDirection,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  announcementContent: string,
  bannerStateKey: string,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
