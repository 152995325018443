import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //
export const SET_INITIAL_STATE = 'app/ProjectPostPage/SET_INITIAL_STATE';

export const QUERY_PROJECT_REQUEST = 'app/ProjectPostPage/QUERY_PROJECT_REQUEST';
export const QUERY_PROJECT_SUCCESS = 'app/ProjectPostPage/QUERY_PROJECT_SUCCESS';
export const QUERY_PROJECT_ERROR = 'app/ProjectPostPage/QUERY_PROJECT_ERROR';

// ================ Reducer ================ //
const initialState = {
  queryProjectInProgress: false,
  queryProjectError: null,
  currentProject: null,
};

export default function ProjectPostPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_PROJECT_REQUEST:
      return { ...state, queryProjectInProgress: true, queryProjectError: null };
    case QUERY_PROJECT_SUCCESS:
      return { ...state, queryProjectInProgress: false, currentProject: payload };
    case QUERY_PROJECT_ERROR:
      console.error(payload);
      return { ...state, queryProjectInProgress: false, queryProjectError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryProjectRequest = slug => ({
  type: QUERY_PROJECT_REQUEST,
  payload: { slug },
});

export const queryProjectSuccess = project => ({
  type: QUERY_PROJECT_SUCCESS,
  payload: project.data.attributes,
});

export const queryProjectError = e => ({
  type: QUERY_PROJECT_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //
export const queryProject = projectSlug => (dispatch, getState, sdk) => {
  const queryParams = {
    populate: {
      heroBanner: true,
    },
  };
  dispatch(queryProjectRequest(projectSlug));
  return strapi
    .get(`/api/projects/${projectSlug}`, queryParams)
    .then(data => {
      dispatch(queryProjectSuccess(data));
    })
    .catch(e => {
      dispatch(queryProjectError(storableError(e.response)));
    });
};

export const loadData = params => dispatch => {
  dispatch(setInitialState());

  const projectSlug = params.slug;

  return dispatch(queryProject(projectSlug));
};