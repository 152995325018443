import {
  denormalisedEntities, denormalisedResponseEntities
} from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import chunk from 'lodash/chunk';
import { types as sdkTypes } from '../../util/sdkLoader';
import { dislikeListingSignal, likeListingSignal } from '../../util/social';
const { UUID } = sdkTypes;

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
export const RESULT_PAGE_SIZE = 12;
// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageFavoriteListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageFavoriteListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageFavoriteListingsPage/FETCH_LISTINGS_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageFavoriteListingsPage/ADD_OWN_ENTITIES';

export const UPDATE_LIKE_LISTINGS = 'app/ManageFavoriteListingsPage/UPDATE_LIKE_LISTINGS';
export const UPDATE_LIKE_REQUEST = 'app/ManageFavoriteListingsPage/UPDATE_LIKE_REQUEST';

export const LOAD_MORE_REQUEST = 'app/ManageFavoriteListingsPage/LOAD_MORE_REQUEST';
export const LOAD_MORE_SUCCESS = 'app/ManageFavoriteListingsPage/LOAD_MORE_SUCCESS';
export const LOAD_MORE_ERROR = 'app/ManageFavoriteListingsPage/LOAD_MORE_ERROR';

export const UPDATE_PAGINATION = 'app/ManageFavoriteListingsPage/UPDATE_PAGINATION';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  listings: [],
  likeInProgress: false,
  likeError: null,
  likedListings: [],
  loadMoreInProgress: false,
  loadMoreError: null,
};

const manageFavoriteListingsPageReducer =
  (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
      case FETCH_LISTINGS_REQUEST:
        return {
          ...state,
          queryParams: payload.queryParams,
          queryInProgress: true,
          queryListingsError: null,
          currentPageResultIds: [],
        };
      case FETCH_LISTINGS_SUCCESS:
        return {
          ...state,
          queryInProgress: false,
          listings: payload.entities,
        };
      case FETCH_LISTINGS_ERROR:
        // eslint-disable-next-line no-console
        console.error(payload);
        return {
          ...state,
          queryInProgress: false,
          queryListingsError: payload,
        };

      case UPDATE_LIKE_REQUEST:
        return { ...state, likeInProgress: true };

      case UPDATE_LIKE_LISTINGS:
        return {
          ...state,
          likedListings: payload,
          likeInProgress: false,
        };

      case LOAD_MORE_REQUEST:
        return {
          ...state,
          loadMoreInProgress: true,
          queryParams: payload.queryParams,
          loadMoreError: null,
        };
      case LOAD_MORE_SUCCESS:
        return {
          ...state,
          listings: [...state.listings, ...payload.listings],
          loadMoreInProgress: false,
        };
      case LOAD_MORE_ERROR:
        console.error(payload);
        return { ...state, loadMoreInProgress: false, loadMoreError: payload };

      case UPDATE_PAGINATION:
        return { ...state, pagination: payload.pagination };

      default:
        return state;
    }
  };

export default manageFavoriteListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingByIds = (state, listingIds) => {
  const { ownEntities } = state.ManageFavoriteListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const updateLikedRequest = () => ({
  type: UPDATE_LIKE_REQUEST
});

export const updateLikedListings = payload => ({
  type: UPDATE_LIKE_LISTINGS,
  payload
});

export const loadMoreRequest = queryParams => ({
  type: LOAD_MORE_REQUEST,
  payload: { queryParams },
});

export const loadMoreSuccess = ({ listings }) => ({
  type: LOAD_MORE_SUCCESS,
  payload: { listings },
});

export const loadMoreError = error => ({ type: LOAD_MORE_ERROR, error: true, payload: error });

export const updatePagination = (pagination) => ({
  type: UPDATE_PAGINATION,
  payload: { pagination },
});

// Throwing error for new (loadData may need that info)

// ================ Thunks ================ //
const getFavoritesListingIds = () => (dispatch, getState) => {
  const { currentUser } = getState().user;
  const { isAuthenticated } = getState().Auth;
  if (isAuthenticated && (!currentUser || !currentUser.id)) {
    return dispatch(fetchCurrentUser()).then(() => {
      const {
        publicData = {}
      } = getState().user.currentUser.attributes.profile;
      return publicData.favorites || [];
    });
  }
  if (isAuthenticated) {
    const { publicData = {} } = getState().user.currentUser.attributes.profile;
    return Promise.resolve(publicData.favorites || []);
  }
  else
    return Promise.resolve([]);
};

const getFavoritesListingByPage = (queryParams) => (dispatch, getState, sdk) => {
  const { page, perPage } = queryParams;
  let paginationIds = [];
  let totalItems = 0;
  let likedIds = [];
  dispatch(updateLikedRequest());

  return dispatch(getFavoritesListingIds())
    .then((ids = []) => {
      likedIds = ids;
      totalItems = ids.length;
      paginationIds = chunk(ids, perPage);
      const queriesIds = paginationIds[page - 1] ?
        paginationIds[page - 1].map(id => {
          if (id instanceof UUID) {
            return id.uuid;
          }
          else
            return id;
        }) : [];

      const params = {
        page: 1,
        per_page: perPage,
        pub_listingId: queriesIds.join(','),
        include: ['images', 'author'],
        'fields.image': ['variants.scaled-small', 'variants.scaled-medium'],
        'limit.images': 1,
        expand: true,
      };

      const meta = {
        page: page,
        totalPages: paginationIds.length,
        totalItems,
        perPage,
      };

      dispatch(updateLikedListings(likedIds));
      dispatch(updatePagination(meta));
      return sdk.listings.query(params);
    })
    .catch(e => {
      console.error(e);
      throw e;
    })
};

export const queryFavoriteListing = queryParams => (dispatch) => {
  dispatch(queryListingsRequest(queryParams));

  return dispatch(getFavoritesListingByPage(queryParams))
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      dispatch(queryListingsSuccess({ entities }));
    })
    .catch(e => {
      console.error(e);
      dispatch(queryListingsError(storableError(e)));
    });
};

export const likeListing = (listingId, queryParams) => (dispatch, getState, sdk) => {
  const id = listingId instanceof UUID ? listingId.uuid : listingId;
  const {
    likedListings: currentLikedListings
  } = getState().ManageFavoriteListingsPage;
  const isDislike = currentLikedListings.includes(id);
  return sdk.currentUser.updateProfile({
    publicData: {
      favorites: isDislike ?
        [...currentLikedListings.filter(i => i !== id)] :
        [...currentLikedListings, id]
    }
  }, {
    expand: true
  })
    .then(res => {
      const currentUser = denormalisedResponseEntities(res)[0];
      if (isDislike) {
        dislikeListingSignal({
          listingId: id,
          currentUserId: currentUser.id.uuid
        });
      } else {
        likeListingSignal({
          listingId: id,
          currentUserId: currentUser.id.uuid
        });
      }
      return dispatch(fetchCurrentUser());
    })
    .then(() => {
      return dispatch(queryFavoriteListing(queryParams));
    });
};

export const like = listingId => (dispatch, getState, sdk) => {
  const id = listingId instanceof UUID ? listingId.uuid : listingId;
  const {
    likedListings: currentLikedListings
  } = getState().ManageFavoriteListingsPage;
  const isDislike = currentLikedListings.includes(id);
  dispatch(updateLikedRequest());
  return sdk.currentUser.updateProfile({
    publicData: {
      favorites: isDislike ?
        [...currentLikedListings.filter(i => i !== id)] :
        [...currentLikedListings, id]
    }
  }, {
    expand: true
  }).then(res => {
    const currentUser = denormalisedResponseEntities(res)[0];
    if (isDislike) {
      dislikeListingSignal({
        listingId: id,
        currentUserId: currentUser.id.uuid
      });
    } else {
      likeListingSignal({
        listingId: id,
        currentUserId: currentUser.id.uuid
      });
    }
    return dispatch(fetchCurrentUser());
  }).then(() => {
    return dispatch(getFavoritesListingIds());
  }).then(ids => {
    dispatch(updateLikedListings(ids));
    return true;
  });
};

export const getCurrentFavoriteListings = () => dispatch => {
  return dispatch(getFavoritesListingIds())
    .then(ids => {
      dispatch(updateLikedListings(ids));
    });
};

const canLoadMore = (pagination = {}) => {
  const { page, totalPages } = pagination || {};
  return page < totalPages;
};

const isLoadingMore = state => {
  const { loadMoreInProgress } = state.ManageFavoriteListingsPage;
  return loadMoreInProgress;
};

export const loadMore = () => (dispatch, getState, sdk) => {
  const { pagination, queryParams } = getState().ManageFavoriteListingsPage;
  if (isLoadingMore(getState()) || !canLoadMore(pagination)) return;

  const params = {
    ...queryParams,
    page: pagination.page + 1,
  };

  dispatch(loadMoreRequest(params));

  return dispatch(getFavoritesListingByPage(params))
    .then(response => {
      const listings = denormalisedResponseEntities(response);
      dispatch(loadMoreSuccess({ listings }));
    })
    .catch(e => dispatch(loadMoreError(storableError(e))));
};

export const loadData = () => dispatch => {
  return dispatch(queryFavoriteListing({ page: 1, perPage: RESULT_PAGE_SIZE }));
};
