import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';
import {
  UNIQUE_PAGE_VIEWS,
} from '../../marketplace-custom-config';
import { parse } from '../../util/urlHelpers';
import { getCurrentFavoriteListings } from '../ManageFavoriteListingsPage/ManageFavoriteListingsPage.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { LISTING_TYPE_ART } from '../../util/types';

const PER_PAGE_ARTWORK_LISTINGS_DEFAULT = 12;

// ================ Action types ================ //
export const SET_INITIAL_STATE = 'app/Exhibition/SET_INITIAL_STATE';

export const QUERY_EXHIBITION_REQUEST = 'app/Exhibition/QUERY_EXHIBITION_REQUEST';
export const QUERY_EXHIBITION_SUCCESS = 'app/Exhibition/QUERY_EXHIBITION_SUCCESS';
export const QUERY_EXHIBITION_ERROR = 'app/Exhibition/QUERY_EXHIBITION_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/Exhibition/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/Exhibition/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/Exhibition/QUERY_LISTINGS_ERROR';

export const LOAD_MORE_REQUEST = 'app/Exhibition/LOAD_MORE_REQUEST';
export const LOAD_MORE_SUCCESS = 'app/Exhibition/LOAD_MORE_SUCCESS';
export const LOAD_MORE_ERROR = 'app/Exhibition/LOAD_MORE_ERROR';

// ================ Reducer ================ //
const initialState = {
  queryExhibitionInProgress: false,
  queryExhibitionError: null,
  currentExhibition: {},
  listingRefs: [],
  queryListingInProgress: false,
  queryParams: null,
  searchParams: null,
  queryListingsError: null,
  pagination: null,
  loadMoreInProgress: false,
  loadMoreError: null,
};

export default function ExhibitionsPostPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_EXHIBITION_REQUEST:
      return { ...state, queryExhibitionInProgress: true, queryExhibitionError: null };
    case QUERY_EXHIBITION_ERROR:
      return { ...state, queryExhibitionInProgress: false, queryExhibitionError: payload };
    case QUERY_EXHIBITION_SUCCESS:
      return { ...state, queryExhibitionInProgress: false, queryExhibitionError: null, ...payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        queryParams: payload.queryParams,
        queryListingsError: null,
        queryListingInProgress: true,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        listingRefs: payload.listingRefs,
        pagination: payload.pagination,
        queryListingInProgress: false,
      };
    case QUERY_LISTINGS_ERROR:
      return { ...state, listingRefs: [], queryListingsError: payload, queryListingInProgress: false };

    case LOAD_MORE_REQUEST:
      return { ...state, queryParams: payload, loadMoreInProgress: true, loadMoreError: null };
    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMoreInProgress: false,
        listingRefs: [...state.listingRefs, ...payload.listingRefs],
        pagination: payload.pagination,
      };
    case LOAD_MORE_ERROR:
      return { ...state, loadMoreInProgress: false, loadMoreError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //
export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryExhibitionRequest = slug => ({
  type: QUERY_EXHIBITION_REQUEST,
  payload: { slug },
});

export const queryExhibitionError = e => ({
  type: QUERY_EXHIBITION_ERROR,
  error: true,
  payload: e,
});

export const queryExhibitionSuccess = exhibition => ({
  type: QUERY_EXHIBITION_SUCCESS,
  payload: { currentExhibition: exhibition.data.attributes },
});

export const queryListingsRequest = (searchParams, queryParams) => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { searchParams, queryParams },
});

export const queryListingsSuccess = ({ listingRefs, pagination }) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs, pagination },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  payload: e,
});

export const loadMoreRequest = queryParams => ({
  type: LOAD_MORE_REQUEST,
  payload: queryParams,
});

export const loadMoreSuccess = ({ listingRefs, pagination }) => ({
  type: LOAD_MORE_SUCCESS,
  payload: { listingRefs, pagination },
});

export const loadMoreError = error => ({ type: LOAD_MORE_ERROR, error: true, payload: error });

// ================ Thunks ================ //
export const queryExhibition = exhibitionSlug => (dispatch, getState, sdk) => {
  const queryParams = {
    populate: {
      heroImg: true,
    },
  };
  dispatch(queryExhibitionRequest(exhibitionSlug));
  return strapi
    .get(`/api/exhibitions/${exhibitionSlug}`, queryParams)
    .then(data => {
      dispatch(queryExhibitionSuccess(data));
    })
    .catch(e => {
      dispatch(queryExhibitionError(storableError(e.response)));
    });
};

export const queryListings = (searchParams) => (dispatch, getState, sdk) => {
  const { currentExhibition = {} } = getState().ExhibitionsPostPage;
  const { listingIds = '' } = currentExhibition;

  const formattedListingIds = listingIds.replace(/\n/g, ',');

  const { perPage, sort, ...rest } = searchParams;

  const queryParams = {
    ...rest,
    ids: formattedListingIds,
    pub_listingType: LISTING_TYPE_ART,
    per_page: perPage || PER_PAGE_ARTWORK_LISTINGS_DEFAULT,
    sort: sort || UNIQUE_PAGE_VIEWS,
    include: ['author', 'images', 'author.profileImage'],
    'limit.images': 1,
    'fields.image': ['variants.scaled-small', 'variants.scaled-medium'],
  };

  dispatch(queryListingsRequest(searchParams, queryParams));

  return sdk.listings
    .query(queryParams)
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess({ listingRefs, pagination: response.data.meta }));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

const canLoadMore = (pagination = {}) => {
  const { page, totalPages } = pagination || {};
  return page < totalPages;
};

const isLoadingMore = state => {
  const { loadMoreInProgress } = state.ExhibitionsPostPage;
  return loadMoreInProgress;
};

export const loadMore = () => (dispatch, getState, sdk) => {
  const { pagination, queryParams } = getState().ExhibitionsPostPage;

  if (isLoadingMore(getState()) || !canLoadMore(pagination)) return;

  const params = {
    ...queryParams,
    page: pagination.page + 1,
  };

  dispatch(loadMoreRequest(params));

  return sdk.listings
    .query(params)
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(loadMoreSuccess({ listingRefs, pagination: response.data.meta }));
    })
    .catch(e => {
      dispatch(loadMoreError(storableError(e)));
    });
};

export const loadData = (params, search) => dispatch => {
  const exhibitionSlug = params.slug;
  const queryParams = parse(search);

  const { page = 1, perPage, ...rest } = queryParams;
  const searchParams = {
    ...rest,
    page,
    perPage,
  };

  dispatch(setInitialState());
  return dispatch(queryExhibition(exhibitionSlug))
    .then(() => {
      return Promise.all([
        dispatch(queryListings(searchParams)),
        dispatch(getCurrentFavoriteListings()),
      ]);
    });
};
