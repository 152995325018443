export const labelsConfig = {
  'Modern Art': 'Abstract',
  'Animal': 'Animal',
  'Beach': 'Beach',
  'Coast': 'Beach',
  'Palm Tree': 'Beach',
  'Shoreline': 'Beach',
  'Bird': 'Bird',
  'Cloud': 'Cloud',
  'Flower': 'Flower',
  'Fruit': 'Fruit',
  'Landscape': 'Landscape',
  'Outdoors': 'Landscape',
  'Scenery': 'Landscape',
  'Pattern': 'Modern',
  'Rug': 'Modern',
  'Mountain': 'Mountain',
  'Plant': 'Nature',
  'Tree': 'Nature',
  'Ocean': 'Ocean',
  'Sea': 'Ocean',
  'Face': 'Portrait',
  'Head': 'Portrait',
  'Human': 'Portrait',
  'Person': 'Portrait',
  'Bowl': 'Still Life',
  'Jug': 'Still Life',
  'Water': 'Water',
  'Ice': 'Winter',
  'Snow': 'Winter'
};
