import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const QUERY_TERMS_REQUEST = 'app/TermsPage/QUERY_TERMS_REQUEST';
export const QUERY_TERMS_SUCCESS = 'app/TermsPage/QUERY_TERMS_SUCCESS';
export const QUERY_TERMS_ERROR = 'app/TermsPage/QUERY_TERMS_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryTermsError: null,
  terms: null,
};

export default function TermsReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_TERMS_REQUEST:
      return { ...state, queryTermsError: null };
    case QUERY_TERMS_SUCCESS:
      return { ...state, terms: payload };
    case QUERY_TERMS_ERROR:
      return { ...state, queryTermsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryTermsRequest = () => ({
  type: QUERY_TERMS_REQUEST,
});

export const queryTermsSuccess = terms => ({
  type: QUERY_TERMS_SUCCESS,
  payload: terms,
});

export const queryTermsError = e => ({
  type: QUERY_TERMS_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(queryTermsRequest());
  return strapi
    .get('/api/term')
    .then(res => dispatch(queryTermsSuccess(res.data.attributes)))
    .catch(e => dispatch(queryTermsError(storableError(e.response))));
};
