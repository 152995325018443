import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const QUERY_COMMERCIAL_ART_CONSULTING_REQUEST =
  'app/CommercialArtConsultingPage/QUERY_COMMERCIAL_ART_CONSULTING_REQUEST';
export const QUERY_COMMERCIAL_ART_CONSULTING_SUCCESS =
  'app/CommercialArtConsultingPage/QUERY_COMMERCIAL_ART_CONSULTING_SUCCESS';
export const QUERY_COMMERCIAL_ART_CONSULTING_ERROR =
  'app/CommercialArtConsultingPage/QUERY_COMMERCIAL_ART_CONSULTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryCommercialArtConsultingError: null,
  commercialArtConsulting: null,
};

export default function CommercialArtConsultingReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_COMMERCIAL_ART_CONSULTING_REQUEST:
      return { ...state, queryCommercialArtConsultingError: null };
    case QUERY_COMMERCIAL_ART_CONSULTING_SUCCESS:
      return { ...state, commercialArtConsulting: payload };
    case QUERY_COMMERCIAL_ART_CONSULTING_ERROR:
      return { ...state, queryCommercialArtConsultingError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryCommercialArtConsultingRequest = () => ({
  type: QUERY_COMMERCIAL_ART_CONSULTING_REQUEST,
});

export const queryCommercialArtConsultingSuccess = commercialArtConsulting => ({
  type: QUERY_COMMERCIAL_ART_CONSULTING_SUCCESS,
  payload: commercialArtConsulting,
});

export const queryCommercialArtConsultingError = e => ({
  type: QUERY_COMMERCIAL_ART_CONSULTING_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(queryCommercialArtConsultingRequest());

  const queryParams = {
    populate: 'heroImage',
  };

  return strapi
    .get('/api/commercial-art-consulting', queryParams)
    .then(res => {
      dispatch(queryCommercialArtConsultingSuccess(res.data.attributes));
    })
    .catch(e => dispatch(queryCommercialArtConsultingError(storableError(e.response))));
};
