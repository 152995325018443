import { sendEnquiryEmailToAdmin } from '../util/ses';

// ================ Action types ================ //

export const SEND_ENQUIRY_REQUEST = 'app/Inquire/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/Inquire/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/Inquire/SEND_ENQUIRY_ERROR';

export const SET_INITIAL_VALUES = 'app/Inquire/SET_INITIAL_VALUES';

// ================ Reducer ================ //

const initialState = {
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  sendEnquirySuccess: false,
};

const inquireReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEND_ENQUIRY_REQUEST:
      return {
        ...state,
        sendEnquiryInProgress: true,
        sendEnquiryError: null,
        sendEnquirySuccess: false,
      };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false, sendEnquirySuccess: true };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case SET_INITIAL_VALUES:
      return { ...initialState };

    default:
      return state;
  }
};

export default inquireReducer;

// ================ Action creators ================ //

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, payload: e });

export const setInitialValues = () => ({ type: SET_INITIAL_VALUES });

// ================ Thunks ================ //

export const resetEnquiryValues = () => dispatch => dispatch(setInitialValues());

export const sendEnquiry = params => (dispatch, getState, sdk) => {
  const { onSendEmail = sendEnquiryEmailToAdmin, ...rest } = params;

  dispatch(sendEnquiryRequest());

  return onSendEmail(rest)
    .then(() => dispatch(sendEnquirySuccess()))
    .catch(error => dispatch(sendEnquiryError(error)));
};
