import { denormalisedResponseEntities } from "../util/data";
import { storableError } from '../util/errors';

// ================ Action types ================ //

export const SHOW_LISTING_REQUEST = 'app/listing/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/listing/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/listing/SHOW_LISTING_ERROR';

export const FETCH_LISTING_REQUEST = 'app/listing/FETCH_LISTING_REQUEST';
export const FETCH_LISTING_SUCCESS = 'app/listing/FETCH_LISTING_SUCCESS';
export const FETCH_LISTING_ERROR = 'app/listing/FETCH_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  showListingError: null,
  listing: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LISTING_REQUEST:
      return { ...state, showListingError: null, listing: null };
    case SHOW_LISTING_SUCCESS:
      return { ...state, listing: payload.data, showListingError: false };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_LISTING_REQUEST:
      return { ...state, updateSingleListingInProgress: true, updateSingleListingError: null };
    case FETCH_LISTING_SUCCESS:
      return { ...state, updateSingleListingInProgress: false, updateSingleListingError: null };
    case FETCH_LISTING_ERROR:
      return { ...state, updateSingleListingInProgress: false, updateSingleListingError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingSuccess = data => ({
  type: SHOW_LISTING_SUCCESS,
  payload: { data },
});
export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchListingsRequest = id => ({
  type: FETCH_LISTING_REQUEST,
  payload: { id },
});
export const fetchListingsSuccess = data => ({
  type: FETCH_LISTING_SUCCESS,
  payload: { data },
});
export const fetchListingsError = e => ({
  type: FETCH_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showListing = params => (dispatch, getState, sdk) => {
  dispatch(showListingRequest());
  return sdk.listings.show(params)
    .then(res => {
      const data = denormalisedResponseEntities(res);
      dispatch(showListingSuccess(data.length > 0 ? data[0] : {}));
      return data[0];
    }).catch(e => {
      dispatch(showListingError(e))
      return null;
    })
};

export const fetchListings = (params = {}) => (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest());
  return sdk.listings
    .query(params)
    .then((res) => {
      const data = denormalisedResponseEntities(res);
      dispatch(fetchListingsSuccess());
      return data;
    })
    .catch(e => {
      dispatch(fetchListingsError(storableError(e)));
      throw e;
    });
};