import isEmpty from 'lodash/isEmpty';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { storableError } from '../util/errors';
import { sendNewUserNotification } from '../util/notifications';
import * as log from '../util/log';
import { logoutIntercomUser } from '../util/intercom';
import { LISTING_TYPE_ARTIST, ACCOUNT_TYPE_ARTIST } from '../util/types';
import config from '../config';
import { parseArtistName } from '../util/parseHelper';
import { createUserWithIdp } from '../util/api';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

const DEFAULT_AVERAGE_NUMBER = -1;
const DEFAULT_VERIFIED_COLLECTORS = 0;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/Auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/Auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/Auth/CONFIRM_ERROR';

export const INIT_ARTIST_REQUEST = 'app/Auth/INIT_ARTIST_REQUEST';
export const INIT_ARTIST_SUCCESS = 'app/Auth/INIT_ARTIST_SUCCESS';
export const INIT_ARTIST_ERROR = 'app/Auth/INIT_ARTIST_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

const applyAsync = (acc, val) => acc.then(val);
const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  authScopes: [],
  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,

  initArtistInProgress: false,
  initArtistError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return { ...state, authInfoLoaded: true, isAuthenticated: authenticated(payload), authScopes: payload.scopes };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true, authScopes: [] };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return { ...state, confirmInProgress: true, loginError: null, confirmError: null };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };

    case INIT_ARTIST_REQUEST:
      return { ...state, initArtistInProgress: true, initArtistError: null };
    case INIT_ARTIST_SUCCESS:
      return { ...state, initArtistInProgress: false };
    case INIT_ARTIST_ERROR:
      return { ...state, initArtistInProgress: false, initArtistError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress, confirmInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress || confirmInProgress;
};

export const isInitArtistInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress, confirmInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress || confirmInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const initArtistRequest = () => ({ type: INIT_ARTIST_REQUEST });
export const initArtistSuccess = () => ({ type: INIT_ARTIST_SUCCESS });
export const initArtistError = error => ({ type: INIT_ARTIST_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => dispatch(loginSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => dispatch(loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());

      // Logout intercom
      logoutIntercomUser(process.env.REACT_APP_INTERCOM_ID);
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};


// We must login the user if signup succeeds since the API doesn't
// do that automatically.

export const createArtistListing = sdk => (listingData) => {
  return sdk.ownListings
    .create(listingData, {})
    .then(response => {
      const listingId = response.data.data.id;
      return { listingId };
    });
};

export const approveArtistListing = async ({ listingId }) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(null);
  }
  await fetch(`${config.serverUrl}/api/listings/approve/${listingId.uuid}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { listingId };
};

export const updateIdArtistListingForUserProfile = sdk => ({ listingId }) => {
  return sdk.currentUser
    .updateProfile({
      publicData: {
        idArtistListing: listingId.uuid
      },
      protectedData: {
        needUpdateArtistProfile: false
      }
    });
};

const getUserType = (accountTypes = [], role) => {
  for (let i = 0; i < accountTypes.length; i++) {
    if (accountTypes[i].key === role) {
      return accountTypes[i].label;
    }
  }
  return null;
};

export const signup = params => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const { email, password, firstName, lastName, role, ...rest } = params;
  const parsedArtistName = parseArtistName(firstName, lastName);

  const userType = getUserType(config.custom.accountType, role);

  const createUserParams = isEmpty(rest)
    ? {
      email, password, firstName, lastName,
      displayName: `${firstName} ${lastName}`,
      publicData: {
        role,
        favorites: [], averageReviews: DEFAULT_AVERAGE_NUMBER,
        verifiedCollectors: DEFAULT_VERIFIED_COLLECTORS
      }
    }
    : {
      email, password, firstName, lastName,
      displayName: `${firstName} ${lastName}`,
      publicData: {
        role,
        favorites: [], averageReviews: DEFAULT_AVERAGE_NUMBER,
        verifiedCollectors: DEFAULT_VERIFIED_COLLECTORS,
      },
      protectedData: {
        ...rest
      }
    };

  if (role === ACCOUNT_TYPE_ARTIST) {
    createUserParams.publicData.parsedArtistName = parsedArtistName;
  }

  return sdk.currentUser
    .create(createUserParams)
    .then((response) => {
      sendNewUserNotification({
        userEmail: email,
        userType,
        userName: `${firstName} ${lastName}`,
        userUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/u/${response.data.data.id.uuid}`
      });
      return dispatch(signupSuccess());
    })
    .then(() => dispatch(login(email, password)))
    .catch(e => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};

export const handleNewUser = () => async (dispatch, getState, sdk) => {
  try {
    if (isInitArtistInProgress(getState())) {
      return {};
    }
    dispatch(initArtistRequest());
    const { currentUser } = getState().user;
    const {
      attributes: {
        email: currentEmail,
        profile: {
          firstName,
          lastName,
          publicData: {
            role,
            idArtistListing
          },
        }
      }
    } = currentUser;

    const userType = getUserType(config.custom.accountType, role);

    const isArtist = role === ACCOUNT_TYPE_ARTIST;
    if (!isArtist) {
      await sendNewUserNotification({
        userEmail: currentEmail,
        userType,
        userName: `${firstName} ${lastName}`,
        userId: currentUser.id.uuid
      });
      return dispatch(initArtistSuccess());
    }

    if (!!idArtistListing) {
      return dispatch(initArtistSuccess());
    }

    await sendNewUserNotification({
      userEmail: currentEmail,
      userType,
      userName: `${firstName} ${lastName}`,
      userUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/artist/${parseArtistName(firstName, lastName)}`
    });

    return dispatch(initArtistSuccess());
  } catch (e) {
    log.error(e, 'update-user-with-idp-failed', e);
    return dispatch(initArtistError(e));
  }
};

export const signupWithIdp = params => (dispatch, getState, sdk) => {
  const { accountType, currentEmail, ...createUserParams } = params;
  const { firstName, lastName } = params;

  const isArtist = accountType === ACCOUNT_TYPE_ARTIST;

  createUserParams.publicData = {
    role: accountType,
    favorites: [],
    averageReviews: DEFAULT_AVERAGE_NUMBER,
    verifiedCollectors: DEFAULT_VERIFIED_COLLECTORS,
    ...(isArtist && { parsedArtistName: parseArtistName(firstName, lastName) })
  };

  createUserParams.protectedData = {
    ...createUserParams.protectedData,
    needUpdateArtistProfile: isArtist,
  }

  dispatch(confirmRequest());
  return createUserWithIdp(createUserParams)
    .then(() => dispatch(fetchCurrentUser({ isFromIDPSignup: true })))
    .then(() => {
      return dispatch(handleNewUser());
    })
    .then(() => {
      return dispatch(confirmSuccess());
    })
    .then(() => {
      return {
        success: true
      };
    })
    .catch(async (e) => {
      log.error(e, 'create-user-with-idp-failed', { params });
      await dispatch(confirmError(storableError(e)));
      return {
        success: false
      };
    });
};
