import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import Form from '../../components/Form/Form';
import FieldKeywordInput from '../../components/FieldKeywordInput/FieldKeywordInput';
import { compose } from 'redux';

import css from './TopbarSearchForm.module.css';
import ListKeyWord from './ListKeyWord';
import debounce from 'lodash/debounce';
import { getSearchText } from '../../util/searchUtil';
const DEBOUNCE_WAIT_TIME = 300;
const KEY_CODE_ARROW_UP = 38;
const KEY_CODE_ARROW_DOWN = 40;
const KEY_CODE_ENTER = 13;
const KEY_CODE_ESC = 27;
const DIRECTION_UP = 'up';
const DIRECTION_DOWN = 'down';
const TOUCH_TAP_RADIUS = 5;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: [],
      inFetching: false,
      hightlightIndex: -1,
      focusInput: false,
      search: '',
      touchStartedFrom: null,
      selectInProgress: false,
      isSwipe: false
    };

    this.prevValue = null;
    this.input = null;
    this.inputRef = React.createRef();
    this.searchBarRef = React.createRef();
  }

  handleFocus = (e) => {
    this.setState({ focusInput: true });
    this.input.onFocus(e);
    this.inputRef.current.removeAttribute('placeholder');
    this.searchBarRef.current.classList.add(css.focusSearchbar);
  };

  changeHighlight = direction => {
    const { result } = this.state;
    this.setState(prevState => {
      const currentIndex = prevState.hightlightIndex;
      let index = currentIndex;

      if (direction === DIRECTION_UP) {
        index = currentIndex === 0 ? 0 : currentIndex - 1;
      }
      else if (direction === DIRECTION_DOWN) {
        index = currentIndex + 1;
      }

      if (index < 0)
        index = -1;
      else if (index >= result.length) {
        index = result.length - 1;
      }
      return { hightlightIndex: index };
    });
  };

  finalize = () => {
    this.setState({
      result: [],
      inFetching: false,
      hightlightIndex: -1,
      focusInput: false,
    });
    this.prevValue = null;
  };

  handleOnBlur = () => {
    const { placeholder, intl } = this.props;
    if (!this.state.inFetching) {
      this.setState({
        inFetching: false,
        hightlightIndex: -1,
        focusInput: false,
      });
      this.prevValue = null;
    }
    this.inputRef.current.setAttribute(
      'placeholder',
      placeholder || intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })
    );
    this.searchBarRef.current.classList.remove(css.focusSearchbar);
  };

  handleInput = input => {
    if (!this.input)
      this.input = input;
  };

  onKeyDown = form => (e) => {
    const keyCode = e.keyCode;
    if (keyCode === KEY_CODE_ARROW_UP) {
      e.preventDefault();
      this.changeHighlight(DIRECTION_UP);
    } else if (keyCode === KEY_CODE_ARROW_DOWN) {
      e.preventDefault();
      this.changeHighlight(DIRECTION_DOWN);
    } else if (keyCode === KEY_CODE_ENTER) {
      document.getElementById('search').blur();
      e.preventDefault();
      const searchItem =
        this.state.result.length && this.state.hightlightIndex >= 0
          ? this.state.result[this.state.hightlightIndex]
          : null;

      const text = searchItem ? getSearchText(searchItem) : form.getState().values['search'];
      form.change('search', text);

      if (searchItem) {
        this.props.handleSelectSearchItem({ search: text, searchItem });
      } else {
        this.props.onSubmit({ search: text });
      }

      this.finalize();
    } else if (e.keyCode === KEY_CODE_ESC) {
      this.finalize();
    }
  };

  handleSearch = debounce(formState => {
    const { values, dirty } = formState;
    if (!dirty)
      return;
    if (typeof window === 'undefined')
      return;
    const { search } = values;
    if (!search || search.length < 2) {
      this.prevValue = search;
      this.setState({ result: [], hightlightIndex: -1 });
      return;
    }
    if (this.state.inFetching)
      return;

    if (this.prevValue !== search) {
      this.prevValue = search;
      this.setState({ inFetching: true, search });
      return this.props.onSearchByKeyword(search)
        .then(result => this.setState({ result, inFetching: false }));
    }
  }, DEBOUNCE_WAIT_TIME, { leading: false, trailing: true });

  handleSelectStart = coordinate => {
    this.setState({
      selectInProgress: true,
      touchStartedFrom: coordinate,
      isSwipe: false
    });
  };

  handleSelectMove = coordinate => {
    this.setState(prevState => {
      const touchStartedFrom = prevState.touchStartedFrom;
      const isTouchAction = !!touchStartedFrom;
      const isSwipe = isTouchAction
        ? Math.abs(touchStartedFrom.y - coordinate.y) > TOUCH_TAP_RADIUS
        : false;
      return { selectInProgress: true, isSwipe };
    });
  };

  handleSelectEnd = form => (text, searchItem) => {
    let selectAndFinalize = false;
    this.setState(prevState => {
      if (!prevState.isSwipe) {
        selectAndFinalize = true;
      }
      return { selectInProgress: false, touchStartedFrom: null, isSwipe: false };
    }, () => {
      if (selectAndFinalize) {
        form.change('search', text);
        this.props.handleSelectSearchItem({ search: text, searchItem });
        this.finalize();
      }
    });
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={(values) => {
          this.props.onSubmit({ search: values.search });
        }}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            form,
            placeholder,
            values,
          } = formRenderProps;
          const classes = classNames(rootClassName || css.root, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          return (
            <Form
              className={classes}
              onSubmit={e => e.preventDefault()}
              enforcePagePreloadFor="SearchPage"
            >
              <div
                onClick={() => {
                  this.inputRef.current.focus();
                }}
              >
                <FieldKeywordInput
                  name="search"
                  id="search"
                  autoComplete="off"
                  className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                  iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                  inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                  placeholder={
                    placeholder || intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })
                  }
                  type="text"
                  onKeyDown={this.onKeyDown(form)}
                  handleInput={this.handleInput}
                  onFocus={this.handleFocus}
                  onBlur={this.handleOnBlur}
                  inputRef={this.inputRef}
                  searchBarRef={this.searchBarRef}
                />
              </div>
              {this.state.result.length > 0 && this.state.focusInput && values.search ? (
                <ListKeyWord
                  className={css.result}
                  result={this.state.result}
                  currentIndex={this.state.hightlightIndex}
                  onSelectStart={this.handleSelectStart}
                  onSelectMove={this.handleSelectMove}
                  onSelectEnd={this.handleSelectEnd(form)}
                  currentSearchValue={values.search}
                />
              ) : null}

              <FormSpy onChange={this.handleSearch} subscription={{ values: true, dirty: true }} />
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  onSearchByKeyword: func.isRequired,
  handleSelectSearchItem: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(TopbarSearchFormComponent);
