import strapi from '../../util/strapi';
const PAGE_SIZE = 24;

// ================ Action types ================ //
export const SET_INITIAL_STATE = 'app/ArticleNavigation/SET_INITIAL_STATE';

export const QUERY_ARTICLES_REQUEST = 'app/ArticleNavigation/QUERY_ARTICLES_REQUEST';
export const QUERY_ARTICLES_SUCCESS = 'app/ArticleNavigation/QUERY_ARTICLES_SUCCESS';
export const QUERY_ARTICLES_ERROR = 'app/ArticleNavigation/QUERY_ARTICLES_ERROR';

export const LOAD_MORE_REQUEST = 'app/ArticleNavigation/LOAD_MORE_REQUEST';
export const LOAD_MORE_SUCCESS = 'app/ArticleNavigation/LOAD_MORE_SUCCESS';
export const LOAD_MORE_ERROR = 'app/ArticleNavigation/LOAD_MORE_ERROR';

// ================ Reducer ================ //

const initialState = {
  articles: [],
  pagination: null,
  params: null,
  loadInProcess: false,
  loadError: null,
  loadMoreInProgress: false,
  loadMoreError: null,
};

export default function ArticleNavigationPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_ARTICLES_REQUEST:
      return { ...state, loadInProcess: true, params: payload, loadError: null };
    case QUERY_ARTICLES_SUCCESS:
      return {
        ...state,
        loadInProcess: false,
        articles: payload.articles,
        pagination: payload.pagination,
      };
    case QUERY_ARTICLES_ERROR:
      return {
        ...state,
        loadInProcess: false,
        articles: [],
        pagination: null,
        loadError: payload,
      };

    case LOAD_MORE_REQUEST:
      return {
        ...state,
        loadMoreInProgress: true,
        params: payload,
        loadMoreError: null,
      };
    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMoreInProgress: false,
        articles: [...state.articles, ...payload.articles],
        pagination: payload.pagination,
      };
    case LOAD_MORE_ERROR:
      return {
        ...state,
        loadMoreInProgress: false,
        loadMoreError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryArticlesRequest = params => ({
  type: QUERY_ARTICLES_REQUEST,
  payload: params,
});

export const queryArticlesSuccess = ({ articles, pagination }) => ({
  type: QUERY_ARTICLES_SUCCESS,
  payload: { articles, pagination },
});

export const queryArticlesError = error => ({
  type: QUERY_ARTICLES_ERROR,
  payload: error,
});

export const loadMoreRequest = params => ({
  type: LOAD_MORE_REQUEST,
  payload: params,
});

export const loadMoreSuccess = ({ articles, pagination }) => ({
  type: LOAD_MORE_SUCCESS,
  payload: { articles, pagination },
});

export const loadMoreError = error => ({ type: LOAD_MORE_ERROR, error: true, payload: error });

// ================ Thunks ================ //
const canLoadMore = (pagination = {}) => {
  const { page, pageCount } = pagination || {};
  return page < pageCount;
};

const isLoadingMore = state => {
  const { loadMoreInProgress } = state.ArticleNavigationPage;
  return loadMoreInProgress;
};

export const loadMore = () => (dispatch, getState) => {
  const { pagination, params } = getState().ArticleNavigationPage;
  if (isLoadingMore(getState()) || !canLoadMore(pagination)) return;

  const newParams = {
    ...params,
    pagination: {
      page: pagination.page + 1,
      pageSize: PAGE_SIZE,
    },
  };

  dispatch(loadMoreRequest(newParams));

  return strapi
    .get('/api/articles', { ...newParams })
    .then(res => {
      dispatch(loadMoreSuccess({ articles: res.data, pagination: res.meta.pagination }));
    })
    .catch(error => dispatch(loadMoreError(error)));
};

export const loadData = () => dispatch => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  const params = {
    sort: ['publishDate:desc'],
    pagination: {
      page: 1,
      pageSize: PAGE_SIZE,
    },
    populate: 'heroImage',
  };

  dispatch(queryArticlesRequest(params));

  return strapi
    .get('/api/articles', { ...params })
    .then(res => {
      dispatch(
        queryArticlesSuccess({ articles: res.data, pagination: res.meta.pagination })
      );
    })
    .catch(error => dispatch(queryArticlesError(error)));
};
