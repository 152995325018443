/**
 * Export loadData calls from ducks modules of different containers
 */
import { loadData as GalleryPageLoader } from './GalleryPage/GalleryPage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as CollectionPageLoader } from './CollectionPage/CollectionPage.duck';
import { loadData as ManageFavoriteListingsPageLoader } from './ManageFavoriteListingsPage/ManageFavoriteListingsPage.duck';
import { loadData as ManageFavoriteArtistsPageLoader } from './ManageFavoriteArtistsPage/ManageFavoriteArtistsPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as ExhibitionsNavigationPageLoader } from './ExhibitionsNavigationPage/ExhibitionsNavigationPage.duck';
import { loadData as ExhibitionsPostPageLoader } from './ExhibitionsPostPage/ExhibitionsPostPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as FeaturedArtistPageLoader } from './FeaturedArtistPage/FeaturedArtistPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as ArtAdvisoryPageLoader } from './ArtAdvisoryPage/ArtAdvisoryPage.duck';
import { loadData as TradeProgramPageLoader } from './TradeProgramPage/TradeProgramPage.duck';
import { loadData as CommercialArtConsultingPageLoader } from './CommercialArtConsultingPage/CommercialArtConsultingPage.duck';
import { loadData as CareersPageLoader } from './CareersPage/CareersPage.duck';
import { loadData as ContactUsPageLoader } from './ContactUsPage/ContactUsPage.duck';
import { loadData as VisitGalleryPageLoader } from './VisitGalleryPage/VisitGalleryPage.duck';
import { loadData as AboutPageLoader } from './AboutPage/AboutPage.duck';
import { loadData as MyArtPageLoader } from './MyArtPage/MyArtPage.duck';
import { loadData as FavoritePageLoader } from './FavoritePage/FavoritePage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as ArticlePageLoader } from './ArticlePage/ArticlePage.duck';
import { loadData as ArticleNavigationPageLoader } from './ArticleNavigationPage/ArticleNavigationPage.duck';
import { loadData as FaqPageLoader } from './FaqPage/FaqPage.duck';
import { loadData as TermsPageLoader } from './TermsPage/TermsPage.duck';
import { loadData as CopyrightPageLoader } from './CopyrightPage/CopyrightPage.duck';
import { loadData as PrivacyPageLoader } from './PrivacyPage/PrivacyPage.duck';
import { loadData as ArtistApplicationPageLoader } from './ArtistApplicationPage/ArtistApplicationPage.duck';
import { loadData as MessagesPageLoader } from './MessagesPage/MessagesPage.duck';
import { loadData as SingleMessagePageLoader } from './SingleMessagePage/SingleMessagePage.duck';
import { loadData as NewGalleryPageLoader } from './NewGalleryPage/NewGalleryPage.duck';
import { loadData as PressPageLoader } from './PressPage/PressPage.duck';
import { loadData as ProjectPageLoader } from './ProjectPage/ProjectPage.duck';
import { loadData as ProjectPostPageLoader } from './ProjectPostPage/ProjectPostPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    GalleryPage: {
      loadData: GalleryPageLoader,
    },
    ArticlePage: {
      loadData: ArticlePageLoader,
    },
    ArticleNavigationPage: {
      loadData: ArticleNavigationPageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    CollectionPage: {
      loadData: CollectionPageLoader,
    },
    ManageFavoriteListingsPage: {
      loadData: ManageFavoriteListingsPageLoader,
    },
    ManageFavoriteArtistsPage: {
      loadData: ManageFavoriteArtistsPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    ExhibitionsNavigationPage: {
      loadData: ExhibitionsNavigationPageLoader,
    },
    ExhibitionsPostPage: {
      loadData: ExhibitionsPostPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    FavoritePage: {
      loadData: FavoritePageLoader,
    },
    FeaturedArtistPage: {
      loadData: FeaturedArtistPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    ArtAdvisoryPage: {
      loadData: ArtAdvisoryPageLoader,
    },
    TradeProgramPage: {
      loadData: TradeProgramPageLoader,
    },
    CommercialArtConsultingPage: {
      loadData: CommercialArtConsultingPageLoader,
    },
    CareersPage: {
      loadData: CareersPageLoader,
    },
    ContactUsPage: {
      loadData: ContactUsPageLoader,
    },
    VisitGalleryPage: {
      loadData: VisitGalleryPageLoader,
    },
    AboutPage: {
      loadData: AboutPageLoader,
    },
    MyArtPage: {
      loadData: MyArtPageLoader,
    },
    FaqPage: {
      loadData: FaqPageLoader,
    },
    TermsPage: {
      loadData: TermsPageLoader,
    },
    CopyrightPage: {
      loadData: CopyrightPageLoader,
    },
    PrivacyPage: {
      loadData: PrivacyPageLoader,
    },
    ArtistApplicationPage: {
      loadData: ArtistApplicationPageLoader,
    },
    MessagesPage: {
      loadData: MessagesPageLoader,
    },
    SingleMessagePage: {
      loadData: SingleMessagePageLoader,
    },
    NewGalleryPage: {
      loadData: NewGalleryPageLoader,
    },
    PressPage: {
      loadData: PressPageLoader,
    },
    ProjectPage: {
      loadData: ProjectPageLoader,
    },
    ProjectPostPage: {
      loadData: ProjectPostPageLoader,
    },
  };
};

export default getPageDataLoadingAPI;
