/* eslint-disable max-len */
/*
 * Marketplace specific configuration.
 */
import { types as sdkTypes } from './util/sdkLoader';
import { LISTING_TYPE_ARTIST_COLLECTION } from './util/types';
const { LatLng } = sdkTypes;

export const EXHIBITION_TAG_PAST = 'past';
export const EXHIBITION_TAG_ON_VIEW = 'onView';
export const EXHIBITION_TAG_UPCOMING = 'upcoming';

export const collections = [
  { key: 'collection_1', label: 'Biophilia' },
  { key: 'BLM', label: 'Black Lives Matter Benefit' },
  { key: 'soothe', label: 'Soothe' },
  { key: 'HL9', label: 'HL9 Artist Residency' },
  { key: 'Still', label: 'Still' },
  { key: 'Birds', label: '#birdsforsofia' },
  { key: 'sound', label: 'Sound and Color' },
  { key: 'plein', label: 'Plein Air' },
  { key: 'amer', label: '-American' },
  { key: 'exit', label: 'Exit Point' },
  { key: 'compendium', label: 'Timothy Wilson | Compendium' },
  { key: 'mori', label: 'Memento Mori' },
  { key: 'skin', label: 'Give Me Some Skin' },
  { key: 'zoey', label: 'Zoey Frank | Summer Paintings' },
  { key: 'PPN', label: 'Post Painterly Now' },
  { key: 'Flora', label: 'Flora Nova' },
  { key: 'blue', label: 'Blue Review Art Prize' },
  { key: 'infinite', label: 'Infinite Thread' },
];

export const amenities = [
  {
    key: 'towels',
    label: 'Towels',
  },
  {
    key: 'bathroom',
    label: 'Bathroom',
  },
  {
    key: 'swimming_pool',
    label: 'Swimming pool',
  },
  {
    key: 'own_drinks',
    label: 'Own drinks allowed',
  },
  {
    key: 'jacuzzi',
    label: 'Jacuzzi',
  },
  {
    key: 'audiovisual_entertainment',
    label: 'Audiovisual entertainment',
  },
  {
    key: 'barbeque',
    label: 'Barbeque',
  },
  {
    key: 'own_food_allowed',
    label: 'Own food allowed',
  },
];

export const yesNo = [
  { key: 'yes', label: 'Yes', labelId: 'EditListingDescriptionForm.yes' },
  { key: 'no', label: 'No', labelId: 'EditListingDescriptionForm.no' },
];

export const styleOptions = [
  // {
  //   key: 'painterlyAbstraction',
  //   label: 'Painterly Abstraction',
  //   labelId: 'StyleOptions.painterlyAbstraction',
  // },
  // {
  //   key: 'geometricAbstraction',
  //   label: 'Geometric Abstraction',
  //   labelId: 'StyleOptions.geometricAbstraction',
  // },
  // {
  //   key: 'colorFieldPainting',
  //   label: 'Color Field Painting',
  //   labelId: 'StyleOptions.colorFieldPainting',
  // },
  // { key: 'Expressionist', label: 'Contemporary', labelId: 'StyleOptions.expressionist' },
  // { key: 'Impressionist', label: 'Impressionist', labelId: 'StyleOptions.impressionist' },
  // { key: 'academicRealism', label: 'Academic Realism', labelId: 'StyleOptions.academicRealism' },

  { key: 'abstract', label: 'Abstract', labelId: 'StyleOptions.abstract' },
  { key: 'geometric', label: 'Geometric', labelId: 'StyleOptions.geometric' },
  { key: 'colorful', label: 'Colorful', labelId: 'StyleOptions.colorful' },
  { key: 'line', label: 'Line', labelId: 'StyleOptions.line' },
  { key: 'shape', label: 'Shape', labelId: 'StyleOptions.shape' },
  { key: 'gestural', label: 'Gestural', labelId: 'StyleOptions.gestural' },
  { key: 'minimal', label: 'Minimal', labelId: 'StyleOptions.minimal' },
  { key: 'textured', label: 'Textured', labelId: 'StyleOptions.textured' },
  { key: 'painterly', label: 'Painterly', labelId: 'StyleOptions.painterly' },
  { key: 'bright', label: 'Bright', labelId: 'StyleOptions.bright' },
  { key: 'gradient', label: 'Gradient', labelId: 'StyleOptions.gradient' },
  {
    key: 'abstractLandscape',
    label: 'Abstract Landscape',
    labelId: 'StyleOptions.abstractLandscape',
  },
  { key: 'bold', label: 'Bold', labelId: 'StyleOptions.bold' },
  { key: 'expressionist', label: 'Expressionist', labelId: 'StyleOptions.expressionist' },
  { key: 'drip', label: 'Drip', labelId: 'StyleOptions.drip' },
  { key: 'colorfield', label: 'Colorfield', labelId: 'StyleOptions.colorfield' },
  { key: 'pattern', label: 'Pattern', labelId: 'StyleOptions.pattern' },
  { key: 'moody', label: 'Moody', labelId: 'StyleOptions.moody' },
  { key: 'monochrome', label: 'Monochrome', labelId: 'StyleOptions.monochrome' },
  { key: 'blackAndWhite', label: 'Black and white', labelId: 'StyleOptions.blackAndWhite' },
  {
    key: 'representational',
    label: 'Representational',
    labelId: 'StyleOptions.representational',
  },
];

export const subjectOptions = [
  { key: 'Abstract', label: 'Abstract', labelId: 'EditListingFeaturesForm.abstractExpressionism' },
  { key: 'Landscape', label: 'Landscape', labelId: 'EditListingFeaturesForm.landscape' },
  // { key: 'Fine Art', label: 'Fine Art', labelId: 'EditListingFeaturesForm.fineArt' },
  { key: 'Modern', label: 'Modern', labelId: 'EditListingFeaturesForm.modern' },
  { key: 'Beach', label: 'Beach', labelId: 'EditListingFeaturesForm.beach' },
  // { key: 'Impressionist', label: 'Impressionist', labelId: 'EditListingFeaturesForm.impressionist' },
  { key: 'Ocean', label: 'Ocean', labelId: 'EditListingFeaturesForm.ocean' },
  { key: 'Water', label: 'Water', labelId: 'EditListingFeaturesForm.water' },
  { key: 'Nature', label: 'Nature', labelId: 'EditListingFeaturesForm.nature' },
  { key: 'Mountain', label: 'Mountains', labelId: 'EditListingFeaturesForm.mountains' },
  { key: 'Portrait', label: 'Portraits', labelId: 'EditListingFeaturesForm.portraits' },
  // { key: 'Plein Air', label: 'Plein Air', labelId: 'EditListingFeaturesForm.pleinAir' },
  { key: 'Cloud', label: 'Clouds', labelId: 'EditListingFeaturesForm.clouds' },
  { key: 'Flower', label: 'Flowers', labelId: 'EditListingFeaturesForm.flowers' },
  { key: 'Winter', label: 'Winter scenes', labelId: 'EditListingFeaturesForm.winterScenes' },
  { key: 'Bird', label: 'Birds', labelId: 'EditListingFeaturesForm.birds' },
  { key: 'Still Life', label: 'Still life', labelId: 'EditListingFeaturesForm.stillLife' },
  // { key: 'Colorful Abstract', label: 'Colorful abstract', labelId: 'EditListingFeaturesForm.colorfulAbstract' },
  // { key: 'Skull', label: 'Skulls', labelId: 'EditListingFeaturesForm.skulls' },
  // { key: 'Nude', label: 'Nudes', labelId: 'EditListingFeaturesForm.nudes' },
  // { key: 'Black and White Abstract', label: 'Black and white abstract', labelId: 'EditListingFeaturesForm.BW' },
  // { key: 'Abstract Landscape', label: 'Abstract landscapes', labelId: 'EditListingFeaturesForm.abstractLandscape' },
  { key: 'Animal', label: 'Animals', labelId: 'EditListingFeaturesForm.animal' },
  { key: 'Fruit', label: 'Fruit', labelId: 'EditListingFeaturesForm.fruit' },
];

// In places where related categories are displayed like Collection Page
// or Profile Page, when no related categories are found, we will display
// the following 4 default labels.
export const labelsRelatedCategories = [
  'Abstract',
  'Abstract Landscape',
  'Modern',
  'Impressionist',
];

export const categories = [
  {
    key: 'oil_painting',
    label: 'Oil',
    labelId: 'EditListingDescriptionForm.oilPainting',
    hideInCreateListing: true,
  },
  {
    key: 'acrylic_painting',
    label: 'Acrylic',
    labelId: 'EditListingDescriptionForm.acrylicPainting',
    hideInCreateListing: true,
  },
  {
    key: 'watercolor_painting',
    label: 'Watercolor',
    labelId: 'EditListingDescriptionForm.watercolorPainting',
    hideInCreateListing: true,
  },
  {
    key: 'painting',
    label: 'Painting',
    labelId: 'EditListingDescriptionForm.painting',
    hideInFilter: true,
  },
  {
    key: 'drawing',
    label: 'Works on Paper',
    labelId: 'EditListingDescriptionForm.drawing',
    hideInFilter: true,
  },
  {
    key: 'sculpture',
    label: 'Sculpture',
    labelId: 'EditListingDescriptionForm.sculpture',
    hideInFilter: true,
  },
];

export const types = [
  {
    key: 'painting',
    label: 'Painting',
    labelId: 'EditListingDescriptionForm.painting',
    hideInFilter: false,
  },
  {
    key: 'drawing',
    label: 'Drawing',
    labelId: 'EditListingDescriptionForm.drawing',
    hideInFilter: false,
  },
  {
    key: 'sculpture',
    label: 'Sculpture',
    labelId: 'EditListingDescriptionForm.sculpture',
    hideInFilter: false,
  },
];

export const material = [
  { key: 'on_canvas', label: 'Canvas', labelId: 'MaterialFilter.onCanvas' },
  { key: 'on_panel', label: 'Panel', labelId: 'MaterialFilter.onPanel' },
  { key: 'on_paper', label: 'Paper', labelId: 'MaterialFilter.onPaper' },
];

export const priceOptions = [
  { key: '0-500', label: '$0 to $500', labelId: 'PriceFilter.0to500' },
  { key: '500-1500', label: '$500 to $1,500', labelId: 'PriceFilter.500to1500' },
  { key: '1500-5000', label: '$1,500 to $5,000', labelId: 'PriceFilter.1500to5000' },
  { key: '5000-10000', label: '$5,000 to $10,000', labelId: 'PriceFilter.5000to10000' },
  { key: '10000+', label: '$10,000+', labelId: 'PriceFilter.over10000' },
];

export const sizeOptions = [
  { key: 'extraSmall', label: 'Mini (less than 10”)', labelId: 'SizeFilter.extraSmall' },
  { key: 'small', label: 'Small (10” to 20”)', labelId: 'SizeFilter.small' },
  { key: 'medium', label: 'Medium (20” to 40”)', labelId: 'SizeFilter.medium' },
  { key: 'large', label: 'Large (40” to 60”)', labelId: 'SizeFilter.large' },
  { key: 'extraLarge', label: 'Extra Large (over 60”)', labelId: 'SizeFilter.extraLarge' },
];

export const displayPrice = [
  {
    key: 'do_not_show_price',
    label: 'Do not display price on website -- “Enquire for price',
    labelId: 'EditListingPricingForm.showPrice',
  },
];

export const deliveryOptions = [
  { key: 'pick_up', label: 'Free local pickup', labelId: 'ListingPage.pickUp' },
  {
    key: 'delivery_install',
    label: 'Sugarlift in-home delivery and installation',
    labelId: 'ListingPage.install',
  },
  { key: 'shipping', label: 'Shipping (USA and Canada)', labelId: 'ListingPage.shipping' },
];
export const deliveryShowOptions = [
  { key: 'pick_up', label: 'Free local pickup', labelId: 'ListingPage.pickUp' },
  { key: 'domestic_shipping', label: 'Domestic Shipping', labelId: 'ListingPage.domesticShipping' },
  {
    key: 'international_shipping',
    label: 'International Shipping',
    labelId: 'ListingPage.internationalShipping',
  },
];

export const AVAILABLE = 'available';
export const ON_HOLD = 'onHold';
export const SOLD = 'sold';
export const ON_CONSIGNMENT = 'onConsignment';

export const AVAILABLE_PRIORITY = 4;
export const ON_HOLD_PRIORITY = 3;
export const SOLD_PRIORITY = 2;
export const ON_CONSIGNMENT_PRIORITY = 1;

export const availability = [
  { key: AVAILABLE, label: 'Available', labelId: 'EditListingAvailabilityForm.available' },
  { key: ON_HOLD, label: 'On Hold', labelId: 'EditListingAvailabilityForm.onHold' },
  {
    key: ON_CONSIGNMENT,
    label: 'Consigned with another gallery',
    labelId: 'EditListingAvailabilityForm.consignedWithAnotherGallery',
    hideInCreateListing: true,
  },
  { key: SOLD, label: 'Sold', labelId: 'EditListingAvailabilityForm.sold' },
];

export const FREE_SHIPPING_PRICE = 0;
export const FREE_SHIPPING = 'freeShipping';
export const CHARGE_FOR_SHIPPING = 'chargeForShipping';

export const shippingPreference = [
  {
    key: FREE_SHIPPING,
    label: 'Free shipping',
    labelId: 'EditListingPriceAndShippingForm.freeShipping',
  },
  {
    key: CHARGE_FOR_SHIPPING,
    label: 'Charge for shipping',
    labelId: 'EditListingPriceAndShippingForm.chargeForShipping',
  },
];

export const BUYER_PAYS_RETURN_SHIPPING = 'buyerPaysReturnShipping';
export const ARTIST_PAYS_RETURN_SHIPPING = 'artistPaysReturnShipping';
export const RETURN_NOT_ACCEPTED = 'returnNotAccepted';

export const returnPreference = [
  {
    key: BUYER_PAYS_RETURN_SHIPPING,
    label: 'Returns accepted (buyer pays return shipping)',
    labelId: 'EditListingPriceAndShippingForm.buyerPaysReturnShipping',
  },
  {
    key: ARTIST_PAYS_RETURN_SHIPPING,
    label: 'Returns accepted (artist pays return shipping)',
    labelId: 'EditListingPriceAndShippingForm.artistPaysReturnShipping',
  },
  {
    key: RETURN_NOT_ACCEPTED,
    label: 'Returns not accepted',
    labelId: 'EditListingPriceAndShippingForm.returnNotAccepted',
  },
];

export const deliveryOfferOptions = [
  { key: 'pick_up', label: 'Free local pickup', labelId: 'ListingPage.pickUp' },
  { key: 'domestic_shipping', label: 'Domestic Shipping', labelId: 'ListingPage.shipping' },
  {
    key: 'international_shipping',
    label: 'International Shipping',
    labelId: 'ListingPage.install',
  },
];

export const conditionOption = {
  key: 'yes',
  label: 'Yes',
  labelId: 'CommonConfig.yes',
};

export const framedConditionOption = {
  key: 'yes',
  label: 'Yes',
  labelId: 'FramedConfig.yes',
};

export const signedConditionOption = {
  key: 'signed',
  label: 'Yes',
  labelId: 'SignedConfig.yes',
};

export const frameOptions = [
  {
    key: 'true',
    label: 'Framed',
    labelId: 'FrameFilter.framed',
  },
  {
    key: 'false',
    label: 'Unframed',
    labelId: 'FrameFilter.unframed',
  },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 10000,
  step: 100,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: false,
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const sizeFilterConfig = {
  min: 0,
  max: 96,
  step: 1,
};

export const accountType = [
  { key: 'artist', label: 'Artist', labelId: 'SignupForm.artist' },
  { key: 'buyer', label: 'Art Buyer', labelId: 'SignupForm.buyer' },
  { key: 'designer', label: 'Interior Designer / Art Advisor', labelId: 'SignupForm.designer' },
];

export const paymentMethod = [
  { key: 'paypal', label: 'Paypal', labelId: 'ContactDetailsForm.paypal' },
  { key: 'venmo', label: 'Venmo', labelId: 'ContactDetailsForm.venmo' },
  { key: 'other', label: 'Other', labelId: 'ContactDetailsForm.other' },
];

export const featuredFilterOptions = [
  { key: true, label: 'Show Featured Art' },
  { key: false, label: 'Hide Featured Art' },
];

export const soldFilterOptions = [
  { key: 'available,sold', label: 'Show sold' },
  { key: 'available', label: 'Hide sold' },
];

export const featuredArtistOptions = [
  { key: true, label: 'Show Featured Artist' },
  { key: false, label: 'Hide Featured Artist' },
];

export const orientationOptions = [
  { key: 'horizontal', label: 'Horizontal', labelId: 'OrientationFilter.horizontal' },
  { key: 'vertical', label: 'Vertical', labelId: 'OrientationFilter.vertical' },
  { key: 'square', label: 'Square', labelId: 'OrientationFilter.square' },
];

export const availabilityOptions = [
  { key: AVAILABLE, label: 'Available', labelId: 'AvailabilityFilter.available' },
  { key: ON_HOLD, label: 'On Hold', labelId: 'AvailabilityFilter.onHold' },
  {
    key: ON_CONSIGNMENT,
    label: 'Consigned with another gallery',
    labelId: 'AvailabilityFilter.consignedWithAnotherGallery',
  },
  { key: SOLD, label: 'Sold', labelId: 'AvailabilityFilter.sold' },
];

export const favoriteOptions = [
  {
    key: process.env.REACT_APP_WRIGHT_USER_ID,
    label: "Wright's favorites",
    labelId: 'FavoriteFilter.wright',
  },
  {
    key: process.env.REACT_APP_HANNAH_USER_ID,
    label: "Hannah's favorites",
    labelId: 'FavoriteFilter.hannah',
  },
  {
    key: process.env.REACT_APP_SYLVIE_USER_ID,
    label: "Sylvie's favorites",
    labelId: 'FavoriteFilter.sylvie',
  },
];

export const colorOptions = [
  {
    key: 'red',
    colorCode: '#ea4335',
    labelId: 'ListingPage.red',
  },
  {
    key: 'orange',
    colorCode: '#ee9f2d',
    labelId: 'ListingPage.orange',
  },
  {
    key: 'yellow',
    colorCode: '#f8e71c',
    labelId: 'ListingPage.yellow',
  },
  {
    key: 'green',
    colorCode: '#34a853',
    labelId: 'ListingPage.green',
  },
  {
    key: 'blue',
    colorCode: '#2557d6',
    labelId: 'ListingPage.blue',
  },
  {
    key: 'purple',
    colorCode: '#a020f0',
    labelId: 'ListingPage.purple',
  },
  {
    key: 'black',
    colorCode: '#000000',
    labelId: 'ListingPage.black',
  },
  {
    key: 'silver',
    colorCode: '#c0c0c0',
    labelId: 'ListingPage.silver',
  },
  {
    key: 'white',
    colorCode: '#ffffff',
    labelId: 'ListingPage.white',
  },
];

export const filterConfig = {
  categoryFilter: {
    name: 'category',
    paramName: 'pub_category',
    isOpen: true,
    isMobileOpen: false,
    type: 'selectMultipleFilter',
    options: categories,
    isRouteParam: true,
    mappingParameterValues: {
      oil_painting: 'oil',
      acrylic_painting: 'acrylic',
      watercolor_painting: 'watercolor',
    },
  },
  typeFilter: {
    name: 'type',
    paramName: 'pub_type',
    isOpen: true,
    isMobileOpen: false,
    type: 'selectMultipleFilter',
    options: types,
    isRouteParam: true,
  },
  // materialFilter: {
  //   name: 'material',
  //   paramName: 'pub_material',
  //   isOpen: true,
  //   isMobileOpen: false,
  //   type: 'selectMultipleFilter',
  //   options: material,
  //   isRouteParam: true,
  //   mappingParameterValues: {
  //     'on_canvas': 'on-canvas',
  //     'on_panel': 'on-panel',
  //     'on_paper': 'on-paper',
  //   }
  // },
  styleFilter: {
    name: 'style',
    paramName: 'pub_styles',
    isOpen: true,
    isMobileOpen: false,
    type: 'selectMultipleFilter',
    options: styleOptions,
  },
  //if you want to uncomment the subject filter, you need to uncomment code in function checkHideInSitemapSearchPage in Page.js
  // subjectFilter: {
  //   name: 'subject',
  //   paramName: 'pub_labels',
  //   isOpen: true,
  //   isMobileOpen: false,
  //   type: 'selectMultipleFilter',
  //   options: subjectOptions,
  //   isRouteParam: true,
  //   mappingParameterValues: {
  //     'Abstract': 'abstract',
  //     'Landscape': 'landscape',
  //     'Modern': 'modern',
  //     'Beach': 'beach',
  //     'Ocean': 'ocean',
  //     'Water': 'water',
  //     'Nature': 'nature',
  //     'Mountain': 'mountain',
  //     'Portrait': 'portrait',
  //     'Cloud': 'cloud',
  //     'Flower': 'flower',
  //     'Winter': 'winter',
  //     'Bird': 'bird',
  //     'Still Life': 'still-life',
  //     'Animal': 'animal',
  //     'Fruit': 'fruit',
  //   }
  // },
  priceRangeFilter: {
    name: 'priceRange',
    paramName: 'pub_priceRange',
    isOpen: true,
    isMobileOpen: false,
    type: 'selectMultipleFilter',
    options: priceOptions,
    isRouteParam: true,
    mappingParameterValues: {
      '0-500': 'under-500',
      '500-1500': '500-1500',
      '1500-5000': '1500-5000',
      '5000-10000': '5000-10000',
      '10000+': 'above-10000',
    },
  },
  sizeFilter: {
    name: 'size',
    paramName: 'pub_size',
    isOpen: true,
    isMobileOpen: false,
    type: 'selectMultipleFilter',
    options: sizeOptions,
    subLabel: true,
    isRouteParam: true,
    mappingParameterValues: {
      extraSmall: 'miniature',
      small: 'small',
      medium: 'medium',
      large: 'large',
      extraLarge: 'extra-large',
    },
  },
  orientationFilter: {
    name: 'orientation',
    paramName: 'pub_orientation',
    isOpen: true,
    isMobileOpen: false,
    type: 'selectMultipleFilter',
    options: orientationOptions,
    isRouteParam: true,
  },
  frameFilter: {
    name: 'frame',
    paramName: 'pub_framed',
    isOpen: true,
    isMobileOpen: false,
    type: 'selectMultipleFilter',
    options: frameOptions,
    isRouteParam: true,
    mappingParameterValues: {
      true: 'framed',
      false: 'unframed',
    },
  },
  colorFilter: {
    name: 'color',
    paramName: 'pub_dominantColors',
    isOpen: true,
    isMobileOpen: false,
    type: 'colorFilter',
    options: colorOptions,
    isRouteParam: true,
    mappingParameterValues: {
      silver: 'gray',
    },
  },
  // availabilityFilter: {
  //   name: 'availability',
  //   paramName: 'pub_availability',
  //   isOpen: true,
  //   isMobileOpen: false,
  //   type: 'selectMultipleFilter',
  //   options: availabilityOptions
  // },
  // favoriteFilter: {
  //   name: 'likers',
  //   paramName: 'meta_likers',
  //   isOpen: false,
  //   type: 'selectMultipleFilter',
  //   options: favoriteOptions,
  // },
  // collectionFilter: {
  //   name: 'collection',
  //   paramName: 'pub_collection',
  //   isOpen: false,
  //   type: 'selectMultipleFilter',
  //   options: collections,
  // },
  // dateRangeFilter: {
  //   paramName: 'dates',
  //   isOpen: true,
  //   config: dateRangeFilterConfig,
  // },
  // keywordFilter: {
  //   paramName: 'keywords',
  //   isOpen: true,
  //   config: keywordFilterConfig,
  // },
  artistFeaturedFilter: {
    paramName: 'pub_isArtistFeatured',
    isOpen: true,
    isMobileOpen: false,
    option: true,
    hideInSitemap: true,
  },
  // artFeaturedFilter: {
  //   paramName: 'pub_isFeatured',
  //   active: true,
  //   isOpen: true,
  //   option: true,
  // },
  // artFeaturedMobile: {
  //   paramName: 'pub_isFeatured',
  //   active: true,
  //   isOpen: true,
  //   options: featuredFilterOptions
  // },
  // soldFilterMobile: {
  //   paramName: 'pub_availability',
  //   active: true,
  //   isOpen: true,
  //   options: soldFilterOptions
  // },
  // artistFeaturedMobile: {
  //   paramName: 'pub_isArtistFeatured',
  //   active: true,
  //   isOpen: true,
  //   options: featuredArtistOptions
  // }
};

export const authentications = [
  { key: 'signed', label: 'Signed', labelId: 'EditListingDescriptionForm.signed' },
  {
    key: 'certificate_of_authenticity',
    label: 'Certificate of Authenticity',
    labelId: 'EditListingDescriptionForm.certificateOfAuthenticity',
  },
];

export const ABSTRACT_CATEGORY = 'abstract';
export const EXPRESSIONIST_CATEGORY = 'expressionist';
export const REPRESENTATIONAL_CATEGORY = 'representational';

export const artistCategories = [
  { key: ABSTRACT_CATEGORY, label: 'Abstract', labelId: 'FeaturedArtistPage.abstract' },
  {
    key: REPRESENTATIONAL_CATEGORY,
    label: 'Representational',
    labelId: 'FeaturedArtistPage.representational',
  },
];

export const stateOptions = [
  { key: 'New York', label: 'New York', labelId: 'FeaturedArtistPage.newYork' },
  { key: 'California', label: 'California', labelId: 'FeaturedArtistPage.california' },
  { key: 'Massachusetts', label: 'Massachusetts', labelId: 'FeaturedArtistPage.massachusetts' },
  { key: 'Pennsylvania', label: 'Pennsylvania', labelId: 'FeaturedArtistPage.pennsylvania' },
  { key: 'New Jersey', label: 'New Jersey', labelId: 'FeaturedArtistPage.newJersey' },
  { key: 'Florida', label: 'Florida', labelId: 'FeaturedArtistPage.florida' },
  { key: 'Colorado', label: 'Colorado', labelId: 'FeaturedArtistPage.colorado' },
  { key: 'Connecticut', label: 'Connecticut', labelId: 'FeaturedArtistPage.connecticut' },
  { key: 'North Carolina', label: 'North Carolina', labelId: 'FeaturedArtistPage.northCarolina' },
  { key: 'Ohio', label: 'Ohio', labelId: 'FeaturedArtistPage.ohio' },
  { key: 'Utah', label: 'Utah', labelId: 'FeaturedArtistPage.utah' },
  { key: 'Virginia', label: 'Virginia', labelId: 'FeaturedArtistPage.virginia' },
  { key: 'Oregon', label: 'Oregon', labelId: 'FeaturedArtistPage.oregon' },
  { key: 'New Hampshire', label: 'New Hampshire', labelId: 'FeaturedArtistPage.newHampshire' },
  { key: 'Maryland', label: 'Maryland', labelId: 'FeaturedArtistPage.maryland' },
  { key: 'New Mexico', label: 'New Mexico', labelId: 'FeaturedArtistPage.newMexico' },
  { key: 'Texas', label: 'Texas', labelId: 'FeaturedArtistPage.texas' },
  { key: 'Washington', label: 'Washington', labelId: 'FeaturedArtistPage.washington' },
  { key: 'Georgia', label: 'Georgia', labelId: 'FeaturedArtistPage.georgia' },
  { key: 'South Carolina', label: 'South Carolina', labelId: 'FeaturedArtistPage.southCarolina' },
  { key: 'Illinois', label: 'Illinois', labelId: 'FeaturedArtistPage.illinois' },
  { key: 'Minnesota', label: 'Minnesota', labelId: 'FeaturedArtistPage.minnesota' },
  { key: 'Montana', label: 'Montana', labelId: 'FeaturedArtistPage.montana' },
];

export const cityOptions = [
  {
    key: 'Brooklyn',
    label: 'Brooklyn',
    labelId: 'FeaturedArtistPage.brooklyn',
    hideInFilter: true,
  },
  { key: 'New York City', label: 'New York City', labelId: 'FeaturedArtistPage.newYorkCity' },
  { key: 'Queens', label: 'Queens', labelId: 'FeaturedArtistPage.queens', hideInFilter: true },
  {
    key: 'Long Island City',
    label: 'Long Island City',
    labelId: 'FeaturedArtistPage.longIslandCity',
    hideInFilter: true,
  },
  { key: 'Los Angeles', label: 'Los Angeles', labelId: 'FeaturedArtistPage.losAngeles' },
  {
    key: 'Jersey City',
    label: 'Jersey City',
    labelId: 'FeaturedArtistPage.jerseyCity',
    hideInFilter: true,
  },
  { key: 'San Francisco', label: 'San Francisco', labelId: 'FeaturedArtistPage.sanFrancisco' },
];

export const countryOptions = [
  { key: 'United States', label: 'USA', labelId: 'FeaturedArtistPage.USA' },
  { key: 'United Kingdom', label: 'UK', labelId: 'FeaturedArtistPage.UK' },
  { key: 'France', label: 'France', labelId: 'FeaturedArtistPage.france' },
  { key: 'Australia', label: 'Australia', labelId: 'FeaturedArtistPage.australia' },
  { key: 'New Zealand', label: 'New Zealand', labelId: 'FeaturedArtistPage.newZealand' },
  { key: 'Argentina', label: 'Argentina', labelId: 'FeaturedArtistPage.argentina' },
  { key: 'Norway', label: 'Norway', labelId: 'FeaturedArtistPage.norway' },
];

export const SORT_BY_NEWEST = 'createdAt';
export const DEFAULT_SORT_BY = 'pub_sortDefault';
export const DEFAULT_SORT_BY_UNIQUE_PAGE_VIEWS = 'pub_uniquePageViews,createdAt';
export const PROFILE_SORT_DEFAULT = 'pub_uniquePageViews,pub_availabilityPriority';

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: DEFAULT_SORT_BY_UNIQUE_PAGE_VIEWS, label: 'Default' },
    { key: SORT_BY_NEWEST, label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },
    { key: 'meta_likes', label: 'Featured' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const sortConfigProfilePage = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: PROFILE_SORT_DEFAULT, label: 'Default' },
    { key: SORT_BY_NEWEST, label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },
    { key: 'meta_likes', label: 'Featured' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const featuredArtistsSortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  options: [{ key: 'pub_artworkQuantity', label: 'Artworks' }],
};

export const ARTIST_TRAFFIC = 'pub_artistTraffic';
export const UNIQUE_PAGE_VIEWS = 'pub_uniquePageViews';
export const FEATURED_ARTIST_URL_PARAM = 'meta_isFeaturedArtist';
export const ARTIST_ASCENDING = '-pub_firstCharacterAscii';
export const ARTIST_DESCENDING = 'pub_firstCharacterAscii';

export const landingsSortConfig = {
  name: 'sort',
  paramName: 'sort',
  queryParamName: 'sort',
  relevanceKey: 'relevance',
  isOpen: true,
  isMobileOpen: false,
  type: 'SelectSingleFilter',

  options: [
    { key: ARTIST_TRAFFIC, label: 'Featured' },
    { key: ARTIST_ASCENDING, label: 'A-Z' },
    { key: ARTIST_DESCENDING, label: 'Z-A' },
  ],
};
// name: 'sort',
// paramName: 'sort',
// isOpen: true,
// isMobileOpen: false,
// type: 'SelectSingleFilter',
// options: [
//   { key: DEFAULT_SORT_BY_FEATURED, label: 'Featured' },
//   { key: `pub_availabilityPriority,${SORT_BY_NEWEST}`, label: 'Newest' },
//   { key: 'pub_availabilityPriority,-price', label: 'Lowest price' },
//   { key: 'pub_availabilityPriority,price', label: 'Highest price' },
// ],

export const sortConfigCollectionPage = collectionType => {
  return {
    // Enable/disable the sorting control in the Collection Page
    active: true,

    // Note: queryParamName 'sort' is fixed,
    // you can't change it since Flex API expects it to be named as 'sort'
    queryParamName: 'sort',

    // Internal key for the relevance option, see notes below.
    relevanceKey: 'relevance',

    // Keyword filter is sorting the results already by relevance.
    // If keyword filter is active, we need to disable sorting.
    conflictingFilters: ['keyword'],

    options:
      collectionType === LISTING_TYPE_ARTIST_COLLECTION
        ? [
            { key: ARTIST_TRAFFIC, label: 'Default' },
            { key: 'meta_likes', label: 'Popular' },
            { key: 'createdAt', label: 'Newest' },
          ]
        : [
            { key: DEFAULT_SORT_BY_FEATURED, label: 'Default' },
            { key: 'meta_likes', label: 'Popular' },
            { key: 'createdAt', label: 'Newest' },
            { key: 'price', label: 'Highest price' },
            { key: '-price', label: 'Lowest price' },
          ],
  };
};

export const sortConfigExhibitionPage = {
  // Enable/disable the sorting control in the Exhibition Page
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: UNIQUE_PAGE_VIEWS, label: 'Default' },
    { key: 'meta_likes', label: 'Popular' },
    { key: 'createdAt', label: 'Newest' },
    { key: 'price', label: 'Highest price' },
    { key: '-price', label: 'Lowest price' },
  ],
};

export const DEFAULT_FEATURED_ARTIST_SORT_BY = 'pub_artworkQuantity';

export const DEFAULT_AVAILABILITY_FILTER = AVAILABLE;

export const DEFAULT_AVAILABILITY_FILTER_IN_PROFILE_PAGE = `${AVAILABLE},${SOLD},${ON_HOLD},${ON_CONSIGNMENT}`;
export const TURN_ON_SHOW_ALL = `${AVAILABLE},${SOLD},${ON_HOLD},${ON_CONSIGNMENT}`;
export const TURN_OFF_SHOW_ALL = `${AVAILABLE},${SOLD}`;

export const recommendSearchesConfig = {
  largeAbstractRecommend: {
    pub_category: 'painting',
    pub_labels: 'Abstract',
    pub_size: 'large,extraLarge',
  },
  giftRecommend: {
    pub_priceRange: '0-500',
    pub_availability: 'available',
    pub_size: 'extraSmall',
  },
  favoriteRecommend: {
    meta_likers:
      process.env.REACT_APP_WRIGHT_USER_ID +
      ',' +
      process.env.REACT_APP_HANNAH_USER_ID +
      ',' +
      process.env.REACT_APP_SYLVIE_USER_ID,
  },
  availableRecommend: {
    pub_availability: 'available',
  },
};

export const perPagesConfig = {
  options: [
    { key: 24, label: '24 per page' },
    { key: 48, label: '48 per page' },
    { key: 96, label: '96 per page' },
  ],
};

export const PER_PAGE_COLLECTION_PAGE_DEFAULT = 24;

export const perPagesCollectionPageConfig = {
  options: [
    { key: PER_PAGE_COLLECTION_PAGE_DEFAULT, label: '24 per page' },
    { key: 36, label: '36 per page' },
    { key: 48, label: '48 per page' },
  ],
};

export const materialKeyword = {
  ['on_canvas']: [' canvas ', ' linen '],
  ['on_panel']: [' panel ', ' board ', ' aluminum ', ' wood ', ' copper '],
  ['on_paper']: [' paper ', ' vellum ', ' yupo '],
};

export const buttonDeclineOffer = 'declineOffer';
export const buttonAcceptOffer = 'acceptOffer';
export const buttonBuyItNow = 'buyItNow';

export const PICK_UP = 'pick_up';
export const DOMESTIC_SHIPPING = 'domestic_shipping';
export const INTERNATIONAL_SHIPPING = 'international_shipping';

export const OFFER_PRICE_CUSTOM = 'OFFER_PRICE_CUSTOM';

export const SCULPTURE = 'sculpture';
export const PAINTING = 'painting';
export const DRAWING = 'drawing';
export const TYPES = new Set([SCULPTURE, PAINTING, DRAWING]);
export const ACRYLIC_PAINTING = 'acrylic_painting';
export const WATERCOLOR_PAINTING = 'watercolor_painting';
export const OIL_PAINTING = 'oil_painting';
export const SUGARLIFT_GOOGLE_MAP = 'https://shorturl.at/mJXZ5';
export const EXHIBITION_LINK = 'https://art.sugarlift.com/blogs/exhibitions/sunrise-daniela-astone';

//Origin for 508 W 28th Street, NY, NY
export const ARTIST_NEW_ME_ORIGIN = new LatLng(40.7509232, -74.0024806);

export const paramsSearchUrlDict = {
  pub_category: 'medium',
  pub_material: 'material',
  pub_styles: 'styles',
  pub_labels: 'subject',
  pub_priceRange: 'price',
  pub_orientation: 'orientation',
  pub_availability: 'availability',
  pub_collection: 'collection',
  pub_size: 'size',
  pub_framed: 'framed',
  pub_dominantColors: 'color',
};

// The following are the filters that have mappingParameterValues dict
export const mappingParamValueFitlers = Object.values(filterConfig).filter(
  filter => filter.mappingParameterValues
);

export const mappingParamNameParamValueDict = mappingParamValueFitlers
  .map(filter => ({
    [filter.paramName]: filter.mappingParameterValues,
  }))
  .reduce((prev, cur) => ({ ...prev, ...cur }), {});

export const mappingParamValueDict = mappingParamValueFitlers
  .map(filter => filter.mappingParameterValues)
  .reduce((prev, cur) => ({ ...prev, ...cur }), {});

//search type
export const ARTIST_TYPE = 'artist';
export const MEDIUM_TYPE = 'medium';
export const SUBJECT_TYPE = 'subject';
export const ARTIST_COLLECTION_TYPE = 'artist_collection';
export const ARTWORK_COLLECTION_TYPE = 'artwork_collection';
export const EDITORIAL_TYPE = 'editorial';
export const EXHIBITION_TYPE = 'exhibition';

export const searchTypeConfig = [
  { key: ARTIST_TYPE, label: 'Artist' },
  { key: MEDIUM_TYPE, label: 'Medium' },
  { key: SUBJECT_TYPE, label: 'Subject' },
  { key: ARTIST_COLLECTION_TYPE, label: 'Artist collection' },
  { key: ARTWORK_COLLECTION_TYPE, label: 'Artwork collection' },
  { key: EDITORIAL_TYPE, label: 'Editorial' },
  { key: EXHIBITION_TYPE, label: 'Exhibition' },
];

export const artistFilterConfig = {
  artistCategoryFilter: {
    name: 'artistCategory',
    paramName: 'pub_artistCategory',
    type: 'selectMultipleFilter',
    options: artistCategories,
    isOpen: true,
  },
};

export const DEFAULT_SORT_BY_FEATURED = 'pub_availabilityPriority,pub_uniquePageViews';

export const sortFilter = {
  name: 'sort',
  paramName: 'sort',
  isOpen: true,
  isMobileOpen: false,
  type: 'SelectSingleFilter',
  options: [
    { key: DEFAULT_SORT_BY_FEATURED, label: 'Featured' },
    { key: `pub_availabilityPriority,${SORT_BY_NEWEST}`, label: 'Newest' },
    { key: 'pub_availabilityPriority,-price', label: 'Lowest price' },
    { key: 'pub_availabilityPriority,price', label: 'Highest price' },
  ],
};

const PAINTERLY = 'painterly';
const GEOMETRIC = 'geometric';
const GESTURAL = 'gestural';
const MINIMALIST = 'minimal';
const COLOR_FIELD = 'colorfield';
const BLACK_AND_WHITE = 'blackAndWhite';
const TEXTURED = 'textured';
const REPRESENTATIONAL = 'representational';

//Painterly, Geometric, Gestural, Minimalist, Color Field, Black and White, Textured, Representational
export const stylesConfig = [
  { key: PAINTERLY, label: 'Painterly' },
  { key: GEOMETRIC, label: 'Geometric' },
  { key: GESTURAL, label: 'Gestural' },
  { key: MINIMALIST, label: 'Minimalist' },
  { key: COLOR_FIELD, label: 'Color Field' },
  { key: BLACK_AND_WHITE, label: 'Black And White' },
  { key: TEXTURED, label: 'Textured' },
  { key: REPRESENTATIONAL, label: 'Representational' },
];

export const bannerStateKey = 'sugarlift/bannerState';

export const quickSearchTags = [
  {
    key: 'viewAll',
    label: 'View All',
    queryParams: {
      sort: 'pub_availabilityPriority,pub_uniquePageViews',
    },
  },
  {
    key: 'abstractPaintings',
    label: 'Abstract Paintings',
    queryParams: {
      pub_styles: 'abstract',
      pub_type: 'painting',
      sort: 'pub_availabilityPriority,pub_uniquePageViews',
    },
  },
  {
    key: 'largePieces',
    label: 'Large Pieces',
    queryParams: {
      pub_size: 'large,extraLarge',
      sort: 'pub_availabilityPriority,pub_uniquePageViews',
    },
  },
  {
    key: 'under1500',
    label: 'Under $1,500',
    queryParams: {
      pub_priceRange: '0-500,500-1500',
      sort: 'pub_availabilityPriority,pub_uniquePageViews',
    },
  },
];

export const pressTags = [
  { key: 'viewAll', label: 'View All' },
  {
    key: 'Artists',
    label: 'Artists',
  },
  {
    key: 'Gallery',
    label: 'Gallery',
  },
  {
    key: 'Projects',
    label: 'Projects',
  },
  {
    key: 'Sugarlift',
    label: 'Sugarlift',
  },
];

export const projectTags = [
  { key: 'viewAll', label: 'View All' },
  {
    key: 'Collectors',
    label: 'Collectors',
  },
  {
    key: 'Multi-Family',
    label: 'Multi-Family',
  },
  {
    key: 'Office',
    label: 'Office',
  },
  {
    key: 'Hospitality',
    label: 'Hospitality',
  },
  {
    key: 'Healthcare',
    label: 'Healthcare',
  },
  {
    key: 'Affordable',
    label: 'Affordable',
  },
];

export const INQUIRE_PROJECT_TYPE = 'Project';
export const INQUIRE_PRESS_TYPE = 'Press';
export const INQUIRE_ARTIST_TYPE = 'Artist';
export const INQUIRE_NEW_GALLERY_TYPE = 'Gallery';
export const INQUIRE_LISTING_TYPE = 'Artwork';
export const INQUIRE_EXHIBITION_TYPE = 'Exhibition';

export const artistTags = [
  {
    key: 'viewAll',
    label: 'View All',
    queryParams: {},
  },
  {
    key: 'abstract',
    label: 'Abstract Artists',
    queryParams: {
      pub_artistCategory: 'abstract',
      sort: 'pub_artistTraffic',
    },
  },
  {
    key: 'representational',
    label: 'Representational Artists',
    queryParams: {
      pub_artistCategory: 'representational',
      sort: 'pub_artistTraffic',
    },
  },
];

export const SCROLL_UP = 'up';
export const SCROLL_DOWN = 'down';
export const SCROLL_DEFAULT_DIRECTION = 'none';
