import strapi from '../../util/strapi';

// ================ Action types ================ //

export const QUERY_TRADE_PROGRAM_REQUEST = 'app/TradeProgramPage/QUERY_TRADE_PROGRAM_REQUEST';
export const QUERY_TRADE_PROGRAM_SUCCESS = 'app/TradeProgramPage/QUERY_TRADE_PROGRAM_SUCCESS';
export const QUERY_TRADE_PROGRAM_ERROR = 'app/TradeProgramPage/QUERY_TRADE_PROGRAM_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryTradeProgramError: null,
  tradeProgram: null,
};

export default function TradeProgramReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_TRADE_PROGRAM_REQUEST:
      return { ...state, queryTradeProgramError: null };
    case QUERY_TRADE_PROGRAM_SUCCESS:
      return { ...state, tradeProgram: payload };
    case QUERY_TRADE_PROGRAM_ERROR:
      return { ...state, queryTradeProgramError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryTradeProgramRequest = () => ({
  type: QUERY_TRADE_PROGRAM_REQUEST,
});

export const queryTradeProgramSuccess = tradeProgram => ({
  type: QUERY_TRADE_PROGRAM_SUCCESS,
  payload: tradeProgram,
});

export const queryTradeProgramError = e => ({
  type: QUERY_TRADE_PROGRAM_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(queryTradeProgramRequest());

  const queryParams = {
    populate: 'heroImage',
  };

  return strapi
    .get('/api/trade-program', queryParams)
    .then(res => {
      dispatch(queryTradeProgramSuccess(res.data.attributes));
    })
    .catch(e => dispatch(queryTradeProgramError(e)));
};
