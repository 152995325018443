import { storableError } from '../../util/errors';
import { LISTING_TYPE_ART } from '../../util/types';

const PER_PAGE_FOR_GETTING_TOTAL_ARTWORKS = 1;
const FIRST_PAGE_FOR_GETTING_TOTAL_ARTWORKS = 1;

// ================ Action types ================ //
const QUERY_TOTAL_ARTWORKS_REQUEST = 'app/TopbarContainer/QUERY_TOTAL_ARTWORKS_REQUEST';
const QUERY_TOTAL_ARTWORKS_SUCCESS = 'app/TopbarContainer/QUERY_TOTAL_ARTWORKS_SUCCESS';
const QUERY_TOTAL_ARTWORKS_ERROR = 'app/TopbarContainer/QUERY_TOTAL_ARTWORKS_ERROR';

// ================ Reducer ================ //
const initialState = {
  totalArtworks: 0,
  queryInProgress: false,
  queryError: null,
};

export default function TopbarContainerReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_TOTAL_ARTWORKS_REQUEST:
      return { ...state, queryError: null, queryInProgress: true };
    case QUERY_TOTAL_ARTWORKS_SUCCESS:
      return { ...state, totalArtworks: payload, queryInProgress: false };
    case QUERY_TOTAL_ARTWORKS_ERROR:
      return { ...state, queryTermsError: payload, queryInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //
export const queryTotalArtworksRequest = () => ({
  type: QUERY_TOTAL_ARTWORKS_REQUEST,
});

export const queryTotalArtworksSuccess = payload => ({
  type: QUERY_TOTAL_ARTWORKS_SUCCESS,
  payload,
});

export const queryTotalArtworksError = payload => ({
  type: QUERY_TOTAL_ARTWORKS_ERROR,
  payload,
});

// ================ Thunks ================ //
export const loadData = () => (dispatch, getState, sdk) => {
  const queryParams = {
    pub_listingType: LISTING_TYPE_ART,
    per_page: PER_PAGE_FOR_GETTING_TOTAL_ARTWORKS,
    page: FIRST_PAGE_FOR_GETTING_TOTAL_ARTWORKS,
  };
  dispatch(queryTotalArtworksRequest());
  sdk.listings
    .query(queryParams)
    .then(response => {
      const {
        data: {
          meta: { totalItems },
        },
      } = response;
      dispatch(queryTotalArtworksSuccess(totalItems));
    })
    .catch(e => {
      dispatch(queryTotalArtworksError(storableError(e)));
    });
};
