import pick from 'lodash/pick';
import config from '../../config';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CUSTOMER_ACCEPT_PAYMENT,
  TRANSITION_RECORD
} from '../../util/transaction';
import * as log from '../../util/log';
import { trackIntercomEvent } from '../../util/intercom';
import { fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_UNITS, LINE_ITEM_TAX, LINE_ITEM_SHIP, LINE_ITEM_DISCOUNT } from '../../util/types';
import { getInfoFromAddress, reverseGeocoding } from '../../util/maps';
import taxData from '../../util/taxData';
import { convertMoneyToNumber } from '../../util/currency';
import Decimal from 'decimal.js';
import { createSlug } from '../../util/urlHelpers';
import { subUnitDivisors } from '../../currency-config';
import { transactionLineItems, userStripeConnect, initiatePrivileged, sendEmail } from '../../util/api';
import { getDeliveryFee } from '../../util/artListing';

const { UUID, Money } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const CREATE_LINE_ITEMS_REQUEST = 'app/ListingPage/CREATE_LINE_ITEMS_REQUEST';
export const CREATE_LINE_ITEMS_SUCCESS = 'app/ListingPage/CREATE_LINE_ITEMS_SUCCESS';
export const CREATE_LINE_ITEMS_ERROR = 'app/ListingPage/CREATE_LINE_ITEMS_ERROR';
// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  createdLineItems: [],
  txCustomerProvider: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case CREATE_LINE_ITEMS_REQUEST:
      return { ...state, createdLineItems: [] };
    case CREATE_LINE_ITEMS_SUCCESS:
      return { ...state, createdLineItems: payload };
    case CREATE_LINE_ITEMS_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, createdLineItems: [] };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});


export const createLineItemsRequest = () => ({ type: CREATE_LINE_ITEMS_REQUEST });

export const createLineItemsSuccess = payload => ({
  type: CREATE_LINE_ITEMS_SUCCESS,
  payload,
});

export const createLineItemsError = e => ({
  type: CREATE_LINE_ITEMS_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */
export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };
  const { listing: { attributes, author, id: idListing }, protectedData } = orderParams;
  const { offerAmount } = protectedData;
  const { title, price } = attributes;
  const priceUnit = offerAmount
    ? {
      amount: offerAmount.amount / 100,
      currency: offerAmount.currency
    }
    : {
      amount: convertMoneyToNumber(price),
      currency: price.currency
    };

  const { displayName: displayNameProvider } = author.attributes.profile;
  const image = orderParams.listing.images[0].attributes.variants;
  const medium = attributes.description;
  const { height, width } = attributes.publicData;
  const splitName = displayNameProvider.split(' ');
  const firstNameProvider = splitName[0];
  const lastNameProvider = splitName.slice(1, splitName.length).join(' ');
  const {
    studioNumber: studioNumberProvider,
    address: addressProvider,
  } = author.attributes.profile.publicData;
  const buyerEmail = getState().user.currentUser.attributes.email;
  const slugTitle = createSlug(attributes.title);
  const { env } = config;
  const environment = env !== 'production' ? 'sugarlift-test' : 'sugarlift';
  const emailParam = {
    title,
    image,
    medium,
    height,
    width,
    studioNumberProvider,
    addressProvider,
    artistEmail: protectedData && protectedData.artistEmail,
    buyerEmail,
    slugTitle,
    environment,
    displayNameProvider,
    firstNameProvider,
    lastNameProvider,
    idListing: idListing.uuid,
    priceUnit
  };
  orderParams.protectedData = { ...orderParams.protectedData, ...emailParam };
  const { listingId, listing, ...restOrderParams } = orderParams;

  const getParams = async () => {
    const [lineItems, authorStripeConnect] = await Promise.all([
      createLineItems({ params: orderParams, state: getState() }),
      userStripeConnect({ userId: author.id.uuid }),
    ]);

    const { stripeConnected } = authorStripeConnect.data;

    const lineItemsWithCommission = await transactionLineItems({
      lineItems,
      listingId
    });

    const bodyParams = transactionId
      ? {
        id: transactionId,
        transition: TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
        params: {
          listingId,
          ...restOrderParams,
          lineItems
        },
      }
      : {
        processAlias: config.bookingProcessAlias,
        transition: TRANSITION_REQUEST_PAYMENT,
        params: {
          ...restOrderParams,
          listingId: stripeConnected ? listingId : new UUID(config.adminListingUUID),
          lineItems: lineItemsWithCommission.data,
        },
      };

    return bodyParams;
  }

  return getParams()
    .then(bodyParams => {
      if (transactionId) {
        return sdk.transactions.transition(bodyParams, queryParams);
      } else {
        return initiatePrivileged({ isSpeculative: false, bodyParams, queryParams });
      }

    })
    .then(response => {
      const entities = denormalisedResponseEntities(response);

      const recordParams = orderParams.txCustomerProvider
        ? {
          id: orderParams.txCustomerProvider.id,
          transition: TRANSITION_CUSTOMER_ACCEPT_PAYMENT,
          params: {
            protectedData: orderParams.protectedData
          }
        }
        : {
          processAlias: config.bookingProcessAlias,
          transition: TRANSITION_RECORD,
          params: {
            listingId,
            protectedData: { ...orderParams.protectedData, ...emailParam }
          },
        };

      const bookListing = orderParams.txCustomerProvider
        ? sdk.transactions.transition
        : sdk.transactions.initiate;

      return bookListing(recordParams, queryParams)
        .then(() => {
          const order = entities[0];
          dispatch(initiateOrderSuccess(order));
          dispatch(fetchCurrentUserHasOrdersSuccess(true));
          trackIntercomEvent('create-order', { transactionId: order.id.uuid });
          return order;
        });
    })
    .catch(e => {
      dispatch(initiateOrderError(storableError(e)));
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
        listingId: orderParams.listingId.uuid,
        bookingStart: orderParams.bookingStart,
        bookingEnd: orderParams.bookingEnd,
      });
      throw e;
    });
};

const signalTransactionComplete = (orderId) => (dispatch, getState, sdk) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(null);
  }
  return fetch(`${config.serverUrl}/api/transactions/${orderId.uuid}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  })
}

const formatMoney = (money) => {
  const moneyNumber = convertMoneyToNumber(money);
  return new Intl.NumberFormat(config.locale, {
    style: 'currency',
    currency: money.currency,
    currencyDisplay: 'symbol',
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(moneyNumber);
}

const getPriceByCode = (lineItems, code) => {
  const item = lineItems.find(lineItem => lineItem.code === code);
  if (!item) return '';
  return formatMoney(item.lineTotal);
}

const createAdminCompleteOrderEmailMsg = ({ transaction }) => {
  const transactionId = transaction.id.uuid;
  const { protectedData, lineItems, payinTotal } = transaction.attributes;
  const {
    medium,
    width,
    height,
    name,
    buyerEmail,
    customerPhoneNumber,
    displayNameProvider,
    addressProvider,
    providerPhoneNumber,
    studioNumberProvider,
    artistEmail,
    slugTitle,
    idListing,
    environment,
    title,
    image,
  } = protectedData;

  const dimension = `${width} x ${height}`;

  const price = getPriceByCode(lineItems, LINE_ITEM_UNITS);
  const shippingPrice = getPriceByCode(lineItems, LINE_ITEM_SHIP);
  const saleTax = getPriceByCode(lineItems, LINE_ITEM_TAX);
  const total = formatMoney(payinTotal);

  const webURL = `${process.env.REACT_APP_CANONICAL_ROOT_URL}/l/${slugTitle}/${idListing}`;
  const flexConsoleURL = `https://flex-console.sharetribe.com/o/sugarlift/m/${environment}/transactions?id=${transactionId}`;

  const imageSrc = image['scaled-medium'].url;

  return {
    to: config.email.adminEmail,
    from: config.email.sender,
    templateId: config.email.adminCompleteOrderTemplateId,
    dynamicTemplateData: {
      listing: {
        title,
        medium,
        dimension,
        price,
        shippingPrice,
        saleTax,
        total,
        webURL,
        flexConsoleURL,
      },
      provider: {
        displayName: displayNameProvider,
        email: artistEmail,
        phoneNumber: providerPhoneNumber,
        address: addressProvider.search,
        studioNumber: studioNumberProvider,
      },
      customer: {
        displayName: name,
        email: buyerEmail,
        phoneNumber: customerPhoneNumber,
      },
      imageSrc,
    },
  };
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      trackIntercomEvent('confirm-payment', { transactionId: order.id.uuid });
      dispatch(signalTransactionComplete(order.id));
      const { transaction } = getState().CheckoutPage;
      const message = createAdminCompleteOrderEmailMsg({ transaction });
      sendEmail(message);
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        trackIntercomEvent('send-message', { transactionId: orderId.uuid });
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate the speculative transaction with the given booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = params => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const { listing, ...restParams } = params;
  const { id: authorId } = listing.author;

  const getParams = async () => {
    const [lineItems, authorStripeConnect] = await Promise.all([
      createLineItems({ params, state: getState() }),
      userStripeConnect({ userId: authorId.uuid }),
    ]);

    const { stripeConnected } = authorStripeConnect.data;

    const lineItemsWithCommission = await transactionLineItems({
      lineItems,
      listingId: listing.id.uuid
    });

    const bodyParams = {
      transition: TRANSITION_REQUEST_PAYMENT,
      processAlias: config.bookingProcessAlias,
      params: {
        ...restParams,
        listingId: stripeConnected ? listing.id : new UUID(config.adminListingUUID),
        cardToken: 'CheckoutPage_speculative_card_token',
        lineItems: lineItemsWithCommission.data,
      },
    };

    return bodyParams;
  }

  return getParams()
    .then(bodyParams =>
      initiatePrivileged({ isSpeculative: true, bodyParams, queryParams }))
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.transactions.initiateSpeculative response');
      }
      const tx = entities[0];
      dispatch(speculateTransactionSuccess(tx));
    })
    .catch(e => {
      const { listingId, bookingStart, bookingEnd } = params;
      log.error(e, 'speculate-transaction-failed', {
        listingId: listingId.uuid,
        bookingStart,
        bookingEnd,
      });
      return dispatch(speculateTransactionError(storableError(e)));
    });
};

export const getLineItems = params => (dispatch, getState, sdk) => {
  dispatch(createLineItemsRequest());
  return createLineItems({ params, state: getState() })
    .then(lineItems => {
      const taxItem = lineItems.find(item => item.code === LINE_ITEM_TAX);
      if (taxItem) {
        dispatch(createLineItemsSuccess([{
          ...taxItem,
          lineTotal: taxItem.unitPrice,
          includeFor: ['customer'],
          quantity: new Decimal(1),
          reversal: false,
        }]));
      }
    })
};

const getCurrentStateData = mapInfo => {
  const { features } = mapInfo || {};
  const featuresArray = Array.isArray(features) ? features : [];
  if (!featuresArray.length) return null;
  const contexts = featuresArray.reduce((prev, cur) => {
    prev = [...prev, ...cur.context];
    return prev;
  }, []);
  const state = contexts.find(ct => ct.id.includes('region'));
  return state;
}

const getAddressData = mapInfo => {
  const { address_components: addresses } = mapInfo;
  const addressObj = addresses.reduce((prev, addressElement) => {
    if (addressElement.types.includes('administrative_area_level_1')) {
      prev.state = addressElement;
    }
    if (addressElement.types.includes('country')) {
      prev.country = addressElement;
    }
    return prev;
  }, {});
  return addressObj;
};

const getStateTaxData = state => {
  return taxData.find(stateTaxData =>
  (stateTaxData.State === state.long_name ||
    stateTaxData.Abbreviation === state.short_name)
  );
};

const calculateTax = (totalPrice, taxPercentage) => {
  const numericPrice = convertMoneyToNumber(totalPrice);
  const taxPrice = new Decimal(numericPrice)
    .times(taxPercentage)
    .toNearest(1, Decimal.ROUND_HALF_UP)
    .toNumber();

  return taxPrice !== 0
    ? new Money(taxPrice, totalPrice.currency || config.currency)
    : new Money(0, totalPrice.currency || config.currency);
};

const calculateDiscountAmount = (price, payoutAfterDiscount) => {
  const currency = price.currency || config.currency;
  const roundedPayoutAfterDiscount =
    Math.round(payoutAfterDiscount.amount / subUnitDivisors[currency]) * subUnitDivisors[currency];
  const discountAmount = roundedPayoutAfterDiscount - price.amount;
  const discountMoney = new Money(discountAmount, currency);
  return discountMoney;
}
const createLineItems = async ({ params, state }) => {
  const { listing, protectedData = {}, txCustomerProvider } = params;
  const { publicData, price } = listing.attributes;
  const finalPrice = txCustomerProvider
    ? txCustomerProvider.attributes.payoutTotal
    : price;
  const lines = [
    {
      code: LINE_ITEM_UNITS,
      quantity: 1,
      unitPrice: price,
    },
    {
      code: LINE_ITEM_DISCOUNT,
      quantity: 1,
      unitPrice: calculateDiscountAmount(price, finalPrice),
    },
  ];
  const { location: customerLocation } = protectedData;
  const {
    location: listingLocation,
    // domesticFee,
    // internationalFee,
    width,
    height,
  } = publicData;

  if (customerLocation && listingLocation) {
    const providerAddressInformation = listingLocation.origin
      ? await reverseGeocoding(listingLocation.origin)
      : await getInfoFromAddress(listingLocation.search || listingLocation.address);
    const customerAddressInformation = customerLocation.selectedPlace
      ? await reverseGeocoding(customerLocation.selectedPlace.origin)
      : await getInfoFromAddress(customerLocation.selectedPlace.address);

    const {
      state: providerState,
      country: providerCountry,
    } = getAddressData(providerAddressInformation);
    const {
      state: customerState,
      country: customerCountry,
    } = getAddressData(customerAddressInformation);

    if (providerState?.short_name === customerState?.short_name) {
      const stateTaxesInformation = getStateTaxData(providerState);
      if (stateTaxesInformation) {
        lines.push({
          code: LINE_ITEM_TAX,
          quantity: 1,
          unitPrice: calculateTax(finalPrice, parseFloat(stateTaxesInformation.Total)),
        });
      }
    }

    const size = height > width ? height : width;
    const deliveryFee = getDeliveryFee(size);
    const domesticFee = deliveryFee.domestic;
    const internationalFee = deliveryFee.international;

    const isSameCountry = providerCountry?.short_name === customerCountry?.short_name;
    const shippingFee = isSameCountry ? domesticFee : internationalFee;
    lines.push({
      code: LINE_ITEM_SHIP,
      quantity: 1,
      unitPrice: new Money(
        shippingFee,
        price.currency || config.currency
      ),
    });
  }
  return lines;
};
