import React from 'react';
import { FormattedMessage } from 'react-intl';
import NamedLink from '../NamedLink/NamedLink';

import css from './ModalMissingInformation.module.css';

const StripeAccountReminder = props => {
  const { className } = props;

  return (
    <div className={className}>
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.missingStripeAccountTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage
          id="ModalMissingInformation.missingStripeAccountText"
          values={{
            email: (
              <a className={css.errorLink} href="mailto:hello@sugarlift.com" target="_top">
                hello@sugarlift.com
              </a>
            ),
          }}
        />
      </p>
      <div className={css.bottomWrapper}>
        <NamedLink className={css.reminderModalLinkButton} name="StripePayoutPage">
          <FormattedMessage id="ModalMissingInformation.gotoPaymentSettings" />
        </NamedLink>
      </div>
    </div>
  );
};

export default StripeAccountReminder;
