import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import ValidationError from '../ValidationError/ValidationError';
import LocationAutocompleteInputImpl from './LocationAutocompleteInputImpl.js';
import css from './LocationAutocompleteInput.module.css';
import classNames from 'classnames';

class LocationAutocompleteInputComponent extends Component {
  render() {
    /* eslint-disable no-unused-vars */
    const { rootClassName, labelClassName, validClassName, inputClassName, ...restProps } = this.props;
    const { input, label, meta, valueFromForm, subLabel, ...otherProps } = restProps;
    /* eslint-enable no-unused-vars */
    const { valid, invalid, touched, error } = meta;
    const hasError = !!(touched && invalid && error);
    const inputLocationClassName = classNames(inputClassName, css.input, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
    });

    const value = typeof valueFromForm !== 'undefined' ? valueFromForm : input.value;
    const locationAutocompleteProps = {
      label,
      meta,
      ...otherProps,
      input: { ...input, value },
      inputClassName: inputLocationClassName,
    };
    const labelInfo = label ? (
      <label className={labelClassName} htmlFor={input.name}>
        {label}
        <span className={css.subLabel}>{subLabel}</span>
      </label>
    ) : null;

    return (
      <div className={rootClassName}>
        {labelInfo}
        <LocationAutocompleteInputImpl {...locationAutocompleteProps} />
        <ValidationError fieldMeta={meta} className={validClassName} />
      </div>
    );
  }
}

LocationAutocompleteInputComponent.defaultProps = {
  rootClassName: null,
  labelClassName: null,
  type: null,
  label: null,
};

LocationAutocompleteInputComponent.propTypes = {
  rootClassName: string,
  labelClassName: string,
  input: shape({
    onChange: func.isRequired,
    name: string.isRequired,
  }).isRequired,
  label: string,
  meta: object.isRequired,
};

export default LocationAutocompleteInputImpl;

export const LocationAutocompleteInputField = props => {
  return <Field component={LocationAutocompleteInputComponent} {...props} />;
};
