import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { ensureCurrentUser } from '../../util/data';
import { currentUserIsArtist } from '../../util/user';
import { InlineTextButton } from '../Button/Button';
import Menu from '../Menu/Menu';
import MenuContent from '../MenuContent/MenuContent';
import MenuItem from '../MenuItem/MenuItem';
import MenuLabel from '../MenuLabel/MenuLabel';
import NamedLink from '../NamedLink/NamedLink';
import { propTypes } from '../../util/types';

import css from './TopbarDesktop.module.css';

const ProfileDropdown = props => {
  const {
    currentUserHasListings,
    isAuthenticated,
    isArtist,
    currentUser,
    currentPage,
    children,
    offersDot,
    messagesDot,
    isMobile,
    onLogout,
    showOnHover,
  } = props;

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const { parsedArtistName } = ensuredCurrentUser.attributes.profile.publicData;

  const activeAccountSettingsPagesMaybe = () =>
    ACCOUNT_SETTINGS_PAGES.filter(page => page !== 'StripePayoutPage');

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && activeAccountSettingsPagesMaybe().includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const offerLink = (
    <MenuItem key="InboxPage">
      <NamedLink
        className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
        name="InboxPage"
        params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
      >
        <span className={css.menuItemBorder} />
        <div className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.inbox" />
          {offersDot}
        </div>
      </NamedLink>
    </MenuItem>
  );

  const messagesLink = (
    <MenuItem key="MessagesPage">
      <NamedLink
        className={classNames(css.inboxLink, currentPageClass('MessagesPage'))}
        name="MessagesPage"
      >
        <span className={css.menuItemBorder} />
        <div className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.messages" />
          {messagesDot}
        </div>
      </NamedLink>
    </MenuItem>
  );

  const addArtLink =
    isAuthenticated && isArtist ? (
      <MenuItem key="MyArtPage">
        <NamedLink
          className={classNames(css.yourListingsLink, currentPageClass('MyArtPage'))}
          name="MyArtPage"
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.createListing" />
        </NamedLink>
      </MenuItem>
    ) : null;

  const profileLink = (
    <MenuItem key="ManageListingsPage">
      <NamedLink
        className={classNames(
          css.yourListingsLink,
          currentPageClass(parsedArtistName ? 'ProfilePage' : 'ProfileBasePage')
        )}
        name={parsedArtistName ? 'ProfilePage' : 'ProfileBasePage'}
        params={{
          name: parsedArtistName,
        }}
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.yourListingsLink" />
      </NamedLink>
    </MenuItem>
  );

  const favoriteLink = (
    <MenuItem key="FavoriteArtPage">
      <NamedLink
        className={classNames(css.favoriteLink, currentPageClass('FavoriteArtPage'))}
        name="FavoriteArtPage"
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.yourFavorites" />
      </NamedLink>
    </MenuItem>
  );

  const payoutLink = (
    <MenuItem key="StripePayoutPage">
      <NamedLink
        className={classNames(css.profileSettingsLink, currentPageClass('StripePayoutPage'))}
        name="StripePayoutPage"
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.payoutLink" />
      </NamedLink>
    </MenuItem>
  );

  const accountSettingLink = (
    <MenuItem key="AccountSettingsPage">
      <NamedLink
        className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
        name="AccountSettingsPage"
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
      </NamedLink>
    </MenuItem>
  );

  const logoutLink = (
    <MenuItem key="logout">
      <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.logout" />
      </InlineTextButton>
    </MenuItem>
  );

  const contentPlacementOffset = isMobile ? 20 : 0;
  const menuContentClasses = classNames(css.profileMenuContent, css.profileDropdown);

  const menuContent = currentUserIsArtist(ensuredCurrentUser) ? (
    <MenuContent className={menuContentClasses}>
      {offerLink}
      {messagesLink}
      {addArtLink}
      {profileLink}
      {favoriteLink}
      {payoutLink}
      {accountSettingLink}
      {logoutLink}
    </MenuContent>
  ) : (
    <MenuContent className={menuContentClasses}>
      {offerLink}
      {messagesLink}
      {favoriteLink}
      {accountSettingLink}
      {logoutLink}
    </MenuContent>
  );

  if (!isAuthenticated && isMobile) {
    const signup = (
      <NamedLink name="SignupPage" className={css.authLink}>
        <FormattedMessage id="TopbarDesktop.signUp" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.authLink}>
        <FormattedMessage id="TopbarDesktop.signIn" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <Menu
        useArrow={false}
        contentPlacementOffset={contentPlacementOffset}
        useOverlay={isMobile}
        showOnHover={showOnHover}
      >
        <MenuLabel
          className={isMobile ? css.profileMenuLabelOnMobile : css.profileMenuLabelOnDesktop}
          isOpenClassName={css.profileMenuIsOpen}
        >
          {children}
        </MenuLabel>
        <MenuContent className={menuContentClasses}>
          <MenuItem key="AuthLinks">
            <span className={css.menuItemBorder} />
            {signupOrLogin}
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  }

  const profileMenu = isAuthenticated ? (
    <Menu
      useArrow={false}
      contentPlacementOffset={contentPlacementOffset}
      useOverlay={isMobile}
      showOnHover={showOnHover}
    >
      <MenuLabel
        className={isMobile ? css.profileMenuLabelOnMobile : css.profileMenuLabelOnDesktop}
        isOpenClassName={css.profileMenuIsOpen}
      >
        {children}
      </MenuLabel>
      {menuContent}
    </Menu>
  ) : null;

  return profileMenu;
};

ProfileDropdown.defaultProps = {
  currentUser: null,
  currentPage: null,
  offersDot: null,
  isMobile: false,
  showOnHover: false,
};

const { bool, string, node, func } = PropTypes;

ProfileDropdown.propTypes = {
  currentUserHasListings: bool.isRequired,
  isAuthenticated: bool.isRequired,
  isArtist: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  children: node.isRequired,
  offersDot: node,
  isMobile: bool,
  onLogout: func.isRequired,
  showOnHover: bool,
};

export default ProfileDropdown;
