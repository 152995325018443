import abstractLandscapeImage from './assets/collections/Abstract_landscape.jpeg';
import abstractImage from './assets/collections/Abstract.jpeg';
import acrylicImage from './assets/collections/Acrylic.jpeg';
import animalsImage from './assets/collections/Animals.jpeg';
import beachImage from './assets/collections/Beach.jpeg';
import birdsImage from './assets/collections/Birds.jpeg';
import blackAndWhiteAbstractImage from './assets/collections/Black_and_white_abstract.jpeg';
import cloudsImage from './assets/collections/Clouds.jpeg';
import colorfulAbstractImage from './assets/collections/Colorful_abstract.jpeg';
import fineArtImage from './assets/collections/Fine-Art.jpeg';
import flowersImage from './assets/collections/Flowers.jpeg';
import fruitImage from './assets/collections/Fruit.jpeg';
import impressionistImage from './assets/collections/Impressionist.jpeg';
import landscapeImage from './assets/collections/Landscape.jpeg';
import modernImage from './assets/collections/Modern.jpeg';
import mountainImage from './assets/collections/Mountain.jpeg';
import natureImage from './assets/collections/Nature.jpeg';
import nudeImage from './assets/collections/Nude.jpeg';
import oceanImage from './assets/collections/Ocean.jpeg';
import oilPaintingAbstractImage from './assets/collections/Oil_painting.jpeg';
import pleinAirImage from './assets/collections/Plein-Air.jpeg';
import portraitImage from './assets/collections/Portrait.jpeg';
import skullImage from './assets/collections/Skull.jpeg';
import stillLifeImage from './assets/collections/Still_life.jpeg';
import waterImage from './assets/collections/Water.jpeg';
import watercolorImage from './assets/collections/Watercolor.jpeg';
import winterImage from './assets/collections/Winter.jpeg';

// Property name here such as oil-paintings, acrylic-paintings, abstract-paintings
// is path in Collection Page.

// path: param on URL. Ex: with http://www.sugarlift.com/collection/abstract-paintings, we will config path is abstract-paintings

// labelKey: value of labels field in Flex Console, also name of label on Google Cloud

// namePainting: is used in schemaTitle, schemaDescription, h1, Title of About section
// schemaTitle: {namePainting} for sale | Sugarlift
// schemaDescription: Collect {namePainting} from today’s best artists on Sugarlift.
// h1: {namePainting} for sale
// Title of About section: About {namePainting}

// collectionName: the text is displayed in Related Categories

// relatedCollections: value of other labels (labelKey) that we will query in Related Categories

// image: displayed in Related Categories

// aboutContent: content of About section

export const collections = {
  'oil-paintings': {
    path: 'oil-paintings',
    labelKey: 'oil_painting',
    namePainting: 'Oil paintings',
    collectionName: 'Oil',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: oilPaintingAbstractImage,
    aboutContent: `Styles of Oil Painting
    There are various oil painting styles and techniques, but almost all start with the artist creating a pencil or charcoal sketch of the subject matter. This is then followed by applying oil paint and a drying oil to the canvas.

    Famous styles include: 

    Chiaroscuro, where the enormous contrast between light and dark sections of the painting creates drama. Chiaroscuro was a favored style in the Old Masters’ oil paintings;

    Scumbling, where a stiff brush is used to apply thin layers of paint, leaving some of the original sketch exposed and creating excellent texture; 

    Impasto oil painting, where thick brush strokes make areas of the canvas prominent, and the mark of the brush itself remains clear. Impasto is often used in abstract oil painting.

    Subjects in Oil Painting
    As one of the oldest and most famous forms of painting, oil paintings have covered a vast range of subject matter in the past. Oil painting is often used for portraits, dramatic crowd scenes, and powerful landscapes. The rich colors, thick textures, and high contrast of the form make it an exceptional way to convey emotional, impactful scenes. 

    The central aspect of oil painting that has affected its subject matter is the need to mix the paint immediately before painting. This largely constrained the art form to the studio until the 19th century and the invention of the metal paint tube. This meant painters could mix colors rapidly and simply, allowing them to venture further afield to paint landscapes, still lifes, flowers, mountains, and seascapes.
    
    Materials Used in Oil Painting
    Oil painting on canvas consists of pigments suspended in a drying oil, which binds the paint together. Each oil has a slightly different effect on the paint’s overall look and style, and linseed oil, poppy seed oil, and walnut oil are all commonplace. Oil paints also often include a resin which acts as a varnish and transmits the classic deep shine and gloss of oil painting on canvas. 

    While the first original oil paintings were found on stone walls, wood panels were the most popular medium until the 16th century. Renaissance artists moved to canvas in the 16th and 17th centuries, and abstract and modern oil paintings can be found on everything from ivory to copper to glass. 
    
    Famous Artists of Oil Paintings
    Oil painting is an ancient art, with the original oil paintings dating back to the 7th century AD, created by Buddhist artists in Afghanistan. The style took off in Western Art during the Renaissance when oil painting on canvas almost completely replaced tempera paints. 

    Almost every major Western artist has used oil painting in some form. Jan van Eyck was one of the first leading proponents of the method in Bruges in the 15th century and defined the Early Netherlandish style with his oil paintings.. Da Vinci, Giorgione, Tintoretto, and Titian created many famous oil paintings in Italy during the Renaissance. Rachel Ruysch was a well known oil painter of flowers in the Netherlands during the 18th century.

    In the 19th century, the invention of the metal paint tube revolutionized the form, and artists like Renoir, Van Gogh, Monet, and Picasso all made oil painting their own.`,
  },
  'acrylic-paintings': {
    path: 'acrylic-paintings',
    labelKey: 'acrylic_painting',
    namePainting: 'Acrylic paintings',
    collectionName: 'Acrylic',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: acrylicImage,
    aboutContent: `Styles of Acrylic Painting
    Acrylic paint is a form of synthetic resin that dries quickly and has both the bold, deep shine and gloss associated with oil painting and the bright transparency of watercolor. The paints are immensely flexible and easy to work with, making them a firm favorite with modern artists.

    Modern acrylic painting takes many forms. Due to its versatility, it can be mixed easily with other media, making abstract acrylic painting extremely popular. Realistic acrylic painting is also commonplace, as details are easy to pick out. 

    Acrylic paint was only invented in the 1940s, so contemporary acrylic painting styles dominate. It was a hugely popular technique during the 1960s, and played a prominent role in Pop Art, Optical Art, and color field painting. 
    
    Subjects in Acrylic Painting
    The main advantage of acrylic paint over oil paint is its fast-drying properties. It makes it incredibly versatile and portable, meaning that the subject matter of acrylic paintings is almost limitless! 

    Acrylic flower painting is often favored by those new to the form, as flowers are a beautiful and colorful subject, brought to life with acrylic paint. Natural themes abound in acrylic painting due to how easy the paint is to work with, making landscapes, trees, the ocean, and mountains popular subjects. 

    Due to the popularity of acrylic as a painting medium during the 60s and its influence on Pop Art, there is an array of famous acrylic paintings that use both hyper-realistic and deeply abstract subjects. 
    
    Materials Used in Acrylic Painting
    Acrylic paint is an extraordinarily flexible medium and, depending on the dilution, can resemble gouache, oil painting, and even watercolor. The paint itself is a mixture of pigment and acrylic polymer emulsion, with various stabilizers and plasticizers to create a fast-drying effect. 

    Acrylic painting on canvas is the most common form, but acrylic paint can bond with almost any surface or material. From paper and engineered wood to plastic and ceramics, colorful acrylic painting on a vast range of media is not unusual. 

    Acrylic painting often uses mixed media to create exciting effects, using sand or rice mixed directly into the paint. Black and white acrylic painting can create dramatic contrasts and is often chosen for large canvases or blank walls. 

    Famous Artists of Acrylic Paintings
    Due to the relative modernity of the style, most famous acrylic paintings date from the second half of the 20th century and onward. Acrylic painting had a significant influence on a variety of art forms from the 60s on, including Pop Art, Optical Art, and the Color Field Movement.

    Andy Warhol and Roy Lichtenstein used acrylic paint extensively in their work. Both Helen Frankenthaler and Kenneth Noland took advantage of the properties of acrylic to use the “soak-stain” effect in their color field paintings, and Morris Louis’s Unfurled series features some of the most ambitious acrylic works ever painted.`,
  },
  'watercolor-paintings': {
    path: 'watercolor-paintings',
    labelKey: 'watercolor_painting',
    namePainting: 'Watercolor paintings',
    collectionName: 'Watercolor',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: watercolorImage,
    aboutContent: `Styles of Watercolor Painting
    Watercolor painting or aquarelle dates back to the ancient paintings on the cave walls of paleolithic Europe and was the preferred method for coloring manuscripts from Ancient Egypt to Medieval France. It became a recognized art form in the 15th and 16th centuries, first with Dürer himself and then with the Dürer Renaissance. 

    Botanical, realistic watercolor paintings have long been an enormously popular expression of the art form and were influential in the formation of the English School. In 18th-century England, watercolor painting was considered a demonstration of a good education.

    More modern watercolor painting styles include abstract and Impressionist paintings, and the form also played a considerable role in the rise of contemporary realism. 
    
    Subjects in Watercolor Painting
    Since its earliest arrival as a recognized art form, watercolor paintings of natural themes such as flowers, animals, trees, and birds have been immensely popular. In Baroque and Renaissance paintings, watercolors were often used for botanical illustrations and scientific or academic publications.

    Due to its transparency and glorious color, aquarelle is a medium that lends itself incredibly well to landscapes and the natural world. Sunset watercolor paintings can capture the golden sun’s rich fading glow, while seascapes and ocean watercolor paintings show shifting colors and reflections on the water perfectly. Still lifes in watercolor highlight the soft coloring of fruit, while landscape still lifes emphasize light and shadow in unique and delicate ways.
 
    Materials Used in Watercolor Paintings
    Aquarelle paint includes four main ingredients: pigment for color, a binding agent, additives such as honey to add thickness, and water as a thinner. 

    Small watercolor paintings on paper are often bright, colorful pieces that use the water in the paint to create a classic drip effect. However, although these washed-out paper aquarelles are the most famous form of the medium, they aren’t the only way of working with watercolors. 

    Watercolor and ink paintings add water-soluble ink to the paint to create a wash effect often used in Asian brush, scroll, or woodcut painting. In Chinese, Japanese, and Korean painting, monochrome aquarelle has often been the dominant form, creating black and white watercolor paintings using inkstick and other pigments.
    
    Famous Artists of Acrylic Paintings
    There are famous watercolor paintings by Van Gogh, Cezanne, Van Dyck, and John Singer Sargent. J.M.W. Turner is one of the most important artists in the history of aquarelle and is often credited with establishing watercolor as a serious art form, for previously, the art establishment in Europe was primarily concerned with oil paintings and did not believe that the same technique and skill could be applied to watercolor paintings.

    Watercolor has a prominent place in contemporary art too. Artists like Kandinsky, Edward Hopper, and Georgia O’Keefe all created beautiful watercolor paintings, and it remains an important medium today.`,
},
  'abstract-paintings': {
    path: 'abstract-paintings',
    labelKey: 'Abstract',
    namePainting: 'Abstract paintings',
    collectionName: 'Abstract',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: abstractImage,
    aboutContent: `Styles of Abstract Painting
    Abstract paintings take the basics of visual art—shape, form, color and line—to create a complete piece that conveys meaning beyond the physical or visual world. The abstract genre has roots in ancient cultures that endeavored to represent concepts of zen and enlightenment. Modern abstract painting grew out of the 19th century ideas of Romanticism, Impressionism and Expressionism, as a way for artists to connect with the emotional aspect of art. Contemporary abstract artists span a wide range of abstract styles, often incorporating simple, minimalist, and geometric motifs.
    Subjects in Abstract Painting
Abstract art is versatile—gallerists, artists, and private collectors have been drawn to it because of its ability to evoke emotion and purity of aesthetic  across cultures and eras. While not everyone relates to abstract painting, the variety employed by abstract artists—from black and white abstract paintings, to bright, blue abstract statements, to monochromatic neutral paintings—makes it easy for anyone to find a piece that speaks to them. Innovative and perceptive pieces take textures, lines and colors and turn them into emotive abstract paintings imbued with energy, emotion or refined visual form.`,
  },
  'landscape-paintings': {
    path: 'landscape-paintings',
    labelKey: 'Landscape',
    namePainting: 'Landscape paintings',
    collectionName: 'Landscape',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: landscapeImage,
    aboutContent: `Styles of Landscape Paintings
    Landscape paintings are a versatile genre that lends itself as much to representation as to abstraction. Landscape paintings are especially popular in Impressionism, as painters sought to represent the motion of - and emotion inspired by - such scenes as a falling autumn leaf or fading purple sunset. Landscape painters often work en plein air, or outdoors, in order to capture their subjects in the moment. Contemporary styles such as modern and abstract landscapes take the same impulse to capture a moment and translate it into minimalist landscapes or monochromatic scenes.
    
    Subjects in Landscape Paintings
    Landscape paintings are studies and celebrations of nature. The infinite ecosystems and habitats of the natural world may be contained within landscape paintings, from the largest vistas to the smallest cross sections of plant and animal life. Mountain landscape paintings may capture dramatic peaks, rolling hills or rocky desert outcroppings. International in their appeal and reach, landscape painters take on everything from Irish coastlines to French country meadows, Japanese mountains, golden Tuscan hilltops or California forests as their subjects. 

Perpetually subject to changes in light and color depending on the season or even the time of day, landscape paintings enable an artist to immortalize a moment in nature. Fleeting sunsets can be captured with a brushstroke, and a single view can be presented completely differently in spring, summer, fall and winter.
    
    Materials Used in Landscape Paintings
    Landscape paintings rely on an artists’ individual technique and style, with material choice greatly influencing a piece’s overall atmosphere. Acrylic landscape paintings are often vibrant with beautiful, layered appearances. Oil paint holds a lot of pigment for rich, deep colors. 

Watercolor landscapes are popular, with watercolor’s signature transparent effect creating stunning skies, seas, and everything in between. Gouache, often used in tandem with watercolor, can add beautiful highlights, fine details, and grounding shadows. Many original landscape pieces use a combination of mediums to create the perfect picture of nature on canvas.
    
    Famous Artists of Landscape Paintings
    Famous landscape paintings populate museums, galleries, homes and collections from all eras of art history. Some of the most famous pieces are from Impressionist masters like Van Gogh, Monet, and Morisot, who painted landscape paintings to represent the moment they were created and often returned to the same subject in different lights and seasons. Modern painters like Georgia O’Keeffe also made their mark on landscapes, inspired by unique, unusual or neglected natural vistas.`,
  },
  'fine-art-paintings': {
    path: 'fine-art-paintings',
    labelKey: 'Fine Art',
    namePainting: 'Fine art paintings',
    collectionName: 'Fine Art',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: fineArtImage,
    aboutContent: `Styles of Fine Art Paintings
    The legacy of art history’s most famous painters lies in fine art paintings. Fine art is a distinction used to designate museum-quality paintings that could be as easily featured in a gallery or in a private home. Modern fine art paintings elevate cultural ideas to poignant, conceptual and nuanced pieces. Sugarlift artists create contemporary fine art in diverse and engaging paintings, from minimalist to abstract and more.
    
    Subjects in Fine Art Paintings
    Fine art paintings take inspiration from nature, culture, color, abstract concepts and everyday life to create unique pieces of art. Throughout history, subjects of fine art paintings have varied as widely as artistic styles. Fine art nudes, landscapes and portraits are found in museums as well as private collections. Animals and the natural world have influenced art in their own ways. Powerful and meaningful paintings of horses, still lifes, flowers, birds and beaches are popular forms of fine art.
    
    Materials Used in Fine Art Paintings
    Traditional fine art includes paintings in oil on canvas, acrylic on canvas, and watercolor. Painters working on studies or collections may master sketches first, creating stunning fine art drawings that may start and end as works on paper or later be incorporated into larger paintings. Fine art paintings are most often created on canvas, with special attention to presentation and durability.
    
    Famous Artists of Fine Art Paintings
    Famous fine art paintings are the inspiration for our artists at Sugarlift. Throughout history, innovation and expression have created the most significant artistic movements and pieces. Fine artists like Claude Monet, Frida Kahlo, Joan Miró and Marc Chagall were all innovators during their time. Our curated collection features contemporary innovators in fine art.`,
  },
  'modern-paintings': {
    path: 'modern-paintings',
    labelKey: 'Modern',
    namePainting: 'Modern paintings',
    collectionName: 'Modern',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: modernImage,
    aboutContent: `Styles of Modern Paintings
    Modern art paintings encompass a variety of painting styles that were popularized in the 20th century by innovative, experimental artists. Characterized by the bold use of shape and color, modern paintings were often abstract and minimalist in nature. Mid-century modern paintings especially relied on geometric patterns and shapes over more traditional representational styles.
    
    Subjects in Modern Paintings
    With experimentation at its core, modern art is marked by uncommon subjects, often emphasizing shapes. Black and white geometric paintings characterize much of the modern art canon. By contrast, other modern works incorporate curves and dots to create lyrical movement or soft pattern, along with bold colors to enhance expression. Distinct forms like basic shapes, curves and dots in bold colors anchor expressive compositions. 
    
    Materials Used in Modern Paintings
    Oil or acrylic on canvas are still the most popular mediums in modern art. Yet, these common materials may be wielded in uncommon ways. Artists create modern paintings that break out of the traditional rectangular mold at oversized scales or in varying shapes such as round tondos, narrow panels or multi-canvas pieces.
    
    Famous Artists of Modern Paintings
    Famous modern paintings are experimental, innovative, and deeply indicative of the human condition. Modern painters often forged their own paths by pursuing unique styles or by joining small schools of like-minded painters in movements like Cubism, Surrealism, and Fauvism. Famous modern artists like Pablo Picasso, Alexander Calder and Joseph Albers used shapes in their own unique ways to create timeless masterpieces. Minimalism is also a trademark of modern art: Agnes Martin used clean lines to create order and beauty in her paintings. The breadth of movements and ideas within modern art makes it one of the most interesting styles to collect.`,
  },
  'beach-paintings': {
    path: 'beach-paintings',
    labelKey: 'Beach',
    namePainting: 'Beach paintings',
    collectionName: 'Beach',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: beachImage,
    aboutContent: `Styles of Beach Paintings
    Beaches, beach scenes, and bathers on the beach are favorite genres for artists because of their expressive nature. Skilled painters are able to capture the tranquility and energy of the beach through art, creating atmospheric paintings that reflect a beach’s environs, whether light and sunny or dark and stormy. 

Abstract beach paintings grasp the essence of the beach, while leaving the viewer to interpret the intricacies of the actions or the subjects for themselves. Abstraction provides a modern take on the classic beach painting. The result is a poignant scene for beach lovers and romantics alike.
    
    Subjects in Beach Paintings
    Beach sunset paintings can be romantic, while beach scenes might be comical or nostalgic. Beach landscapes with crashing waves, the calm ocean or lighthouses in the distance create a sense of calm and connection to nature. Subjects like beach umbrellas, chairs and bathers can evoke nostalgia or tranquility, especially if the pieces are inspired by the painter’s own experiences and emotions.
    
    Materials Used in Beach Paintings
    Romantic watercolors are perfect for creating movement in waves and soft, natural lines of sand and water. Beach paintings in acrylic or oil layer vibrant swatches of color or blend subtle changes in tone and colors of the beach. Beach canvas paintings can be done en plein air—outdoors—to capture the natural contours of islands or coastlines. 
    
    Famous Artists of Beach Paintings
    Many famous artists have created beach paintings to capture the nostalgia, humanity and natural beauty of a day at the beach. Monet’s famous beach paintings are a study in color and contour, and they inspire painters and beachgoers alike to this day. Romantic and nautical enthusiast J.M.W. Turner painted dramatic, movement-filled beach paintings. Neoclassical painter and influential American Realist Edward Hopper’s beach paintings are idyllic pieces of Americana. Famous beach paintings inspire the artists and collectors at Sugarlift, with nostalgic and tranquil scenes evocative of seashores near and far.`,
  },
  'impressionist-paintings': {
    path: 'impressionist-paintings',
    labelKey: 'Impressionist',
    namePainting: 'Impressionist paintings',
    collectionName: 'Impressionist',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: impressionistImage,
     aboutContent: `Styles of Impressionist Paintings
    Impressionist painting was born in the 19th century and was initially considered a radical movement that broke the rules of academic painting. Originating with a small group of artists based in Paris, French Impressionist painting was initially rejected by the establishment before gaining immense popularity. By the end of the 19th century, Impressionist paintings had become widespread in the Salon: the authoritative institution on fine art in Paris.

The Impressionist style is defined by small, thin brushstrokes, unconventional angles, and a commitment to accurately depicting light and movement. The idea behind the technique is to show the overall look and feel of a scene rather than sticking to rigid lines and contours. 

In the 20th century, Impressionist painting was not restricted to France. There were a large number of American Impressionist painters, as well as others around the world. Impressionism also inspired new styles itself, including New Impressionism and pointillism.
    
    Subjects in Impressionist Paintings
    Impressionist painters generally painted outdoors, ‘en plein air’, so Impressionist landscape paintings, beach paintings, and other natural themes are typical. Impressionist painters focused on ordinary subject matter; day-to-day scenes, everyday life, and simple settings. As a result, there is a range of Impressionist paintings of Paris, where the movement was born, of figures, and of daily scenes. 

Because Impressionism is concerned principally with light and movement, the paintings’ actual subjects are often secondary to the overall visual effect they create. Impressionist landscape paintings and Impressionist paintings of water, for example, often convey the look and feel of the outdoors and the natural world rather than sticking to strict realism, contours, and defined lines. 
    
    Materials Used in Impressionist Paintings
    Perhaps more so than any other primary style of Western art, Impressionism was defined and driven by technological advances in painting materials. Before the mid-19th century, working with oil paints outside of the studio was complicated, as it involved carefully mixing pigment with oil. However, with the invention of the metal paint tube, Impressionist oil painting could be more spontaneous. It could leave the confines of the studio, to create the ‘en plein air’ style. 

Pure Impressionism tends to avoid black paint, relying on greys and the merging of darker colors to create shadow and contrast. While the most famous Impressionist painters worked in oil paint, more modern times have produced plenty of Impressionist acrylic and watercolor paintings. 
    
    Famous Artists of Impressionist Paintings
    The most famous Impressionist paintings tend to be by the small, original group of artists who founded the movement in Paris in the 19th century. Four painters in particular - Claude Monet, Alfred Sisley, Auguste Renoir, and Frédéric Bazille - are generally considered the fathers of Impressionism. Indeed, the movement took its name from a Monet painting, Impression, Soleil Levant.

Throughout the 19th century, other artists joined the original group. Henri Mattisse, Camille Pissarro, and Edgar Degas are probably the most famous later Impressionists, as well as the American Impressionist painter Mary Cassatt and the German Impressionist painter Max Liebermann.`,
  },
  'ocean-paintings': {
    path: 'ocean-paintings',
    labelKey: 'Ocean',
    namePainting: 'Ocean paintings',
    collectionName: 'Ocean',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: oceanImage,
    aboutContent: `Styles of Ocean Paintings
    For almost as long as art has existed, the ocean has been a significant inspiration. Artists across history and around the world have been drawn to the majesty, power, and extraordinary potential that ocean-themed paintings offer. 

Ocean paintings have featured particularly prominently in Japanese art. Since the 16th century, waves, the coast, and the sea have been some of the most important and significant aspects of Japanese art. There is an entire genre of Japanese screen painting known as ‘rough seas screens’, and large waves are commonplace themes too.

Abstract paintings also often use the ocean as a subject. The shifting colors, glistening reflections, and depth of the sea make it perfect for abstract artworks. 
    
    Subjects in Ocean Paintings
    Unsurprisingly, the primary subject matter of ocean paintings is the sea itself. However, this is far from the whole story. From sunsets sinking beyond the horizon to vast, crashing waves, ocean paintings offer an enormous range of subjects from which to choose. Artists use color in subtle and nuanced ways to capture the exact blue of the location they are depicting.

The drama of the sea has captured the imagination of artists throughout history, and stormy ocean paintings feature in the work of painters from Abraham Willaerts to Pierre-Auguste Renoir. Ocean water painting isn’t always a direct representation of the subject matter either. Artists like Walter Crane have reimagined the form, featuring horses or other abstract concepts in his paintings of the ocean and the sky. 
    
    Materials Used in Ocean Paintings
    Ocean art has been painted in every era of history, throughout every style of recognized art, and using every material it is possible to think of. The nature of the ocean - powerful, changeable, vast - means that there is no limit to the materials that can be used to capture its essence.

Dark, dramatic oil paintings of the ocean were favored by artists in the 17th, 18th, and 19th centuries. Acrylic ocean paintings tend to be brighter and more realistic, taking advantage of the pigment’s flexibility and color. Unsurprisingly, watercolor painting is a beautiful technique for exploring the ocean on canvas, with transparent washes that perfectly mimic the sea’s look and feel.
    
    Famous Artists of Ocean Paintings
    Some of the most well-known paintings by the greatest artists in history have been their paintings of the sea. The famous ocean painting The Great Wave off Kanagawa by Katsushika Hokusai is generally considered to be the most recognizable piece of Japanese art in the world. Similarly, the ocean seascapes of JMW Turner are some of his best works and arguably what brought him such international acclaim.

Other famous artists inspired by the sea include Vija Celmins, Caspar David Friedrich, and Frederic Edwin Church. The painting by Claude Monet that began the Impressionist movement, Impression, Soleil Levant, was a seascape. Finally, Winslow Homer, and J.M.W. Turner are two of the most famous American artists of the 19th century, and are best known for their ocean paintings of the United States.`,
  },
  'water-paintings': {
    path: 'water-paintings',
    labelKey: 'Water',
    namePainting: 'Water paintings',
    collectionName: 'Water',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: waterImage,
    aboutContent: `Styles of Water Paintings
    Paintings of water are universal in their ability to evoke emotion through memory and sensory association. Representational and realist paintings of water tend to be highly detailed and focus on rendering the subject as it is in nature. Impressionism delicately or boldly captures water’s light and refraction, giving form to fleeting moments of salt spray or glinting sunlight off a lake surface. Abstract water paintings capture a memory or a feeling associated with being by the water.
    
    Subjects in Water Paintings
    Water is an alluring subject for painters and collectors alike. Art lovers may admire an artist’s technical ability to express the shape of water, and they find inspiration in its malleable yet constant nature. 

Water scenes may be quite tranquil, depicting calming ripples or sublime sunsets over water and an endless horizon. Ocean paintings may depict a fun, lighthearted beach scene, or they may challenge the viewer with impossibly vast open oceans or even stormy seas. 
    
    Materials Used in Water Paintings
    Details like glistening drops, sea foam or light reflections off of waves or calm bodies of water may be beautifully expressed by artists in oil or acrylic. Watercolor--no surprise--washes across a page in a water painting to capture the subject’s very essence. Oil pastel or gouache can also exquisitely highlight and express the water’s ever-changing nature.
    
    Famous Artists of Water Paintings
    Water paintings are a favorite subject in representational art as well as in abstract art. While modern painters like David Hockney take an experimental approach to water, emphasizing form and color, water paintings have generally inspired realist painters throughout the ages. Dutch master Willem Van de Velde specialized in realist paintings of ships, which inspired later generations of artists like J.M.W. Turner to create Romantic representations of water scenes. Later realists in contemporary art, such as photorealist painter Vija Celmins, took a minimal approach to water paintings.

Monet’s Water Lilies is perhaps the most famous series of water paintings, consisting of over 250 oil paintings that are now found in museums all over the world.`,
  },
  'nature-paintings': {
    path: 'nature-paintings',
    labelKey: 'Nature',
    namePainting: 'Nature paintings',
    collectionName: 'Nature',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: natureImage,
    aboutContent: `Styles of Nature Paintings
    Nature paintings, often of landscapes or animals, bring artists and viewers in touch with humanity as part of a larger ecosystem. Nature paintings can be meaningful reflections of our own existence, as seen in a landscape, a herd of wild horses, a tiny insect balanced on a leaf--subject matter as wide ranging as our natural world is diverse. Abstract nature paintings can evoke the same reflection while calling forth more conceptual ideas of nature. 
    
    Subjects in Nature Paintings
    Landscape nature paintings highlight the natural world in everything from expansive scenery to a small cross section of the environment. Paintings may feature forests, mountains or fields of wildflowers. 

Nature paintings are a versatile genre; ranging from photorealist paintings of majestic predators to ethereal, Impressionism-inspired works capturing the wave of a bamboo grove in the wind or the trickle of a stream through a forest.
    
    Materials Used in Nature Paintings
    Nature paintings require blending, layering and high color and contrast control to capture the natural beauty of a landscape or living being. Nature acrylic paintings may utilize sculptural or topographical elements as layers of paint build upon each other. 

Nature paintings can fill large canvases in oils, oil pastels, or acrylics, juxtaposing large brushstrokes with precise details. Nature watercolors blend effortlessly, showing the interconnectedness of the natural world.
    
    Famous Artists of Nature Paintings
    Famous nature paintings from history are awe-inspiring in their detail and scope. French painters’ legacy of nature paintings dates back to the pre-Impressionism of Jean-Baptiste-Camille Corot. 

Impressionists and Romantics like Alfred Sisley and Gustave Courbet soon followed in Corot’s footsteps. American Romantic painters like John Constable, Thomas Cole, and Albert Bierstadt became known for sweeping landscapes of pristine wilderness in the United States, specifically as part of the Hudson River School movement. Contemporary painters, such as Yayoi Kusama, combine abstraction and representation to render colorful, expressionist paintings of our natural world.`,
  },
  'mountain-paintings': {
    path: 'mountain-paintings',
    labelKey: 'Mountain',
    namePainting: 'Mountain paintings',
    collectionName: 'Mountain',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: mountainImage,
    aboutContent: `Styles of Mountain Paintings
    Mountain ranges’ natural variation throughout the world has served as inspiration for many stunning artworks. Mountains have distinct forms and shapes that lend themselves to geometric paintings and minimalist scenes. Large mountain paintings can cover entire walls in murals or multi-canvas collections. Abstract mountain paintings capture the essence of a mountain—the sublime “top of the world” feeling or the isolation of standing removed from those in the valley below.
    
    Subjects in Mountain Paintings
    Mountain landscape painters incorporate light in unexpected ways, such as depicting the mountain at dusk or at night. Seasons and weather can completely change the emotional quality of a mountain. A painting of a snowy mountain might feel stark or minimal, while a mountain range painting capturing spring or fall might feel cozy or uplifting. 

Mountain scenes vary in topography and play host to a myriad of surrounding flora and fauna. Blue Ridge Mountain paintings feature soft, rolling peaks and layers of background mountains stretching for miles, while paintings of the Rocky Mountains  in Colorado contrast towering summits with surrounding flat plains.
    
    Materials Used in Mountain Paintings
    Due to the particular attention paid to the lighting in mountain paintings, many painters turn to versatile paints such as acrylic or oil in order to create both the broad, sweeping strokes and fine detailed work which come together to create a spectacular mountain landscape.

Watercolor mountain paintings can create a perfect diffuse wash of distant mountain ranges, while still keeping the foreground details fresh and vibrant. Mountains can be beautifully rendered in black and white paintings, in acrylic or oil on canvas. Vibrant hues or spring or fall create stunning colorful mountain paintings, often in bright blue and greens.
    
    Famous Artists of Mountain Paintings
    Mountain paintings were a favorite of 19th century Romantic painters, and remain popular for modern, minimalist and contemporary painters today. Romantics like John Constable brought dramatic majesty to their mountain paintings—with sweeping vistas and detailed skyscapes. 

The Hudson River School, made up of the likes of Albert Bierstadt, Frederic Edwin Church and Thomas Cole,especially loved painting the American mountain ranges of the Catskills, Adirondacks and White Mountains, and their paintings have become iconic renderings of the hopefulness and power harnessed within nature.`,
  },
  'portrait-paintings': {
    path: 'portrait-paintings',
    labelKey: 'Portrait',
    namePainting: 'Portrait paintings',
    collectionName: 'Portrait',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: portraitImage,
    aboutContent: `Styles of Portrait Paintings
    Portrait paintings are a classic genre of fine art, with examples from many movements throughout art history. Portraits are fascinating windows into the humanity of painters and their subjects, and portraits can be some of the most expressive pieces in a collection. 

Examples of Classical and Renaissance portraiture show the values of the both painter and the subject, featuring conspicuous displays of wealth along with virtuosic talent and technique from the painter in creating realistic portraits. These pieces formed the basis of modern portrait paintings and contemporary portraiture. Moving beyond realism into expressionism and abstraction, contemporary portraits display the same artistic prowess balanced with the emotion, depth, and inner life of the subject. 
    
    Subjects in Portrait Paintings
    Portrait painting is as much an expression of the artist as it is of the subject. Artists choose diverse subjects for portraits, often created as studies or in series with multiple pieces of the same subject. Artists may undertake self portraiture to hone technique or explore inner psychologies. Whether meant for private studio practice or public exhibition, self portraits offer a fascinating look into how artists choose to represent themselves. 

Traditional portrait paintings have featured individuals in highly posed and choreographed settings. Unsurprisingly, modern artists have subverted such tradition by choosing unique settings or even centering a non-human subject in a portrait style.
    
    Materials Used in Portrait Paintings
    Classical portrait paintings are in oil on canvas. Contemporary artists may lean into or consciously subvert this convention, playing with new materials for portrait paintings. 

Acrylic portrait paintings can have a more modern look, with the medium engendering colorful and energetic pieces. Watercolor portraits are also popular, with poetic realism achieved by layering washes of tones to create skin, hair, and features.
    
    Famous Artists of Portrait Paintings
    In a society that weighs artistic talent as a unique kind of genius, many of the most famous portrait paintings are self-portraits by famous artists throughout time. Baroque painter Rembrandt and Early Renaissance artist Sandro Botticelli also took brush to canvas for portraits of noble people and mythological figures. Of course, Renaissance portraiture notably includes Mona Lisa by Leonardo DaVinci. 

Later painters who were especially known for self-portraits include Frida Kahlo and Vincent van Gogh. Though the two used very different styles, the flawed yet proud nature of their self-portraits is strikingly similar. The famous artist Pablo Picasso was also a prolific painter of portraits, both self-portraits and portraits of models and friends.`,
  },
  'plein-air-paintings': {
    path: 'plein-air-paintings',
    labelKey: 'Plein Air',
    namePainting: 'Plein air paintings',
    collectionName: 'Plein Air',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: pleinAirImage,
    aboutContent: `Styles of Plein Air Paintings
    The French en plein air translates to “outdoors.”  Plein air paintings are done outside the artist’s studio, with a portable easel or just a notebook of paper and a few brushes. Paintings “en plein air” are extemporaneous, capturing a moment as it appears to the painter. Artists can return to the same scene in multiple days and paint completely different pieces based on the same subject. 

Impressionist painters especially enjoyed plein air paintings because they were able to observe a moment as it happened and represent the passing of time. Plein air settings are also ideal for abstract paintings, which draw inspiration from atmosphere and feeling.
    
    Subjects in Plein Air Paintings
    Plein air landscapes are the hallmark of the genre, and are the result of artists setting up easels anywhere -- from the remote wilderness to their own backyards. As is typical with other styles of paintings, plein air encapsulates a moment in time. Thus, external elements such as weather and time of year greatly influence painting compositions. Winter plein air paintings can be still and contemplative, incorporating bare trees or snowy hills, while beach plein air paintings offer motion in breezes, waves or wildlife. 
    
    Materials Used in Plein Air Paintings
    Plein air paintings are often created with gouache, a versatile, water-soluble paint perfect for quick renderings or more considered layers of composition. Gouache is often paired with watercolor, which is also a popular medium for plein air paintings. 

Acrylic plein air paintings are favored by some artists because acrylic paints are easily portable and dry quickly. Plein air landscapes in oil paint are a classic, traditional style that many painters still use, as well. Oil paints’ rich hues and ability to blend create stunning landscape paintings.
    
    Famous Artists of Plein Air Paintings
    Plein air paintings were the hallmark of many Impressionists, who broke down traditional barriers between artist and subject by traipsing into fields, setting up easels and creating impressions of a moment in their paintings. 

John Singer Sargent was an early adopter of the plein air style and inspired many subsequent generations of painters. Sargent’s fellow Impressionists like Claude Monet, Pierre-Auguste Renoir and Berthe Morisot also painted en plein air, creating a series of plein air landscape studies to observe lighting, composition and natural balance.`,
  },
  'cloud-paintings': {
    path: 'cloud-paintings',
    labelKey: 'Cloud',
    namePainting: 'Cloud paintings',
    collectionName: 'Cloud',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: cloudsImage,
    aboutContent: `Styles of Cloud Paintings
    Cloud paintings are inspiring in their transient nature—clouds in any configuration will appear completely different at any moment. Abstract cloud paintings capture the essence of clouds and allow the viewer to interpret the shapes within their ephemeral nature. Cloud paintings are just as inspiring in realist or photorealist styles, where representation is clearer, and balance in nature becomes all the more important.
    
    Subjects in Cloud Paintings
    Cloud paintings capture the endless variations of the sky—balancing light, shape and form. Sunset paintings feature stunning clouds in colorful iterations of purple, pink and orange. Cloud paintings at dusk and storm cloud paintings create effortlessly moody pieces, while still maintaining balance. Shades of purple and pink give way into night, where light from the moon creates shadows in the dark clouds.
    
    Materials Used in Cloud Paintings
    Cloud paintings can be beautifully rendered in every classic paint media. Acrylic cloud paintings sometimes feature sculptural elements of clumped or layered paint to create texture in a natural landscape. Oil paintings of clouds can be realistic or abstract, with masterful blending creating highlights and shadows in an ethereal, ever-changing sky.
    
    Famous Artists of Cloud Paintings
    Dutch masters like Jacob van Ruisdael instilled a moody effect into realist landscapes with shadowy clouds. Romantics J.M.W. Turner and John Constable were famous for dreamy landscapes with voluminous clouds. 

As painters turned away from realism in the late 19th to 20th centuries, they started creating paintings of just clouds or skylines with geometric patterns. Georgia O’Keeffe’s geometric series on clouds is a seminal work of abstract art. René Magritte’s Surrealist clouds work with whimsy and color to create profound meaning. Modern and contemporary painters’ skyscapes are as varied as their cloud subjects.`,
  },
  'flower-paintings': {
    path: 'flower-paintings',
    labelKey: 'Flower',
    namePainting: 'Flower paintings',
    collectionName: 'Flower',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: flowersImage,
    aboutContent: `Styles of Flower Paintings
    Flower paintings are some of the most popular paintings throughout art history. As varied in hue as they are in shape and setting, flowers offer diverse subject matter for painters, past and present. Still life flower paintings are a classic style where a painter might study and capture a bouquet, a table set with flowers or even a single stem. Dutch Golden Age painters created realistic flower paintings, while Impressionist artists moved away from representation into expression. 

Modern flower paintings capture nature’s beauty, offering sweet moments of aesthetic pleasure in our increasingly busy and urban lives. Bold colors and shapes in modern renderings give way to elegant details in minimalist flower paintings, highlighting the flowers’ fragility and fleeting beauty. Abstract flower paintings may be more gestural, intellectualizing this popular subject. 
    
    Subjects in Flower Paintings
    Flowers are versatile subjects for paintings. They can represent abundance, as in a painting of an expansive wildflower field, or they can create a sense of stillness. Flower bouquet paintings may feature combinations of  roses and peonies in beautiful arrangements. Still lifes feature flower vases in colorful or black and white paintings. 

Delicate flowers and elegant blooms come in a multitude of colors and types. Yellow daisy flower paintings are vibrant pops of color, while blue and white flower paintings can blend and create impressions of floral scenes. Paintings with fields of red poppies or soothing lavender flowers evoke smells as well as sights to summon memories of carefree summer days.
    
    Materials Used in Flower Paintings
    Color is vital to flower paintings, and vibrant acrylic flower paintings are popular for their layered, many-hued nature. Flower paintings on canvas might be life-sized or bloom larger on oversized canvases. Flower oil paintings can create a traditional look, with perfectly blended composition and delicate floral details.
    
    Famous Artists of Flower Paintings
    Flower paintings in landscape and still life styles have been popular throughout all eras of art history. The most famous flower paintings are from Impressionist icons like Claude Monet and Édouard Manet. Expressionist masters like Vincent van Gogh and Henri Matisse utilized vibrant, almost wild color palettes to perfectly capture flowers’ prismatic vibrance. 

Pablo Picasso’s flower paintings paved the way for modern painters to experiment with color, shape and abstraction. In Contemporary art, Georgia O’Keeffe created abstract flower paintings of many styles, and pop artists like Takashi Murakami have experimented with minimalist, colorful and geometric flower paintings.`,
  },
  'winter-paintings': {
    path: 'winter-paintings',
    labelKey: 'Winter',
    namePainting: 'Winter paintings',
    collectionName: 'Winter',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: winterImage,
    aboutContent: `Styles of Winter Landscape Paintings
    Idyllic, peaceful and charming, winter landscape paintings can be some of the most beautiful works of art. The genre can also be challenging for the artist, to capture the reflection of light off of snow and the subtle magic a viewer feels upon seeing a landscape freshly blanketed in snow. Abstract winter landscapes evoke the enchanting hush of winter with a few brushstrokes and can capture the anticipation of awaiting spring or the peaceful slumber of embracing the season. Painters in the Dutch golden age created winter landscapes that were full of life and activity, an elegant juxtaposition to the sleepy snowy backdrop.
    
    Subjects in Winter Landscape Paintings
    Winter landscape paintings are pristine examples of form and balance in nature. Winter tree landscapes without leaves or flowers strip the volume out of forests and bring the eye to the details of composition and design in the winter forest. Snowy mountains or gentle rolling hills covered in snow are a favorite subject for painters of winter landscapes, and a favorite of collectors as well.
    
    Materials Used in Winter Landscape Paintings
    Material choice in winter landscapes is especially important because of the large amounts of white and the fine detail prevalent in snowscapes. Winter landscapes in oils bring out the glistening details of snow on branches or vintage-inspired scenes of red barns in the snow. 

Winter landscapes are also stunning in watercolors, blending soft winter skies seamlessly and adding stark details. Gouache is often used as a white highlight, and watercolor snowdrifts can be beautifully tinged by subtle blue.
    
    Famous Artists of Winter Landscape Paintings
    Winter landscape paintings by famous artists range from Renaissance to Contemporary periods. Pieter Bruegel painted winter landscapes of ice skaters, birds and snow-covered houses in the 16th century. Dutch painters such as Aert van der Neer followed in his footsteps, including bustling villages in their snowy scenes.  

Perhaps one of the most well-known landscape painters, Claude Monet, created his famous winter landscape paintings ‘en plein air’ or outdoors, braving the cold and wind in northern France to paint Impressionist pieces on snow-covered fields. Vincent van Gogh also painted winter landscapes, in his signature Expressionist style.`,
  },
  'bird-paintings': {
    path: 'bird-paintings',
    labelKey: 'Bird',
    namePainting: 'Bird paintings',
    collectionName: 'Bird',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: birdsImage,
    aboutContent: `Styles of Bird Paintings
    Birds have a natural beauty that inspires artists and collectors alike. Birds are classic motifs that can be symbolic of freedom or elegance. Paintings in styles from photorealism to impressionism show birds in their natural habitat, soaring in the sky or perched in a tree. Abstract bird paintings capture the joy of flight and a bird’s natural poise. Modern bird paintings emphasize color and shape—creating vibrant pieces of geometric inspired birds and habitats.
    
    Subjects in Bird Paintings
    Bird paintings offer endless fodder for elegant, intriguing bird subjects. Hummingbird paintings relate the breathlessness of the quick-moving birds as well as the exotic beauty of their tropical habitats. Modern paintings of birds on a telephone wire or colorful bird paintings in red or blue merge geometric shapes with a simple, relatable scene. 

Birds in their natural habitat, like flying bird paintings or paintings of birds on a tree bring the animal’s natural character to the foreground. Certain birds are also associated with seasons or actions, like stunning crimson cardinals during winter and singing bluebirds in spring.
    
    Materials Used in Bird Paintings
    Bird paintings rely on details and layering, making watercolor a popular choice for bird paintings. Watercolors, especially abstract watercolor bird paintings, create subtle washes of color, perfect for such an elegant subject. Acrylic and oil on canvas bird paintings are classic choices that lend versatility to realist pieces as well as modern creations. 
    
    Famous Artists of Bird Paintings
    Exotic, majestic birds have been a favorite subject for painters throughout history. Leornardo da Vinci was famous for his bird paintings, which are as much works of art as they are scientific marvels, as da Vinci worked out on paper the physics of a bird’s flight. Renowned bird enthusiast John James Audubon created stunning scientific drawings and paintings of birds in their natural habitat as he studied them. 

Famous bird paintings were also created by modern artists like Pablo Picasso, Henri Matisse, Joan Miro and Frida Kahlo. Picasso chose minimalist, colorful and Cubist styles for his bird paintings. His and Matisse’s flying white dove paintings evoked peace in times of war. Birds rendered in fractured lines and shapes frequented Miro’s canvases and Kahlo added many different birds to her famous self-portraits, including hummingbirds and parrots.`,
  },
  'still-life-paintings': {
    path: 'still-life-paintings',
    labelKey: 'Still Life',
    namePainting: 'Still life paintings',
    collectionName: 'Still Life',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: stillLifeImage,
    aboutContent: `Styles of Still Life Paintings
    Still life paintings are a classic genre that can show off a painter’s technique, unique style and point of view. Highly skilled painters from Baroque to Contemporary eras have used still lifes to study light, hone their talents, and show the beauty of shape and form. 

Historical pieces like Baroque and Renaissance still life paintings give insight into people’s daily lives and priorities during those times. Dutch still life paintings were popular during the Dutch Golden Age as showcases of a painter’s technical skill and social commentaries. 

Modern and contemporary still lifes subvert the realist ideas of earlier eras with styles ranging from delicate minimalism to colorful Cubism. Abstract still life paintings bring conceptual thoughts, feelings or ideas into the forefront. 
    
    Subjects in Still Life Paintings
    The beauty of a still life painting is often in the simplicity of its subject. Everyday objects like a bowl of fruit or a basket of flowers can create a compelling still life scene. Fruit still life paintings are popular and classically depict apples, oranges or lemons in a fruit bowl. Beyond the basics, painters have used any combination of grapes, watermelon, pears and other fruit in still life paintings. 

Vanitas still life paintings are especially symbolic, commenting on the fleeting nature of life and inevitability of death. Whether symbolic or prosaic, still life objects can range from the everyday, like a ceramic bowl, to the sumptuous, like lobster or a plate of oysters accompanied by spiral-peeled lemon. 
    
    Materials Used in Still Life Paintings
    Still life pieces, like many others, are often done as sketches, studies or in series. Sketches of still lifes can be pencil or charcoal drawings. Balance, composition, and form take precedence as sketches are incorporated into paintings. Watercolor still lifes are beautiful examples of still life paintings, with delicate layers creating the balance the genre is known for.
    
    Famous Artists of Still Life Paintings
    Still life paintings are a classic genre that many painters throughout history have used to express their own personal style. Famous still life paintings are hallmarks of every era, but the most well-known still life painter is probably the post-Impressionist Paul Cézanne. 

Cézanne’s still lifes and other works laid the foundations for later Expressionist and Fauvist pieces. Painters like Henri Matisse and Vincent van Gogh credit previous still lifes with their freedom to express and create the styles they are so well known for. Modernist still life is best known in the work of Pablo Picasso. Picasso created still life paintings throughout periods of his work, moving through multiple styles in modern art.`,
  },
  'colorful-abstract-art': {
    path: 'colorful-abstract-art',
    labelKey: 'Colorful Abstract',
    namePainting: 'Colorful abstract paintings',
    collectionName: 'Colorful Abstract',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: colorfulAbstractImage,
  },
  'skull-paintings': {
    path: 'skull-paintings',
    labelKey: 'Skull',
    namePainting: 'Skull paintings',
    collectionName: 'Skull',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: skullImage,
    aboutContent: `Styles of Skull Paintings
    Skull paintings are popular in many styles, from the macabre to cartoon and everything in between. Powerful symbols of life, death and humanity, skulls are compelling subjects for realist and abstract artists alike. Abstract skull paintings take the concept of humanity and allow room for viewer interpretation. Skull paintings are often black and white, but they can be especially powerful in colorful interpretations as well.
    
    Subjects in Skull Paintings
    Because of the strong representational ties of skull paintings, there are common themes that painters can work with to subvert and direct the viewer. Vanitas skull paintings are highly symbolic works that comment on the fleeting nature of life and the certainty of death. Other symbolic paintings of skulls reflect the concept of ‘Memento mori’ or ‘remember that you will die.’ More lighthearted paintings of skulls can include colorful representations or skulls and roses, representing the idea of love balancing death.
    
    Materials Used in Skull Paintings
    Artists can use many different media to create skull paintings, but most commonly they are in oil or acrylic on canvas. Acrylic skull paintings can be stunning in black and white or in color. Watercolor and oil paintings offer beautifully blended artwork and versatility from a classic still life to a modern or abstract piece.
    
    Famous Artists of Skull Paintings
    Skull paintings have been popular among famous painters throughout history. The medieval Vanitas style was expressed in stark detail by Baroque painters, with Dutch master Pieter Claesz becoming well known for his skull paintings. In the Renaissance era, the artist El Greco created his iconic skull paintings in the current realist style. 

More modern painters have been intrigued by the symbolism of skulls and have incorporated animal and human skulls into Expressionist, Modern and Abstract art. Post-impressionist painter Vincent Van Gogh’s famous “Head of a Skeleton with a Burning Cigarette” resides at the Van Gogh museum in Amsterdam. Pablo Picasso created Cubist pieces featuring exaggerated skull art and unusual scene choice. Georgia O’Keeffe is well known for her animal skull paintings featuring vibrant backdrops, and fellow Contemporary painter Jean-Michel Basquiat used his vibrant colors to deconstruct the skull form in his paintings.`,
  },
  'nude-paintings': {
    path: 'nude-paintings',
    labelKey: 'Nude',
    namePainting: 'Nude paintings',
    collectionName: 'Nude',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: nudeImage,
    aboutContent: `Styles of Nude Paintings
    Paintings of figures in the nude have been a common practice among artists from the beginning of art history. The fine art nude may lean in to or subvert ideas of privacy, beauty and refinement. Classical nude paintings were often of mythological figures, and nudes without mythological background were introduced in the Renaissance. 

Modern nude paintings focus on form, placing figures in unique or stripped-down settings. Abstract nude paintings capture the essence of humanity in a nude figure and play with the ideas of beauty, modesty and reality.
    
    Subjects in Nude Paintings
    Nude paintings feature diverse subjects and often focus on form and shape to create the scene. Nude woman paintings have been popular since the Baroque era, and contemporary nude figure paintings still commonly feature women. While the female nude remains prevalent, modern representations of identity and gender abound in contemporary art. Nude couples can convey intimacy and male nude figures may grapple with appearances of masculinity and expressions of strength and power, while also evoking beauty.

Models often pose for nude paintings, but nudes in candid attitudes are also common. Reclining nude paintings, standing nude paintings or nudes in silhouette can all be created from a model posing in a studio. These paintings are often created in series, with many pieces of the same subject from different angles or in different poses. 
    
    Materials Used in Nude Paintings
    Nude figures are commonly sketched numerous times as a painter creates studies and series of nudes to be included in paintings. Nude oil paintings have a classic look and often show beautiful blending to create depth. Acrylic paintings of nudes lend themselves to colorful pieces with plenty of personality.
    
    Famous Artists of Nude Paintings
    Famous artists have created nude paintings and sketches in every style throughout art history. Baroque painters like Sandro Botticelli created sumptuous nudes, often in mythological settings. The subsequent period of the Renaissance featured human and model subjects in nude paintings. Leonardo da Vinci’s famous nudes were studies of human anatomy and fuel for his inventions and scientific inquiries. 

Modern artists added expressive color palettes, geometric shapes and unique settings to their nudes. Henri Matisse created nude paintings in his signature colorful Fauvist style. Pablo Picasso painted exaggerated Cubist nude figures as well as more minimal figures in varied and non-traditional positions. Contemporary painters still paint nudes, from the subtle to the daring such as Jeff Koons’ controversial pieces.`,
  },
  'black-and-white-abstract-art': {
    path: 'black-and-white-abstract-art',
    labelKey: 'Black and White Abstract',
    namePainting: 'Black and white abstract art',
    collectionName: 'Black And White Abstract',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: blackAndWhiteAbstractImage,
  },
  'abstract-landscape-paintings': {
    path: 'abstract-landscape-paintings',
    labelKey: 'Abstract Landscape',
    namePainting: 'Abstract landscape paintings',
    collectionName: 'Abstract Landscape',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: abstractLandscapeImage,
    aboutContent: `Styles of Abstract Landscape Paintings
    Abstract landscape paintings draw on nature to bring out conceptual ideas and play on feeling as much as form. Abstract landscapes may be nonrepresentational pieces with fine detail in their brushstrokes, or they might be minimal, even gestural creations evoking a feeling, thought, or a memory associated with a landscape. Black and white abstract landscapes can add a minimal element to a scene, allowing the viewer to access the painting in a different way than a realist piece would. 
    
    Subjects in Abstract Landscape Paintings
    Landscapes draw from nature and show the beauty and variety of natural scenery in paintings. Abstract landscapes take that variety and distill it to a conceptual indication of a natural landscape. 

Abstract deserts may comment on the resilience of life and the wonder of nature in a starkly beautiful environment. Abstract beach paintings are great fodder for viewers’ own memories and interpretations of coastal scenes. Trees and forests are common features across continents, and abstract trees may represent grounded, rooted life and longevity.
    
    Materials Used in Abstract Landscape Paintings
    With the versatility of the abstract style, abstract landscape paintings can be created in any medium. Large canvases evoking expansive vistas are common, with mountain, forest or seascape scenes rendered in acrylic or oil. 

Acrylic abstract landscapes can be beautifully layered and use textural elements. Abstract landscape paintings in watercolor follow the loose, flowing quality of watercolors to create symbolic natural features. 
    
    Famous Artists of Abstract Landscape Paintings
    Famous abstract landscape paintings evolved out of the experimental work of pre-Impressionism. Paul Cézanne created non-realist landscapes in the 19th century, his work encouraging Expressionists like Vincent van Gogh to heighten emotion over realistic representation. 

Modern painters experiment with geometric shapes, colors and non-realistic elements to create abstract landscapes. Cubist and Pop artist David Hockney creates minimal, yet whimsical landscapes. Etel Adnan relies on geometric forms to anchor her colorful abstract landscapes. Perhaps the most conceptual of these modern artists is Richard Diebenkorn, whose colorful abstract landscapes evoke natural scenery while allowing a viewer to interpret his paintings in their own way.`,
  },
  'animal-paintings': {
    path: 'animal-paintings',
    labelKey: 'Animal',
    namePainting: 'Animal paintings',
    collectionName: 'Animal',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: animalsImage,
    aboutContent: `Styles in Animal Paintings
    Paintings of animals have varied widely in style throughout history. Contemporary animal paintings may have subjects ranging from the household to the exotic or fantastical. Animal paintings are popular in fine art as well as pop art, and modern abstract animal paintings present minimal ideas of nature and animals. 

Animals have appeared in paintings since the Classical era, and Renaissance animal paintings often featured trophies of hunts, animals in nature or family pets in unique singular or family portraits. 
    
    Subjects in Animal Paintings
    Animal paintings may be set in nature, such as wild horses on open plains. Wild animals and sea animals may be captured in their natural habitat or proudly posed across neutral backgrounds to highlight their majestic forms. 

Animal paintings as portraits of pets or beloved farm animals are close to many collector’s and private owner’s hearts. Many artists create custom pet portraits of their own animals or for other people’s cats or dogs. Particularly throughout history, dog portraits may feature specific breeds to commemorate the family pet. Baby animal paintings can adorn a nursery or add whimsy to any collection. 
    
    Materials Used in Animal Paintings
    The materials used in animal paintings depend on the style of the painting. Watercolor may beautifully render animals in the wild, with realistic movement and motion achieved through watercolor washes and layers. Acrylic animal paintings can be vibrantly colorful and whimsical. Animal oil paintings might lean more realistic, creating realistic scenes featuring pets or posed animals in a natural habitat.
    
    Famous Artists of Animal Paintings
    Famous animal paintings hang on museum walls and in collector’s homes from every era throughout history. Baroque and Dutch Golden Age painters added animals to paintings to create pastoral, idyllic scenes. Renaissance painters like Albrecht Durer created studies of animals from the exotic to the commonplace. Romantic painters were inspired by majestic, active creatures. For example, George Stubbs created elegant paintings of hounds on the hunt.

Modern artists have used animals to explore and comment on the nature of humanity. Georgia O’Keeffe’s animal paintings often feature skulls and stark deserts, while Henri Rousseau employed primitivism and magical realism to show real animals in a natural habitat, as well as imagined, mythical animals. Walton Ford’s paintings in the style of naturalistic illustrations highlight extinct, even fantastical creatures.`,
  },
  'fruit-paintings': {
    path: 'fruit-paintings',
    labelKey: 'Fruit',
    namePainting: 'Fruit paintings',
    collectionName: 'Fruit',
    relatedCollections: ['Abstract', 'Abstract Landscape', 'Modern', 'Impressionist'],
    image: fruitImage,
    aboutContent: `Styles in Fruit Paintings
    Fruit paintings are the perfect genre for artists and collectors alike to focus on detail, style and expression. Fruit still life paintings can serve as studies in technique and opportunities for an artist to experiment with style and representation. Realistic fruit paintings were common in the Renaissance, representing the refined style of the time and sometimes including social commentaries, witticisms or even souvenirs of exotic places. 

Impressionist painters created many fruit paintings, showing the impression of a round and textural piece of fruit. Abstract fruit paintings capture the feeling of fruit in your hand or the taste of a fruit’s juicy, perfect bite.
    
    Subjects in Fruit Paintings
    Fruit paintings often fall into the still life genre, with fruit in bowls or poetically arranged on tables. Fruit tree paintings create pastoral scenes of gathering fruit from an orchard or focal tree. 

Bowls of fruit like oranges, grapefruits and lemons are perfect subjects for fruit paintings, but artists often experiment with less common fruits as well. Dragon fruit paintings or exotic star fruit and lychee create interesting textures and details for a painter to play with. 
    
    Materials Used in Fruit Paintings
    Fruit paintings are versatile and can be rendered in many different mediums. Acrylic fruit paintings can be easily layered and hold bold, vibrant hues. 

Fruit watercolor paintings are full of movement and ethereal layers, which are perfect for watercolor still lifes of fruit. Fruit oil paintings on canvas blend seamlessly, creating beautiful highlights and shadows to focus the viewer on the painting’s rich colors. 
    
    Famous Artists of Fruit Paintings
    Fruit paintings by famous artists are prized by collectors and museums in styles and eras throughout art history. Baroque painters such as Caravaggio and Louise Moillon created fruit still lifes to focus on lighting and composition. 

Influential pre-Impressionist Paul Cézanne created his famous fruit paintings experimenting with the boundaries of the realism and representationalism of the time. Later modern painters like Frida Kahlo also used the still life style for her fruit paintings, focusing on vibrant colors and unique settings for different types of fruit.`,
  },
};
