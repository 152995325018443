/**
 * This component wraps React-Router's Link by providing name-based routing.
 *
 * The `name` prop should match a route in the flattened
 * routeConfiguration passed in context by the RoutesProvider
 * component. The `params` props is the route params for the route
 * path of the given route name.
 *
 * The `to` prop is an object with the same shape as Link requires,
 * but without `pathname` that will be generated from the given route
 * name.
 *
 * Some additional props can be passed for the <a> element like
 * `className` and `style`.
 *
 * The component can also be given the `activeClassName` prop that
 * will be added to the element className if the current URL matches
 * the one in the generated pathname of the link.
 */
import React from 'react';
import compact from 'lodash/compact';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import config from '../../config';

export const NamedLinkComponent = props => {
  const { name, params, title, isNewTab, path, useAbsoluteUrl, history, openInNewTab } = props;
  const routes = routeConfiguration();

  const onOver = () => {
    const { component: Page } = findRouteByRouteName(name, routes);
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  };

  // Link props
  const { to, children } = props;
  const pathname = path || pathByRouteName(name, routes, params);
  const { match } = props;
  const active = match.url && match.url === pathname;

  // <a> element props
  const { className, style, activeClassName } = props;
  const aElemProps = {
    className: classNames(className, { [activeClassName]: active }),
    style,
    title,
  };

  const combineLinkProps = () => {
    const { search, hash } = to;
    return compact([search, hash]).join('');
  }

  const linkProps = combineLinkProps();
  const absoluteUrl = `${config.canonicalRootURL}${pathname}${linkProps}`;

  const onClick = (event) => {
    if (!isNewTab) {
      event.preventDefault();
      history.push(`${pathname}${linkProps}`);
    }
  }

  const targetAttr = isNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  return useAbsoluteUrl ? (
    <a
      onMouseOver={onOver}
      onTouchStart={onOver}
      onClick={onClick}
      href={absoluteUrl}
      {...aElemProps}
      {...targetAttr}
    >
      {children}
    </a>
  ) : (
    <Link
      onMouseOver={onOver}
      onTouchStart={onOver}
      to={{ pathname, ...to }}
      {...aElemProps}
      {...targetAttr}
    >
      {children}
    </Link>
  );
};

const { object, string, shape, any, bool, func } = PropTypes;

NamedLinkComponent.defaultProps = {
  params: {},
  to: {},
  children: null,
  className: '',
  style: {},
  activeClassName: 'NamedLink_active',
  title: null,
  match: {},
  isNewTab: false,
  path: null,
  useAbsoluteUrl: false,
};

// This ensures a nice display name in snapshots etc.
NamedLinkComponent.displayName = 'NamedLink';

NamedLinkComponent.propTypes = {
  // name of the route in routeConfiguration
  name: string.isRequired,
  // params object for the named route
  params: object,
  // Link component props
  to: shape({ search: string, hash: string, state: object }),
  children: any,

  // generic props for the underlying <a> element
  className: string,
  style: object,
  activeClassName: string,
  title: string,

  match: object,
  isNewTab: bool,
  path: string,
  useAbsoluteUrl: bool,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const NamedLink = withRouter(NamedLinkComponent);
NamedLink.displayName = 'NamedLink';

export default NamedLink;
