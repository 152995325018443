import { ensureUser, ensureCurrentUser } from "./data";
import isEmpty from 'lodash/isEmpty';
import { ACCOUNT_TYPE_ARTIST } from '../util/types';

export const userIsArtist = user => {
  const ensuredUser = ensureUser(user);
  const { publicData = {} } = ensuredUser.attributes.profile;
  const { role } = publicData;
  return role === ACCOUNT_TYPE_ARTIST;
};

export const currentUserIsArtist = user => {
  const ensuredUser = ensureCurrentUser(user);
  const { publicData = {} } = ensuredUser.attributes.profile;
  const { role } = publicData;
  return role === ACCOUNT_TYPE_ARTIST;
};

export const artistIsMissingInfo = user => {
  const ensuredUser = ensureCurrentUser(user);
  const { publicData = {}, bio } = ensuredUser.attributes.profile;
  const { address } = publicData;
  return !(!isEmpty(address) && !!bio);
};

export const splitUserNameToSearchName = username => {
  const result = [];
  const words = username
    .toLowerCase()
    .trim()
    .replace(/\\/g, '\\\\')
    .split(/\s+/);

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    for (let j = 1; j <= word.length; j++) {
      for (let k = 0; k < word.length; k++) {
        const temp = word.slice(k, k + j);
        if (temp.length === j) result.push(temp);
      }
    }
  }
  return result;
};
