import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import config from '../../config';
import {
  getCurrentFavoriteListings
} from '../ManageFavoriteListingsPage/ManageFavoriteListingsPage.duck';
import { DEFAULT_SORT_BY_FEATURED, filterConfig, paramsSearchUrlDict } from '../../marketplace-custom-config';
import { search } from '../../util/api';
import { parse, reverseMappingParamSearchURL, reverseMappingParamValue } from '../../util/urlHelpers';
const RESULT_PAGE_SIZE = 18;
// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const SEARCH_ARTIST_FEATURED = 'app/SearchPage/SEARCH_ARTIST_FEATURED';

export const LOAD_MORE_REQUEST = 'app/SearchPage/LOAD_MORE_REQUEST';
export const LOAD_MORE_SUCCESS = 'app/SearchPage/LOAD_MORE_SUCCESS';
export const LOAD_MORE_ERROR = 'app/SearchPage/LOAD_MORE_ERROR';

// ================ Utils ================== //

export const checkHasKey = (options, key) =>
  options.some(option => option.key === key && !option.hideInFilter);
const compose = (...fns) => (...args) => fns.reduce((res, fn) => [fn.call(null, ...res)], args)[0];
/**
 * Join multiple choice route param with query param by ','
 * @param {Object} searchFilters
 * @param {Object} routeParams
 * @param {String} paramName
 * @returns {String} the param value or '' if the param is not found
 */
export const mixRouteAndQueryParam = (searchFilters, routeParams, paramName) => {
  if (routeParams?.hasOwnProperty(paramName)) {
    return `${routeParams[paramName]}${
      searchFilters[paramName] ? `,${searchFilters[paramName]}` : ''
    }`;
  }
  return '';
};

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  params: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  artists: [],
  loadMoreInProgress: false,
  loadMoreError: false,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        params: payload.params,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    case SEARCH_ARTIST_FEATURED:
      return {
        ...state,
        searchInProgress: false,
        artists: payload,
      };
    case LOAD_MORE_REQUEST:
      return {
        ...state,
        loadMoreInProgress: true,
        loadMoreError: null,
      };
    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMoreInProgress: false,
        currentPageResultIds: [...state.currentPageResultIds, ...resultIds(payload.data)],
        pagination: payload.data.meta,
      };
    case LOAD_MORE_ERROR:
      return {
        ...state,
        loadMoreInProgress: false,
        loadMoreError: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = (searchParams, params = {}) => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams, params },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const searchArtistFeatured = payload => ({ type: SEARCH_ARTIST_FEATURED, payload });

const loadMoreRequest = () => ({ type: LOAD_MORE_REQUEST });
const loadMoreSuccess = response => ({ type: LOAD_MORE_SUCCESS, payload: { data: response.data } });
const loadMoreError = error => ({ type: LOAD_MORE_ERROR, payload: error });

export const searchListings = (searchParams, pageSearch) => (dispatch, getState, sdk) => {
  dispatch(getCurrentFavoriteListings());
  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const priceSearchParamsForStored = priceParam => {
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [parseInt(values[0], 10), parseInt(values[1], 10)].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.bookingUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

    return hasValues
      ? {
          start: formatDateStringToUTC(startDate),
          end: formatDateStringToUTC(endDate),
          // Availability can be full or partial. Default value is full.
          availability: 'full',
        }
      : {};
  };
  const {
    perPage,
    price,
    dates,
    keywords,
    meta_likers,
    pub_availability,
    ...rest
  } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);

  const params = {
    // pub_isArtistFeatured: false,
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    pub_isAdmin: false,
    pub_listingType: 'art',
    per_page: perPage,
    'fields.listing': ['description', 'geolocation', 'price', 'title', 'publicData', 'metadata'],
  };

  for (let paramName in paramsSearchUrlDict) {
    if (paramName === 'pub_framed' || paramName === 'pub_dominantColors') {
      continue;
    }
    const paramValue = searchParams[paramName];
    if (paramValue) {
      params[paramName] = `has_any: ${paramValue}`;
    }
  }

  if (meta_likers) {
    params.meta_likers = `has_any: ${meta_likers}`;
  }

  if (keywords) {
    params.keywords = keywords;
  }

  if (!params.sort) {
    params.sort = DEFAULT_SORT_BY_FEATURED;
  }

  if (params.pub_roundHeight) {
    const heightSplit = params.pub_roundHeight.split(',');
    if (heightSplit[0] === heightSplit[1]) {
      params.pub_roundHeight = `${heightSplit[0]},${++heightSplit[1]}`;
    }
  }

  if (params.pub_roundWidth) {
    const widthSplit = params.pub_roundWidth.split(',');
    if (widthSplit[0] === widthSplit[1]) {
      params.pub_roundWidth = `${widthSplit[0]},${++widthSplit[1]}`;
    }
  }

  dispatch(
    searchListingsRequest(
      {
        ...params,
        ...priceSearchParamsForStored(price),
      },
      pageSearch
    )
  );

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest());

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
    pub_isFeatured: true,
    pub_isAdmin: false,
    'fields.listing': ['description', 'geolocation', 'price', 'title', 'publicData'],
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const searchByKeyword = keyword => (dispatch, getState, sdk) => {
  return search(keyword).then(res => res.data);
};

const canLoadMore = (pagination = {}) => {
  const { page, totalPages } = pagination || {};
  return page < totalPages;
};

const isLoadingMore = state => {
  const { loadMoreInProgress } = state.SearchPage;
  return loadMoreInProgress;
};

export const loadMore = () => (dispatch, getState, sdk) => {
  const { pagination, searchParams } = getState().SearchPage;
  if (isLoadingMore(getState()) || !canLoadMore(pagination)) return;

  dispatch(loadMoreRequest());
  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const { page, price, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const { page: currentPage, perPage } = pagination;
  const newParams = {
    page: currentPage + 1,
    ...rest,
    ...priceMaybe,
    per_page: perPage,
    pub_isAdmin: false,
  };
  return sdk.listings
    .query(newParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(loadMoreSuccess(response));
    })
    .catch(e => {
      dispatch(loadMoreError(storableError(e)));
      throw e;
    });
};

/**
 * Parse params of filters from url route params
 */
 export const parseFilterParams = filters => params => {
  const parsedFilters = {};
  Object.values(params).forEach(paramValue => {
    filters.forEach(filter => {
      if (checkHasKey(filter.options, paramValue)) {
        parsedFilters[filter.paramName] = paramValue;
      }
    });
  });
  return parsedFilters;
}

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  getCurrentFavoriteListings();
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...otherParams } = queryParams;
  const mixedPrimaryFilters = {};
  const primaryFilters = Object.values(filterConfig).filter(filter => filter.isRouteParam);
  const routeParams = compose(reverseMappingParamValue, parseFilterParams(primaryFilters))(params);
  const reversedSearchParams = compose(reverseMappingParamValue, reverseMappingParamSearchURL)(otherParams);

  primaryFilters.forEach(filter => {
    // Mix route param and query params for searching listings
    const mixedFilters = mixRouteAndQueryParam(
      reversedSearchParams,
      routeParams,
      filter.paramName
    );
    if (mixedFilters) {
      mixedPrimaryFilters[filter.paramName] = mixedFilters;
    }
  });

  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return dispatch(
    searchListings(
      {
        ...reversedSearchParams,
        ...originMaybe,
        ...mixedPrimaryFilters,
        page,
        perPage: RESULT_PAGE_SIZE,
        include: ['author', 'images'],
        'fields.listing': ['title', 'geolocation', 'price'],
        'fields.image': [
          'variants.scaled-medium',
          'variants.scaled-large',
          'variants.landscape-crop',
          'variants.landscape-crop2x',
        ],
        'limit.images': 1,
      },
      { ...reversedSearchParams, ...originMaybe, ...mixedPrimaryFilters }
    )
  );
};
