import moment from 'moment';
import { cloneDeep } from 'lodash';
import strapi from '../../util/strapi';
import {
  CURRENT_EXHIBITION,
  PAST_EXHIBITION,
  UPCOMING_EXHIBITION,
} from '../ExhibitionsNavigationPage/ExhibitionsNavigationPage.duck';

const EXHIBITION_PAGE_SIZE_DEFAULT = 12;
const WAITING_FOR_LOAD_EXHIBITIONS = 1000; // 1 second

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/NewGalleryPage/SET_INITIAL_STATE';

export const QUERY_GALLERY_PAGE_STRAPI_SUCCESS =
  'app/NewGalleryPage/QUERY_GALLERY_PAGE_STRAPI_SUCCESS';

export const QUERY_EXHIBITIONS_REQUEST = 'app/NewGalleryPage/QUERY_EXHIBITIONS_REQUEST';
export const QUERY_EXHIBITIONS_SUCCESS = 'app/NewGalleryPage/QUERY_EXHIBITIONS_SUCCESS';
export const QUERY_EXHIBITIONS_ERROR = 'app/NewGalleryPage/QUERY_EXHIBITIONS_ERROR';

export const LOAD_MORE_EXHIBITION_REQUEST = 'app/NewGalleryPage/LOAD_MORE_EXHIBITION_REQUEST';
export const LOAD_MORE_EXHIBITION_SUCCESS = 'app/NewGalleryPage/LOAD_MORE_EXHIBITION_SUCCESS';
export const LOAD_MORE_EXHIBITION_ERROR = 'app/NewGalleryPage/LOAD_MORE_EXHIBITION_ERROR';

// ================ Reducer ================ //

const initialState = {
  galleryData: null,
  exhibitions: [],
  currentExhibitionParamArray: [],
  queryExhibitionsError: null,
  loadMoreExhibitionInProgress: false,
  loadMoreExhibitionError: null,
  currentExhibitionPage: null,
  storedExhibitions: [],
};

export default function NewGalleryPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_GALLERY_PAGE_STRAPI_SUCCESS:
      return { ...state, galleryData: payload };

    case QUERY_EXHIBITIONS_REQUEST:
      return {
        ...state,
        currentExhibitionParamArray: payload.currentExhibitionParamArray,
        currentExhibitionPage: payload.currentExhibitionPage,
        queryExhibitionsError: null,
      };
    case QUERY_EXHIBITIONS_SUCCESS:
      return {
        ...state,
        storedExhibitions: payload.storedExhibitions,
        renderedExhibitions: payload.renderedExhibitions,
      };
    case QUERY_EXHIBITIONS_ERROR:
      return { ...state, queryExhibitionsError: payload };

    case LOAD_MORE_EXHIBITION_REQUEST:
      return {
        ...state,
        loadMoreExhibitionInProgress: true,
        loadMoreExhibitionError: null,
      };
    case LOAD_MORE_EXHIBITION_SUCCESS:
      return {
        ...state,
        loadMoreExhibitionInProgress: false,
      };
    case LOAD_MORE_EXHIBITION_ERROR:
      return { ...state, loadMoreExhibitionInProgress: false, loadMoreExhibitionError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryGalleryStrapiSuccess = data => ({
  type: QUERY_GALLERY_PAGE_STRAPI_SUCCESS,
  payload: data,
});

export const queryArtworkListingsSuccess = listings => ({
  type: QUERY_ARTWORK_LISTINGS_SUCCESS,
  payload: listings,
});

export const queryExhibitionsRequest = ({
  currentExhibitionParamArray,
  currentExhibitionPage,
}) => ({
  type: QUERY_EXHIBITIONS_REQUEST,
  payload: { currentExhibitionParamArray, currentExhibitionPage },
});
export const queryExhibitionsSuccess = ({ storedExhibitions, renderedExhibitions }) => ({
  type: QUERY_EXHIBITIONS_SUCCESS,
  payload: {
    storedExhibitions,
    renderedExhibitions,
  },
});
export const queryExhibitionsError = error => ({
  type: QUERY_EXHIBITIONS_ERROR,
  error: true,
  payload: error,
});

export const loadMoreExhibitionRequest = () => ({
  type: LOAD_MORE_EXHIBITION_REQUEST,
});
export const loadMoreExhibitionSuccess = () => ({
  type: LOAD_MORE_EXHIBITION_SUCCESS,
});
export const loadMoreExhibitionError = error => ({
  type: LOAD_MORE_EXHIBITION_ERROR,
  error: true,
  payload: error,
});

// ================ Util functions ================ //

export const mapExhibitionTypeToQuery = exhibitionType => {
  const today = moment().format(strapi.DATE_FORMAT);
  switch (exhibitionType) {
    case CURRENT_EXHIBITION:
      return {
        populate: '*',
        filters: {
          $and: [{ startDate: { $lte: today } }, { endDate: { $gte: today } }],
        },
        sort: { startDate: 'desc' },
      };
    case PAST_EXHIBITION:
      return {
        populate: '*',
        filters: {
          endDate: { $lt: today },
        },
        sort: { startDate: 'desc' },
      };
    case UPCOMING_EXHIBITION:
      return {
        populate: '*',
        filters: {
          startDate: { $gt: today },
        },
        sort: { startDate: 'asc' },
      };
    default:
      return {
        populate: '*',
      };
  }
};

// ================ Thunks ================ //
const canNotQueryMoreExhibitionAPI = (storedExhibitions, currentExhibitionPage) => {
  const {
    current: {
      meta: {
        pagination: { pageCount: currentPageCount },
      },
    },
    upcoming: {
      meta: {
        pagination: { pageCount: upcomingPageCount },
      },
    },
    past: {
      meta: {
        pagination: { pageCount: pastPageCount },
      },
    },
  } = storedExhibitions;

  return (
    currentExhibitionPage >= currentPageCount &&
    currentExhibitionPage >= upcomingPageCount &&
    currentExhibitionPage >= pastPageCount
  );
};

const getNewStoredExhibitions = (
  storedExhibitions,
  currentExhibitionRes,
  upcomingExhibitionRes,
  pastExhibitionRes
) => {
  const {
    current: { data: currentData },
    upcoming: { data: upcomingData },
    past: { data: pastData },
  } = storedExhibitions;

  return {
    current: {
      data: [...currentData, ...currentExhibitionRes.data],
      meta: currentExhibitionRes.meta,
    },
    upcoming: {
      data: [...upcomingData, ...upcomingExhibitionRes.data],
      meta: upcomingExhibitionRes.meta,
    },
    past: {
      data: [...pastData, ...pastExhibitionRes.data],
      meta: pastExhibitionRes.meta,
    },
  };
};

const mapExhibitionDataAfterQuerying = newestStoredExhibitions => async (
  dispatch,
  getState,
  sdk
) => {
  const {
    current: newestCurrentExhibition,
    upcoming: newestUpcomingExhibition,
  } = newestStoredExhibitions;

  const newestCurrentExhibitionLength = newestCurrentExhibition.data.length;
  const newestUpcomingExhibitionLength = newestUpcomingExhibition.data.length;

  const {
    renderedExhibitions: renderedExhibitionsState = [],
    storedExhibitions: storedExhibitionsState,
  } = getState().NewGalleryPage;

  const storedExhibitions = cloneDeep(newestStoredExhibitions || storedExhibitionsState);
  let renderedExhibitions = cloneDeep(renderedExhibitionsState);

  const {
    current: { data: currentDataRaw },
    upcoming: { data: upcomingDataRaw },
    past: { data: pastDataRaw },
  } = storedExhibitions;

  // Only render current exhibitions
  if (newestCurrentExhibitionLength === EXHIBITION_PAGE_SIZE_DEFAULT) {
    renderedExhibitions = [...renderedExhibitions, ...currentDataRaw];
    storedExhibitions.current.data = [];
  }
  // Only render current and upcoming exhibitions
  else if (
    newestCurrentExhibitionLength + newestUpcomingExhibitionLength >=
    EXHIBITION_PAGE_SIZE_DEFAULT
  ) {
    const necessaryUpcomingQuantity = EXHIBITION_PAGE_SIZE_DEFAULT - newestCurrentExhibitionLength;
    const slicedUpcomingExhibitions = upcomingDataRaw.slice(0, necessaryUpcomingQuantity);

    renderedExhibitions = [...renderedExhibitions, ...currentDataRaw, ...slicedUpcomingExhibitions];

    storedExhibitions.current.data = [];
    storedExhibitions.upcoming.data =
      upcomingDataRaw.length === necessaryUpcomingQuantity
        ? []
        : upcomingDataRaw.slice(necessaryUpcomingQuantity);
  }
  // Render current, upcoming and past exhibitions
  else {
    const necessaryPastQuantity =
      EXHIBITION_PAGE_SIZE_DEFAULT - newestCurrentExhibitionLength - newestUpcomingExhibitionLength;
    const slicedPastExhibitions = pastDataRaw.slice(0, necessaryPastQuantity);

    renderedExhibitions = [
      ...renderedExhibitions,
      ...currentDataRaw,
      ...upcomingDataRaw,
      ...slicedPastExhibitions,
    ];

    storedExhibitions.current.data = [];
    storedExhibitions.upcoming.data = [];
    storedExhibitions.past.data =
      pastDataRaw.length === necessaryPastQuantity ? [] : pastDataRaw.slice(necessaryPastQuantity);
  }

  return { renderedExhibitions, storedExhibitions };
};

const generateExhibitionParamsAndPromisesForLoadMore = (currentExhibitionParamArray, newPage) => {
  return currentExhibitionParamArray.reduce(
    (result, param) => {
      param.pagination.page = newPage;
      result.exhibitionParams.push(param);
      result.exhibitionPromises.push(strapi.get(`/api/exhibitions`, param));
      return result;
    },
    { exhibitionParams: [], exhibitionPromises: [] }
  );
};

const delay = time => {
  return new Promise(resolve => setTimeout(resolve, time));
};

const loadMoreExhibitionThroughStateData = storedExhibitionsState => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    // We use storedExhibitions data that already in redux state for rendering exhibitions,
    // so we add delay to simulate for spinner of loading more
    await delay(WAITING_FOR_LOAD_EXHIBITIONS);

    const { storedExhibitions, renderedExhibitions } = await dispatch(
      mapExhibitionDataAfterQuerying(storedExhibitionsState)
    );

    dispatch(
      queryExhibitionsSuccess({
        storedExhibitions,
        renderedExhibitions,
      })
    );

    return dispatch(loadMoreExhibitionSuccess());
  } catch (error) {
    dispatch(loadMoreExhibitionError(error));
  }
};

const loadMoreExhibitionThroughAPIData = () => async (dispatch, getState, sdk) => {
  try {
    const {
      currentExhibitionPage,
      currentExhibitionParamArray,
      storedExhibitions,
    } = getState().NewGalleryPage;

    const newPage = currentExhibitionPage + 1;
    const { exhibitionParams, exhibitionPromises } = generateExhibitionParamsAndPromisesForLoadMore(
      currentExhibitionParamArray,
      newPage
    );

    dispatch(
      queryExhibitionsRequest({
        currentExhibitionParamArray: exhibitionParams,
        currentExhibitionPage: newPage,
      })
    );

    const [currentExhibitionRes, upcomingExhibitionRes, pastExhibitionRes] = await Promise.all(
      exhibitionPromises
    );

    const newStoredExhibitions = getNewStoredExhibitions(
      storedExhibitions,
      currentExhibitionRes,
      upcomingExhibitionRes,
      pastExhibitionRes
    );

    const {
      storedExhibitions: mappedStoredExhibitions,
      renderedExhibitions: mappedRenderedExhibitions,
    } = await dispatch(mapExhibitionDataAfterQuerying(newStoredExhibitions));

    dispatch(
      queryExhibitionsSuccess({
        storedExhibitions: mappedStoredExhibitions,
        renderedExhibitions: mappedRenderedExhibitions,
      })
    );

    return dispatch(loadMoreExhibitionSuccess());
  } catch (error) {
    dispatch(loadMoreExhibitionError(error));
  }
};

export const loadMoreExhibition = () => (dispatch, getState, sdk) => {
  const {
    loadMoreExhibitionInProgress,
    currentExhibitionPage,
    storedExhibitions,
  } = getState().NewGalleryPage;

  if (loadMoreExhibitionInProgress) return;

  dispatch(loadMoreExhibitionRequest());

  const canNotQueryMoreExhibitions = canNotQueryMoreExhibitionAPI(
    storedExhibitions,
    currentExhibitionPage
  );

  if (canNotQueryMoreExhibitions) {
    const {
      current: { data: currentData },
      upcoming: { data: upcomingData },
      past: { data: pastData },
    } = storedExhibitions;

    // Run out of data to render more exhibitions
    if (currentData.length === 0 && upcomingData.length === 0 && pastData.length === 0) {
      return dispatch(loadMoreExhibitionSuccess());
    }

    // There is still data in redux to render more exhibitions
    return dispatch(loadMoreExhibitionThroughStateData(storedExhibitions));
  }

  // Render more exhibitions through data from query API
  return dispatch(loadMoreExhibitionThroughAPIData());
};

const initiateExhibitionParamsAndPromises = () => {
  return [CURRENT_EXHIBITION, UPCOMING_EXHIBITION, PAST_EXHIBITION].reduce(
    (result, type) => {
      const paramsDefault = {
        pagination: {
          page: 1,
          pageSize: EXHIBITION_PAGE_SIZE_DEFAULT,
        },
      };
      const queryParamsExhibition = Object.assign(mapExhibitionTypeToQuery(type), paramsDefault);
      result.exhibitionParams.push(queryParamsExhibition);
      result.exhibitionPromises.push(strapi.get(`/api/exhibitions`, queryParamsExhibition));

      return result;
    },
    { exhibitionParams: [], exhibitionPromises: [] }
  );
};

export const loadData = () => async (dispatch, getState, sdk) => {
  try {
    // Clear state so that previously loaded data is not visible
    // in case this page load fails.
    dispatch(setInitialState());

    const {
      exhibitionParams,
      exhibitionPromises,
    } = initiateExhibitionParamsAndPromises();

    dispatch(
      queryExhibitionsRequest({
        currentExhibitionParamArray: exhibitionParams,
        currentExhibitionPage: 1,
      })
    );

    const queryGalleryPromise = strapi.get('/api/gallery');

    const [
      galleryRes,
      currentExhibitionRes,
      upcomingExhibitionRes,
      pastExhibitionRes,
    ] = await Promise.all([queryGalleryPromise, ...exhibitionPromises]);

    dispatch(queryGalleryStrapiSuccess(galleryRes.data.attributes));

    const initialStoredExhibitions = {
      current: currentExhibitionRes,
      upcoming: upcomingExhibitionRes,
      past: pastExhibitionRes,
    };

    const { storedExhibitions, renderedExhibitions } = await dispatch(
      mapExhibitionDataAfterQuerying(initialStoredExhibitions)
    );

    dispatch(
      queryExhibitionsSuccess({
        storedExhibitions,
        renderedExhibitions,
      })
    );
  } catch (error) {
    dispatch(queryExhibitionsError(error));
  }
};
