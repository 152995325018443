import React, { useState } from 'react';
import css from './AnnouncementBanner.module.css';
import RichTextCkEditor from '../RichTextCkEditor/RichTextCkEditor';
import { sessionStorageSync } from '../../util/localStorage';
import IconClose from '../IconClose/IconClose';
import { string } from 'prop-types';
import classNames from 'classnames';

const SHOW_BANNER = 'true';
const AnnouncementBanner = React.forwardRef(
  ({ announcementContent, bannerStateKey, className }, ref) => {
    const bannerState = sessionStorageSync.getItem(bannerStateKey);
    const shouldShowBanner = bannerState ? bannerState === SHOW_BANNER : true;
    const [showBanner, setShowBanner] = useState(shouldShowBanner);
    const handleCloseBanner = () => {
      setShowBanner(false);
      sessionStorageSync.setItem(bannerStateKey, false);
    };

    return (
      announcementContent &&
      showBanner && (
        <div className={classNames(css.announcementBanner, className)} ref={ref}>
          <RichTextCkEditor contentHtml={announcementContent} />
          <button className={css.closeBannerBtn} onClick={handleCloseBanner}>
            <IconClose />
          </button>
        </div>
      )
    );
  }
);

AnnouncementBanner.propTypes = {
  announcementContent: string,
  bannerStateKey: string,
  className: string,
};

AnnouncementBanner.defaultProps = {
  announcementContent: '',
  bannerStateKey: null,
  className: null,
};

export default AnnouncementBanner;
