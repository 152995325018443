import {
  ARTIST_COLLECTION_TYPE,
  ARTIST_TYPE,
  ARTWORK_COLLECTION_TYPE,
  EDITORIAL_TYPE,
  EXHIBITION_TYPE,
  MEDIUM_TYPE,
  searchTypeConfig,
  SUBJECT_TYPE,
} from '../marketplace-custom-config';
import routeConfiguration from "../routeConfiguration";
import { createResourceLocatorString } from "./routes";

export const getRedirectRouteFromSearchItem = (searchItem) => {
  let route;
  const { searchType } = searchItem;

  switch (searchType) {
    case ARTIST_TYPE:
      route = createResourceLocatorString(
        'ProfilePage',
        routeConfiguration(),
        { name: searchItem.parsedArtistName },
        {}
      );
      break;

    case MEDIUM_TYPE:
      route = createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        { pub_category: searchItem.key }
      );
      break;

    case SUBJECT_TYPE:
      route = createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        { pub_labels: searchItem.key }
      );
      break;

    case ARTIST_COLLECTION_TYPE:
      route = createResourceLocatorString(
        'ArtistsCollectionPage',
        routeConfiguration(),
        { slug: searchItem.slug },
        {}
      );
      break;

    case ARTWORK_COLLECTION_TYPE:
      route = createResourceLocatorString(
        'ArtworksCollectionPage',
        routeConfiguration(),
        { slug: searchItem.slug },
        {}
      );
      break;

    case EDITORIAL_TYPE:
      route = createResourceLocatorString(
        'ArticlePage',
        routeConfiguration(),
        { title: searchItem.slug },
        {}
      );
      break;

    case EXHIBITION_TYPE:
      route = createResourceLocatorString(
        'ExhibitionsPostPage',
        routeConfiguration(),
        { slug: searchItem.slug },
        {}
      );
      break;

    default:
      break;
  }

  return route;
};

export const getSearchText = item => {
  const { searchType } = item;
  let text = '';

  switch (searchType) {
    case ARTIST_TYPE:
      text = item.name;
      break;
    case MEDIUM_TYPE:
      text = item.label;
      break;
    case SUBJECT_TYPE:
      text = item.label;
      break;
    case ARTIST_COLLECTION_TYPE:
      text = item.title;
      break;
    case ARTWORK_COLLECTION_TYPE:
      text = item.title;
      break;
    case EDITORIAL_TYPE:
      text = item.title;
      break;
    case EXHIBITION_TYPE:
      text = item.title;
      break;

    default:
      break;
  }

  return text;
};

export const getSearchTypeLabel = searchItem => {
  const searchType = searchTypeConfig.find(element => element.key === searchItem.searchType);
  return searchType.label;
};

export const boldString = (str, subStr) => {
  if (!subStr) return str;

  const words = subStr
    .trim()
    .replace(/\\/g, '\\\\')
    .split(/\s+/);

  const strRegExp = new RegExp(words.join('|'), 'gi');
  return str.replace(strRegExp, str => str.bold());
};
