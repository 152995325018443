import cloneDeep from 'lodash/cloneDeep';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { artistFilterConfig, ARTIST_TRAFFIC, ARTIST_TYPE } from '../../marketplace-custom-config';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import { mixRouteAndQueryParam, parseFilterParams } from '../SearchPage/SearchPage.duck';

const NEW_YORK_CITY = 'New York';
const RESULT_PAGE_SIZE = 12;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';

export const QUERY_ARTISTS_LISTING_REQUEST = 'app/LandingPage/QUERY_ARTISTS_LISTING_REQUEST';
export const QUERY_ARTISTS_LISTING_SUCCESS = 'app/LandingPage/QUERY_ARTISTS_LISTING_SUCCESS';
export const QUERY_ARTISTS_LISTING_ERROR = 'app/LandingPage/QUERY_ARTISTS_LISTING_ERROR';

export const LOAD_MORE_REQUEST = 'app/LandingPage/LOAD_MORE_REQUEST';
export const LOAD_MORE_SUCCESS = 'app/LandingPage/LOAD_MORE_SUCCESS';
export const LOAD_MORE_ERROR = 'app/LandingPage/LOAD_MORE_ERROR';

export const UPDATE_PAGINATION = 'app/LandingPage/UPDATE_PAGINATION';

// ================ Reducer ================ //

const initialState = {
  searchInProgress: false,
  searchListingsError: null,
  listings: [],
  loadMoreInProgress: false,
  loadMoreError: null,
  pagination: null,
  searchParams: null,
  params: null,
};

export default function LandingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_ARTISTS_LISTING_REQUEST:
      return {
        ...state,
        searchInProgress: true,
        searchParams: payload.searchParams,
        params: payload.params,
        searchListingsError: null,
      };
    case QUERY_ARTISTS_LISTING_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        listings: payload,
      };
    case QUERY_ARTISTS_LISTING_ERROR:
      return { ...state, searchInProgress: false, listings: [], searchListingsError: payload };

    case LOAD_MORE_REQUEST:
      return {
        ...state,
        loadMoreInProgress: true,
        loadMoreError: null,
        searchParams: payload,
      };
    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        listings: [...state.listings, ...payload],
        loadMoreInProgress: false,
      };
    case LOAD_MORE_ERROR:
      return { ...state, loadMoreInProgress: false, loadMoreError: payload };

    case UPDATE_PAGINATION:
      return { ...state, pagination: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryArtistListingsRequest = (searchParams, params) => ({
  type: QUERY_ARTISTS_LISTING_REQUEST,
  payload: { searchParams, params },
});

export const queryArtistListingsSuccess = listings => ({
  type: QUERY_ARTISTS_LISTING_SUCCESS,
  payload: listings,
});

export const queryArtistListingsError = e => ({
  type: QUERY_ARTISTS_LISTING_ERROR,
  error: true,
  payload: e,
});

export const loadMoreRequest = searchParams => ({
  type: LOAD_MORE_REQUEST,
  payload: searchParams,
});

export const loadMoreSuccess = response => ({
  type: LOAD_MORE_SUCCESS,
  payload: response,
});

export const loadMoreError = error => ({ type: LOAD_MORE_ERROR, payload: error });

export const updatePagination = pagination => ({
  type: UPDATE_PAGINATION,
  payload: pagination,
});

// ================ Thunks ================ //

const queryArtworks = artists => async (dispatch, getState, sdk) => {
  try {
    const artworks = artists.map(artist => {
      const artParams = {
        pub_listingType: 'art',
        author_id: artist.author.id.uuid,
        include: ['images', 'author'],
        'fields.image': [
          'variants.scaled-medium',
          'variants.scaled-large',
          'variants.landscape-crop',
          'variants.landscape-crop2x',
        ],
        'limit.images': 1,
        sort: 'pub_uniquePageViews',
        page: 1,
        per_page: 3,
      };

      return sdk.listings.query(artParams);
    });

    const artworksValue = await Promise.all(artworks);

    const listings = artists.map((artist, index) => {
      const arts = denormalisedResponseEntities(artworksValue[index]);
      return { ...artist, images: arts };
    });

    return listings;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const queryArtistListings = params => (dispatch, getState, sdk) => {
  return sdk.listings.query(params).then(response => {
    const artists = denormalisedResponseEntities(response);
    dispatch(updatePagination(response.data.meta));
    return dispatch(queryArtworks(artists));
  });
};

const canLoadMore = (pagination = {}) => {
  const { page, totalPages } = pagination || {};
  return page < totalPages;
};

const getRequestParam = (searchParams) => {
  const requestParams = cloneDeep(searchParams);
  const pubCity = requestParams.pub_city;
  if(pubCity && pubCity.includes(NEW_YORK_CITY)) {
    const hideCities = config.custom.cityOptions.filter(city => city.hideInFilter).map(city => city.key);
    const extraParamValue = ',' + hideCities.join(',');
    requestParams.pub_city += extraParamValue;
  }
  return requestParams;
}

export const loadMore = () => (dispatch, getState, sdk) => {
  const landingPageState = getState().LandingPage;
  const { pagination, searchParams, loadMoreInProgress } = landingPageState;

  if (loadMoreInProgress || !canLoadMore(pagination)) return;

  const params = {
    ...searchParams,
    page: pagination.page + 1,
  };

  dispatch(loadMoreRequest(params));

  const requestParams = getRequestParam(params);

  return dispatch(queryArtistListings(requestParams))
    .then(listings => {
      dispatch(loadMoreSuccess(listings));
    })
    .catch(e => dispatch(loadMoreError(storableError(e))));
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // eslint-disable-next-line no-unused-vars
  const { page = 1, address, origin, perPage, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const mixedPrimaryFilters = {};
  const filters = Object.values(artistFilterConfig);
  const originParams = {};
  // Replace hyphen in param with space
  for (const key in params) {
    originParams[key] = params[key].replace(/-/g, ' ');
  }

  const routeParams = parseFilterParams(filters)(originParams);
  if(routeParams.pub_city) {
    const cities = routeParams.pub_city.replace(/New York City/g, NEW_YORK_CITY);
    routeParams.pub_city = cities;
  }

  filters.forEach(filter => {
    const mixedFilters = mixRouteAndQueryParam(rest, routeParams, filter.paramName);
    if (mixedFilters) {
      mixedPrimaryFilters[filter.paramName] = mixedFilters;
    }
  });

  const searchParams = {
    ...rest,
    ...originMaybe,
    ...mixedPrimaryFilters,
    pub_listingType: ARTIST_TYPE,
    pub_artworkQuantity: '1,',
    pub_isAdmin: false,
    page,
    per_page: perPage || RESULT_PAGE_SIZE,
    include: ['author', 'author.profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  if (!searchParams.sort) {
    searchParams.sort = ARTIST_TRAFFIC;
  }

  dispatch(setInitialState());
  dispatch(
    queryArtistListingsRequest(searchParams, { ...rest, ...originMaybe, ...mixedPrimaryFilters })
  );

  const requestParams = getRequestParam(searchParams);

  return dispatch(queryArtistListings(requestParams))
    .then(listings => {
      dispatch(queryArtistListingsSuccess(listings));
    })
    .catch(e => dispatch(queryArtistListingsError(storableError(e))));
};
