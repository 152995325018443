import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const QUERY_VISIT_GALLERY_REQUEST = 'app/VisitGalleryPage/QUERY_VISIT_GALLERY_REQUEST';
export const QUERY_VISIT_GALLERY_SUCCESS = 'app/VisitGalleryPage/QUERY_VISIT_GALLERY_SUCCESS';
export const QUERY_VISIT_GALLERY_ERROR = 'app/VisitGalleryPage/QUERY_VISIT_GALLERY_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryVisitGalleryError: null,
  visitGalleryData: null,
};

export default function VisitGalleryReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_VISIT_GALLERY_REQUEST:
      return { ...state, queryVisitGalleryError: null };
    case QUERY_VISIT_GALLERY_SUCCESS:
      return { ...state, visitGalleryData: payload };
    case QUERY_VISIT_GALLERY_ERROR:
      return { ...state, queryVisitGalleryError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryVisitGalleryRequest = () => ({
  type: QUERY_VISIT_GALLERY_REQUEST,
});

export const queryVisitGallerySuccess = visitGalleryData => ({
  type: QUERY_VISIT_GALLERY_SUCCESS,
  payload: visitGalleryData,
});

export const queryVisitGalleryError = e => ({
  type: QUERY_VISIT_GALLERY_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(queryVisitGalleryRequest());

  const queryParams = {
    populate: 'heroImage',
  };

  return strapi
    .get('/api/visit-the-gallery', queryParams)
    .then(res => dispatch(queryVisitGallerySuccess(res.data.attributes)))
    .catch(e => dispatch(queryVisitGalleryError(storableError(e.response))));
};
