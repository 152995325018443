import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const QUERY_FOOTER_REQUEST = 'app/Footer/QUERY_FOOTER_REQUEST';
export const QUERY_FOOTER_SUCCESS = 'app/Footer/QUERY_FOOTER_SUCCESS';
export const QUERY_FOOTER_ERROR = 'app/Footer/QUERY_FOOTER_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryFooterError: null,
  footerData: null,
};

export default function FooterReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_FOOTER_REQUEST:
      return { ...state, queryFooterError: null };
    case QUERY_FOOTER_SUCCESS:
      return { ...state, footerData: payload };
    case QUERY_FOOTER_ERROR:
      return { ...state, queryFooterError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryFooterRequest = () => ({
  type: QUERY_FOOTER_REQUEST,
});

export const queryFooterSuccess = aboutData => ({
  type: QUERY_FOOTER_SUCCESS,
  payload: aboutData,
});

export const queryFooterError = e => ({
  type: QUERY_FOOTER_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(queryFooterRequest());

  return strapi
    .get('/api/footer')
    .then(res => dispatch(queryFooterSuccess(res.data.attributes)))
    .catch(e => dispatch(queryFooterError(storableError(e.response))));
};
