/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import NamedLink from '../NamedLink/NamedLink';
import NotificationBadge from '../NotificationBadge/NotificationBadge';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    currentPage,
    notificationCount,
    intl,
  } = props;

  const activeAccountSettingsPagesMaybe = () =>
    ACCOUNT_SETTINGS_PAGES.filter(page => page !== 'StripePayoutPage');

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && activeAccountSettingsPagesMaybe().includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const artistLink = (
    <NamedLink
      name="LandingPage"
      className={classNames(css.navigationLink, currentPageClass('LandingPage'))}
    >
      <FormattedMessage id="TopbarDesktop.artist" />
    </NamedLink>
  );

  const aboutLink = (
    <NamedLink
      className={classNames(
        css.navigationLink,
        css.navigationLinkSmall,
        currentPageClass('AboutPage')
      )}
      name="AboutPage"
    >
      <FormattedMessage id="TopbarMobileMenu.aboutPageLink" />
    </NamedLink>
  );

  const exhibitionsLink = (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('NewGalleryPage'))}
      name="NewGalleryPage"
    >
      {intl.formatMessage({ id: 'TopbarMobileMenu.exhibitionsPageLink' })}
    </NamedLink>
  );

  const projectsLink = (
    <NamedLink
      className={classNames(
        css.navigationLink,
        currentPageClass('ProjectPage')
      )}
      name="ProjectPage"
    >
      {intl.formatMessage({id: 'TopbarMobileMenu.projectsPageLink' })}
    </NamedLink>
  );

  const contactUsLink = (
    <NamedLink
      className={classNames(
        css.navigationLink,
        css.navigationLinkSmall,
        currentPageClass('ContactUsPage')
      )}
      name="ContactUsPage"
    >
      {intl.formatMessage({id: 'TopbarMobileMenu.contactUsPageLink' })}
    </NamedLink>
  );

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const divider = <div className={css.divider}></div>

  return (
    <div className={css.root}>
      <div className={css.content}>
        {artistLink}
        {exhibitionsLink}
        {projectsLink}
        {divider}
        {aboutLink}
        {contactUsLink}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  currentPage: string,
  notificationCount: number,
  intl: intlShape.isRequired,
};

export default injectIntl(TopbarMobileMenu);
