import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const QUERY_CONTACT_US_REQUEST = 'app/ContactUsPage/QUERY_CONTACT_US_REQUEST';
export const QUERY_CONTACT_US_SUCCESS = 'app/ContactUsPage/QUERY_CONTACT_US_SUCCESS';
export const QUERY_CONTACT_US_ERROR = 'app/ContactUsPage/QUERY_CONTACT_US_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryContactUsError: null,
  contactUsData: null,
};

export default function ContactUsReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_CONTACT_US_REQUEST:
      return { ...state, queryContactUsError: null };
    case QUERY_CONTACT_US_SUCCESS:
      return { ...state, contactUsData: payload };
    case QUERY_CONTACT_US_ERROR:
      return { ...state, queryContactUsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryContactUsRequest = () => ({
  type: QUERY_CONTACT_US_REQUEST,
});

export const queryContactUsSuccess = contactUsData => ({
  type: QUERY_CONTACT_US_SUCCESS,
  payload: contactUsData,
});

export const queryContactUsError = e => ({
  type: QUERY_CONTACT_US_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(queryContactUsRequest());

  const queryParams = {
    populate: 'heroImage',
  };

  return strapi
    .get('/api/contact-us', queryParams)
    .then(res => dispatch(queryContactUsSuccess(res.data.attributes)))
    .catch(e => dispatch(queryContactUsError(storableError(e.response))));
};
