import pick from 'lodash/pick';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { getCurrentFavoriteListings } from '../ManageFavoriteListingsPage/ManageFavoriteListingsPage.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import * as log from '../../util/log';
import config from '../../config';
import { fetchListings } from '../../ducks/listing.duck';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import {
  PROFILE_SORT_DEFAULT,
  TURN_OFF_SHOW_ALL,
  TURN_ON_SHOW_ALL,
} from '../../marketplace-custom-config';
import { localStorageSync } from '../../util/localStorage';
import { parse } from '../../util/urlHelpers';
import isEmpty from 'lodash/isEmpty';
import { VIEW_ART_PROFILE_TAB } from '../../util/types';
import strapi from '../../util/strapi';

export const RESULT_PAGE_SIZE = 12;
const MINIMUM_NUMBER_OF_ARTWORKS = 1;
const MINIMUM_ARTWORKS_RANDOM = 1;
const PER_PAGE_FOLLOWING = 25;
const QUANTITY_ARTISTS_PER_PAGE = 25;
const PER_PAGE_FOR_META_DESCRIPTION = 3;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ProfilePage/SET_INITIAL_VALUES';
export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_THREE_LATEST_LISTINGS_REQUEST =
  'app/ProfilePage/QUERY_THREE_LATEST_LISTINGS_REQUEST';
export const QUERY_THREE_LATEST_LISTINGS_SUCCESS =
  'app/ProfilePage/QUERY_THREE_LATEST_LISTINGS_SUCCESS';
export const QUERY_THREE_LATEST_LISTINGS_ERROR =
  'app/ProfilePage/QUERY_THREE_LATEST_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const SEARCH_LISTINGS_REQUEST = 'app/ProfilePage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/ProfilePage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/ProfilePage/SEARCH_LISTINGS_ERROR';

export const FETCH_ARTISTS_LISTING_REQUEST = 'app/ProfilePage/FETCH_ARTISTS_LISTING_REQUEST';
export const FETCH_ARTISTS_LISTING_SUCCESS = 'app/ProfilePage/FETCH_ARTISTS_LISTING_SUCCESS';
export const FETCH_ARTISTS_LISTING_ERROR = 'app/ProfilePage/FETCH_ARTISTS_LISTING_ERROR';

export const FETCH_FOLLOWING_ARTISTS_LISTING_REQUEST =
  'app/ProfilePage/FETCH_FOLLOWING_ARTISTS_LISTING_REQUEST';
export const FETCH_FOLLOWING_ARTISTS_LISTING_SUCCESS =
  'app/ProfilePage/FETCH_FOLLOWING_ARTISTS_LISTING_SUCCESS';
export const FETCH_FOLLOWING_ARTISTS_LISTING_ERROR =
  'app/ProfilePage/FETCH_FOLLOWING_ARTISTS_LISTING_ERROR';

export const FETCH_FOLLOWED_BY_ARTISTS_LISTING_REQUEST =
  'app/ProfilePage/FETCH_FOLLOWED_BY_ARTISTS_LISTING_REQUEST';
export const FETCH_FOLLOWED_BY_ARTISTS_LISTING_SUCCESS =
  'app/ProfilePage/FETCH_FOLLOWED_BY_ARTISTS_LISTING_SUCCESS';
export const FETCH_FOLLOWED_BY_ARTISTS_LISTING_ERROR =
  'app/ProfilePage/FETCH_FOLLOWED_BY_ARTISTS_LISTING_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ProfilePage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ProfilePage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ProfilePage/SEND_ENQUIRY_ERROR';

export const FETCH_ARTIST_STRAPI_REQUEST = 'app/ProfilePage/FETCH_ARTIST_STRAPI_REQUEST';
export const FETCH_ARTIST_STRAPI_SUCCESS = 'app/ProfilePage/FETCH_ARTIST_STRAPI_SUCCESS';
export const FETCH_ARTIST_STRAPI_ERROR = 'app/ProfilePage/FETCH_ARTIST_STRAPI_ERROR';

export const FETCH_PROJECTS_STRAPI_SUCCESS = 'app/ProfilePage/FETCH_PROJECTS_STRAPI_SUCCESS';
export const FETCH_EXHIBITIONS_STRAPI_SUCCESS = 'app/ProfilePage/FETCH_EXHIBITIONS_STRAPI_SUCCESS';
export const FETCH_PRESSES_STRAPI_SUCCESS = 'app/ProfilePage/FETCH_PRESSES_STRAPI_SUCCESS';

export const SHOW_LESS = 'app/ProfilePage/SHOW_LESS';

export const UPDATE_CURRENT_TAB = 'app/ProfilePage/UPDATE_CURRENT_TAB';

// ================ Reducer ================ //

const initialState = {
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  searchInProgress: false,
  searchParams: null,
  currentPageResultIds: [],
  fetchArtistInProgress: false,
  fetchArtistListingError: null,
  artistId: null,
  artistListing: null,
  pagination: null,
  fetchFollowingArtistInProgress: false,
  fetchFollowingArtistListingError: null,
  fetchFollowedByArtistInProgress: false,
  fetchFollowedByArtistListingError: null,
  followingArtists: [],
  followedByArtists: [],
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  loadMoreInProgress: false,
  loadMoreError: null,
  currentTab: VIEW_ART_PROFILE_TAB,
  queryThreeLatestListingsInProgress: false,
  queryThreeLatestListings: [],
  queryThreeLatestListingsError: null,
  fetchArtistStrapiInProgress: false,
  fetchArtistStrapiError: null,
  artistStrapi: null,
  exhibitions: [],
  projects: [],
  presses: [],
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      const currentTab = state.currentTab;
      return { ...initialState, currentTab };
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.artistId === state.artistId ? state.userListingRefs : [],
        searchParams: payload.searchParams,
        isSearching: payload.isSearching,
        queryListingsError: null,
        loadMoreError: null,
        searchInProgress: !payload.isLoadingMore,
        loadMoreInProgress: payload.isLoadingMore,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        userListingRefs: [...state.userListingRefs, ...payload.listingRefs],
        pagination: payload.pagination,
        searchInProgress: false,
        loadMoreInProgress: false,
      };
    case QUERY_LISTINGS_ERROR:
      return {
        ...state,
        userListingRefs: [],
        queryListingsError: !payload.isLoadingMore ? payload.error : null,
        loadMoreError: payload.isLoadingMore ? payload.error : null,
        loadMoreInProgress: false,
        searchInProgress: false,
      };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    case FETCH_ARTISTS_LISTING_REQUEST:
      return { ...state, fetchArtistInProgress: true, fetchArtistListingError: null };
    case FETCH_ARTISTS_LISTING_SUCCESS:
      return {
        ...state,
        fetchArtistInProgress: false,
        artistId: payload.artistId,
        artistListing: payload.artistListing,
      };
    case FETCH_ARTISTS_LISTING_ERROR:
      return { ...state, fetchArtistInProgress: false, fetchArtistListingError: payload };

    case FETCH_FOLLOWING_ARTISTS_LISTING_REQUEST:
      return {
        ...state,
        fetchFollowingArtistInProgress: true,
        fetchFollowingArtistListingError: null,
      };
    case FETCH_FOLLOWING_ARTISTS_LISTING_SUCCESS:
      return { ...state, fetchFollowingArtistInProgress: false, followingArtists: payload.data };
    case FETCH_FOLLOWING_ARTISTS_LISTING_ERROR:
      return {
        ...state,
        fetchFollowingArtistInProgress: false,
        fetchFollowingArtistListingError: payload,
      };

    case FETCH_FOLLOWED_BY_ARTISTS_LISTING_REQUEST:
      return {
        ...state,
        fetchFollowedByArtistInProgress: true,
        fetchFollowedByArtistListingError: null,
      };
    case FETCH_FOLLOWED_BY_ARTISTS_LISTING_SUCCESS:
      return { ...state, fetchFollowedByArtistInProgress: false, followedByArtists: payload.data };
    case FETCH_FOLLOWED_BY_ARTISTS_LISTING_ERROR:
      return {
        ...state,
        fetchFollowedByArtistInProgress: false,
        fetchFollowedByArtistListingError: payload,
      };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case SHOW_LESS:
      return {
        ...state,
        userListingRefs: state.userListingRefs.slice(0, RESULT_PAGE_SIZE),
        pagination: { ...state.pagination, page: 1 },
        searchParams: { ...state.searchParams, page: 1 },
      };

    case UPDATE_CURRENT_TAB:
      return {
        ...state,
        currentTab: payload.tab,
      };

    case QUERY_THREE_LATEST_LISTINGS_REQUEST:
      return {
        ...state,
        queryThreeLatestListingsInProgress: true,
      };
    case QUERY_THREE_LATEST_LISTINGS_SUCCESS:
      return {
        ...state,
        queryThreeLatestListings: payload,
        queryThreeLatestListingsInProgress: false,
      };
    case QUERY_THREE_LATEST_LISTINGS_ERROR:
      return {
        ...state,
        queryThreeLatestListingsError: payload,
        queryThreeLatestListingsInProgress: false,
      };

    case FETCH_ARTIST_STRAPI_REQUEST:
      return { ...state, fetchArtistStrapiInProgress: true, fetchArtistStrapiError: null };
    case FETCH_ARTIST_STRAPI_SUCCESS:
      return {
        ...state,
        fetchArtistStrapiInProgress: false,
        artistStrapi: payload,
      };
    case FETCH_ARTIST_STRAPI_ERROR:
      return { ...state, fetchArtistStrapiInProgress: false, fetchArtistStrapiError: payload };

    case FETCH_PROJECTS_STRAPI_SUCCESS:
      return {
        ...state,
        projects: payload,
      };
    case FETCH_EXHIBITIONS_STRAPI_SUCCESS:
      return {
        ...state,
        exhibitions: payload,
      };
    case FETCH_PRESSES_STRAPI_SUCCESS:
      return {
        ...state,
        presses: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryListingsRequest = ({ artistId, searchParams, isSearching, isLoadingMore }) => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { artistId, searchParams, isSearching, isLoadingMore },
});

export const queryListingsSuccess = ({ listingRefs, pagination }) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs, pagination },
});

export const queryListingsError = (error, isLoadingMore) => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: { error, isLoadingMore },
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchArtistListingsRequest = () => ({
  type: FETCH_ARTISTS_LISTING_REQUEST,
});

export const fetchArtistListingsSuccess = (artistId, artistListing) => ({
  type: FETCH_ARTISTS_LISTING_SUCCESS,
  payload: { artistId, artistListing },
});

export const fetchArtistListingsError = e => ({
  type: FETCH_ARTISTS_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchFollowingArtistListingsRequest = () => ({
  type: FETCH_FOLLOWING_ARTISTS_LISTING_REQUEST,
});

export const fetchFollowingArtistListingsSuccess = artistListings => ({
  type: FETCH_FOLLOWING_ARTISTS_LISTING_SUCCESS,
  payload: { data: artistListings },
});

export const fetchFollowingArtistListingsError = e => ({
  type: FETCH_FOLLOWING_ARTISTS_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchFollowedByArtistListingsRequest = () => ({
  type: FETCH_FOLLOWED_BY_ARTISTS_LISTING_REQUEST,
});

export const fetchFollowedByArtistListingsSuccess = artistListings => ({
  type: FETCH_FOLLOWED_BY_ARTISTS_LISTING_SUCCESS,
  payload: { data: artistListings },
});

export const fetchFollowedByArtistListingsError = e => ({
  type: FETCH_FOLLOWED_BY_ARTISTS_LISTING_ERROR,
  error: true,
  payload: e,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

const fetchArtistStrapiRequest = () => ({ type: FETCH_ARTIST_STRAPI_REQUEST });
const fetchArtistStrapiSuccess = artistStrapi => ({
  type: FETCH_ARTIST_STRAPI_SUCCESS,
  payload: artistStrapi,
});
const fetchArtistStrapiError = e => ({ type: FETCH_ARTIST_STRAPI_ERROR, error: true, payload: e });

const fetchProjectsStrapiSuccess = data => ({
  type: FETCH_PROJECTS_STRAPI_SUCCESS,
  payload: data,
});
const fetchExhibitionsStrapiSuccess = data => ({
  type: FETCH_EXHIBITIONS_STRAPI_SUCCESS,
  payload: data,
});
const fetchPressesStrapiSuccess = data => ({
  type: FETCH_PRESSES_STRAPI_SUCCESS,
  payload: data,
});

export const showLess = () => ({ type: SHOW_LESS });

export const updateCurrentTab = tab => ({ type: UPDATE_CURRENT_TAB, payload: { tab } });

export const queryThreeLatestListingsRequest = () => ({
  type: QUERY_THREE_LATEST_LISTINGS_REQUEST,
});
export const queryThreeLatestListingsSuccess = payload => ({
  type: QUERY_THREE_LATEST_LISTINGS_SUCCESS,
  payload,
});
export const queryThreeLatestListingsError = payload => ({
  type: QUERY_THREE_LATEST_LISTINGS_ERROR,
  payload,
});

// ================ Thunks ================ //

const providerRelationship = txResponse => {
  return txResponse.data.data.relationships.provider.data;
};

const priceSearchParams = priceParam => {
  const inSubunits = value =>
    convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
  const values = priceParam ? priceParam.split(',') : [];
  return priceParam && values.length === 2
    ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
    : {};
};

const datesSearchParams = datesParam => {
  const values = datesParam ? datesParam.split(',') : [];
  const hasValues = datesParam && values.length === 2;
  const startDate = hasValues ? values[0] : null;
  const isNightlyBooking = config.bookingUnitType === 'line-item/night';
  const endDate =
    hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

  return hasValues
    ? {
        start: formatDateStringToUTC(startDate),
        end: formatDateStringToUTC(endDate),
        availability: 'full',
      }
    : {};
};

export const queryUserListings = ({ artistId, searchParams, isSearching, isLoadingMore }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(queryListingsRequest({ artistId, searchParams, isSearching, isLoadingMore }));
  const { currentUser } = getState().user;
  const {
    perPage,
    price,
    dates,
    keywords,
    pub_category,
    pub_labels,
    pub_priceRange,
    pub_orientation,
    pub_availability,
    pub_collection,
    pub_size,
    meta_likers,
    showAllToggle,
    ...rest
  } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    pub_isAdmin: false,
    pub_listingType: 'art',
    per_page: perPage || RESULT_PAGE_SIZE,
    'fields.listing': ['description', 'geolocation', 'price', 'title', 'publicData', 'metadata'],
  };

  if (pub_category) {
    params.pub_category = `has_any: ${pub_category}`;
  }
  if (pub_labels) {
    params.pub_labels = `has_any: ${pub_labels}`;
  }
  if (pub_priceRange) {
    params.pub_priceRange = `has_any: ${pub_priceRange}`;
  }
  if (pub_size) {
    params.pub_size = `has_any: ${pub_size}`;
  }
  if (pub_orientation) {
    params.pub_orientation = `has_any: ${pub_orientation}`;
  }
  if (!showAllToggle) {
    params.pub_availability = `has_any: ${TURN_ON_SHOW_ALL}`;
  } else {
    params.pub_availability = `has_any: ${TURN_OFF_SHOW_ALL}`;
  }
  if (meta_likers) {
    params.meta_likers = `has_any: ${meta_likers}`;
  }
  if (pub_collection) {
    params.pub_collection = `has_any: ${pub_collection}`;
  }

  if (keywords) {
    params.keywords = keywords;
  }

  if (!params.sort) {
    params.sort = PROFILE_SORT_DEFAULT;
  }

  if (params.pub_roundHeight) {
    const heightSplit = params.pub_roundHeight.split(',');
    if (heightSplit[0] === heightSplit[1]) {
      params.pub_roundHeight = `${heightSplit[0]},${++heightSplit[1]}`;
    }
  }

  if (params.pub_roundWidth) {
    const widthSplit = params.pub_roundWidth.split(',');
    if (widthSplit[0] === widthSplit[1]) {
      params.pub_roundWidth = `${widthSplit[0]},${++widthSplit[1]}`;
    }
  }

  const request =
    !!currentUser && artistId === currentUser.id.uuid && !isSearching
      ? sdk.ownListings.query({
          include: ['author', 'images'],
          'fields.image': ['variants.scaled-small', 'variants.scaled-medium'],
          'limit.images': 1,
          ...params,
        })
      : sdk.listings.query({
          author_id: artistId,
          include: ['author', 'images'],
          'fields.image': ['variants.scaled-small', 'variants.scaled-medium'],
          'limit.images': 1,
          pub_listingType: 'art',
          ...params,
        });

  return request
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess({ listingRefs, pagination: response.data.meta }));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e), isLoadingMore)));
};

export const queryUserReviews = artistId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: artistId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const fetchArtistListing = ({ parsedArtistName }) => (dispatch, getState, sdk) => {
  dispatch(fetchArtistListingsRequest());
  return sdk.listings
    .query({
      pub_listingType: 'artist',
      pub_parsedArtistName: decodeURIComponent(parsedArtistName),
      include: ['author', 'author.profileImage', 'profile.displayName'],
      'fields.image': [
        'variants.square-small',
        'variants.square-small2x',
        'variants.facebook',
        'variants.twitter',
      ],
    })
    .then(response => {
      const entity = denormalisedResponseEntities(response);
      const listing = entity && entity.length > 0 ? entity[0] : null;
      const { publicData = {}, metadata = {} } = listing?.attributes || {};
      const { idUser } = publicData;
      const { likers } = metadata;
      const { likedArtistListings } = listing?.author?.attributes?.profile?.metadata || {};

      storeRecentlyViewedArtistIds(listing.id.uuid);
      dispatch(fetchFollowedByArtistListings(likers));

      if (likedArtistListings) {
        dispatch(fetchFollowingArtistListings(likedArtistListings));
      }

      dispatch(fetchArtistListingsSuccess(idUser, listing));
      return idUser;
    })
    .catch(e => dispatch(fetchArtistListingsError(storableError(e))));
};

const random = (mn, mx) => {
  return Math.random() * (mx - mn) + mn;
};

export const handleGetArtworkImage = artistListings => async (dispatch, getState, sdk) => {
  return Promise.all(
    artistListings.map(async artListing => {
      const authorId = artListing.author.id.uuid;
      const artworks = await dispatch(
        fetchListings({
          pub_listingType: 'art',
          authorId,
          perPage: 5,
          include: ['images'],
          'limit.images': 1,
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
        })
      );
      const maxRandom = artworks.length || 1;
      const randomPosition =
        Math.floor(random(MINIMUM_ARTWORKS_RANDOM, maxRandom)) - MINIMUM_ARTWORKS_RANDOM;
      const randomArtwork = artworks[randomPosition];
      return { ...artListing, images: randomArtwork?.images };
    })
  );
};

export const fetchFollowingArtistListings = (likedArtistListingsIds = []) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchFollowingArtistListingsRequest());
  return sdk.listings
    .query({
      ids: likedArtistListingsIds,
      pub_listingType: 'artist',
      pub_artworkQuantity: `${MINIMUM_NUMBER_OF_ARTWORKS},`,
      sort: 'pub_artistTraffic',
      per_page: PER_PAGE_FOLLOWING,
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(async response => {
      const listings = denormalisedResponseEntities(response);
      const formattedArtistListings = await dispatch(handleGetArtworkImage(listings));
      dispatch(fetchFollowingArtistListingsSuccess(formattedArtistListings));
    })
    .catch(e => dispatch(fetchFollowingArtistListingsError(storableError(e))));
};

export const fetchFollowedByArtistListings = (likers = []) => (dispatch, getState, sdk) => {
  dispatch(fetchFollowedByArtistListingsRequest());
  return sdk.listings
    .query({
      pub_idUser: likers.join(','),
      pub_listingType: 'artist',
      pub_artworkQuantity: `${MINIMUM_NUMBER_OF_ARTWORKS},`,
      sort: 'pub_artistTraffic',
      per_page: PER_PAGE_FOLLOWING,
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(async response => {
      const listings = denormalisedResponseEntities(response);
      const formattedArtistListings = await dispatch(handleGetArtworkImage(listings));
      dispatch(fetchFollowedByArtistListingsSuccess(formattedArtistListings));
    })
    .catch(e => dispatch(fetchFollowedByArtistListingsError(storableError(e))));
};

export const checkCuratorFavorite = (artistId, likerId) => (dispatch, getState, sdk) => {
  const queryParam = {
    authorId: artistId,
    meta_likers: likerId,
  };
  return sdk.listings
    .query(queryParam)
    .then(response => {
      const listings = denormalisedResponseEntities(response);
      if (listings.length > 0) {
        return likerId;
      }
      return null;
    })
    .catch(e => {
      log.error(e, 'check-curator-favorite-failed');
      return null;
    });
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  const queryParams = {
    expand: true,
  };
  return sdk.transactions
    .initiate(bodyParams, queryParams)
    .then(response => {
      const transaction = response.data.data;

      return sdk.messages
        .send({
          transactionId: transaction.id,
          content: message,
        })
        .then(() => {
          dispatch(sendEnquirySuccess());
          dispatch(fetchCurrentUserHasOrdersSuccess(true));
          return transaction.id;
        });
    })
    .catch(e => {
      const error = storableError(e);
      dispatch(sendEnquiryError(error));
      console.error(error);
      throw e;
    });
};

const storeRecentlyViewedArtistIds = listingId => {
  const viewedArtistIdStorage = localStorageSync.getItem('recentlyViewedArtistIds');
  let recentlyViewedArtistIds = viewedArtistIdStorage ? JSON.parse(viewedArtistIdStorage) : [];
  recentlyViewedArtistIds = recentlyViewedArtistIds
    .filter(id => id !== listingId)
    .slice(0, QUANTITY_ARTISTS_PER_PAGE - 1);
  recentlyViewedArtistIds.unshift(listingId);

  localStorageSync.setItem('recentlyViewedArtistIds', JSON.stringify(recentlyViewedArtistIds));
};

const fetchThreeLatestListings = artistId => (dispatch, getState, sdk) => {
  dispatch(queryThreeLatestListingsRequest());

  const params = {
    pub_listingType: 'art',
    per_page: PER_PAGE_FOR_META_DESCRIPTION,
    author_id: artistId,
    'fields.listing': ['title'],
    include: ['author'],
  };

  sdk.listings
    .query(params)
    .then(response => {
      const listings = denormalisedResponseEntities(response);
      dispatch(queryThreeLatestListingsSuccess(listings));
    })
    .catch(error => {
      dispatch(queryThreeLatestListingsError(storableError(error)));
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // eslint-disable-next-line no-unused-vars
  const { page, address, origin, perPage, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const artistName = params.name;
  const searchParams = {
    ...rest,
    ...originMaybe,
    page: 1,
    perPage: RESULT_PAGE_SIZE,
  };

  const isSearching = !isEmpty(rest);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchArtistListing({ parsedArtistName: artistName })),
    dispatch(fetchCurrentUser()),
  ]).then(res => {
    const artistId = res[0];
    return Promise.all([
      dispatch(queryUserListings({ artistId, searchParams, isSearching })),
      dispatch(getCurrentFavoriteListings()),
      dispatch(fetchThreeLatestListings(artistId)),
      dispatch(fetchArtistStrapi(artistId)),
    ]).then(res => {
      const strapiArtist = res[3];

      const {
        attributes: {
          projects: { data: projectsData } = {},
          exhibitions: { data: exhibitonsData } = {},
          presses: { data: pressesData } = {},
        } = {},
      } = strapiArtist || {};

      return Promise.all([
        dispatch(fetchPressesStrapiSuccess(pressesData)),
        dispatch(fetchProjectsStrapiSuccess(projectsData)),
        dispatch(fetchExhibitionsStrapiSuccess(exhibitonsData)),
      ]);
    });
  });
};

export const loadMore = () => (dispatch, getState, sdk) => {
  const { pagination, searchParams: params, artistId, isSearching } = getState().ProfilePage;

  const searchParams = {
    ...params,
    page: pagination.page + 1,
  };

  return dispatch(queryUserListings({ artistId, searchParams, isSearching, isLoadingMore: true }));
};

export const showLessListings = () => dispatch => {
  return dispatch(showLess());
};

const fetchArtistStrapi = artistId => (dispatch, getState, sdk) => {
  dispatch(fetchArtistStrapiRequest());

  const params = {
    filters: {
      artistId: {
        $eq: artistId,
      },
    },
    populate:
      'heroContent, heroContent.desktopCarousel, heroContent.mobileCarousel, exhibitions, exhibitions.heroImg, presses, presses.heroImage, projects, projects.heroBanner',
  };

  return strapi
    .get(`/api/artists`, params)
    .then(data => {
      const result = data.data.length > 0 ? data.data[0] : null;
      dispatch(fetchArtistStrapiSuccess(result));
      return result;
    })
    .catch(e => {
      const error = storableError(e.response);
      console.error(error);
      dispatch(fetchArtistStrapiError(error));
    });
};
