import React, { Component } from 'react';
import drop from 'lodash/drop';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import { metaTagProps } from '../../util/seo';
import { canonicalRoutePath, matchPathname, pathByRouteName } from '../../util/routes';
import { artistCategories, filterConfig } from '../../marketplace-custom-config';
import { isScrollingDisabled } from '../../ducks/UI.duck';

import facebookImage from '../../assets/sugarliftFacebook.jpg';
import twitterImage from '../../assets/sugarliftFacebook.jpg';
import css from './Page.module.css';

const LANDING_PAGE_PARAMS_LIMIT = 2;
const SEARCH_PAGE_PARAMS_LIMIT = 3;
const NUMBER_OF_ELEMENTS_TO_DROP = 2;

const preventDefault = e => {
  e.preventDefault();
};

const twitterPageURL = siteTwitterHandle => {
  if (siteTwitterHandle && siteTwitterHandle.charAt(0) === '@') {
    return `https://twitter.com/${siteTwitterHandle.substring(1)}`;
  } else if (siteTwitterHandle) {
    return `https://twitter.com/${siteTwitterHandle}`;
  }
  return null;
};

class PageComponent extends Component {
  constructor(props) {
    super(props);
    // Keeping scrollPosition out of state reduces rendering cycles (and no bad states rendered)
    this.scrollPosition = 0;
    this.contentDiv = null;
    this.scrollingDisabledChanged = this.scrollingDisabledChanged.bind(this);
  }

  componentDidMount() {
    // By default a dropped file is loaded in the browser window as a
    // file URL. We want to prevent this since it might loose a lot of
    // data the user has typed but not yet saved. Preventing requires
    // handling both dragover and drop events.
    document.addEventListener('dragover', preventDefault);
    document.addEventListener('drop', preventDefault);
  }

  componentWillUnmount() {
    document.removeEventListener('dragover', preventDefault);
    document.removeEventListener('drop', preventDefault);
  }

  componentWillReceiveProps(nextProps) {
    const scrollingDisabled = nextProps.scrollingDisabled;
    const scrollingDisabledHasChanged = scrollingDisabled !== this.props.scrollingDisabled;

    if (scrollingDisabled && scrollingDisabledHasChanged) {
      // Update current scroll position, if scrolling is disabled (e.g. modal is open)
      this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    }
  }

  scrollingDisabledChanged(currentScrollingDisabled) {
    if (currentScrollingDisabled && currentScrollingDisabled !== this.scrollingDisabled) {
      // Update current scroll position, if scrolling is disabled (e.g. modal is open)
      this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.scrollingDisabled = currentScrollingDisabled;
    } else if (currentScrollingDisabled !== this.scrollingDisabled) {
      this.scrollingDisabled = currentScrollingDisabled;
    }
  }

  render() {
    const {
      className,
      rootClassName,
      contentClassName,
      children,
      location,
      intl,
      scrollingDisabled,
      referrer,
      author,
      contentType,
      description,
      facebookImages,
      published,
      schema,
      tags,
      title,
      twitterHandle,
      twitterImages,
      updated,
      rating,
      userRatingsTotal,
      isFilteringByKeywords,
      shouldPreventIndexing,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className, {
      [css.scrollingDisabled]: scrollingDisabled,
    });

    this.scrollingDisabledChanged(scrollingDisabled);
    const referrerMeta = referrer ? <meta name="referrer" content={referrer} /> : null;

    const canonicalRootURL = config.canonicalRootURL;
    const shouldReturnPathOnly = referrer && referrer !== 'unsafe-url';
    const canonicalPath = isFilteringByKeywords
      ? pathByRouteName('SearchPage', routeConfiguration(), {})
      : canonicalRoutePath(routeConfiguration(), location, shouldReturnPathOnly);
    const canonicalUrl = `${canonicalRootURL}${canonicalPath}`;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'Page.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'Page.schemaDescription' });
    const metaTitle = title || schemaTitle;
    const metaDescription = description || schemaDescription;
    const facebookImgs = facebookImages || [
      {
        name: 'facebook',
        url: `${canonicalRootURL}${facebookImage}`,
        width: 1200,
        height: 630,
      },
    ];
    const twitterImgs = twitterImages || [
      {
        name: 'twitter',
        url: `${canonicalRootURL}${twitterImage}`,
        width: 600,
        height: 314,
      },
    ];

    const metaToHead = metaTagProps({
      author,
      contentType,
      description: metaDescription,
      facebookImages: facebookImgs,
      twitterImages: twitterImgs,
      published,
      tags,
      title: metaTitle,
      twitterHandle,
      updated,
      url: canonicalUrl,
      locale: intl.locale,
    });

    // eslint-disable-next-line react/no-array-index-key
    const metaTags = metaToHead.map((metaProps, i) => <meta key={i} {...metaProps} />);

    const facebookPage = config.siteFacebookPage;
    const twitterPage = twitterPageURL(config.siteTwitterHandle);
    const instagramPage = config.siteInstagramPage;
    const sameOrganizationAs = [facebookPage, twitterPage, instagramPage].filter(v => v != null);

    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format

    // Schema attribute can be either single schema object or an array of objects
    // This makes it possible to include several different items from the same page.
    // E.g. Product, Place, Video
    const schemaFromProps = Array.isArray(schema) ? schema : [schema];
    const schemaArrayJSONString = JSON.stringify([
      ...schemaFromProps,
      {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        '@id': `${canonicalRootURL}#organization`,
        url: canonicalRootURL,
        name: siteTitle,
        sameAs: sameOrganizationAs,
        logo: `${canonicalRootURL}/static/webapp-icon-192x192.png`,
        address: config.address,
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: rating,
          reviewCount: userRatingsTotal,
        },
        // NOTE: DO NOT add properties that are BELOW aggregateRating.
        // you must add ABOVE it.
        // Check the renderer.js file for more information.
      },
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: canonicalRootURL,
        description: schemaDescription,
        publisher: {
          '@id': `${canonicalRootURL}#organization`,
        },
      },
    ]);

    const scrollPositionStyles = scrollingDisabled
      ? { marginTop: `${-1 * this.scrollPosition}px` }
      : {};

    // If scrolling is not disabled, but content element has still scrollPosition set
    // in style attribute, we scrollTo scrollPosition.
    const hasMarginTopStyle = this.contentDiv && this.contentDiv.style.marginTop;
    if (!scrollingDisabled && hasMarginTopStyle) {
      window.requestAnimationFrame(() => {
        window.scrollTo(0, this.scrollPosition);
      });
    }

    const pageRouteConfig = matchPathname(location.pathname, routeConfiguration());
    const route = pageRouteConfig[0]?.route;
    const { name, hideInSitemap } = route || {};
    const params = drop(location.pathname.split('/'), NUMBER_OF_ELEMENTS_TO_DROP);

    const checkHideInSitemapLandingPage = () => {
      if (params.length > LANDING_PAGE_PARAMS_LIMIT) return true;
      if (params.length === 0) return false;
      const matchArtistCategories = params.filter(param =>
        artistCategories.find(artistCategory => artistCategory.key === param)
      );
      return matchArtistCategories.length !== 0;
    };

    const checkHideInSitemapSearchPage = () => {
      if (params.length > SEARCH_PAGE_PARAMS_LIMIT) return true;
      if (params.length < SEARCH_PAGE_PARAMS_LIMIT) return false;

      const [firstParam, secondParam, thirdParam] = params;

      const {
        categoryFilter: {
          mappingParameterValues: { oil_painting: oilPainting },
        },
        // subjectFilter: {
        //   mappingParameterValues: { Mountain: mountain },
        // },
      } = filterConfig;

      // if (firstParam !== oilPainting || secondParam !== mountain) {
      if (firstParam !== oilPainting) {
        return true;
      }

      const {
        priceRangeFilter,
        sizeFilter,
        orientationFilter,
        frameFilter,
        colorFilter,
      } = filterConfig;
      const filterArr = [priceRangeFilter, sizeFilter, orientationFilter, frameFilter, colorFilter];
      let mappingValue = thirdParam;

      filterArr.forEach(({ mappingParameterValues }) => {
        if (mappingParameterValues) {
          const index = Object.values(mappingParameterValues).findIndex(v => v === thirdParam);
          if (index !== -1) {
            mappingValue = Object.keys(mappingParameterValues)[index];
          }
        }
      });
      return !filterArr.some(({ options }) => options.some(opt => opt.key === mappingValue));
    };

    const checkHideInSitemapLandingAndSearchPage = pageName => {
      return pageName === 'LandingPage'
        ? checkHideInSitemapLandingPage()
        : checkHideInSitemapSearchPage();
    };

    const isRouteHideInSitemap =
      location.search ||
      (name === 'LandingPage' || name === 'SearchPage'
        ? checkHideInSitemapLandingAndSearchPage(name)
        : hideInSitemap);

    const isAdminListingURL = location.pathname.includes(config.adminListingUUID);

    const robotMetaTag =
      shouldPreventIndexing || isRouteHideInSitemap || isAdminListingURL ? (
        <meta
          name="robots"
          content="nofollow, noindex, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
      ) : (
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
      );

    return (
      <div className={classes}>
        <Helmet
          htmlAttributes={{
            lang: intl.locale,
          }}
        >
          <title>{title}</title>
          {referrerMeta}
          <link rel="canonical" href={canonicalUrl} />
          <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta httpEquiv="Content-Language" content={intl.locale} />
          {robotMetaTag}
          {metaTags}
          <script type="application/ld+json">{schemaArrayJSONString}</script>
        </Helmet>
        <div
          className={classNames(css.content, contentClassName)}
          style={scrollPositionStyles}
          ref={c => {
            this.contentDiv = c;
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

const { any, array, arrayOf, bool, func, number, object, oneOfType, shape, string } = PropTypes;

PageComponent.defaultProps = {
  className: null,
  rootClassName: null,
  contentClassName: null,
  children: null,
  author: null,
  contentType: 'website',
  description: null,
  facebookImages: null,
  twitterImages: null,
  published: null,
  referrer: null,
  schema: null,
  tags: null,
  twitterHandle: null,
  updated: null,
  rating: null,
  userRatingsTotal: null,
  shouldPreventIndexing: false,
  scrollingDisabled: false,
};

PageComponent.propTypes = {
  className: string,
  rootClassName: string,
  contentClassName: string,
  children: any,
  scrollingDisabled: bool.isRequired,

  // Handle referrer policy
  referrer: string,

  // SEO related props
  author: string,
  contentType: string, // og:type
  description: string, // page description
  facebookImages: arrayOf(
    shape({
      width: number.isRequired,
      height: number.isRequired,
      url: string.isRequired,
    })
  ),
  twitterImages: arrayOf(
    shape({
      width: number.isRequired,
      height: number.isRequired,
      url: string.isRequired,
    })
  ),
  published: string, // article:published_time
  schema: oneOfType([object, array]), // http://schema.org
  tags: string, // article:tag
  title: string.isRequired, // page title
  twitterHandle: string, // twitter handle
  updated: string, // article:modified_time

  // from withRouter
  history: shape({
    listen: func.isRequired,
  }).isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  rating: number,
  userRatingsTotal: number,
  shouldPreventIndexing: bool,
};

const mapStateToProps = state => {
  const { rating, userRatingsTotal } = state.Page;

  return {
    rating,
    userRatingsTotal,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const Page = compose(withRouter, connect(mapStateToProps), injectIntl)(PageComponent);

Page.displayName = 'Page';

export default Page;
