import React from 'react';
import PropTypes from 'prop-types';

import css from './Logo.module.css';
import classNames from 'classnames';

const Logo = props => {
  const { className, ...rest } = props;
  const logoClassName = classNames(css.root, className);

  return (
    <svg
      className={logoClassName}
      {...rest}
      viewBox="0 0 123 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4529_3489)">
        <path
          d="M6.32826 8.89618C4.61622 8.89618 3.4524 9.93374 3.4524 11.3186C3.4524 12.5647 4.10168 13.4635 5.88362 14.1214L7.66387 14.7445C10.6433 15.8178 12.8345 16.9251 12.8345 20.0744C12.8345 23.5012 9.54686 25.2656 6.50005 25.2656C3.41788 25.2656 0.679283 23.4663 0.0283203 20.4549L2.28774 19.7637C2.83597 21.7359 4.44443 22.9471 6.50005 22.9471C8.28031 22.9471 10.0606 21.9087 10.0606 20.1093C10.0606 18.5517 8.99864 17.721 7.15018 17.0639L5.1636 16.3361C2.04858 15.2288 0.712126 13.4635 0.712126 11.3186C0.712126 8.51486 3.17787 6.57593 6.26005 6.57593C9.13591 6.57593 11.157 8.16929 12.1153 10.5568L9.78603 11.2488C9.10222 9.79585 7.97209 8.89618 6.32826 8.89618Z"
          fill="#050707"
        />
        <path
          d="M15.4004 17.3408V6.88948H18.2089V17.2719C18.2089 21.0442 20.1609 22.6708 22.7968 22.6708C25.9817 22.6708 28.1729 19.6951 28.6176 17.5136V6.88948H31.426V24.8855H28.6176V20.6978C28.2765 21.9439 26.1181 25.1621 22.214 25.1621C18.7563 25.1621 15.4004 22.9134 15.4004 17.3408Z"
          fill="#050707"
        />
        <path
          d="M50.1208 15.8869C50.1208 11.2847 46.9359 9.10407 43.8891 9.10407C40.8751 9.10407 37.3829 11.3537 37.3829 15.8869C37.3829 20.4551 40.8751 22.7055 43.8891 22.7055C46.9359 22.7055 50.1208 20.524 50.1208 15.8869M52.7912 6.88936V24.8854C52.7912 30.3191 48.0661 32.0146 44.642 32.0146C41.9025 32.0146 40.1568 31.2886 39.2995 30.6647V28.2432C40.1568 28.8654 41.9025 29.5923 44.642 29.5923C47.7233 29.5923 49.9827 27.8968 49.9827 24.8854V21.4246C49.1625 23.5014 46.7313 25.2318 43.5801 25.2318C39.5395 25.2318 34.6426 22.1514 34.6426 15.8869C34.6426 9.69222 39.5395 6.57698 43.5801 6.57698C46.7313 6.57698 49.1625 8.34228 49.9827 10.2804V6.88936H52.7912Z"
          fill="#050707"
        />
        <path
          d="M58.764 15.8866C58.764 20.4548 62.2571 22.7052 65.2702 22.7052C68.3187 22.7052 71.502 20.5237 71.502 15.8866C71.502 11.2844 68.3187 9.10373 65.2702 9.10373C62.2571 9.10373 58.764 11.3533 58.764 15.8866ZM71.3656 10.28V6.88902H74.1732V24.885H71.3656V21.4242C70.5436 23.501 68.1124 25.2314 64.962 25.2314C60.9224 25.2314 56.0254 22.1511 56.0254 15.8866C56.0254 9.69188 60.9224 6.57665 64.962 6.57665C68.1124 6.57665 70.5436 8.34195 71.3656 10.28"
          fill="#050707"
        />
        <path
          d="M88.5564 6.68121V9.34618C88.2836 9.27723 87.9745 9.27723 87.7345 9.27723C84.345 9.27723 81.571 11.7345 81.0918 15.7826V24.8857H78.2842V6.88889H81.0918V11.6298C81.7428 8.96571 84.1732 6.61226 87.7008 6.61226C88.009 6.61226 88.2836 6.61226 88.5564 6.68121"
          fill="#050707"
        />
        <path d="M93.8927 24.8848H91.0859V0.416569H93.8927V24.8848Z" fill="#050707" />
        <path
          d="M100.735 24.8848H97.9268V6.88791H100.735V24.8848ZM100.735 2.9428H97.9268V0.416569H100.735V2.9428Z"
          fill="#050707"
        />
        <path
          d="M108.198 9.27756V24.8861H105.391V9.27756H103.439V6.88921H105.391V5.60822C105.391 0.867278 108.507 0.0714479 110.768 0.0714479C112.238 0.0714479 113.609 0.486812 114.636 1.14391L113.609 3.11689C112.821 2.73557 112.033 2.45894 111.007 2.45894C109.124 2.45894 108.198 3.39351 108.198 5.60822V6.88921H115.697V2.66662H118.471V6.88921H122.237V9.27756H118.471V20.8712C118.471 21.9445 118.882 22.6016 119.908 22.6016C120.798 22.6016 121.449 22.3939 122.202 22.0483V24.4707C121.449 24.8512 120.558 25.0938 119.155 25.0938C117.34 25.0938 115.697 24.2622 115.697 21.1129V9.27756H108.198Z"
          fill="#050707"
        />
      </g>
      <defs>
        <clipPath id="clip0_4529_3489">
          <rect width="122.256" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string, number, shape } = PropTypes;

Logo.defaultProps = {
  className: null,
};

Logo.propTypes = {
  className: string,
};

export default Logo;
