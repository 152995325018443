import { storableError } from '../../util/errors';
import {
  TRANSITION_ENQUIRE,
  TRANSITION_PROVIDER_READ,
  TRANSITION_CUSTOMER_READ,
  TRANSITION_PROVIDER_REPLY,
  TRANSITION_CUSTOMER_REPLY,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import uniq from 'lodash.uniq';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

export const convertMetaMsgToDefaultMsg = (tx, defaultMessages) => {
  const {
    provider,
    customer,
    attributes: {
      metadata: { messages: metaMessages },
    },
  } = tx;

  const convertedMessages = metaMessages.map(message => {
    const { content, createdAtTimestamp, senderId } = message;

    return {
      id: new UUID(createdAtTimestamp.toString()),
      attributes: {
        content,
        createdAt: new Date(createdAtTimestamp),
      },
      sender: senderId === provider.id.uuid ? provider : customer,
    };
  });

  const messages = defaultMessages
    .concat(convertedMessages)
    .sort((a, b) => a.attributes.createdAt - b.attributes.createdAt);

  const removedDuplicateMsgs = uniq(messages, msg => msg.createdAt);

  return { ...tx, messages: removedDuplicateMsgs };
}

const mergeMsg = (txs) => {
  return txs.map(tx => {
    const metaMessages = tx.attributes.metadata.messages;
    if (metaMessages?.length > 0) {
      return convertMetaMsgToDefaultMsg(tx, tx.messages);
    }
    return tx;
  })
}

// ================ Action types ================ //

export const FETCH_TRANSACTIONS_REQUEST = 'app/MessagesPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/MessagesPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/MessagesPage/FETCH_TRANSACTIONS_ERROR';

export const LOAD_MORE_REQUEST = 'app/MessagesPage/LOAD_MORE_REQUEST';
export const LOAD_MORE_SUCCESS = 'app/MessagesPage/LOAD_MORE_SUCCESS';
export const LOAD_MORE_ERROR = 'app/MessagesPage/LOAD_MORE_ERROR';

// ================ Reducer ================ //

const initialState = {
  transactions: [],
  pagination: null,
  fetchTransactionsInProgress: false,
  fetchTransactionsError: null,
  loadMoreInProgress: false,
  loadMoreError: null,
};

export default function MessagesReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        params: payload.params,
        fetchTransactionsInProgress: true,
        fetchTransactionsError: null,
      };
    case FETCH_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        fetchTransactionsInProgress: false,
        transactions: payload.transactions,
        pagination: payload.pagination,
      };
    }
    case FETCH_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionsInProgress: false, fetchTransactionsError: payload };

    case LOAD_MORE_REQUEST:
      return {
        ...state,
        params: payload.params,
        loadMoreInProgress: true,
        loadMoreError: null,
      };
    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMoreInProgress: false,
        transactions: [...state.transactions, ...payload.transactions],
        pagination: payload.pagination,
      };
    case LOAD_MORE_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, loadMoreInProgress: false, loadMoreError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchTransactionsRequest = params => ({
  type: FETCH_TRANSACTIONS_REQUEST,
  payload: { params },
});
const fetchTransactionsSuccess = ({ transactions, pagination }) => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: { transactions, pagination },
});
const fetchTransactionsError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

const loadMoreRequest = params => ({
  type: LOAD_MORE_REQUEST,
  payload: { params },
});
const loadMoreSuccess = ({ transactions, pagination }) => ({
  type: LOAD_MORE_SUCCESS,
  payload: { transactions, pagination },
});
const loadMoreError = e => ({
  type: LOAD_MORE_ERROR,
  error: true,
  payload: e,
});
// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

const canLoadMore = (pagination = {}) => {
  const { page, totalPages } = pagination || {};
  return page < totalPages;
};

const isLoadingMore = state => {
  const { loadMoreInProgress } = state.MessagesPage;
  return loadMoreInProgress;
};

export const loadMore = () => (dispatch, getState, sdk) => {
  const { pagination, params } = getState().MessagesPage;
  if (isLoadingMore(getState()) || !canLoadMore(pagination)) return;

  const newParams = {
    ...params,
    page: pagination.page + 1,
  };

  dispatch(loadMoreRequest(newParams));

  return sdk.transactions
    .query(newParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));

      const { meta } = response.data;
      dispatch(
        loadMoreSuccess({
          transactions: mergeMsg(denormalisedResponseEntities(response)),
          pagination: meta,
        })
      );

      return response;
    })
    .catch(e => {
      dispatch(loadMoreError(storableError(e)));
      throw e;
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  const filterTransitions = [
    TRANSITION_ENQUIRE,
    TRANSITION_PROVIDER_READ,
    TRANSITION_CUSTOMER_READ,
    TRANSITION_PROVIDER_REPLY,
    TRANSITION_CUSTOMER_REPLY,
  ];

  const params = {
    lastTransitions: filterTransitions,
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'messages',
      'messages.sender',
    ],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page: 1,
    per_page: INBOX_PAGE_SIZE,
  };

  dispatch(fetchTransactionsRequest(params));

  return sdk.transactions
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));

      const { meta } = response.data;
      dispatch(
        fetchTransactionsSuccess({
          transactions: mergeMsg(denormalisedResponseEntities(response)),
          pagination: meta,
        })
      );

      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionsError(storableError(e)));
      throw e;
    });
};
