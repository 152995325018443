import qs from 'qs';
import config from '../config';
import axios from 'axios';
import { types as sdkTypes } from './sdkLoader';

const { UUID } = sdkTypes;

const { apiUrl, apiKey } = config.strapiConfiguration;
const DATE_FORMAT = 'YYYY-MM-DD';

export const get = (path, query) => {
  const url = `${apiUrl}${path}${
    query ? `?${qs.stringify(query, { encodeValuesOnly: true })}` : ''
  }`;

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
    },
  };

  return axios
    .get(url, options)
    .then(async res => {
      return res.data;
    })
    .catch(error => {
      throw error;
    });
};

const getSlugsFilter = slugs => {
  const arr = [];
  const slugArray = slugs.split(',');

  // Need to separate same slug propertes
  for (let i = 0; i < slugArray.length; i++) {
    const obj = { slug: { $eq: slugArray[i].trim() } };
    arr.push(obj);
  }
  return arr;
};

const denormalisedImages = images => {
  return images.data.map(image => {
    const {
      id,
      attributes: { alternativeText: alt, formats: variants, url },
    } = image;

    return {
      alt,
      url,
      attributes: { variants },
      //here we mimic the id and type of Flex' images to accomodate the props type image
      id: new UUID(id),
      type: 'image',
    };
  });
};

const denormalisedImage = image => {
  const {
    id,
    attributes: { alternativeText: alt, formats: variants, url },
  } = image.data;

  const { formats, ...originVariants } = image.data.attributes;
  variants.origin = originVariants;

  return {
    alt,
    url,
    attributes: { variants },
    //here we mimic the id and type of Flex' images to accomodate the props type image
    id: new UUID(id),
    type: 'image',
  };
};

const strapi = {
  get,
  DATE_FORMAT,
  getSlugsFilter,
  denormalisedImages,
  denormalisedImage,
};

export default strapi;
