import find from 'lodash/find';
import values from 'lodash/values';
import { matchPath } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';
import { mappingParamValue, stringify } from './urlHelpers';
import {
  DEFAULT_SORT_BY_FEATURED,
  filterConfig,
  paramsSearchUrlDict,
  artistFilterConfig,
  ARTIST_TRAFFIC,
} from '../marketplace-custom-config';
const findRouteByName = (nameToFind, routes) => find(routes, route => route.name === nameToFind);
const compose = (...fns) => (...args) => fns.reduce((res, fn) => [fn.call(null, ...res)], args)[0];

/**
 * E.g. ```const toListingPath = toPathByRouteName('ListingPage', routes);```
 * Then we can generate listing paths with given params (```toListingPath({ id: uuidX })```)
 */
const toPathByRouteName = (nameToFind, routes) => {
  const route = findRouteByName(nameToFind, routes);
  if (!route) {
    throw new Error(`Path "${nameToFind}" was not found.`);
  }
  return pathToRegexp.compile(route.path);
};

/**
 * Shorthand for single path call. (```pathByRouteName('ListingPage', routes, { id: uuidX });```)
 */
export const pathByRouteName = (nameToFind, routes, params = {}) => {
  const hasEmptySlug = params && params.hasOwnProperty('slug') && params.slug === '';
  const pathParams = hasEmptySlug ? { ...params, slug: 'no-slug' } : params;
  return toPathByRouteName(nameToFind, routes)(pathParams);
};

/**
 * Find the matching routes and their params for the given pathname
 *
 * @param {String} pathname - Full URL path from root with possible
 * search params and hash included
 *
 * @return {Array<{ route, params }>} - All matches as { route, params } objects if matches has
 * exact flag set to false. If not, an array containing just the first matched exact route is returned.
 */
export const matchPathname = (pathname, routeConfiguration) => {
  const matchedRoutes = routeConfiguration.reduce((matches, route) => {
    const { path, exact = true } = route;
    const match = matchPath(pathname, { path, exact });
    if (match) {
      matches.push({
        route,
        params: match.params || {},
      });
    }
    return matches;
  }, []);

  const matchedExactRoute = matchedRoutes.find(r => {
    return r.exact === true || r.exact == null;
  });

  // We return matched 'exact' path route only if such exists
  // and all matches if no exact flag exists.
  return matchedExactRoute ? [matchedExactRoute] : matchedRoutes;
};

/**
 * ResourceLocatorString is used to direct webapp to correct page.
 * In contrast to Universal Resource Locator (URL), this doesn't contain protocol, host, or port.
 */
export const createResourceLocatorString = (
  routeName,
  routes,
  pathParams = {},
  searchParams = {},
  hash = ''
) => {
  if (routeName === 'SearchPage') {
    return createSearchPageResourceLocatorString(routeName, routes, searchParams);
  }
  if (routeName === 'LandingPage') {
    return createLandingPageResourceLocatorString(routeName, routes, searchParams);
  }
  const searchQuery = stringify(searchParams);
  const includeSearchQuery = searchQuery.length > 0 ? `?${searchQuery}` : '';
  const path = pathByRouteName(routeName, routes, pathParams);
  return `${path}${includeSearchQuery}${hash}`;
};

/**
 * Find component related to route name
 * E.g. `const PageComponent = findComponentByRouteName('CheckoutPage', routes);`
 * Then we can call static methods of given component:
 * `dispatch(PageComponent.setInitialValues({ listing, bookingDates }));`
 *
 * @param {String} nameToFind - Route name
 * @param {Array<{ route }>} routes - Route configuration as flat array.
 *
 * @return {Route} - Route that matches the given route name.
 */
export const findRouteByRouteName = (nameToFind, routes) => {
  const route = findRouteByName(nameToFind, routes);
  if (!route) {
    throw new Error(`Component "${nameToFind}" was not found.`);
  }
  return route;
};

/**
 * Get the canonical URL from the given location
 *
 * @param {Array<{ route }>} routes - Route configuration as flat array
 * @param {Object} location - location object from React Router
 *
 * @return {String} Canonical URL of the given location
 *
 */
export const canonicalRoutePath = (routes, location, pathOnly = false) => {
  const { pathname, search, hash } = location;
  return pathOnly ? pathname : `${pathname}${search}${hash}`;
};

const separateFirstOptionFromRest = filters => {
  if (filters && filters.length > 0) {
    const [firstOption, ...filtersList] = filters.split(',');
    return [firstOption, filtersList];
  }
  return ['', ''];
};

const mappingUrlParameters = (params) => {
  const mappedParams = params;
  for (let param in params) {
    const mappedParam = paramsSearchUrlDict[param];
    if (mappedParam) {
      mappedParams[mappedParam] = params[param];
      delete mappedParams[param];
    }
  }
  return mappedParams
}

const sortQueryParams = ({routeParamNames, queryParams}) =>
  routeParamNames.forEach(param => {
    if (queryParams[param]) {
      const paramValue = queryParams[param];
      delete queryParams[param];
      queryParams[param] = paramValue;
    }
  });

const splitParams = ({ routeParamNames, queryParams }) => {
  const { searchParams, routeParams } = Object.entries(queryParams).reduce(
    (acc, queryParam) => {
      const paramName = queryParam[0];
      const paramValue = queryParam[1]; // not use deconstructing here to avoid error in case of empty paramValue
      const mappedParamValue = mappingParamValue(paramName, paramValue);
      if (routeParamNames.includes(paramName)) {
        acc.routeParams.push([paramName, mappedParamValue]);
      } else {
        acc.searchParams[paramName] = mappedParamValue;
      }
      return acc;
    },
    { searchParams: {}, routeParams: [] }
  );

  return { searchParams, routeParams };
};

const createRouteArray = ({routeParams, searchParams}) => {
  const routeParamsUrl = [];
  routeParams.forEach(([paramName, filters]) => {
    const [firstOption, restOptions] = separateFirstOptionFromRest(filters);
    // If first option is not empty, add it to route params, the rest of options will be added to search params
    const filterUrl = firstOption ? `/${firstOption}` : '';
    routeParamsUrl.push(filterUrl);
    if (restOptions.length > 0) {
      const mappedParamName = mappingUrlParameters(paramName);
      searchParams[mappedParamName] = restOptions.join(',');
    }
  });
  return routeParamsUrl;
}

export const createSearchPageResourceLocatorString = (routeName, routes, queryParams) => {
  const routeParamNames = Object.values(filterConfig).filter(filter => filter.isRouteParam).map(filter => filter.paramName);
  // Sort query params keys order the same as in filterConfig
  sortQueryParams({routeParamNames, queryParams});
  // Split route params from rest of search params
  const { searchParams, routeParams } = splitParams({ routeParamNames, queryParams });

  const routeParamsUrl = createRouteArray({routeParams, searchParams});
  // check if sort using the default sorting, if yes, remove it
  const { sort } = searchParams;
  if (sort === DEFAULT_SORT_BY_FEATURED) {
    delete searchParams.sort;
  }

  const searchQuery = compose(
    mappingUrlParameters, 
    stringify, 
    searchQuery => searchQuery.length > 0 ? `?${searchQuery}` : ''
  )(searchParams);
  const path = `${pathByRouteName(routeName, routes, {})}`;
  return `${path}${routeParamsUrl.join('')}${searchQuery}`;
};

export const createLandingPageResourceLocatorString = (routeName, routes, queryParams) => {
  const routeParamNames = values(artistFilterConfig).map(filter => filter.paramName);
  // Sort query params keys order the same as in filterConfig
  sortQueryParams({routeParamNames, queryParams});

  // Split route params from rest of search params
  const { searchParams, routeParams } = splitParams({ routeParamNames, queryParams });

  const routeParamsUrl = createRouteArray({routeParams, searchParams});
  // Replace space in param with hyphen
  const normalRouteParamsUrl = routeParamsUrl.map(path => path.replace(/ /g, '-'));
  // check if sort using the default sorting, if yes, remove it
  const { sort } = searchParams;
  if (sort === ARTIST_TRAFFIC) {
    delete searchParams.sort;
  }

  const searchQuery = compose(
    mappingUrlParameters, 
    stringify, 
    searchQuery => searchQuery.length > 0 ? `?${searchQuery}` : ''
  )(searchParams);
  const path = `${pathByRouteName(routeName, routes, {})}`;
  return `${path}${normalRouteParamsUrl.join('')}${searchQuery}`;
}