import { FAVORITE_ARTISTS_TAB } from '../../util/types';
import {loadData as loadFavoriteArtists} from '../ManageFavoriteArtistsPage/ManageFavoriteArtistsPage.duck';
import {loadData as loadFavoriteListings} from '../ManageFavoriteListingsPage/ManageFavoriteListingsPage.duck';

// ================ Thunks ================ //

export const queryFavorite = (tab) => (dispatch) => {
  const loadDataForTab = tab === FAVORITE_ARTISTS_TAB ? loadFavoriteArtists : loadFavoriteListings;
  return dispatch(loadDataForTab());
};

export const loadData = (tab) => dispatch => {
  return dispatch(queryFavorite(tab));
};
