import { pressTags } from '../../marketplace-custom-config';
import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';
import omit from 'lodash/omit';

const ITEMS_PER_PAGE = 12;

// ================ Action types ================ //

export const QUERY_PRESS_PAGE_REQUEST = 'app/PressPage/QUERY_PRESS_PAGE_REQUEST';
export const QUERY_PRESS_PAGE_SUCCESS = 'app/PressPage/QUERY_PRESS_PAGE_SUCCESS';
export const QUERY_PRESS_PAGE_ERROR = 'app/PressPage/QUERY_PRESS_PAGE_ERROR';

export const QUERY_PRESS_ITEMS_REQUEST = 'app/PressPage/QUERY_PRESS_ITEMS_REQUEST';
export const QUERY_PRESS_ITEMS_SUCCESS = 'app/PressPage/QUERY_PRESS_ITEMS_SUCCESS';
export const QUERY_PRESS_ITEMS_ERROR = 'app/PressPage/QUERY_PRESS_ITEMS_ERROR';

export const LOAD_MORE_PRESS_ITEMS_REQUEST = 'app/PressPage/LOAD_MORE_PRESS_ITEMS_REQUEST';
export const LOAD_MORE_PRESS_ITEMS_SUCCESS = 'app/PressPage/LOAD_MORE_PRESS_ITEMS_SUCCESS';
export const LOAD_MORE_PRESS_ITEMS_ERROR = 'app/PressPage/LOAD_MORE_PRESS_ITEMS_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryPressPageError: null,
  pressPage: null,
  queryPressPageInProgress: false,
  pressItems: null,
  queryPressItemsError: null,
  queryParams: null,
  pagination: null,
  loadMorePressItemsInProgress: false,
  loadMorePressItemsError: null,
};

export default function PressReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_PRESS_PAGE_REQUEST:
      return { ...state, queryPressPageError: null, queryPressPageInProgress: true };
    case QUERY_PRESS_PAGE_SUCCESS:
      return { ...state, pressPage: payload, queryPressPageInProgress: false };
    case QUERY_PRESS_PAGE_ERROR:
      return { ...state, queryPressPageError: payload, queryPressPageInProgress: false };

    case QUERY_PRESS_ITEMS_REQUEST:
      return {
        ...state,
        queryPressItemsError: null,
        queryParams: payload,
      };
    case QUERY_PRESS_ITEMS_SUCCESS:
      return {
        ...state,
        pressItems: payload.data,
        pagination: payload.meta.pagination,
      };
    case QUERY_PRESS_ITEMS_REQUEST:
      return { ...state, queryPressItemsError: payload, };

    case LOAD_MORE_PRESS_ITEMS_REQUEST:
      return {
        ...state,
        loadMorePressItemsError: null,
        loadMorePressItemsInProgress: true,
        queryParams: payload,
      };
    case LOAD_MORE_PRESS_ITEMS_SUCCESS:
      return {
        ...state,
        loadMorePressItemsInProgress: false,
        pressItems: [...state.pressItems, ...payload.data],
        pagination: payload.meta.pagination,
      };
    case LOAD_MORE_PRESS_ITEMS_ERROR:
      return { ...state, loadMorePressItemsError: payload, loadMorePressItemsInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryPressPageRequest = () => ({
  type: QUERY_PRESS_PAGE_REQUEST,
});

export const queryPressPageSuccess = pressInfo => ({
  type: QUERY_PRESS_PAGE_SUCCESS,
  payload: pressInfo,
});

export const queryPressPageError = e => ({
  type: QUERY_PRESS_PAGE_ERROR,
  payload: e,
});

export const queryPressItemsRequest = queryParams => ({
  type: QUERY_PRESS_ITEMS_REQUEST,
  payload: queryParams,
});

export const queryPressItemsSuccess = pressItems => ({
  type: QUERY_PRESS_ITEMS_SUCCESS,
  payload: pressItems,
});

export const queryPressItemsError = e => ({
  type: QUERY_PRESS_ITEMS_ERROR,
  payload: e,
});

export const loadMorePressItemsRequest = queryParams => ({
  type: LOAD_MORE_PRESS_ITEMS_REQUEST,
  payload: queryParams,
});

export const loadMorePressItemsSuccess = pressItems => ({
  type: LOAD_MORE_PRESS_ITEMS_SUCCESS,
  payload: pressItems,
});

export const loadMorePressItemsError = e => ({
  type: LOAD_MORE_PRESS_ITEMS_ERROR,
  payload: e,
});

// ================ Thunks ================ //

const canLoadMore = (pagination = {}) => {
  const { page, pageCount } = pagination || {};
  return page < pageCount;
};

export const queryPressItems = tag => (dispatch, getState) => {
  const { queryParams } = getState().PressPage;
  const [_, ...queryableTags] = pressTags;
  const tagParam = queryableTags.find(queryableTag => queryableTag.key === tag);
  const pagination = {
    page: 1,
    pageSize: ITEMS_PER_PAGE,
  };

  const params = tagParam
    ? {
        ...queryParams,
        pagination,
        filters: {
          tag: {
            $eq: tagParam.key,
          },
        },
      }
    : { ...omit(queryParams, ['filters']), pagination };

  dispatch(queryPressItemsRequest(params));

  return strapi
    .get('/api/presses', params)
    .then(response => dispatch(queryPressItemsSuccess(response)))
    .catch(e => dispatch(queryPressItemsError(storableError(e.response))));
};

export const loadMore = () => (dispatch, getState) => {
  const { pagination, queryParams, loadMorePressItemsInProgress } = getState().PressPage;

  if (loadMorePressItemsInProgress || !canLoadMore(pagination)) return;

  const { page, pageSize } = pagination;

  const params = {
    ...queryParams,
    pagination: {
      pageSize,
      page: page + 1,
    },
  };

  dispatch(loadMorePressItemsRequest(params));

  return strapi
    .get('/api/presses', params)
    .then(response => dispatch(loadMorePressItemsSuccess(response)))
    .catch(error => dispatch(loadMorePressItemsError(error)));
};

export const loadData = () => dispatch => {
  const queryParams = {
    populate: 'heroImage',
    sort: ['publishDate:desc'],
    pagination: {
      page: 1,
      pageSize: ITEMS_PER_PAGE,
    },
  };

  dispatch(queryPressPageRequest());
  dispatch(queryPressItemsRequest(queryParams));

  const pressPageRequest = strapi.get('/api/press-page');

  const pressRequest = strapi.get('/api/presses', queryParams);

  return Promise.all([pressPageRequest, pressRequest])
    .then(([pressPage, pressItems]) => {
      dispatch(queryPressItemsSuccess(pressItems));
      dispatch(queryPressPageSuccess(pressPage.data.attributes));
    })
    .catch(e => dispatch(queryPressPageError(storableError(e.response))));
};
