import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const QUERY_ART_ADVISORY_REQUEST = 'app/ArtAdvisoryPage/QUERY_ART_ADVISORY_REQUEST';
export const QUERY_ART_ADVISORY_SUCCESS = 'app/ArtAdvisoryPage/QUERY_ART_ADVISORY_SUCCESS';
export const QUERY_ART_ADVISORY_ERROR = 'app/ArtAdvisoryPage/QUERY_ART_ADVISORY_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryArtAdvisoryError: null,
  artAdvisory: null,
};

export default function ArtAdvisoryReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_ART_ADVISORY_REQUEST:
      return { ...state, queryArtAdvisoryError: null };
    case QUERY_ART_ADVISORY_SUCCESS:
      return { ...state, artAdvisory: payload };
    case QUERY_ART_ADVISORY_ERROR:
      return { ...state, queryArtAdvisoryError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryArtAdvisoryRequest = () => ({
  type: QUERY_ART_ADVISORY_REQUEST,
});

export const queryArtAdvisorySuccess = artAdvisory => ({
  type: QUERY_ART_ADVISORY_SUCCESS,
  payload: artAdvisory,
});

export const queryArtAdvisoryError = e => ({
  type: QUERY_ART_ADVISORY_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(queryArtAdvisoryRequest());

  const queryParams = {
    populate: 'heroImage',
  };

  return strapi
    .get('/api/art-advisory', queryParams)
    .then(res => dispatch(queryArtAdvisorySuccess(res.data.attributes)))
    .catch(e => dispatch(queryArtAdvisoryError(storableError(e.response))));
};
