import React, { useState, useEffect } from 'react';
import { string, object, func } from 'prop-types';
import classNames from 'classnames';

import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { artistIsMissingInfo } from '../../util/user';
import Modal from '../Modal/Modal';
import NamedLink from '../NamedLink/NamedLink';
import css from './ModalArtistMissingInformation.module.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import { IconCheck } from '../../containers/ListingPage/SectionDelivery';

const BLACK_LIST = ['NewListingPage', 'EditListingPage'];

const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const ModalArtistMissingInformation = props => {
  const {
    rootClassName,
    className,
    location,
    currentUser,
    containerClassName,
    intl,
    onManageDisableScrolling,
  } = props;

  const [show, setShow] = useState(false);

  const handleShowModal = () => {
    const routes = routeConfiguration();
    const blacklistedPaths = BLACK_LIST.map(
      page =>
        pathByRouteName(page, routes, { slug: draftSlug, id: draftId, type: 'new', tab: 'photos' }) // get default path for create new listing
    );
    const isInBlacklist = blacklistedPaths.includes(location.pathname);
    const isMissingInfo = currentUser && artistIsMissingInfo(currentUser);
    if (isInBlacklist && isMissingInfo) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    handleShowModal();
  }, [currentUser]);

  if (!currentUser || !currentUser.id) {
    return null;
  }
  const classes = classNames(rootClassName || css.root, className);

  return (
    <Modal
      id="ArtistMissingInformationForm"
      containerClassName={containerClassName}
      isOpen={show}
      hideCloseButton
      onManageDisableScrolling={onManageDisableScrolling}
      onClose={() => false}
    >
      <div className={classes}>
        <div className={css.title}>
          {intl.formatMessage({ id: 'ModalArtistMissingInformation.title' })}
        </div>
        <p>
          {intl.formatMessage({
            id: 'ModalArtistMissingInformation.missingInfoText',
          })}
        </p>
        <p className={css.fieldRequired}>
          <FormattedMessage
            id="ModalArtistMissingInformation.missingInfoText2"
            values={{
              profileSetting: (
                <b>{intl.formatMessage({ id: 'ModalArtistMissingInformation.profileSetting' })}</b>
              ),
              accountSetting: (
                <b>{intl.formatMessage({ id: 'ModalArtistMissingInformation.accountSetting' })}</b>
              ),
              checkMark: <IconCheck isVisible={true} />,
            }}
          />
        </p>
        <NamedLink name="ProfileSettingsPage" className={css.button}>
          {intl.formatMessage({
            id: 'ModalArtistMissingInformation.goToSettingPage',
          })}
        </NamedLink>
      </div>
    </Modal>
  );
};

ModalArtistMissingInformation.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
};

ModalArtistMissingInformation.propTypes = {
  rootClassName: string,
  className: string,
  location: object.isRequired,
  onManageDisableScrolling: func.isRequired,
};

export default injectIntl(ModalArtistMissingInformation);
