import { ensureTransaction } from './data';
import findLastIndex from 'lodash/findLastIndex';
export const txIsRequested = () => false;

const ALIAS_CUSTOMER_PRICING = 'custom-pricing';
const ALIAS_OFFER_PROCESS = 'offer-process';

export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';

const STATE_INITIAL = 'initial';

export const TRANSITION_MADE_OFFER = 'transition/made-offer';

export const TRANSITION_CUSTOMER_OFFER_EXPIRED = 'transition/customer-offer-expired';
export const TRANSITION_PROVIDER_DECLINED = 'transition/provider-declined';
export const TRANSITION_PROVIDER_OFFER = 'transition/provider-offer';
export const TRANSITION_PROVIDER_ACCEPT_OFFER = 'transition/provider-accept-offer';
export const TRANSITION_OFFER_EXPIRED = 'transition/offer-expired';
export const TRANSITION_CUSTOMER_ACCEPT_PAYMENT = 'transition/customer-accept-payment';

export const TRANSITION_CUSTOMER_DECLINED = 'transition/customer-declined';
export const TRANSITION_PROVIDER_OFFER_EXPIRED = 'transition/provider-offer-expired';
export const TRANSITION_CUSTOMER_OFFER = 'transition/customer-offer';
export const TRANSITION_CUSTOMER_ACCEPT_OFFER = 'transition/customer-accept-offer';

export const TRANSITION_PROVIDER_COMPLETE_EXPIRED = 'transition/PROVIDER_COMPLETE_EXPIRED';
export const TRANSITION_ADMIN_CANCELED = 'transition/admin-canceled';
export const TRANSITION_PROVIDER_CANCELED = 'transition/provider-canceled';
export const TRANSITION_ADMIN_COMPLETE = 'transition/admin-complete';
export const TRANSITION_PROVIDER_COMPLETE = 'transition/provider-complete';
export const TRANSITION_RECORD = 'transition/record';

export const STATE_CUSTOMER_MADE_OFFER = 'customer-made-offer';
export const STATE_DECLINED = 'declined';
export const STATE_PROVIDER_MADE_OFFER = 'provider-made-offer';
export const STATE_SHARE_PAYMENT_INFO = 'share-payment-info';
export const STATE_CANCELED = 'canceled';
export const STATE_SUCCESS = 'success';
export const STATE_RECORDED = 'recorded';
export const STATE_EXPIRED = 'expired';
export const STATE_WAITING_PAYMENT = 'waiting-payment';

//-------------------------------------------------------------//

export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_PROVIDER_READ = 'transition/provider-read';
export const TRANSITION_PROVIDER_REPLY = 'transition/provider-reply';
export const TRANSITION_CUSTOMER_READ = 'transition/customer-read';
export const TRANSITION_CUSTOMER_REPLY = 'transition/customer-reply';
export const TRANSITION_REQUEST = 'transition/request';
export const TRANSITION_REQUEST_TAX = 'transition/request-tax';
export const TRANSITION_REQUEST_AFTER_ENQUIRY = 'transition/request-after-enquiry';
export const TRANSITION_REQUEST_AFTER_ENQUIRY_TAX = 'transition/request-after-enquiry-tax';
export const TRANSITION_EXPIRE_PAYMENT = 'transition/payment-expired';
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_DECLINE = 'transition/decline';
export const TRANSITION_EXPIRE = 'transition/expire';
export const TRANSITION_COMPLETE_BY_ADMIN = 'transition/complete-by-admin';
export const TRANSITION_AUTOCOMPLETE = 'transition/autocomplete';
export const TRANSITION_AUTO_COMPLETE = 'transition/auto-complete';
export const TRANSITION_COMPLETE = 'transition/complete';
export const TRANSITION_REFUND = 'transition/refund';
export const TRANSITION_CANCEL = 'transition/cancel';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';


export const STATE_ENQUIRY = 'enquiry';
export const STATE_SEEN = 'seen';
export const STATE_UNSEEN = 'unseen';
export const STATE_PENDING_PAYMENT = 'pending-payment';
export const STATE_PAYMENT_EXPIRED = 'payment-expired';
export const STATE_PREAUTHORIZED = 'preauthorized';
export const STATE_ACCEPTED = 'accepted';
export const STATE_DELIVERED = 'delivered';
export const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
export const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
export const STATE_REVIEWED = 'reviewed';


const offerProcessDescription = {
  id: 'offer-process/release-1',
  initial: STATE_INITIAL,
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_RECORD]: STATE_RECORDED,
        [TRANSITION_MADE_OFFER]: STATE_CUSTOMER_MADE_OFFER,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
        [TRANSITION_ENQUIRE]: STATE_UNSEEN,
      },
    },
    [STATE_UNSEEN]: {
      on: {
        [TRANSITION_PROVIDER_READ]: STATE_SEEN,
        [TRANSITION_CUSTOMER_READ]: STATE_SEEN,
      },
    },
    [STATE_SEEN]: {
      on: {
        [TRANSITION_PROVIDER_REPLY]: STATE_UNSEEN,
        [TRANSITION_CUSTOMER_REPLY]: STATE_UNSEEN,
      },
    },
    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
      },
    },
    [STATE_PREAUTHORIZED]: {
      [TRANSITION_COMPLETE]: STATE_DELIVERED,
      [TRANSITION_REFUND]: STATE_DELIVERED,
      [TRANSITION_AUTO_COMPLETE]: STATE_DELIVERED,
    },
    [STATE_CUSTOMER_MADE_OFFER]: {
      on: {
        [TRANSITION_CUSTOMER_OFFER_EXPIRED]: STATE_EXPIRED,
        [TRANSITION_PROVIDER_DECLINED]: STATE_DECLINED,
        [TRANSITION_PROVIDER_ACCEPT_OFFER]: STATE_WAITING_PAYMENT,
      },
    },
    [STATE_WAITING_PAYMENT]: {
      on: {
        [TRANSITION_OFFER_EXPIRED]: STATE_EXPIRED,
        [TRANSITION_CUSTOMER_ACCEPT_PAYMENT]: STATE_RECORDED,
      },
    },
    [STATE_DECLINED]: { type: 'final' },
    [STATE_EXPIRED]: { type: 'final' },
    [STATE_DELIVERED]: { type: 'final' },
    [STATE_RECORDED]: { type: 'final' },
    [STATE_PAYMENT_EXPIRED]: { type: 'final' },
  },
};


const offerProcessWithoutAdminDescription = {
  id: 'offer-process/release-1',
  initial: STATE_INITIAL,
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_UNSEEN,
        [TRANSITION_RECORD]: STATE_RECORDED,
        [TRANSITION_MADE_OFFER]: STATE_CUSTOMER_MADE_OFFER,
      },
    },
    [STATE_UNSEEN]: {
      on: {
        [TRANSITION_PROVIDER_READ]: STATE_SEEN,
        [TRANSITION_CUSTOMER_READ]: STATE_SEEN,
      },
    },
    [STATE_SEEN]: {
      on: {
        [TRANSITION_PROVIDER_REPLY]: STATE_UNSEEN,
        [TRANSITION_CUSTOMER_REPLY]: STATE_UNSEEN,
      },
    },
    [STATE_CUSTOMER_MADE_OFFER]: {
      on: {
        [TRANSITION_CUSTOMER_OFFER_EXPIRED]: STATE_DECLINED,
        [TRANSITION_PROVIDER_DECLINED]: STATE_DECLINED,
        [TRANSITION_PROVIDER_ACCEPT_OFFER]: STATE_SHARE_PAYMENT_INFO,
        [TRANSITION_PROVIDER_OFFER]: STATE_PROVIDER_MADE_OFFER,
      },
    },
    [STATE_PROVIDER_MADE_OFFER]: {
      on: {
        [TRANSITION_CUSTOMER_OFFER]: STATE_CUSTOMER_MADE_OFFER,
        [TRANSITION_CUSTOMER_DECLINED]: STATE_DECLINED,
        [TRANSITION_PROVIDER_OFFER_EXPIRED]: STATE_DECLINED,
        [TRANSITION_CUSTOMER_ACCEPT_OFFER]: STATE_SHARE_PAYMENT_INFO,
      },
    },
    [STATE_SHARE_PAYMENT_INFO]: {
      on: {
        [TRANSITION_PROVIDER_COMPLETE_EXPIRED]: STATE_CANCELED,
        [TRANSITION_ADMIN_CANCELED]: STATE_CANCELED,
        [TRANSITION_PROVIDER_CANCELED]: STATE_CANCELED,
        [TRANSITION_ADMIN_COMPLETE]: STATE_SUCCESS,
        [TRANSITION_PROVIDER_COMPLETE]: STATE_SUCCESS,
      },
    },
    [STATE_CANCELED]: { type: 'final' },
    [STATE_SUCCESS]: { type: 'final' },
    [STATE_DELIVERED]: { type: 'final' },
    [STATE_RECORDED]: { type: 'final' },
  },
};

const customPricingDescription = {
  id: 'custom-pricing/release-1',
  initial: STATE_INITIAL,
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_REQUEST]: STATE_PENDING_PAYMENT,
        [TRANSITION_REQUEST_TAX]: STATE_PENDING_PAYMENT
      },
    },
    [STATE_ENQUIRY]: {
      on: {
        [TRANSITION_REQUEST_AFTER_ENQUIRY]: STATE_PENDING_PAYMENT,
        [TRANSITION_REQUEST_AFTER_ENQUIRY_TAX]: STATE_PENDING_PAYMENT
      },
    },
    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_DECLINE]: STATE_DECLINED,
        [TRANSITION_EXPIRE]: STATE_DECLINED,
        [TRANSITION_ACCEPT]: STATE_ACCEPTED,
      },
    },

    [STATE_DECLINED]: {},
    [STATE_ACCEPTED]: {
      on: {
        [TRANSITION_COMPLETE_BY_ADMIN]: STATE_DELIVERED,
        [TRANSITION_AUTOCOMPLETE]: STATE_DELIVERED,
        [TRANSITION_CANCEL]: STATE_CANCELED
      },
    },
    [STATE_CANCELED]: {},
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },
    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};


export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

const statesFromStateDescription = description => description.states || {};

const stateDescription = processName => {
  switch (processName) {
    case ALIAS_CUSTOMER_PRICING: {
      return customPricingDescription;
    }
    case ALIAS_OFFER_PROCESS: {
      return offerProcessDescription;
    }
    default: {
      throw (new Error(`unknow state ${processName}`));
    }
  }
};

const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

export const TRANSITIONS = [
  ...getTransitions(statesFromStateDescription(offerProcessWithoutAdminDescription)).map(
    t => t.key
  )
]

const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

const getTransitionsToState = processName => getTransitionsToStateFn(stateDescription(processName));

export const transitionsToRequested = [
  ...getTransitionsToState(ALIAS_CUSTOMER_PRICING)(STATE_PREAUTHORIZED),
  ...getTransitionsToState(ALIAS_OFFER_PROCESS)(STATE_CUSTOMER_MADE_OFFER)
];

export const transitionsToUnreadState = [
  ...getTransitionsToState(ALIAS_OFFER_PROCESS)(STATE_UNSEEN),
];

export const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx => txLastTransition(tx) === TRANSITION_ENQUIRE;

export const txIsRecorded = tx => txLastTransition(tx) === TRANSITION_RECORD;

export const txIsPaymentPending = tx => getTransitionsToState(tx.attributes.processName)(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPreauthorized = tx => txLastTransition(tx) === TRANSITION_CONFIRM_PAYMENT;

export const txIsPaymentExpired = tx => txLastTransition(tx) === TRANSITION_EXPIRE_PAYMENT;

export const txIsAccepted = tx => txLastTransition(tx) === TRANSITION_ACCEPT || getTransitionsToState(tx.attributes.processName)(STATE_SHARE_PAYMENT_INFO).includes(txLastTransition(tx));

export const txIsDeclined = tx => getTransitionsToState(tx.attributes.processName)(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsCanceled = tx => getTransitionsToState(tx.attributes.processName)(STATE_CANCELED).includes(txLastTransition(tx));

export const txIsDelivered = tx => getTransitionsToState(tx.attributes.processName)(STATE_DELIVERED).includes(txLastTransition(tx));

export const txIsExpired = tx => getTransitionsToState(tx.attributes.processName)(STATE_EXPIRED).includes(txLastTransition(tx));

export const txIsUnseen = tx => {
  const lastTransition = txLastTransition(tx);
  return (
    lastTransition === TRANSITION_ENQUIRE ||
    lastTransition === TRANSITION_CUSTOMER_REPLY ||
    lastTransition === TRANSITION_PROVIDER_REPLY
  );
};

export const txIsSeen = tx => {
  const lastTransition = txLastTransition(tx);
  return (
    lastTransition === TRANSITION_CUSTOMER_READ ||
    lastTransition === TRANSITION_PROVIDER_READ
  );
};

const firstReviewTransition = [
  ...getTransitionsToStateFn(customPricingDescription)(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToStateFn(customPricingDescription)(STATE_REVIEWED_BY_PROVIDER)
];

export const txIsInFirstReview = tx => firstReviewTransition.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToStateFn(customPricingDescription)(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToStateFn(customPricingDescription)(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx => getTransitionsToStateFn(customPricingDescription)(STATE_REVIEWED).includes(txLastTransition(tx));

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassesTransition = (transitionName, tx) => !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx => getTransitionsToState(tx.attributes.processName)(state).filter(t => hasPassesTransition(t, tx)).length;

export const txHasBeenAccepted = tx => hasPassedStateFn(STATE_ACCEPTED)(tx) || hasPassedStateFn(STATE_SHARE_PAYMENT_INFO)(tx);

export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED);

export const transitionIsReviewed = tx => transition => getTransitionsToState(tx.attributes.processName)(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = tx => (transition, isCustomer) => isCustomer ? getTransitionsToState(tx.attributes.processName)(STATE_REVIEWED_BY_CUSTOMER).includes(transition) : getTransitionsToState(tx.attributes.processName)(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer => isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer => isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

export const txIsComplete = tx => getTransitionsToState(tx.attributes.processName)(STATE_SUCCESS).includes(txLastTransition(tx));

export const txIsProviderOffer = tx => txLastTransition(tx) === TRANSITION_PROVIDER_OFFER;
export const txIsCustomerOffer = tx => txLastTransition(tx) === TRANSITION_CUSTOMER_OFFER;

export const txIsCustomerAcceptOffer = tx => txLastTransition(tx) === TRANSITION_CUSTOMER_ACCEPT_OFFER;

export const txIsProviderAcceptOffer = tx => txLastTransition(tx) === TRANSITION_PROVIDER_ACCEPT_OFFER;

export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_ACCEPT,
    TRANSITION_CANCEL,
    TRANSITION_COMPLETE_BY_ADMIN,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_DECLINE,
    TRANSITION_EXPIRE,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_MADE_OFFER,
    TRANSITION_PROVIDER_DECLINED,
    TRANSITION_PROVIDER_OFFER,
    TRANSITION_PROVIDER_ACCEPT_OFFER,
    TRANSITION_CUSTOMER_OFFER,
    TRANSITION_CUSTOMER_DECLINED,
    TRANSITION_CUSTOMER_ACCEPT_OFFER,
    TRANSITION_CUSTOMER_OFFER_EXPIRED,
    TRANSITION_PROVIDER_OFFER_EXPIRED,
    TRANSITION_ADMIN_CANCELED,
    TRANSITION_PROVIDER_CANCELED,
    TRANSITION_PROVIDER_COMPLETE_EXPIRED,
    TRANSITION_ADMIN_COMPLETE,
    TRANSITION_PROVIDER_COMPLETE,
    TRANSITION_OFFER_EXPIRED,
    TRANSITION_CUSTOMER_OFFER_EXPIRED
  ].includes(transition);
};

export const isCustomerReview = transition => [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);

export const isProviderReview = transition => [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

export const txIsMadeOffer = tx => txLastTransition(tx) === TRANSITION_MADE_OFFER;

export const getLastOfferRole = tx => {
  const { transitions = [] } = tx.attributes;
  const customerIndex = findLastIndex(transitions, transition => transition.transition === TRANSITION_CUSTOMER_OFFER);
  const providerIndex = findLastIndex(transitions, transition => transition.transition === TRANSITION_PROVIDER_OFFER);

  return providerIndex > customerIndex ? TX_TRANSITION_ACTOR_PROVIDER : TX_TRANSITION_ACTOR_CUSTOMER;
}

export const getLastTxTransition = tx => tx.attributes.transitions.find(transition => transition.transition === txLastTransition(tx));

export const isOfferProcess = tx => tx.attributes.processName === ALIAS_OFFER_PROCESS;

export const txIsCustomerDecline = tx => txLastTransition(tx) === TRANSITION_CUSTOMER_DECLINED;

export const txIsProviderDeclined = tx => txLastTransition(tx) === TRANSITION_PROVIDER_DECLINED;

export const txIsCanceledByProvider = tx => txLastTransition(tx) === TRANSITION_PROVIDER_CANCELED;

export const txIsCanceledByAdmin = tx => txLastTransition(tx) === TRANSITION_ADMIN_CANCELED;

export const txIsAutoCanceled = tx => txLastTransition(tx) === TRANSITION_PROVIDER_COMPLETE_EXPIRED;

export const txIsCounter = tx => [TRANSITION_CUSTOMER_OFFER, TRANSITION_PROVIDER_OFFER].includes(txLastTransition(tx));

export const isPrivileged = transition => {
  return [TRANSITION_REQUEST_PAYMENT].includes(
    transition
  );
};
