import { fetchCurrentUser } from './user.duck';

// ================ Action types ================ //

import { favoriteArtistListingSignal } from "../util/social";
import { storableError } from '../util/errors';

export const LIKE_ARTIST_LISTING_REQUEST = 'app/FavoriteArtist/LIKE_ARTIST_LISTING_REQUEST';
export const LIKE_ARTIST_LISTING_SUCCESS = 'app/FavoriteArtist/LIKE_ARTIST_LISTING_SUCCESS';
export const LIKE_ARTIST_LISTING_ERROR = 'app/FavoriteArtist/LIKE_ARTIST_LISTING_ERROR';

// ================ Reducer ================ //
const initialState = {
  likeArtistListingInProgress: false,
  likeArtistListingsRefs: [],
};

const favoriteArtistReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case LIKE_ARTIST_LISTING_REQUEST:
      return {
        ...state,
        likeArtistListingInProgress: true,
        likeArtistListingsRefs: [...state.likeArtistListingsRefs, payload.uuid],
      };
    case LIKE_ARTIST_LISTING_SUCCESS:
      return {
        ...state,
        likeArtistListingInProgress: false,
      };
    case LIKE_ARTIST_LISTING_ERROR:
      return {
        ...state,
        likeArtistListingInProgress: false,
      };
    default:
      return state;
  }
};

export default favoriteArtistReducer;

// ================ Action creators ================ //

export const likeArtistListingRequest = (listingId) => ({
  type: LIKE_ARTIST_LISTING_REQUEST,
  payload: listingId,
});

export const likeArtistListingSuccess = () => ({
  type: LIKE_ARTIST_LISTING_SUCCESS,
});

export const likeArtistListingError = e => ({
  type: LIKE_ARTIST_LISTING_ERROR,
  error: true,
  payload: e,
});

export const likeArtistListing = (listingId) => (dispatch, getState, sdk) => {
  dispatch(likeArtistListingRequest(listingId));

  return favoriteArtistListingSignal({ listingId })
    .then(() => {
      dispatch(likeArtistListingSuccess());
      dispatch(fetchCurrentUser());

      return true;
    })
    .catch(e => {
      return dispatch(likeArtistListingError(storableError(e)));
    });
};