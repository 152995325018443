import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Copied directly from css of ckeditor
// For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html

// The copied file is left unedited, and all the overrides are defined in
// the component CSS file below if available.
import './ckeditor.css';

const CLASS_FROM_CKEDITOR_MODULE = 'ck-content';

const RichTextCkEditor = React.forwardRef((props, ref) => {
  const { className, contentHtml } = props;
  const classes = classNames(className, CLASS_FROM_CKEDITOR_MODULE);

  return (
    <div
      ref={ref}
      className={classes}
      dangerouslySetInnerHTML={{ __html: contentHtml }}
    />
  );
});

RichTextCkEditor.defaultProps = {
  className: null,
  contentHtml: null,
};

const { string } = PropTypes;

RichTextCkEditor.propTypes = {
  className: string,
  contentHtml: string,
};

export default RichTextCkEditor;
