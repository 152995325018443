import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';
import css from './IconMagnifyingGlass.module.css';

const IconMagnifyingGlass = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5109_908)">
        <path
          d="M6.75586 13C3.15586 13 0.255859 10.1 0.255859 6.5C0.255859 2.9 3.15586 0 6.75586 0C10.3559 0 13.2559 2.9 13.2559 6.5C13.2559 10.1 10.3559 13 6.75586 13ZM6.75586 1C3.70586 1 1.25586 3.45 1.25586 6.5C1.25586 9.55 3.70586 12 6.75586 12C9.80586 12 12.2559 9.55 12.2559 6.5C12.2559 3.45 9.80586 1 6.75586 1Z"
          fill="#9A9A9A"
        />
        <path
          d="M11.5976 10.6333L16.0876 15.1233L15.3806 15.8303L10.8906 11.3403L11.5976 10.6333Z"
          fill="#9A9A9A"
        />
      </g>
      <defs>
        <clipPath id="clip0_5109_908">
          <rect width="16" height="16" fill="white" transform="translate(0.255859)" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconMagnifyingGlass.propTypes = {
  rootClassName: string,
  className: string,
};

IconMagnifyingGlass.defaultProps = {
  rootClassName: null,
  className: null,
};

export default IconMagnifyingGlass;
