import React, { useEffect } from 'react';
import { func, string, bool } from 'prop-types';
import { loadData } from './Footer.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import config from '../../config';
import CircleIconSocialMediaInstagram from '../CircleIconSocialMediaInstagram/CircleIconSocialMediaInstagram';
import ExternalLink from '../ExternalLink/ExternalLink';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteInstagramPage } = config;
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <CircleIconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl, footerData, onLoadFooterData } = props;
  const joiningTitle =
    footerData?.joiningTitle || intl.formatMessage({ id: 'Footer.joiningTitleDefault' });
  const joiningDescription =
    footerData?.joiningDescription ||
    intl.formatMessage({ id: 'Footer.joiningDescriptionDefault' });
  const buttonText = footerData?.buttonText || intl.formatMessage({ id: 'Footer.buttonText' });
  const buttonLink = footerData?.buttonLink || config.canonicalRootURL;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  const address = intl.formatMessage({ id: 'Footer.address' });
  const activeTime = intl.formatMessage({ id: 'Footer.activeTime' });
  const copyRight = intl.formatMessage({ id: 'Footer.copyright' });

  useEffect(() => {
    if(!footerData) {
      onLoadFooterData();
    }
  }, [])

  return (
    <div className={classes}>
      {/* ---------- Join Sugarlift Section ---------- */}
      {/* {showJoinSugarliftSection ? (
        <div className={css.joiningSection}>
          <div className={css.leftJoiningSection}>
            <h2 className={css.joiningTitle}>{joiningTitle}</h2>
            <p
              className={css.joiningSubTitle}
              dangerouslySetInnerHTML={{ __html: joiningDescription }}
            />
            <ExternalLink href={buttonLink} className={css.signUpButton}>
              {buttonText}
            </ExternalLink>
          </div>
        </div>
      ) : null} */}
      <div className={css.infoSection}>
        <div className={css.content}>
          <div
            className={css.someLinksMobile}
          >
            {socialMediaLinks}
          </div>
          <div className={css.address}>{address}</div>
          <div className={css.activeTime}>{activeTime}</div>
          <div className={css.copyRight}>{copyRight}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ footerData: state.Footer.footerData });

const mapDispatchToProps = dispatch => ({
  onLoadFooterData: () => dispatch(loadData()),
});

Footer.defaultProps = {
  rootClassName: null,
  className: null,
  footerData: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  footerData: propTypes.footer,
  onLoadFooterData: func.isRequired,
};

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(Footer);
