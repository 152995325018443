import React from 'react';
import loadable from '@loadable/component';
import values from 'lodash/values';
import { NotFoundPage } from './containers';
import NamedRedirect from './components/NamedRedirect/NamedRedirect';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import {
  FAVORITE_ARTISTS_TAB,
  FAVORITE_ARTS_TAB,
  LISTING_TYPE_ARTIST_COLLECTION,
  LISTING_TYPE_ARTWORK_COLLECTION
} from './util/types';
import { filterConfig, artistFilterConfig } from './marketplace-custom-config';

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'));
const ArtAdvisoryPage = loadable(() => import(/* webpackChunkName: "ArtAdvisoryPage" */ './containers/ArtAdvisoryPage/ArtAdvisoryPage'));
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'));
const CommercialArtConsultingPage = loadable(() => import(/* webpackChunkName: "CommercialArtConsultingPage" */ './containers/CommercialArtConsultingPage/CommercialArtConsultingPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'));
const ExhibitionsNavigationPage = loadable(() => import(/* webpackChunkName: "ExhibitionsNavigationPage" */ './containers/ExhibitionsNavigationPage/ExhibitionsNavigationPage'));
const ExhibitionsPostPage = loadable(() => import(/* webpackChunkName: "ExhibitionsPostPage" */ './containers/ExhibitionsPostPage/ExhibitionsPostPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/LandingPage/LandingPage'));
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ './containers/ListingPage/ListingPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'));
const FeaturedArtistPage = loadable(() => import(/* webpackChunkName: "FeaturedArtistPage" */ './containers/FeaturedArtistPage/FeaturedArtistPage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ './containers/SearchPage/SearchPage'));
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'));
const TradeProgramPage = loadable(() => import(/* webpackChunkName: "TradeProgramPage" */ './containers/TradeProgramPage/TradeProgramPage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'));
const CollectionPage = loadable(() => import(/* webpackChunkName: "CollectionPage" */ './containers/CollectionPage/CollectionPage'));
const VisitingPage = loadable(() => import(/* webpackChunkName: "VisitingPage" */ './containers/VisitingPage/VisitingPage'));
const GalleryPage = loadable(() => import(/* webpackChunkName: "GalleryPage" */ './containers/GalleryPage/GalleryPage'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'));
const VisitGalleryPage = loadable(() => import(/* webpackChunkName: "VisitGalleryPage" */ './containers/VisitGalleryPage/VisitGalleryPage'));
const ContactUsPage = loadable(() => import(/* webpackChunkName: "ContactUsPage" */ './containers/ContactUsPage/ContactUsPage'));
const CareersPage = loadable(() => import(/* webpackChunkName: "CareersPage" */ './containers/CareersPage/CareersPage'));
const MyArtPage = loadable(() => import(/* webpackChunkName: "MyArtPage" */ './containers/MyArtPage/MyArtPage'));
const FavoritePage = loadable(() => import(/* webpackChunkName: "FavoritePage" */ './containers/FavoritePage/FavoritePage'));
const ArticlePage = loadable(() => import(/* webpackChunkName: "ArticlePage" */ './containers/ArticlePage/ArticlePage'));
const ArticleNavigationPage = loadable(() => import(/* webpackChunkName: "ArticleNavigationPage" */ './containers/ArticleNavigationPage/ArticleNavigationPage'));
const FaqPage =loadable(() => import(/* webpackChunkName: "FaqPage" */ './containers/FaqPage/FaqPage'));
const TermsPage =loadable(() => import(/* webpackChunkName: "TermsPage" */ './containers/TermsPage/TermsPage'));
const PrivacyPage =loadable(() => import(/* webpackChunkName: "PrivacyPage" */ './containers/PrivacyPage/PrivacyPage'));
const CopyrightPage =loadable(() => import(/* webpackChunkName: "CopyrightPage" */ './containers/CopyrightPage/CopyrightPage'));
const ArtistApplicationPage = loadable(() => import(/* webpackChunkName: "ArtistApplicationPage" */ './containers/ArtistApplicationPage/ArtistApplicationPage'));
const MessagesPage = loadable(() => import(/* webpackChunkName: "MessagesPage" */ './containers/MessagesPage/MessagesPage'));
const SingleMessagePage = loadable(() => import(/* webpackChunkName: "SingleMessagePage" */ './containers/SingleMessagePage/SingleMessagePage'));
const NewGalleryPage = loadable(() => import(/* webpackChunkName: "NewGalleryPage" */ './containers/NewGalleryPage/NewGalleryPage'));
const PressPage = loadable(() => import(/* webpackChunkName: "PressPage" */ './containers/PressPage/PressPage'));
const ProjectPage = loadable(() => import(/* webpackChunkName: "ProjectPage" */ './containers/ProjectPage/ProjectPage'));
const ProjectPostPage = loadable(() => import(/* webpackChunkName: "ProjectPostPage" */ './containers/ProjectPostPage/ProjectPostPage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;
const pageDataLoadingAPI = getPageDataLoadingAPI();

const browseArtRoutes = [
  ...Object.keys(filterConfig)
    .filter(key => filterConfig[key].isRouteParam)
    .map((_, length) => ({
      path: `/art/${
        new Array(length+1).fill()
        .map((_, index) => `:${index}?`)
        .join('/')
      }`,
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
      hideInSitemap: true,
    }))
];

const browseArtistRoutes = values(artistFilterConfig).map((_, index) => ({
  path: `/artists/${new Array(index + 1).fill().map((_, pos) => `:${pos}?`).join('/')}`,
  name: 'LandingPage',
  component: LandingPage,
  loadData: pageDataLoadingAPI.LandingPage.loadData,
  hideInSitemap: true,
}));

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.

// If you want to change the name (ex name: 'ProfilePage') of following page, please update it in sitemap.js too.
// - ProfilePage
// - ListingPage
// - ExhibitionsPostPage
// - ArtworksCollectionPage
// - ArticlePage
// - SearchPage
// hideInSitemap prevent adding route to sitemap.xml
// When editing slugs in the path, please update the slugs array if it exists.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'GalleryPage',
      component: GalleryPage,
      loadData: pageDataLoadingAPI.GalleryPage.loadData,
    },
    {
      path: '/visit',
      name: 'VisitGalleryPage',
      component: VisitGalleryPage,
      loadData: pageDataLoadingAPI.VisitGalleryPage.loadData,
    },
    {
      path: '/contact',
      name: 'ContactUsPage',
      component: ContactUsPage,
      loadData: pageDataLoadingAPI.ContactUsPage.loadData,
    },
    {
      path: '/careers',
      name: 'CareersPage',
      component: CareersPage,
      loadData: pageDataLoadingAPI.CareersPage.loadData,
    },
    {
      path: '/article/:title',
      name: 'ArticlePage',
      component: ArticlePage,
      loadData: pageDataLoadingAPI.ArticlePage.loadData,
      slugs: [':title'],
    },
    {
      path: '/editorial',
      name: 'ArticleNavigationPage',
      component: ArticleNavigationPage,
      loadData: pageDataLoadingAPI.ArticleNavigationPage.loadData,
    },
    {
      path: '/bluereview',
      name: 'BlueReview',
      component: () => {
        if (typeof window !== 'undefined') {
          window.location.href =
            'https://art.sugarlift.com/blogs/exhibitions/the-blue-review-art-prize';
        }
        return null;
      },
      hideInSitemap: true,
    },
    {
      path: '/blogs/events/me',
      name: 'Blogs1',
      component: () => {
        if (typeof window !== 'undefined') {
          window.location.href =
            'https://art.sugarlift.com/blogs/exhibitions/me-an-exhibition-of-contemporary-self-portraiture';
        }
        return null;
      },
      isBlogPage: true,
    },
    {
      path: '/blogs/art-101/the-statement-piece-above-the-couch',
      name: 'Blogs2',
      component: () => {
        if (typeof window !== 'undefined') {
          window.location.href =
            'https://art.sugarlift.com/blogs/art-101/the-statement-piece-above-the-couch';
        }
        return null;
      },
      isBlogPage: true,
    },
    {
      path: '/blogs/exhibitions/still',
      name: 'Blogs3',
      component: () => {
        if (typeof window !== 'undefined') {
          window.location.href = 'https://art.sugarlift.com/blogs/exhibitions/still';
        }
        return null;
      },
      isBlogPage: true,
    },
    {
      path: '/blogs/exhibitions/give-me-some-skin',
      name: 'Blogs4',
      component: () => {
        if (typeof window !== 'undefined') {
          window.location.href = 'https://art.sugarlift.com/blogs/exhibitions/give-me-some-skin';
        }
        return null;
      },
      isBlogPage: true,
    },
    {
      path: '/blogs/exhibitions/biophilia',
      name: 'Blogs5',
      component: () => {
        if (typeof window !== 'undefined') {
          window.location.href = 'https://art.sugarlift.com/blogs/exhibitions/biophilia';
        }
        return null;
      },
      isBlogPage: true,
    },
    {
      path: '/blogs/exhibitions/birds-for-sofia',
      name: 'Blogs6',
      component: () => {
        if (typeof window !== 'undefined') {
          window.location.href = 'https://art.sugarlift.com/blogs/exhibitions/birds-for-sofia';
        }
        return null;
      },
      isBlogPage: true,
    },
    {
      path: '/blogs/the-blog/sketchbook-vol-1',
      name: 'Blogs7',
      component: () => {
        if (typeof window !== 'undefined') {
          window.location.href = 'https://art.sugarlift.com/blogs/the-blog/sketchbook-vol-1';
        }
        return null;
      },
      isBlogPage: true,
    },
    {
      path: '/collections/guno-park',
      name: 'Blogs7',
      component: () => {
        if (typeof window !== 'undefined') {
          window.location.href = 'https://www.sugarlift.com/artist/guno-park';
        }
        return null;
      },
    },
    {
      path: '/blogs/art-101/4-approaches-to-art-for-your-home-office',
      name: 'Blogs8',
      component: () => {
        if (typeof window !== 'undefined') {
          window.location.href =
            'https://art.sugarlift.com/blogs/art-101/4-approaches-to-art-for-your-home-office';
        }
        return null;
      },
      isBlogPage: true,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
      loadData: pageDataLoadingAPI.AboutPage.loadData,
    },
    {
      path: '/visit-the-gallery',
      name: 'VisitingPage',
      component: VisitingPage,
    },
    {
      path: '/art-advisory',
      name: 'ArtAdvisoryPage',
      component: ArtAdvisoryPage,
      loadData: pageDataLoadingAPI.ArtAdvisoryPage.loadData,
    },
    {
      path: '/trade-program',
      name: 'TradeProgramPage',
      component: TradeProgramPage,
      loadData: pageDataLoadingAPI.TradeProgramPage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/commercial-art-consulting',
      name: 'CommercialArtConsultingPage',
      component: CommercialArtConsultingPage,
      loadData: pageDataLoadingAPI.CommercialArtConsultingPage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/art-application',
      name: 'ArtApplicationPage',
      component: () => (
        <NamedRedirect
          name="SearchPage"
          search="bounds=65.43079644%2C-62.0594005%2C-2.65790911%2C-134.3002042&mapSearch=true&pub_availability=available&pub_isFeatured=true"
        />
      ),
      hideInSitemap: true,
    },
    {
      path: '/how-it-works',
      name: 'HowItWorksPage',
      component: () => (
        <NamedRedirect
          name="SearchPage"
          search="bounds=65.43079644%2C-62.0594005%2C-2.65790911%2C-134.3002042&mapSearch=true&pub_availability=available&pub_isFeatured=true"
        />
      ),
      hideInSitemap: true,
    },
    {
      path: '/art',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    ...browseArtRoutes,
    {
      path: '/my-art',
      name: 'MyArtPage',
      component: MyArtPage,
      auth: true,
      authPage: 'LoginPage',
      loadData: pageDataLoadingAPI.MyArtPage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/s/filters',
      name: 'SearchFiltersPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
      extraProps: { tab: 'filters' },
      hideInSitemap: true,
    },
    {
      path: '/s/listings',
      name: 'SearchListingsPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
      extraProps: { tab: 'listings' },
      hideInSitemap: true,
    },
    {
      path: '/s/map',
      name: 'SearchMapPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
      extraProps: { tab: 'map' },
      hideInSitemap: true,
    },
    // Artists Collection
    {
      path: '/collective/:slug',
      name: 'ArtistsCollectionPage',
      component: CollectionPage,
      loadData: (params, search) =>
        pageDataLoadingAPI.CollectionPage.loadData({ ...params, collectionType: LISTING_TYPE_ARTIST_COLLECTION }, search),
      extraProps: { collectionType: LISTING_TYPE_ARTIST_COLLECTION },
      hideInSitemap: true,
    },
    // Artworks Collection
    {
      path: '/collection/:slug',
      name: 'ArtworksCollectionPage',
      component: CollectionPage,
      loadData: (params, search) =>
        pageDataLoadingAPI.CollectionPage.loadData({ ...params, collectionType: LISTING_TYPE_ARTWORK_COLLECTION }, search),
      extraProps: { collectionType: LISTING_TYPE_ARTWORK_COLLECTION },
      slugs: [':slug'],
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'OldListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      extraProps: { isRedirectToNewRoute: true },
      hideInSitemap: true,
    },
    {
      path: '/artist/:artistSlug/:artSlug',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      slugs: [':artistSlug', ':artSlug'],
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
      hideInSitemap: true,
    },
    {
      path: '/artist/:artistSlug/:artSlug/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'photos' }}
        />
      ),
      hideInSitemap: true,
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
      hideInSitemap: true,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/artist',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/artist/:name',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
      slugs: [':name'],
    },
    {
      path: '/artists',
      name: 'LandingPage',
      component: LandingPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
      ...browseArtistRoutes
    ,
    {
      path: '/artist-search',
      name: 'FeaturedArtistPage',
      component: FeaturedArtistPage,
      loadData: pageDataLoadingAPI.FeaturedArtistPage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
      hideInSitemap: true,
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/messages/:txId',
      name: 'SingleMessagePage',
      auth: true,
      authPage: 'LoginPage',
      component: SingleMessagePage,
      loadData: pageDataLoadingAPI.SingleMessagePage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/messages',
      name: 'MessagesPage',
      auth: true,
      authPage: 'LoginPage',
      component: MessagesPage,
      loadData: pageDataLoadingAPI.MessagesPage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
      hideInSitemap: true,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
      hideInSitemap: true,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
      extraProps: { transactionRole: 'customer' },
      hideInSitemap: true,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
      hideInSitemap: true,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
      extraProps: { transactionRole: 'provider' },
      hideInSitemap: true,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
      hideInSitemap: true,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
      hideInSitemap: true,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
      hideInSitemap: true,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
      hideInSitemap: true,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
      hideInSitemap: true,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
      hideInSitemap: true,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
      hideInSitemap: true,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: NotFoundPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage,
      hideInSitemap: true,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
      hideInSitemap: true,
    },
    {
      path: '/favorite-artists',
      name: 'FavoriteArtistPage',
      auth: true,
      authPage: 'LoginPage',
      component: FavoritePage,
      loadData: pageDataLoadingAPI.FavoritePage.loadData.bind(null, FAVORITE_ARTISTS_TAB),
      extraProps: { tab: FAVORITE_ARTISTS_TAB },
      hideInSitemap: true,
    },
    {
      path: '/favorite-art',
      name: 'FavoriteArtPage',
      auth: true,
      authPage: 'LoginPage',
      component: FavoritePage,
      loadData: pageDataLoadingAPI.FavoritePage.loadData.bind(null, FAVORITE_ARTS_TAB),
      extraProps: { tab: FAVORITE_ARTS_TAB },
      hideInSitemap: true,
    },
    {
      path: '/exhibition/:slug',
      name: 'ExhibitionsPostPage',
      component: ExhibitionsPostPage,
      loadData: pageDataLoadingAPI.ExhibitionsPostPage.loadData,
      slugs: [':slug'],
    },
    {
      path: '/old-exhibitions',
      name: 'ExhibitionsNavigationPage',
      component: ExhibitionsNavigationPage,
      loadData: pageDataLoadingAPI.ExhibitionsNavigationPage.loadData,
    },
    {
      path: '/faq',
      name: 'FaqPage',
      component: FaqPage,
      loadData: pageDataLoadingAPI.FaqPage.loadData,
    },
    {
      path: '/terms',
      name: 'TermsPage',
      component: TermsPage,
      loadData: pageDataLoadingAPI.TermsPage.loadData,
    },
    {
      path: '/privacy',
      name: 'PrivacyPage',
      component: PrivacyPage,
      loadData: pageDataLoadingAPI.PrivacyPage.loadData,
    },
    {
      path: '/copyright',
      name: 'CopyrightPage',
      component: CopyrightPage,
      loadData: pageDataLoadingAPI.CopyrightPage.loadData,
    },
    {
      path: '/artist-application',
      name: 'ArtistApplicationPage',
      component: ArtistApplicationPage,
      loadData: pageDataLoadingAPI.ArtistApplicationPage.loadData,
    },
    {
      path: '/exhibitions',
      name: 'NewGalleryPage',
      component: NewGalleryPage,
      loadData: pageDataLoadingAPI.NewGalleryPage.loadData,
    },
    {
      path: '/press',
      name: 'PressPage',
      component: PressPage,
      loadData: pageDataLoadingAPI.PressPage.loadData,
    },
    {
      path: '/projects',
      name: 'ProjectPage',
      component: ProjectPage,
      loadData: pageDataLoadingAPI.ProjectPage.loadData,
    },
    {
      path: '/project/:slug',
      name: 'ProjectPostPage',
      component: ProjectPostPage,
      loadData: pageDataLoadingAPI.ProjectPostPage.loadData,
    }
  ];
};

export default routeConfiguration;
