import React from 'react';
import { string, oneOfType, bool } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  ensureUser,
  ensureCurrentUser,
  userDisplayNameAsString,
  userAbbreviatedName,
} from '../../util/data';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
import IconBannedUser from '../IconBannedUser/IconBannedUser';
import NamedLink from '../NamedLink/NamedLink';


import css from './Avatar.module.css';

// Responsive image sizes hint
const AVATAR_SIZES_SMALL = '32px';
const AVATAR_SIZES = '40px';
const AVATAR_SIZES_MEDIUM = '60px';
const AVATAR_SIZES_LARGE = '96px';

const AVATAR_IMAGE_VARIANTS = [
  // 240x240
  'square-small',

  // 480x480
  'square-small2x',
];

export const AvatarComponent = props => {
  const {
    rootClassName,
    className,
    user,
    renderSizes,
    disableProfileLink,
    intl,
    avatarImgClassName,
  } = props;

  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const isBannedUser = avatarUser.attributes.banned;
  const isDeletedUser = avatarUser.attributes.deleted;

  const bannedUserDisplayName = intl.formatMessage({
    id: 'Avatar.bannedUserDisplayName',
  });

  const deletedUserDisplayName = intl.formatMessage({
    id: 'Avatar.deletedUserDisplayName',
  });

  const defaultUserDisplayName = isBannedUser
    ? bannedUserDisplayName
    : isDeletedUser
      ? deletedUserDisplayName
      : '';

  const defaultUserAbbreviatedName = '';

  const { parsedArtistName } = avatarUser.attributes.profile.publicData || {};

  const displayName = userDisplayNameAsString(avatarUser, defaultUserDisplayName);
  const abbreviatedName = userAbbreviatedName(avatarUser, defaultUserAbbreviatedName);
  const linkProps = parsedArtistName
    ? { name: 'ProfilePage', params: { name: parsedArtistName } }
    : { name: 'ProfileBasePage' };
  const hasProfileImage = avatarUser.profileImage && avatarUser.profileImage.id;
  const profileLinkEnabled = !disableProfileLink;
  const rootCss = hasProfileImage ? css.rootWithoutFrame : css.root;
  const classes = classNames(rootClassName || rootCss, className);
  const rootProps = { className: classes, title: displayName };

  if (isBannedUser || isDeletedUser) {
    return (
      <div {...rootProps}>
        <IconBannedUser className={css.bannedUserIcon} />
      </div>
    );
  } else if (hasProfileImage && profileLinkEnabled) {
    return (
      <NamedLink {...rootProps} {...linkProps}>
        <ResponsiveImage
          rootClassName={classNames(css.avatarImage, avatarImgClassName)}
          alt={displayName}
          image={avatarUser.profileImage}
          variants={AVATAR_IMAGE_VARIANTS}
          sizes={renderSizes}
        />
      </NamedLink>
    );
  } else if (hasProfileImage) {
    return (
      <div {...rootProps}>
        <ResponsiveImage
          rootClassName={classNames(css.avatarImage, avatarImgClassName)}
          alt={displayName}
          image={avatarUser.profileImage}
          variants={AVATAR_IMAGE_VARIANTS}
          sizes={renderSizes}
        />
      </div>
    );
  } else if (profileLinkEnabled) {
    // Placeholder avatar (initials)
    return (
      <NamedLink {...rootProps} {...linkProps}>
        <span className={css.initials}>{abbreviatedName}</span>
      </NamedLink>
    );
  } else {
    // Placeholder avatar (initials)
    return (
      <div {...rootProps}>
        <span className={css.initials}>{abbreviatedName}</span>
      </div>
    );
  }
};

AvatarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  avatarImgClassName: null,
  user: null,
  renderSizes: AVATAR_SIZES,
  disableProfileLink: false,
};

AvatarComponent.propTypes = {
  rootClassName: string,
  className: string,
  avatarImgClassName: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),

  renderSizes: string,
  disableProfileLink: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Avatar = injectIntl(AvatarComponent);

export default Avatar;

export const AvatarSmall = props => {
  const { user } = props;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
  const hasProfileImage = avatarUser.profileImage && avatarUser.profileImage.id;
  return user.id ? (
    <Avatar
      rootClassName={hasProfileImage ? css.smallAvatarWithoutFrame : css.smallAvatar}
      renderSizes={AVATAR_SIZES_SMALL}
      {...props}
    />
  ) : null;
};

export const AvatarMedium = props => {
  const { user } = props;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
  const hasProfileImage = avatarUser.profileImage && avatarUser.profileImage.id;
  return user.id ? (
    <Avatar
      rootClassName={hasProfileImage ? css.mediumAvatarWithoutFrame : css.mediumAvatar}
      renderSizes={AVATAR_SIZES_MEDIUM}
      {...props}
    />
  ) : null;
};
AvatarMedium.displayName = 'AvatarMedium';

export const AvatarLarge = props => {
  const { user } = props;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
  const hasProfileImage = avatarUser.profileImage && avatarUser.profileImage.id;
  return user.id ? (
    <Avatar
      rootClassName={hasProfileImage ? css.largeAvatarWithoutFrame : css.largeAvatar}
      renderSizes={AVATAR_SIZES_LARGE}
      {...props}
    />
  ) : null;
};
AvatarLarge.displayName = 'AvatarLarge';

export const AvatarXLarge = props => {
  const { user } = props;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
  const hasProfileImage = avatarUser.profileImage && avatarUser.profileImage.id;
  return user.id ? (
    <Avatar
      rootClassName={hasProfileImage ? css.xLargeAvatarWithoutFrame : css.xLargeAvatar}
      renderSizes={AVATAR_SIZES_LARGE}
      {...props}
    />
  ) : null;
};
AvatarLarge.displayName = 'AvatarXLarge';

export const AvatarXXLarge = props => {
  const { user } = props;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
  const hasProfileImage = avatarUser.profileImage && avatarUser.profileImage.id;
  return user.id ? (
    <Avatar
      rootClassName={hasProfileImage ? css.xxLargeAvatarWithoutFrame : css.xxLargeAvatar}
      renderSizes={AVATAR_SIZES_LARGE}
      {...props}
    />
  ) : null;
};
AvatarLarge.displayName = 'AvatarXXLarge';
