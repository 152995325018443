export const getDeliveryFee = size => {
  if (!size) {
    return {
      domestic: 0,
      international: 0,
    };
  }

  if (size <= 14) {
    return {
      domestic: 2500,
      international: 10000,
    };
  } else if (size <= 18) {
    return {
      domestic: 5000,
      international: 12500,
    };
  } else if (size <= 24) {
    return {
      domestic: 7500,
      international: 15000,
    };
  } else if (size <= 30) {
    return {
      domestic: 12500,
      international: 25000,
    };
  } else if (size <= 36) {
    return {
      domestic: 15000,
      international: 30000,
    };
  } else if (size <= 48) {
    return {
      domestic: 25000,
      international: 50000,
    };
  } else if (size <= 60) {
    return {
      domestic: 35000,
      international: 75000,
    };
  } else if (size > 60) {
    return {
      domestic: 50000,
      international: 100000,
    };
  }
};


export const formatDimensionToNumber = dimension => {
  return Number.parseFloat(dimension.replace('"'));
};
