import React, { Component as ReactComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { SCROLL_DOWN, SCROLL_UP, SCROLL_DEFAULT_DIRECTION } from '../marketplace-custom-config';
import { useState, useEffect } from 'react';

const DEFAULT_THRESHOLD = 64;

/**
 * A higher order component (HOC) to take the togglePageClassNames function from
 * the context that the Page component has provided.
 */
export const withTogglePageClassNames = Component => {
  const WithTogglePageClassNamesComponent = (props, context) => (
    <Component togglePageClassNames={context.togglePageClassNames} {...props} />
  );

  WithTogglePageClassNamesComponent.displayName = `withTogglePageClassNames(${Component.displayName ||
    Component.name})`;

  const { func } = PropTypes;

  WithTogglePageClassNamesComponent.contextTypes = {
    togglePageClassNames: func.isRequired,
  };

  return WithTogglePageClassNamesComponent;
};

/**
 * A higher order component (HOC) that provides the current viewport
 * dimensions to the wrapped component as a `viewport` prop that has
 * the shape `{ width: 600, height: 400}`.
 */
export const withViewport = Component => {
  // The resize event is flooded when the browser is resized. We'll
  // use a small timeout to throttle changing the viewport since it
  // will trigger rerendering.
  const WAIT_MS = 100;

  class WithViewportComponent extends ReactComponent {
    constructor(props) {
      super(props);
      this.state = { width: 0, height: 0 };
      this.handleWindowResize = this.handleWindowResize.bind(this);
      this.setViewport = throttle(this.setViewport.bind(this), WAIT_MS);
    }
    componentDidMount() {
      this.setViewport();
      window.addEventListener('resize', this.handleWindowResize);
      window.addEventListener('orientationchange', this.handleWindowResize);
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize);
      window.removeEventListener('orientationchange', this.handleWindowResize);
    }
    handleWindowResize() {
      this.setViewport();
    }
    setViewport() {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    render() {
      const viewport = this.state;
      const props = { ...this.props, viewport };
      return <Component {...props} />;
    }
  }

  WithViewportComponent.displayName = `withViewport(${Component.displayName || Component.name})`;

  return WithViewportComponent;
};

/**
 * A higher order component (HOC) that provides dimensions to the wrapped component as a
 * `dimensions` prop that has the shape `{ width: 600, height: 400}`.
 *
 * @param {React.Component} Component to be wrapped by this HOC
 * @param {Object} options pass in options like maxWidth and maxHeight.
 *
 * @return {Object} HOC component which knows its dimensions
 */
export const withDimensions = (Component, options = {}) => {
  // The resize event is flooded when the browser is resized. We'll
  // use a small timeout to throttle changing the viewport since it
  // will trigger rerendering.
  const THROTTLE_WAIT_MS = 200;
  // First render default wait after mounting (small wait for styled paint)
  const RENDER_WAIT_MS = 100;

  class WithDimensionsComponent extends ReactComponent {
    constructor(props) {
      super(props);
      this.element = null;
      this.defaultRenderTimeout = null;

      this.state = { width: 0, height: 0 };

      this.handleWindowResize = throttle(this.handleWindowResize.bind(this), THROTTLE_WAIT_MS);
      this.setDimensions = this.setDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleWindowResize);
      window.addEventListener('orientationchange', this.handleWindowResize);

      this.defaultRenderTimeout = window.setTimeout(() => {
        this.setDimensions();
      }, RENDER_WAIT_MS);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize);
      window.removeEventListener('orientationchange', this.handleWindowResize);
      window.clearTimeout(this.defaultRenderTimeout);
    }

    handleWindowResize() {
      window.requestAnimationFrame(() => {
        this.setDimensions();
      });
    }

    setDimensions() {
      this.setState(prevState => {
        const { clientWidth, clientHeight } = this.element || { clientWidth: 0, clientHeight: 0 };
        return { width: clientWidth, height: clientHeight };
      });
    }

    render() {
      // Dimensions from state (i.e. dimension after previous resize)
      // These are needed for component rerenders
      const { width, height } = this.state;

      // Current dimensions from element reference
      const { clientWidth, clientHeight } = this.element || { clientWidth: 0, clientHeight: 0 };
      const hasDimensions =
        (width !== 0 && height !== 0) || (clientWidth !== 0 && clientHeight !== 0);

      // clientWidth and clientHeight
      const currentDimensions =
        clientWidth !== 0 && clientHeight !== 0
          ? { width: clientWidth, height: clientHeight }
          : width !== 0 && height !== 0
          ? { width, height }
          : {};

      const props = { ...this.props, dimensions: currentDimensions };

      // lazyLoadWithDimensions HOC needs to take all given space
      // unless max dimensions are provided through options.
      const { maxWidth, maxHeight } = options;
      const maxWidthMaybe = maxWidth ? { maxWidth } : {};
      const maxHeightMaybe = maxHeight ? { maxHeight } : {};
      const style =
        maxWidth || maxHeight
          ? { width: '100%', height: '100%', ...maxWidthMaybe, ...maxHeightMaybe }
          : { position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 };

      return (
        <div
          ref={element => {
            this.element = element;
          }}
          style={style}
        >
          {hasDimensions ? <Component {...props} /> : null}
        </div>
      );
    }
  }

  WithDimensionsComponent.displayName = `withDimensions(${Component.displayName ||
    Component.name})`;

  return WithDimensionsComponent;
};

/**
 * A higher order component (HOC) that lazy loads the current element and provides
 * dimensions to the wrapped component as a `dimensions` prop that has
 * the shape `{ width: 600, height: 400}`.
 *
 * @param {React.Component} Component to be wrapped by this HOC
 * @param {Object} options pass in options like maxWidth and maxHeight. To load component after
 * initial rendering has passed or after user has interacted with the window (e.g. scrolled),
 * use`loadAfterInitialRendering: 1500` (value should be milliseconds).
 *
 * @return {Object} HOC component which knows its dimensions
 */
export const lazyLoadWithDimensions = (Component, options = {}) => {
  // The resize event is flooded when the browser is resized. We'll
  // use a small timeout to throttle changing the viewport since it
  // will trigger rerendering.
  const THROTTLE_WAIT_MS = 200;
  // First render default wait after mounting (small wait for styled paint)
  const RENDER_WAIT_MS = 100;

  // Scrolling and other events that affect to viewport location have this safety margin
  // for lazy loading
  const NEAR_VIEWPORT_MARGIN = 50;

  class LazyLoadWithDimensionsComponent extends ReactComponent {
    constructor(props) {
      super(props);
      this.element = null;
      this.defaultRenderTimeout = null;
      this.afterRenderTimeout = null;

      this.state = { width: 0, height: 0 };

      this.handleWindowResize = throttle(this.handleWindowResize.bind(this), THROTTLE_WAIT_MS);
      this.isElementNearViewport = this.isElementNearViewport.bind(this);
      this.setDimensions = this.setDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleWindowResize);
      window.addEventListener('orientationchange', this.handleWindowResize);
      if (this.props.containerRef?.current) {
        const element = this.props.containerRef.current;
        element.addEventListener('scroll', this.handleWindowResize);
      } else {
        window.addEventListener('scroll', this.handleWindowResize);
      }

      this.defaultRenderTimeout = window.setTimeout(() => {
        if (this.isElementNearViewport(0)) {
          this.setDimensions();
        } else {
          const loadAfterInitialRendering = options.loadAfterInitialRendering;
          if (typeof loadAfterInitialRendering === 'number') {
            this.afterRenderTimeout = window.setTimeout(() => {
              window.requestAnimationFrame(() => {
                this.setDimensions();
              });
            }, loadAfterInitialRendering);
          }
        }
      }, RENDER_WAIT_MS);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleWindowResize);
      window.removeEventListener('resize', this.handleWindowResize);
      window.removeEventListener('orientationchange', this.handleWindowResize);
      window.clearTimeout(this.defaultRenderTimeout);

      if (this.afterRenderTimeout) {
        window.clearTimeout(this.afterRenderTimeout);
      }
    }

    handleWindowResize() {
      const shouldLoadToImproveScrolling = typeof options.loadAfterInitialRendering === 'number';
      if (this.isElementNearViewport(NEAR_VIEWPORT_MARGIN) || shouldLoadToImproveScrolling) {
        window.requestAnimationFrame(() => {
          this.setDimensions();
        });
      }
    }

    setDimensions() {
      this.setState(prevState => {
        const { clientWidth, clientHeight } = this.element || { clientWidth: 0, clientHeight: 0 };
        return { width: clientWidth, height: clientHeight };
      });
    }

    isElementNearViewport(safetyBoundary) {
      if (this.element) {
        const rect = this.element.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        return (
          (rect.top >= 0 && rect.top <= viewportHeight + safetyBoundary) ||
          (rect.bottom >= -1 * safetyBoundary && rect.bottom <= viewportHeight)
        );
      }
      return false;
    }

    render() {
      const dimensions = this.state;
      const { width, height } = dimensions;
      const props = { ...this.props, dimensions };

      // lazyLoadWithDimensions HOC needs to take all given space
      // unless max dimensions are provided through options.
      const { maxWidth, maxHeight } = options;
      const maxWidthMaybe = maxWidth ? { maxWidth } : {};
      const maxHeightMaybe = maxHeight ? { maxHeight } : {};
      const style =
        maxWidth || maxHeight
          ? { width: '100%', height: '100%', ...maxWidthMaybe, ...maxHeightMaybe }
          : { position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 };

      return (
        <div
          ref={element => {
            this.element = element;
          }}
          style={style}
        >
          {width !== 0 && height !== 0 ? <Component {...props} /> : null}
        </div>
      );
    }
  }

  LazyLoadWithDimensionsComponent.displayName = `lazyLoadWithDimensions(${Component.displayName ||
    Component.name})`;

  return LazyLoadWithDimensionsComponent;
};

/**
 * A higher order component (HOC) that detects scroll direction (up or down) at the component level (scrollable)
 * If the user scrolls more than threshold, HOC detects the scroll direction.
 *
 * @param {React.Component} Component to be wrapped by this HOC
 * 
 * @return {Object} HOC component which knows scroll direction
 */
export const withComponentScrollDirection = (Component, thresholdPixels) => {
  const threshold = thresholdPixels || DEFAULT_THRESHOLD;
  const direction = {
    up: SCROLL_UP,
    down: SCROLL_DOWN,
  }

  class WithScrollDirectionComponent extends ReactComponent {
    constructor(props) {
      super(props);
      this.state = { scrollDirection: direction.up };
      this.containerRef = props.containerRef;
      this.isAttachScrollEvent = false;
      this.previousScrollYPosition = 0;
      this.isScrollingUp = this.isScrollingUp.bind(this);
      this.scrolledMoreThanThreshold = this.scrolledMoreThanThreshold.bind(this);
      this.handleWindowScroll = this.handleWindowScroll.bind(this);
      this.updateScrollDirection = this.updateScrollDirection.bind(this);
    }

    componentDidUpdate() {
      const container = this.containerRef.current;
      if(container && !this.isAttachScrollEvent) {
        this.isAttachScrollEvent = true;
        container.addEventListener('scroll', this.handleWindowScroll);
      }
    }

    componentWillUnmount() {
      this.containerRef.current?.removeEventListener('scroll', this.handleWindowScroll);
    }

    isScrollingUp(currentScrollYPosition) {
      const previousScrollYPosition = this.previousScrollYPosition;
      return (
        currentScrollYPosition > previousScrollYPosition &&
        !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
        !(currentScrollYPosition > 0 && previousScrollYPosition === 0)
      );
    }

    scrolledMoreThanThreshold(currentScrollYPosition) {
      return Math.abs(currentScrollYPosition - this.previousScrollYPosition) > threshold;
    }

    updateScrollDirection() {
      const currentScrollYPosition = this.containerRef.current?.scrollTop || 0;

      if (this.scrolledMoreThanThreshold(currentScrollYPosition)) {
        const newScrollDirection = this.isScrollingUp(currentScrollYPosition)
          ? direction.down
          : direction.up;
        this.previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
        if(newScrollDirection !== this.state.scrollDirection) {
          this.setState(() => ({
            scrollDirection: newScrollDirection,
          }));
        }
      }
    }

    handleWindowScroll() {
      this.updateScrollDirection();
    }

    render() {
      const props = { ...this.props };
      return <Component {...props} scrollDirection={this.state.scrollDirection} />;
    }
  }

  WithScrollDirectionComponent.displayName = `withScrollDirection(${Component.displayName || Component.name})`;

  return WithScrollDirectionComponent;
}

// Detect scroll direction at the page level
export const useScrollDirection = ({
  initialDirection = SCROLL_DEFAULT_DIRECTION,
  thresholdPixels = DEFAULT_THRESHOLD,
} = {}) => {
  const [scrollDir, setScrollDir] = useState(initialDirection);

  useEffect(() => {
    const threshold = thresholdPixels || 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }

      setScrollDir(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll);

      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
  }, [initialDirection, thresholdPixels]);

  return scrollDir;
};