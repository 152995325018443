// ================ Reducer ================ //

const initialState = {
  rating: null,
  userRatingsTotal: null,
  bestRating: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
  }
};