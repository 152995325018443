import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const QUERY_ABOUT_REQUEST = 'app/AboutPage/QUERY_ABOUT_REQUEST';
export const QUERY_ABOUT_SUCCESS = 'app/AboutPage/QUERY_ABOUT_SUCCESS';
export const QUERY_ABOUT_ERROR = 'app/AboutPage/QUERY_ABOUT_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryAboutPageError: null,
  aboutData: null,
};

export default function AboutReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_ABOUT_REQUEST:
      return { ...state, queryAboutPageError: null };
    case QUERY_ABOUT_SUCCESS:
      return { ...state, aboutData: payload };
    case QUERY_ABOUT_ERROR:
      return { ...state, queryAboutPageError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryAboutRequest = () => ({
  type: QUERY_ABOUT_REQUEST,
});

export const queryAboutSuccess = aboutData => ({
  type: QUERY_ABOUT_SUCCESS,
  payload: aboutData,
});

export const queryAboutPageError = e => ({
  type: QUERY_ABOUT_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(queryAboutRequest());

  const queryParams = {
    populate: [
      'horizVideo',
      'vertVideo',
      'carousel',
      'members.primaryHeroImage',
      'members.secondHeroImage',
      'additionalButton',
    ],
  };

  return strapi
    .get('/api/new-about', queryParams)
    .then(res => dispatch(queryAboutSuccess(res.data.attributes)))
    .catch(e => dispatch(queryAboutPageError(storableError(e.response))));
};
