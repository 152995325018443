import { projectTags } from '../../marketplace-custom-config';
import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

const ITEMS_PER_PAGE = 12;

// ================ Action types ================ //

export const QUERY_PROJECT_PAGE_REQUEST = 'app/ProjectPage/QUERY_PROJECT_PAGE_REQUEST';
export const QUERY_PROJECT_PAGE_SUCCESS = 'app/ProjectPage/QUERY_PROJECT_PAGE_SUCCESS';
export const QUERY_PROJECT_PAGE_ERROR = 'app/ProjectPage/QUERY_PROJECT_PAGE_ERROR';

export const QUERY_PROJECT_ITEMS_REQUEST = 'app/ProjectPage/QUERY_PROJECT_ITEMS_REQUEST';
export const QUERY_PROJECT_ITEMS_SUCCESS = 'app/ProjectPage/QUERY_PROJECT_ITEMS_SUCCESS';
export const QUERY_PROJECT_ITEMS_ERROR = 'app/ProjectPage/QUERY_PROJECT_ITEMS_ERROR';

export const LOAD_MORE_PROJECT_ITEMS_REQUEST = 'app/ProjectPage/LOAD_MORE_PROJECT_ITEMS_REQUEST';
export const LOAD_MORE_PROJECT_ITEMS_SUCCESS = 'app/ProjectPage/LOAD_MORE_PROJECT_ITEMS_SUCCESS';
export const LOAD_MORE_PROJECT_ITEMS_ERROR = 'app/ProjectPage/LOAD_MORE_PROJECT_ITEMS_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryProjectPageError: null,
  projectPage: null,
  queryProjectPageInProgress: false,
  projects: null,
  queryProjectsError: null,
  queryParams: null,
  pagination: null,
  loadMoreProjectsInProgress: false,
  loadMoreProjectsError: null,
};

export default function ProjectPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_PROJECT_PAGE_REQUEST:
      return { ...state, queryProjectPageError: null, queryProjectPageInProgress: true };
    case QUERY_PROJECT_PAGE_SUCCESS:
      return { ...state, projectPage: payload, queryProjectPageInProgress: false };
    case QUERY_PROJECT_PAGE_ERROR:
      return { ...state, queryProjectPageError: payload, queryProjectPageInProgress: false };

    case QUERY_PROJECT_ITEMS_REQUEST:
      return {
        ...state,
        queryProjectsError: null,
        queryParams: payload,
      };
    case QUERY_PROJECT_ITEMS_SUCCESS:
      return {
        ...state,
        projects: payload.data,
        pagination: payload.meta.pagination,
      };
    case QUERY_PROJECT_ITEMS_REQUEST:
      return { ...state, queryProjectsError: payload, };

    case LOAD_MORE_PROJECT_ITEMS_REQUEST:
      return {
        ...state,
        loadMoreProjectsError: null,
        loadMoreProjectsInProgress: true,
        queryParams: payload,
      };
    case LOAD_MORE_PROJECT_ITEMS_SUCCESS:
      return {
        ...state,
        loadMoreProjectsInProgress: false,
        projects: [...state.projects, ...payload.data],
        pagination: payload.meta.pagination,
      };
    case LOAD_MORE_PROJECT_ITEMS_ERROR:
      return { ...state, loadMoreProjectsError: payload, loadMoreProjectsInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryProjectPageRequest = () => ({
  type: QUERY_PROJECT_PAGE_REQUEST,
});

export const queryProjectPageSuccess = projectInfo => ({
  type: QUERY_PROJECT_PAGE_SUCCESS,
  payload: projectInfo,
});

export const queryProjectPageError = e => ({
  type: QUERY_PROJECT_PAGE_ERROR,
  payload: e,
});

export const queryProjectsRequest = queryParams => ({
  type: QUERY_PROJECT_ITEMS_REQUEST,
  payload: queryParams,
});

export const queryProjectsSuccess = projects => ({
  type: QUERY_PROJECT_ITEMS_SUCCESS,
  payload: projects,
});

export const queryProjectsError = e => ({
  type: QUERY_PROJECT_ITEMS_ERROR,
  payload: e,
});

export const loadMoreProjectsRequest = queryParams => ({
  type: LOAD_MORE_PROJECT_ITEMS_REQUEST,
  payload: queryParams,
});

export const loadMoreProjectsSuccess = projects => ({
  type: LOAD_MORE_PROJECT_ITEMS_SUCCESS,
  payload: projects,
});

export const loadMoreProjectsError = e => ({
  type: LOAD_MORE_PROJECT_ITEMS_ERROR,
  payload: e,
});

// ================ Thunks ================ //

const canLoadMore = (pagination = {}) => {
  const { page, pageCount } = pagination || {};
  return page < pageCount;
};

export const queryProjects = tag => (dispatch, getState) => {
  const { queryParams } = getState().ProjectPage;
  const [_, ...queryableTags] = projectTags;
  const tagParam = queryableTags.find(queryableTag => queryableTag.key === tag);
  const pagination = {
    page: 1,
    pageSize: ITEMS_PER_PAGE,
  };

  const params = tagParam
    ? {
        ...queryParams,
        pagination,
        filters: {
          sugarliftProject: {
            $eq: true,
          },
          tag: {
            $eq: tagParam.key,
          },
        },
      }
    : {
        ...queryParams,
        filters: {
          sugarliftProject: {
            $eq: true,
          },
        },
        pagination,
      };

  dispatch(queryProjectsRequest(params));

  return strapi
    .get('/api/projects', params)
    .then(response => dispatch(queryProjectsSuccess(response)))
    .catch(e => dispatch(queryProjectsError(storableError(e.response))));
};

export const loadMore = () => (dispatch, getState) => {
  const { pagination, queryParams, loadMoreProjectsInProgress } = getState().ProjectPage;

  if (loadMoreProjectsInProgress || !canLoadMore(pagination)) return;

  const { page, pageSize } = pagination;

  const params = {
    ...queryParams,
    pagination: {
      pageSize,
      page: page + 1,
    },
  };

  dispatch(loadMoreProjectsRequest(params));

  return strapi
    .get('/api/projects', params)
    .then(response => dispatch(loadMoreProjectsSuccess(response)))
    .catch(error => dispatch(loadMoreProjectsError(error)));
};

export const loadData = () => dispatch => {
  const queryParams = {
    populate: 'heroBanner',
    sort: ['order', 'publishedAt:desc'],
    filters: {
      sugarliftProject: {
        $eq: true,
      },
    },
    pagination: {
      page: 1,
      pageSize: ITEMS_PER_PAGE,
    },
  };

  dispatch(queryProjectPageRequest());
  dispatch(queryProjectsRequest(queryParams));

  const projectPageRequest = strapi.get('/api/project-page');

  const projectsRequest = strapi.get('/api/projects', queryParams);

  return Promise.all([projectPageRequest, projectsRequest])
    .then(([projectPage, projects]) => {
      dispatch(queryProjectsSuccess(projects));
      dispatch(queryProjectPageSuccess(projectPage.data.attributes));
    })
    .catch(e => dispatch(queryProjectPageError(storableError(e.response))));
};
