import strapi from '../../util/strapi';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { ARTIST_TRAFFIC, UNIQUE_PAGE_VIEWS } from '../../marketplace-custom-config';
import {
  LISTING_TYPE_ART,
  LISTING_TYPE_ARTIST,
  LISTING_TYPE_ARTWORK_COLLECTION,
} from '../../util/types';

const ARTICLE_PAGE_SIZE_DEFAULT = 6;
const PAGE_SIZE_DEFAULT = 5;

// ================ Action types ================ //

const SET_INITIAL_STATE = 'app/Gallery/SET_INITIAL_STATE';

const QUERY_HOMEPAGE_STRAPI_REQUEST = 'app/Gallery/QUERY_HOMEPAGE_STRAPI_REQUEST';
const QUERY_HOMEPAGE_STRAPI_SUCCESS = 'app/Gallery/QUERY_HOMEPAGE_STRAPI_SUCCESS';

const QUERY_ARTICLES_REQUEST = 'app/Gallery/QUERY_ARTICLES_REQUEST';
const QUERY_ARTICLES_SUCCESS = 'app/Gallery/QUERY_ARTICLES_SUCCESS';

const QUERY_FEATURED_ARTISTS_REQUEST = 'app/Gallery/QUERY_FEATURED_ARTISTS_REQUEST';
const QUERY_FEATURED_ARTISTS_SUCCESS = 'app/Gallery/QUERY_FEATURED_ARTISTS_SUCCESS';

export const QUERY_ANNOUNCEMENT_SUCCESS = 'app/Gallery/QUERY_ANNOUNCEMENT_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  homepageData: null,
  queryHomepageDataInProgress: true,

  articles: [],
  queryArticlesInProgress: false,

  featuredArtists: [],
  queryFeaturedArtistsInProgress: false,

  announcementContent: null,
};

export default function GalleryPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState, announcementContent: state.announcementContent };

    case QUERY_HOMEPAGE_STRAPI_REQUEST:
      return { ...state, queryHomepageDataInProgress: true };
    case QUERY_HOMEPAGE_STRAPI_SUCCESS:
      return { ...state, homepageData: payload, queryHomepageDataInProgress: false };

    case QUERY_ARTICLES_REQUEST:
      return { ...state, queryArticlesInProgress: true };
    case QUERY_ARTICLES_SUCCESS:
      return { ...state, articles: payload, queryArticlesInProgress: false };

    case QUERY_FEATURED_ARTISTS_REQUEST:
      return { ...state, queryFeaturedArtistsInProgress: true };
    case QUERY_FEATURED_ARTISTS_SUCCESS:
      return { ...state, featuredArtists: payload, queryFeaturedArtistsInProgress: false };

    case QUERY_ANNOUNCEMENT_SUCCESS:
      return { ...state, announcementContent: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

const queryHomepageStrapiRequest = () => ({ type: QUERY_HOMEPAGE_STRAPI_REQUEST });
const queryHomepageStrapiSuccess = data => ({
  type: QUERY_HOMEPAGE_STRAPI_SUCCESS,
  payload: data,
});

const queryArticlesRequest = () => ({ type: QUERY_ARTICLES_REQUEST });
const queryArticlesSuccess = articles => ({
  type: QUERY_ARTICLES_SUCCESS,
  payload: articles,
});

const queryFeaturedArtistsRequest = () => ({ type: QUERY_FEATURED_ARTISTS_REQUEST });
const queryFeaturedArtistsSuccess = artists => ({
  type: QUERY_FEATURED_ARTISTS_SUCCESS,
  payload: artists,
});

export const queryAnnouncementSuccess = response => ({
  type: QUERY_ANNOUNCEMENT_SUCCESS,
  payload: response,
});

// ================ Thunks ================ //

const queryArticles = () => async (dispatch, getState, sdk) => {
  dispatch(queryArticlesRequest());

  const queryParamsArticle = {
    sort: ['publishDate:desc'],
    pagination: {
      page: 1,
      pageSize: ARTICLE_PAGE_SIZE_DEFAULT,
    },
    populate: 'heroImage',
  };

  return strapi.get(`/api/articles`, queryParamsArticle).then(response => {
    dispatch(queryArticlesSuccess(response.data));
  });
};

const queryHomePageData = () => (dispatch, getState, sdk) => {
  dispatch(queryHomepageStrapiRequest());

  const queryParams = {
    populate:
      'desktopCarousel, desktopCarousel.image, desktopCarousel.url, mobileCarousel, mobileCarousel.image, mobileCarousel.url, topWordingSection, topWordingSection.ctaButton, bottomWordingSection, bottomWordingSection.ctaButton, artistsSection, artistsSection.ctaButton, artistsSection.artists, exhSection, exhSection.ctaButton, exhSection.exhibitions, exhSection.exhibitions.heroImg, pressSection, pressSection.ctaButton, pressSection.presses, pressSection.presses.heroImage, projectsSection, projectsSection.ctaButton, projectsSection.projects, projectsSection.projects.heroBanner, artistsSection.artists.mainImage, artistsSection.artists.hoverImage',
  };

  return strapi.get('/api/homepage', queryParams).then(async res => {
    const homepageData = res.data.attributes;
    const { exhSection, ...rest } = homepageData;
    dispatch(
      queryHomepageStrapiSuccess({
        ...rest,
        exhibitionsSection: homepageData.exhSection,
      })
    );
  });
};

export const queryListings = collection => (dispatch, getState, sdk) => {
  const { listingIds = '', type } = collection.attributes;

  const isArtworkCollection = type === LISTING_TYPE_ARTWORK_COLLECTION;
  const listOfCollectionId = listingIds?.replace(/\n/g, ',');

  const queryParams = {
    ids: listOfCollectionId,
    pub_listingType: isArtworkCollection ? LISTING_TYPE_ART : LISTING_TYPE_ARTIST,
    sort: isArtworkCollection ? UNIQUE_PAGE_VIEWS : ARTIST_TRAFFIC,
    per_page: PAGE_SIZE_DEFAULT,
    include: ['author', 'images', 'author.profileImage'],
    'limit.images': 1,
    'fields.image': isArtworkCollection
      ? ['variants.scaled-small', 'variants.scaled-medium']
      : null,
  };

  return sdk.listings.query(queryParams).then(response => {
    const listings = denormalisedResponseEntities(response);
    return listings;
  });
};

export const queryProfile = id => (dispatch, getState, sdk) => {
  const queryParams = {
    id,
    include: ['profile.publicData', 'profileImage'],
    'limit.images': 1,
    'fields.image': ['variants.scaled-medium'],
  };

  return sdk.users.show(queryParams).then(response => {
    const artist = denormalisedResponseEntities(response);
    return artist;
  });
};

export const queryArtistListing = id => (dispatch, getState, sdk) => {
  const queryParams = {
    id,
    include: ['profile.publicData', 'profileImage'],
    'limit.images': 1,
    'fields.image': ['variants.scaled-medium'],
  };

  return sdk.listings.show({ id }).then(response => {
    const listing = denormalisedResponseEntities(response);
    return listing;
  });
};

const queryFeaturedArtists = artists => async (dispatch, getState, sdk) => {
  dispatch(queryFeaturedArtistsRequest());

  return Promise.all(artists.map(id => dispatch(queryProfile(id))))
    .then(artists => {
      return Promise.all(
        artists.map(artist =>
          dispatch(queryArtistListing(artist[0].attributes.profile.publicData.idArtistListing))
        )
      ).then(response => {
        const artistsWithListings = artists.map((artist, index) => {
          return {
            ...artist[0],
            //Get the first item returned from API (index = 0) from an array returned by promise with index
            listing: response[index][0],
          };
        });
        dispatch(queryFeaturedArtistsSuccess(artistsWithListings));
      });
    })
    .catch(e => {
      console.error(storableError(e));
    });
};

const queryAnnouncementBanner = () => async (dispatch, getState, sdk) => {
  return strapi
    .get('/api/announcement-banner')
    .then(res => dispatch(queryAnnouncementSuccess(res.data.attributes?.content || null)))
    .catch(e => console.error(storableError(e)));
};

export const loadData = () => async (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(queryHomePageData()),
    dispatch(queryArticles()),
    dispatch(queryAnnouncementBanner()),
  ])
    .then(() => {
      const artistsFromStrapi =
        getState().GalleryPage.homepageData.artistsSection?.artists?.data || [];
      const artists = artistsFromStrapi.map(item => item.attributes.artistId);
      dispatch(queryFeaturedArtists(artists));
    })
    .catch(e => {
      console.error(storableError(e));
    });
};
