import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import Avatar from '../Avatar/Avatar';
import Logo from '../Logo/Logo';
import NamedLink from '../NamedLink/NamedLink';
import ProfileDropdown from './ProfileDropdown';

import css from './TopbarDesktop.module.css';
import TopbarSearchForm from '../../forms/TopbarSearchForm/TopbarSearchForm';

const TopbarDesktop = props => {
  const {
    className,
    navItemClassName,
    logoClassName,
    searchClassName,
    loginButtonClassName,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    unreadMessagesCount,
    intl,
    isAuthenticated,
    onLogout,
    isArtist,
    onSearchSubmit,
    initialSearchFormValues,
    onSearchByKeyword,
    handleSelectSearchItem,
    totalArtworks,
    fetchCurrentUserInProgress,
  } = props;

  const classes = classNames(
    rootClassName || css.root,
    className,
    { [css.profileMenuPadding]: !!currentUser },
    { [css.boxShadowForSearchPage]: currentPage === 'SearchPage' },
  );

  const navItemClasses = classNames(css.navItemLink, navItemClassName);
  const loginButtonClasses = classNames(css.loginButton, loginButtonClassName)

  const search = (
    <TopbarSearchForm
      className={classNames(css.searchLink, { [css.searchLinkMargin]: !!currentUser })}
      desktopInputRoot={classNames(css.topbarSearchWithLeftPadding, searchClassName)}
      form="TopbarSearchFormDesktop"
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      onSearchByKeyword={onSearchByKeyword}
      placeholder={intl.formatMessage({ id: 'KeywordsSearch.desktopPlaceholder' })}
      handleSelectSearchItem={handleSelectSearchItem}
    />
  );

  const offersDot = notificationCount > 0 && <div className={css.notificationDot} />;
  const messagesDot = unreadMessagesCount > 0 && <div className={css.notificationDot} />;
  const avatarDot = unreadMessagesCount > 0 && <div className={css.avatarDot} />;

  const artistLink = (
    <NamedLink className={navItemClasses} name="LandingPage">
      <span className={css.staticText}>
        <FormattedMessage id="TopbarDesktop.artist" />
      </span>
    </NamedLink>
  );

  const exhibitionsLink = (
    <NamedLink className={navItemClasses} name="NewGalleryPage">
      <span className={css.staticText}>
        <FormattedMessage id="TopbarDesktop.exhibition" />
      </span>
    </NamedLink>
  );

  const projectsLink = (
    <NamedLink className={navItemClasses} name="ProjectPage">
      <span className={css.staticText}>
        <FormattedMessage id="TopbarDesktop.projects" />
      </span>
    </NamedLink>
  );

  const aboutLink = (
    <NamedLink className={navItemClasses} name="AboutPage">
      <span className={css.staticText}>
        <FormattedMessage id="TopbarDesktop.aboutUs" />
      </span>
    </NamedLink>
  );

  const profileMenu = (
    <ProfileDropdown
      currentUserHasListings={currentUserHasListings}
      isAuthenticated={isAuthenticated}
      isArtist={isArtist}
      currentUser={currentUser}
      currentPage={currentPage}
      offersDot={offersDot}
      messagesDot={messagesDot}
      onLogout={onLogout}
      showOnHover
    >
      <div className={css.avatarGroup}>
        <Avatar user={currentUser} disableProfileLink />
        {avatarDot}
      </div>
    </ProfileDropdown>
  );

  const loginButton = (
    <NamedLink name="LoginPage" className={css.authLink}>
      <button className={loginButtonClasses}>
        <FormattedMessage id="TopbarDesktop.signIn" />
      </button>
    </NamedLink>
  );

  const authButton = !isAuthenticated && loginButton;

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="GalleryPage">
        <Logo className={logoClassName}/>
      </NamedLink>
      {totalArtworks === 0 ? null : (
        <>
          <div className={css.linkContainer}>
            {artistLink}
            {exhibitionsLink}
            {projectsLink}
            {aboutLink}
          </div>
          {search}
          {profileMenu}
          {authButton}
        </>
      )}
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  navItemClassName: string,
  logoClassName: null,
  searchClassName: null,
  loginButtonClassName: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  unreadMessagesCount: 0,
  initialSearchFormValues: {},
  fetchCurrentUserInProgress: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  navItemClassName: string,
  logoClassName: string,
  searchClassName: string,
  loginButtonClassName: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  unreadMessagesCount: number,
  onSearchSubmit: func.isRequired,
  handleSelectSearchItem: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  totalArtworks: number.isRequired,
  fetchCurrentUserInProgress: bool,
};

export default TopbarDesktop;
