// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const detectLabels = body => {
  return fetch(`${config.serverUrl}/api/images/predict`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(body),
    headers: {
      "Content-type": 'application/json'
    }
  })
    .then(async res => {
      const responses = await res.json();
      const { predictions } = responses;
      return predictions.labels;
    })
    .catch(error => {
      throw error;
    });
};

export const extractColors = body => {
  return fetch(`${config.serverUrl}/api/images/extractColor`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(body),
    headers: {
      "Content-type": 'application/json'
    }
  })
    .then(async res => {
      const responses = await res.json();
      const { colors } = responses;
      return colors
    })
    .catch(error => {
      throw error;
    });
};

export const fetchImageDataByURL = (patch, type) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(null);
  }
  return fetch(patch, {
    method: 'GET',
    mode: 'cors',
  })
    .then(async res => {
      const arrayBuffer = await res.arrayBuffer();
      switch (type) {
        case 'base64':
          return new Buffer(arrayBuffer).toString('base64');

        case 'buffer':
          return Buffer.from(arrayBuffer);
      }
    })
};

// Reply above function
// export const sendMessageAndEmail = body => {
//   return fetch(`${config.serverUrl}/api/messages/send`, {
//     method: 'POST',
//     mode: 'cors',
//     body: JSON.stringify(body),
//     headers: {
//       "Content-type": 'application/json'
//     }
//   })
//     .then(async res => {
//       const responses = await res.json();
//       return responses;
//     })
//     .catch(error => {
//       throw error;
//     });
// };

export const userStripeConnect = body => {
  return post('/api/stripe-account', body);
};

export const sendEmail = body => {
  return post('/api/send-email', body);
};

export const search = keyword => {
  return fetch(`${config.serverUrl}/api/search?keyword=${keyword}&&perPage=${5}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .catch(error => {
      throw error;
    });
}

export const createArtistApplicationRecord = body => post('/api/create-airtable-records', body);
