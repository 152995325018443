import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getCurrentFavoriteListings } from '../ManageFavoriteListingsPage/ManageFavoriteListingsPage.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
export const RESULT_PAGE_SIZE = 12;
// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/MyArtPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/MyArtPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/MyArtPage/FETCH_LISTINGS_ERROR';

export const ADD_OWN_ENTITIES = 'app/MyArtPage/ADD_OWN_ENTITIES';

export const LOAD_MORE_REQUEST = 'app/MyArtPage/LOAD_MORE_REQUEST';
export const LOAD_MORE_SUCCESS = 'app/MyArtPage/LOAD_MORE_SUCCESS';
export const LOAD_MORE_ERROR = 'app/MyArtPage/LOAD_MORE_ERROR';

export const UPDATE_PAGINATION = 'app/MyArtPage/UPDATE_PAGINATION';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  listings: [],
  loadMoreInProgress: false,
  loadMoreError: null,
};

const myArtPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        queryInProgress: false,
        listings: payload.entities,
      };
    case FETCH_LISTINGS_ERROR:
      return {
        ...state,
        queryInProgress: false,
        queryListingsError: payload,
      };

    case LOAD_MORE_REQUEST:
      return {
        ...state,
        loadMoreInProgress: true,
        queryParams: payload.queryParams,
        loadMoreError: null,
      };
    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        listings: [...state.listings, ...payload.listings],
        loadMoreInProgress: false,
      };
    case LOAD_MORE_ERROR:
      return { ...state, loadMoreInProgress: false, loadMoreError: payload };

    case UPDATE_PAGINATION:
      return { ...state, pagination: payload.pagination };

    default:
      return state;
  }
};

export default myArtPageReducer;

// ================ Action creators ================ //
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const loadMoreRequest = queryParams => ({
  type: LOAD_MORE_REQUEST,
  payload: { queryParams },
});

export const loadMoreSuccess = ({ listings }) => ({
  type: LOAD_MORE_SUCCESS,
  payload: { listings },
});

export const loadMoreError = error => ({ type: LOAD_MORE_ERROR, error: true, payload: error });

export const updatePagination = pagination => ({
  type: UPDATE_PAGINATION,
  payload: { pagination },
});

// Throwing error for new (loadData may need that info)

// ================ Thunks ================ //
const getCurrentUser = () => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const { isAuthenticated } = getState().Auth;
  if (isAuthenticated && (!currentUser || !currentUser.id)) {
    return dispatch(fetchCurrentUser()).then(() => getState().user.currentUser);
  }

  return Promise.resolve(getState().user.currentUser);
};

const getMyListingByPage = queryParams => (dispatch, getState, sdk) => {
  const { perPage, page } = queryParams;

  return dispatch(getCurrentUser()).then(user => {
    const params = {
      pub_listingType: 'art',
      page,
      per_page: perPage,
      include: ['images', 'author'],
      'fields.image': ['variants.scaled-small', 'variants.scaled-medium'],
      'limit.images': 1,
      expand: true,
    };

    return sdk.ownListings.query(params).then(response => {
      const entities = denormalisedResponseEntities(response);
      const listings = entities.filter(
        e =>
          e.attributes.publicData.listingType === 'art' &&
          e.id.uuid !== user.attributes.profile.publicData.idArtistListing
      );
      dispatch(updatePagination(response.data.meta));
      return Promise.resolve(listings);
    });
  });
};

export const queryMyListing = queryParams => dispatch => {
  dispatch(queryListingsRequest(queryParams));

  return dispatch(getMyListingByPage(queryParams))
    .then(listings => {
      dispatch(queryListingsSuccess({ entities: listings }));
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
    });
};

const canLoadMore = (pagination = {}) => {
  const { page, totalPages } = pagination || {};
  return page < totalPages;
};

const isLoadingMore = state => {
  const { loadMoreInProgress } = state.MyArtPage;
  return loadMoreInProgress;
};

export const loadMore = () => (dispatch, getState, sdk) => {
  const { pagination, queryParams } = getState().MyArtPage;
  if (isLoadingMore(getState()) || !canLoadMore(pagination)) return;

  const params = {
    ...queryParams,
    page: pagination.page + 1,
  };

  dispatch(loadMoreRequest(params));

  return dispatch(getMyListingByPage(params))
  .then(listings => {
    dispatch(loadMoreSuccess({ listings }));
  })
    .catch(e => dispatch(loadMoreError(storableError(e))));
};

export const loadData = () => dispatch => {
  return Promise.all([
    dispatch(queryMyListing({ page: 1, perPage: RESULT_PAGE_SIZE })),
    dispatch(getCurrentFavoriteListings()),
  ])
};
