import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const UserIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootUserIcon, className);

  return (
    <svg
      className={classes}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8.28564C13.2937 8.28564 12.6032 8.49511 12.0158 8.88754C11.4285 9.27997 10.9708 9.83775 10.7004 10.4903C10.4301 11.1429 10.3594 11.861 10.4972 12.5538C10.635 13.2466 10.9752 13.883 11.4746 14.3825C11.9741 14.8819 12.6105 15.2221 13.3033 15.3599C13.9961 15.4977 14.7142 15.427 15.3667 15.1566C16.0193 14.8863 16.5771 14.4286 16.9696 13.8413C17.362 13.2539 17.5714 12.5634 17.5714 11.8571C17.5714 10.9099 17.1952 10.0015 16.5254 9.33169C15.8556 8.66192 14.9472 8.28564 14 8.28564ZM14 13.9999C13.5762 13.9999 13.1619 13.8743 12.8095 13.6388C12.4571 13.4033 12.1825 13.0687 12.0203 12.6771C11.8581 12.2856 11.8157 11.8547 11.8983 11.439C11.981 11.0233 12.1851 10.6415 12.4848 10.3418C12.7845 10.0422 13.1663 9.83807 13.582 9.75539C13.9976 9.67271 14.4285 9.71514 14.8201 9.87733C15.2116 10.0395 15.5463 10.3142 15.7817 10.6666C16.0172 11.019 16.1429 11.4333 16.1429 11.8571C16.1423 12.4252 15.9164 12.9699 15.5146 13.3717C15.1129 13.7734 14.5682 13.9994 14 13.9999Z"
        fill="#050707"
      />
      <path
        d="M14 4C12.0222 4 10.0888 4.58649 8.4443 5.6853C6.79981 6.78412 5.51809 8.3459 4.76121 10.1732C4.00433 12.0004 3.8063 14.0111 4.19215 15.9509C4.578 17.8907 5.53041 19.6725 6.92894 21.0711C8.32746 22.4696 10.1093 23.422 12.0491 23.8078C13.9889 24.1937 15.9996 23.9957 17.8268 23.2388C19.6541 22.4819 21.2159 21.2002 22.3147 19.5557C23.4135 17.9112 24 15.9778 24 14C23.997 11.3488 22.9424 8.80698 21.0677 6.93227C19.193 5.05756 16.6512 4.00302 14 4ZM9.71429 21.4121V20.4286C9.71486 19.8604 9.9408 19.3157 10.3425 18.914C10.7443 18.5122 11.289 18.2863 11.8571 18.2857H16.1429C16.711 18.2863 17.2557 18.5122 17.6575 18.914C18.0592 19.3157 18.2851 19.8604 18.2857 20.4286V21.4121C16.9851 22.1716 15.5061 22.5718 14 22.5718C12.4939 22.5718 11.0149 22.1716 9.71429 21.4121ZM19.7086 20.3757C19.6943 19.4388 19.3126 18.545 18.6457 17.8868C17.9787 17.2287 17.0799 16.8589 16.1429 16.8571H11.8571C10.9201 16.8589 10.0213 17.2287 9.35435 17.8868C8.68739 18.545 8.30567 19.4388 8.29143 20.3757C6.99612 19.2191 6.08267 17.6963 5.67203 16.009C5.26139 14.3218 5.37293 12.5495 5.99189 10.927C6.61084 9.30455 7.70802 7.90833 9.13813 6.92326C10.5682 5.93819 12.2638 5.41073 14.0004 5.41073C15.7369 5.41073 17.4325 5.93819 18.8626 6.92326C20.2927 7.90833 21.3899 9.30455 22.0088 10.927C22.6278 12.5495 22.7393 14.3218 22.3287 16.009C21.9181 17.6963 21.0046 19.2191 19.7093 20.3757H19.7086Z"
        fill="#050707"
      />
    </svg>
  );
};

const { string } = PropTypes;

UserIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

UserIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default UserIcon;
