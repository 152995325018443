import React from 'react';
import {
  ARTIST_TYPE,
} from '../../marketplace-custom-config';
import { boldString, getSearchText, getSearchTypeLabel } from '../../util/searchUtil';
import css from './TopbarSearchForm.module.css';

const getTouchCoordinates = nativeEvent => {
  const touch = nativeEvent && nativeEvent.changedTouches ? nativeEvent.changedTouches[0] : null;
  return touch ? { x: touch.screenX, y: touch.screenY } : null;
};

class ListKeyWord extends React.Component {
  render() {
    const {
      className,
      currentIndex,
      onSelectStart,
      onSelectMove,
      onSelectEnd,
      currentSearchValue = '',
      result = []
    } = this.props;

    const item = (searchItem, index) => {
      const isHighlighted = index === currentIndex;
      const searchText = getSearchText(searchItem) || '';
      const formattedSearchText = boldString(searchText, currentSearchValue);

      return (
        <li
          className={isHighlighted ? css.highlighted : null}
          key={index}
          onTouchStart={e => {
            e.preventDefault();
            onSelectStart(getTouchCoordinates(e.nativeEvent));
          }}
          onMouseDown={e => {
            e.preventDefault();
            onSelectStart(getTouchCoordinates(e.nativeEvent));
          }}
          onTouchMove={e => {
            e.preventDefault();
            onSelectMove(getTouchCoordinates(e.nativeEvent));
          }}
          onMouseUp={e => {
            e.preventDefault();
            onSelectEnd(getSearchText(searchItem), searchItem);
          }}
        >
          <div className={searchItem.searchType === ARTIST_TYPE ? css.blueTag : css.greyTag}>
            {getSearchTypeLabel(searchItem)}
          </div>
          <div
            className={css.searchItemText}
            dangerouslySetInnerHTML={{ __html: formattedSearchText }}
          />
        </li>
      );
    };
    return (
      <div className={className}>
        <ul className={css.list}>{result.map(item)}</ul>
      </div>
    );
  }
}


export default ListKeyWord;
