import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const QUERY_PRIVACY_REQUEST = 'app/PrivacyPage/QUERY_PRIVACY_REQUEST';
export const QUERY_PRIVACY_SUCCESS = 'app/PrivacyPage/QUERY_PRIVACY_SUCCESS';
export const QUERY_PRIVACY_ERROR = 'app/PrivacyPage/QUERY_PRIVACY_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryPrivacyError: null,
  privacy: null,
};

export default function PrivacyReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_PRIVACY_REQUEST:
      return { ...state, queryPrivacyError: null };
    case QUERY_PRIVACY_SUCCESS:
      return { ...state, privacy: payload };
    case QUERY_PRIVACY_ERROR:
      return { ...state, queryPrivacyError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryPrivacyRequest = () => ({
  type: QUERY_PRIVACY_REQUEST,
});

export const queryPrivacySuccess = privacy => ({
  type: QUERY_PRIVACY_SUCCESS,
  payload: privacy,
});

export const queryPrivacyError = e => ({
  type: QUERY_PRIVACY_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(queryPrivacyRequest());

  return strapi
    .get('/api/privacy')
    .then(res => dispatch(queryPrivacySuccess(res.data.attributes)))
    .catch(e => dispatch(queryPrivacyError(storableError(e.response))));
};
