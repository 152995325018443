import strapi from '../../util/strapi'

// ================ Action types ================ //
export const SET_INITIAL_STATE = 'app/Article/SET_INITIAL_STATE';

export const QUERY_ARTICLE_REQUEST = 'app/Article/QUERY_ARTICLE_REQUEST';
export const QUERY_ARTICLE_SUCCESS = 'app/Article/QUERY_ARTICLE_SUCCESS';
export const QUERY_ARTICLE_ERROR = 'app/Article/QUERY_ARTICLE_ERROR';

// ================ Reducer ================ //
const initialState = {
  article: null,
  loadInProcess: false,
  loadError: null,
};

export default function ArticlePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_ARTICLE_REQUEST:
      return { ...state, loadInProcess: true, params: payload, loadError: null };
    case QUERY_ARTICLE_SUCCESS:
      return { ...state, loadInProcess: false, article: payload };
    case QUERY_ARTICLE_ERROR:
      return { ...state, loadInProcess: false, article: null, loadError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //
export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryArticleRequest = params => ({
  type: QUERY_ARTICLE_REQUEST,
  payload: params,
});

export const queryArticleSuccess = article => ({
  type: QUERY_ARTICLE_SUCCESS,
  payload: article,
});

export const queryArticleError = error => ({
  type: QUERY_ARTICLE_ERROR,
  payload: error,
});

// ================ Thunks ================ //
export const loadData = (params) => (dispatch, getState, sdk) => {
  dispatch(setInitialState());
  
  const newParams = {
    filters: {
      slug: {
        $eq: params.title,
      },
    },
    populate: 'heroImage',
  };

  dispatch(queryArticleRequest(newParams));

  return strapi
    .get('/api/articles', { ...newParams })
    .then(res => {
      dispatch(queryArticleSuccess(res.data[0]));
    })
    .catch(error => dispatch(queryArticleError(error)));
};
