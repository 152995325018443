export const localStorageSync = {
  setItem: (key, value) => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.setItem(key, value);
    }
    return;
  },
  getItem: (key) => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(key);
    }
    return;
  },
  removeItem: (key) => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.removeItem(key);
    }
    return;
  }
};

export const sessionStorageSync = {
  setItem: (key, value) => {
    if (typeof sessionStorage !== 'undefined') {
      return sessionStorage.setItem(key, value);
    }
    return;
  },
  getItem: (key) => {
    if (typeof sessionStorage !== 'undefined') {
      return sessionStorage.getItem(key);
    }
    return;
  },
  removeItem: (key) => {
    if (typeof sessionStorage !== 'undefined') {
      return sessionStorage.removeItem(key);
    }
    return;
  }
};