import React from 'react';
import classNames from 'classnames';
import RichTextCkEditor from '../RichTextCkEditor/RichTextCkEditor';
import { bool, string } from 'prop-types';

import css from './SectionHeading.module.css';

const SectionHeading = props => {
  const { title, content, className, titleTag, useH1, titleClassName, contentClassName } = props;
  const classes = classNames(css.container, className);
  const Tag = titleTag || useH1 ? 'h1' : 'div';
  return (
    <div className={classes}>
      <Tag className={classNames(css.title, titleClassName)}>{title}</Tag>

      <RichTextCkEditor
        className={classNames(css.content, contentClassName)}
        contentHtml={content}
      />
    </div>
  );
};

SectionHeading.propTypes = {
  title: string,
  content: string,
  className: string,
  useH1: bool,
  titleClassName: string,
  contentClassName: string,
  titleTag: string,
};

SectionHeading.defaultProps = {
  title: null,
  content: null,
  className: null,
  useH1: false,
  titleClassName: null,
  contentClassName: null,
  titleTag: '',
};

export default SectionHeading;
