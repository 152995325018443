import pick from 'lodash/pick';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { getCurrentFavoriteListings } from '../ManageFavoriteListingsPage/ManageFavoriteListingsPage.duck';
import { localStorageSync } from '../../util/localStorage';
import { isUUID } from '../../util/validators';
import { sendEnquiryEmailToAdmin } from '../../util/ses';
import { DEFAULT_SORT_BY_FEATURED } from '../../marketplace-custom-config';

const { UUID } = sdkTypes;
const UUID_V4_LENGTH = 36;
const LISTINGS_PER_PAGE = 12;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const SEND_ENQUIRY_ART_REQUEST = 'app/ListingPage/SEND_ENQUIRY_ART_REQUEST';
export const SEND_ENQUIRY_ART_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_ART_SUCCESS';
export const SEND_ENQUIRY_ART_ERROR = 'app/ListingPage/SEND_ENQUIRY_ART_ERROR';

export const QUERY_MORE_ART_LISTINGS_REQUEST = 'app/ListingPage/QUERY_MORE_ART_LISTINGS_REQUEST';
export const QUERY_MORE_ART_LISTINGS_SUCCESS = 'app/ListingPage/QUERY_MORE_ART_LISTINGS_SUCCESS';
export const QUERY_MORE_ART_LISTINGS_ERROR = 'app/ListingPage/QUERY_MORE_ART_LISTINGS_ERROR';

export const LOAD_MORE_ART_LISTINGS_REQUEST = 'app/ListingPage/LOAD_MORE_ART_LISTINGS_REQUEST';
export const LOAD_MORE_ART_LISTINGS_SUCCESS = 'app/ListingPage/LOAD_MORE_ART_LISTINGS_SUCCESS';
export const LOAD_MORE_ART_LISTINGS_ERROR = 'app/ListingPage/LOAD_MORE_ART_LISTINGS_ERROR';

export const QUERY_RECENTLY_VIEWED_LISTINGS_REQUEST = 'app/ListingPage/QUERY_RECENTLY_VIEWED_LISTINGS_REQUEST';
export const QUERY_RECENTLY_VIEWED_LISTINGS_SUCCESS = 'app/ListingPage/QUERY_RECENTLY_VIEWED_LISTINGS_SUCCESS';
export const QUERY_RECENTLY_VIEWED_LISTINGS_ERROR = 'app/ListingPage/QUERY_RECENTLY_VIEWED_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  userId: null,
  artListingRefs: [],
  queryArtListingsError: null,
  recentlyViewedListingRefs: [],
  recentlyViewedListingsError: null,
  sendEnquiryArtInProgress: false,
  sendEnquiryArtError: null,
  loadMoreArtsInProgress: false,
  loadMoreArtsError: null,
  loadMoreArtsPagination: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case SEND_ENQUIRY_ART_REQUEST:
      return { ...state, sendEnquiryArtInProgress: true, sendEnquiryArtError: null };
    case SEND_ENQUIRY_ART_SUCCESS:
      return { ...state, sendEnquiryArtInProgress: false };
    case SEND_ENQUIRY_ART_ERROR:
      return { ...state, sendEnquiryArtInProgress: false, sendEnquiryArtError: payload };

    case QUERY_MORE_ART_LISTINGS_REQUEST:
      return {
        ...state,
        queryArtListingsError: null,
        userId: payload.userId?.uuid,
      };
    case QUERY_MORE_ART_LISTINGS_SUCCESS:
      return {
        ...state,
        artListingRefs: payload.artListingRefs,
        loadMoreArtsPagination: payload.pagination,
      };
    case QUERY_MORE_ART_LISTINGS_ERROR:
      return { ...state, queryArtListingsError: payload };

    case LOAD_MORE_ART_LISTINGS_REQUEST:
      return {
        ...state,
        loadMoreArtsInProgress: true,
        loadMoreArtsError: null,
      };
    case LOAD_MORE_ART_LISTINGS_SUCCESS:
      return {
        ...state,
        artListingRefs: [...state.artListingRefs, ...payload.artListingRefs],
        loadMoreArtsInProgress: false,
        loadMoreArtsPagination: payload.pagination,
      };
    case LOAD_MORE_ART_LISTINGS_ERROR:
      return {
        ...state,
        loadMoreArtsError: payload,
        loadMoreArtsInProgress: false,
      };

    case QUERY_RECENTLY_VIEWED_LISTINGS_REQUEST:
      return {
        ...state,
        recentlyViewedListingsError: null,
      };
    case QUERY_RECENTLY_VIEWED_LISTINGS_SUCCESS:
      return { ...state, recentlyViewedListingRefs: payload.recentlyViewedListingRefs };
    case QUERY_RECENTLY_VIEWED_LISTINGS_ERROR:
      return { ...state, recentlyViewedListingRefs: [], recentlyViewedListingsError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
export const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchTimeSlotsError = error => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const sendEnquiryArtRequest = () => ({ type: SEND_ENQUIRY_ART_REQUEST });
export const sendEnquiryArtSuccess = () => ({ type: SEND_ENQUIRY_ART_SUCCESS });
export const sendEnquiryArtError = e => ({ type: SEND_ENQUIRY_ART_ERROR, payload: e });

export const queryArtListingsRequest = userId => ({
  type: QUERY_MORE_ART_LISTINGS_REQUEST,
  payload: { userId },
});
export const queryArtListingsSuccess = (artListingRefs, pagination) => ({
  type: QUERY_MORE_ART_LISTINGS_SUCCESS,
  payload: { artListingRefs, pagination },
});
export const queryArtListingsError = e => ({
  type: QUERY_MORE_ART_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const loadMoreArtsRequest = () => ({
  type: LOAD_MORE_ART_LISTINGS_REQUEST,
});
export const loadMoreArtsSuccess = (artListingRefs, pagination) => ({
  type: LOAD_MORE_ART_LISTINGS_SUCCESS,
  payload: { artListingRefs, pagination },
});
export const loadMoreArtsError = e => ({
  type: LOAD_MORE_ART_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryRecentlyViewedListingsRequest = () => ({
  type: QUERY_RECENTLY_VIEWED_LISTINGS_REQUEST,
});
export const queryRecentlyViewedListingsSuccess = recentlyViewedListingRefs => ({
  type: QUERY_RECENTLY_VIEWED_LISTINGS_SUCCESS,
  payload: { recentlyViewedListingRefs },
});
export const queryRecentlyViewedListingsError = e => ({
  type: QUERY_RECENTLY_VIEWED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const providerRelationship = txResponse => {
  return txResponse.data.data.relationships.provider.data;
};

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      //Images for checkout page
      'variants.landscape-crop',
      'variants.landscape-crop2x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      const entities = denormalisedResponseEntities(data);
      if (entities.length > 0) {
        const { author } = entities[0];
        dispatch(queryMoreArtListings(author.id, listingId));
        dispatch(queryRecentlyViewedListings(listingId.uuid));
      }
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = authorId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      subjectId: authorId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment
    .utc()
    .startOf('day')
    .toDate();
  const end = moment()
    .utc()
    .startOf('day')
    .add(timeSlotsRange, 'days')
    .toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end)
            .add(secondRange, 'days')
            .toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  const queryParams = {
    expand: true,
  };
  return sdk.transactions
    .initiate(bodyParams, queryParams)
    .then(response => {
      const transaction = response.data.data;

      return sdk.messages
        .send({
          transactionId: transaction.id,
          content: message,
        })
        .then(() => {
          dispatch(sendEnquirySuccess());
          dispatch(fetchCurrentUserHasOrdersSuccess(true));
          return transaction.id;
        });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const queryMoreArtListings = (userId, listingId) => (dispatch, getState, sdk) => {
  dispatch(queryArtListingsRequest(userId));
  return sdk.listings
    .query({
      author_id: userId,
      pub_listingType: 'art',
      include: ['author', 'images'],
      'fields.image': [
        // Listing page
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        //Images for checkout page
        'variants.landscape-crop',
        'variants.landscape-crop2x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      perPage: LISTINGS_PER_PAGE,
      sort: DEFAULT_SORT_BY_FEATURED,
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data
        .filter(listing => listing.id.uuid !== listingId.uuid)
        .slice(0, LISTINGS_PER_PAGE)
        .map(({ id, type }) => ({ id, type }));

      dispatch(addMarketplaceEntities(response));
      dispatch(queryArtListingsSuccess(listingRefs, response.data.meta));
      return response;
    })
    .catch(e => dispatch(queryArtListingsError(storableError(e))));
};

const canLoadMore = (pagination = {}) => {
  const { page, totalPages } = pagination || {};
  return page < totalPages;
};

export const loadMoreArts = (listingId) => (dispatch, getState, sdk) => {
  const { loadMoreArtsPagination, loadMoreArtsInProgress, userId } = getState().ListingPage;
  if (loadMoreArtsInProgress || !canLoadMore(loadMoreArtsPagination)) return;

  const { page: currentPage } = loadMoreArtsPagination;

  dispatch(loadMoreArtsRequest());
  return sdk.listings
    .query({
      author_id: userId,
      pub_listingType: 'art',
      include: ['author', 'images'],
      'fields.image': [
        // Listing page
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        //Images for checkout page
        'variants.landscape-crop',
        'variants.landscape-crop2x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      page: currentPage + 1,
      perPage: LISTINGS_PER_PAGE,
      sort: 'pub_uniquePageViews',
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data
        .filter(listing => listing.id.uuid !== listingId.uuid)
        .slice(0, LISTINGS_PER_PAGE)
        .map(({ id, type }) => ({ id, type }));

      dispatch(addMarketplaceEntities(response));
      dispatch(loadMoreArtsSuccess(listingRefs, response.data.meta));
      return response;
    })
    .catch(e => dispatch(loadMoreArtsError(storableError(e))));
};

export const queryRecentlyViewedListings = listingId => (dispatch, getState, sdk) => {
  dispatch(queryRecentlyViewedListingsRequest());

  const viewedListingIdStorage = localStorageSync.getItem('recentlyViewedListingIds');
  let recentlyViewedListingIds = viewedListingIdStorage
    ? JSON.parse(viewedListingIdStorage)
    : [];

  if (recentlyViewedListingIds.length <= 0) {
    return storeRecentlyViewedIds(listingId);
  }

  recentlyViewedListingIds = recentlyViewedListingIds
    .filter(id => id !== listingId && isUUID(id))
    .slice(0, 4);

  return sdk.listings
    .query({
      ids: recentlyViewedListingIds,
      pub_listingType: 'art',
      include: ['author', 'images'],
      'fields.image': [
        // Listing page
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        //Images for checkout page
        'variants.landscape-crop',
        'variants.landscape-crop2x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    })
    .then(response => {
      storeRecentlyViewedIds(listingId);

      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));

      dispatch(addMarketplaceEntities(response));
      dispatch(queryRecentlyViewedListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => dispatch(queryRecentlyViewedListingsError(storableError(e))));
};

const storeRecentlyViewedIds = listingId => {
  const viewedListingIdStorage = localStorageSync.getItem('recentlyViewedListingIds');
  let recentlyViewedListingIds = viewedListingIdStorage
    ? JSON.parse(viewedListingIdStorage)
    : [];
  recentlyViewedListingIds = recentlyViewedListingIds
    .filter(id => id !== listingId)
    .slice(0, 4);
  recentlyViewedListingIds.unshift(listingId);

  localStorageSync.setItem('recentlyViewedListingIds', JSON.stringify(recentlyViewedListingIds));
};

export const getIdFromArtSlug = artSlug => {
  if (!artSlug) return '';
  const id = artSlug.slice(artSlug.length - UUID_V4_LENGTH, artSlug.length);
  return id;
};

export const getArtNameFromArtSlug = artSlug => {
  if (!artSlug) return '';
  const artName = artSlug.slice(0, artSlug.length - UUID_V4_LENGTH - 1);
  return artName;
};


export const loadData = (params, search) => dispatch => {
  const { artSlug, id } = params;
  const rawId = getIdFromArtSlug(artSlug) || id;
  const listingId = new UUID(rawId);

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  if (config.enableAvailability) {
    return Promise.all([
      dispatch(showListing(listingId)),
      dispatch(getCurrentFavoriteListings()),
      dispatch(fetchTimeSlots(listingId)),
    ]);
  } else {
    return Promise.all([
      dispatch(showListing(listingId)),
      dispatch(getCurrentFavoriteListings()),
    ]);
  }
};

export const sendEnquiryArt = params => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryArtRequest());

  return sendEnquiryEmailToAdmin(params)
    .then(() => dispatch(sendEnquiryArtSuccess()))
};
