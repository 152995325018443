import { sendListingAddedEmail, sendUserSignedUpEmail } from './ses';

export const sendSlackNotification = (text) => {
  return fetch(process.env.REACT_APP_SLACK_NOTIFICATION_API_URL,{
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text }),
  })
    .then(res => res.json())
};

export const sendListingPublishedNotification = ({ userEmail, listingUrl, listingTitle, userName, listingDimensions, listingPrice, adminUrl }) => {
  const notificationText = `A listing has been published: ${listingUrl}
  - Title: ${listingTitle}
  - Dimensions: ${listingDimensions}
  - Price: ${listingPrice}
  - Artist: ${userName}
  - Admin Url: ${adminUrl}`;

  sendSlackNotification(notificationText)
    .then(() => {
      console.log('Slack notification sent!');
    });
  sendListingAddedEmail({
    email: userEmail,
    listingUrl,
    listingTitle,
    listingDimensions,
    listingPrice,
    adminUrl,
    userName
  }).then(() => console.log('Email notification sent!'))
};

export const sendNewUserNotification = ({ userEmail, userUrl, userType, userName, userId }) => {
  const notificationText = `A user has been signed up: ${userEmail} - ${userName} - ${userType} - ${userUrl || userId}`;
  sendSlackNotification(notificationText)
    .then(() => {
      console.log('Slack notification sent!');
    });
  sendUserSignedUpEmail({
    email: userEmail,
    userUrl,
    userName,
    userType,
    userId
  }).then(() => console.log('Email notification sent!'))
};

if (typeof window !== 'undefined') {
  window.sendListingPublishedNotification = sendListingPublishedNotification;
}
