import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const QUERY_CAREERS_REQUEST = 'app/CareersPage/QUERY_CAREERS_REQUEST';
export const QUERY_CAREERS_SUCCESS = 'app/CareersPage/QUERY_CAREERS_SUCCESS';
export const QUERY_CAREERS_ERROR = 'app/CareersPage/QUERY_CAREERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryCareersError: null,
  careersData: null,
};

export default function CareersReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_CAREERS_REQUEST:
      return { ...state, queryCareersError: null };
    case QUERY_CAREERS_SUCCESS:
      return { ...state, careersData: payload };
    case QUERY_CAREERS_ERROR:
      return { ...state, queryCareersError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryCareersRequest = () => ({
  type: QUERY_CAREERS_REQUEST,
});

export const queryCareersSuccess = careersData => ({
  type: QUERY_CAREERS_SUCCESS,
  payload: careersData,
});

export const queryCareersError = e => ({
  type: QUERY_CAREERS_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(queryCareersRequest());

  const queryParams = {
    populate: 'heroImage',
  };

  return strapi
    .get('/api/career', queryParams)
    .then(res => dispatch(queryCareersSuccess(res.data.attributes)))
    .catch(e => dispatch(queryCareersError(storableError(e.response))));
};
