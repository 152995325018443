import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { DEFAULT_FEATURED_ARTIST_SORT_BY } from '../../marketplace-custom-config';
import { parse } from '../../util/urlHelpers';
import config from '../../config';

const RESULT_PAGE_SIZE = 12;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/FeaturedArtistPage/SET_INITIAL_STATE';

export const QUERY_ARTISTS_LISTING_REQUEST = 'app/FeaturedArtistPage/QUERY_ARTISTS_LISTING_REQUEST';
export const QUERY_ARTISTS_LISTING_SUCCESS = 'app/FeaturedArtistPage/QUERY_ARTISTS_LISTING_SUCCESS';
export const QUERY_ARTISTS_LISTING_ERROR = 'app/FeaturedArtistPage/QUERY_ARTISTS_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  searchInProgress: false,
  searchListingsError: null,
  listings: [],
};

export default function FeaturedArtistPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_ARTISTS_LISTING_REQUEST:
      return { ...state, searchInProgress: true, searchParams: payload.searchParams, searchListingsError: null };
    case QUERY_ARTISTS_LISTING_SUCCESS:
      return { ...state, searchInProgress: false, listings: payload.listings, pagination: payload.pagination };
    case QUERY_ARTISTS_LISTING_ERROR:
      return { ...state, searchInProgress: false, listings: [], searchListingsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryArtistListingsRequest = (searchParams) => ({
  type: QUERY_ARTISTS_LISTING_REQUEST,
  payload: { searchParams }
});

export const queryArtistListingsSuccess = ({ listings, pagination }) => ({
  type: QUERY_ARTISTS_LISTING_SUCCESS,
  payload: { listings, pagination },
});

export const queryArtistListingsError = e => ({
  type: QUERY_ARTISTS_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //
const queryRandomArtwork = artists => async (dispatch, getState, sdk) => {
  try {
    const artworks = artists.map(artist => {
      const artParams = {
        pub_listingType: 'art',
        author_id: artist.author.id.uuid,
        include: ['images'],
        'limit.images': 1,
        page: 1,
        per_page: 5,
      };

      return sdk.listings.query(artParams);
    });

    const artworksValue = await Promise.all(artworks);

    const listings = artists.map((artist, index) => {
      const arts = denormalisedResponseEntities(artworksValue[index]);
      const randomIndex = Math.floor(Math.random() * arts.length);
      const randomArtwork = arts.length === 0 ? null : arts[randomIndex];
      return { ...artist, images: randomArtwork?.images };
    });

    return listings;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const queryArtistListings = (searchParams) => (dispatch, getState, sdk) => {
  let pagination;
  const {
    perPage,
    page,
    ...rest
  } = searchParams;

  const params = {
    pub_listingType: 'artist',
    meta_isFeaturedArtist : true,
    page,
    per_page: perPage || RESULT_PAGE_SIZE,
    include: ['author', 'author.profileImage'],
    ...rest
  };

  if (!params.sort) {
    params.sort = DEFAULT_FEATURED_ARTIST_SORT_BY;
  }

  dispatch(queryArtistListingsRequest(params));

  return sdk.listings
    .query(params)
    .then(response => {
      pagination = response.data.meta;
      const artists = denormalisedResponseEntities(response);
      return dispatch(queryRandomArtwork(artists));
    })
    .then(listings => {
      dispatch(queryArtistListingsSuccess({ listings, pagination}));
    })
    .catch(e => dispatch(queryArtistListingsError(storableError(e))));
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // eslint-disable-next-line no-unused-vars
  const { page = 1, address, origin, perPage, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const searchParams = {
    ...rest,
    ...originMaybe,
    page,
    perPage,
  };

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());
  return dispatch(queryArtistListings(searchParams));
};
