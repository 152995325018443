import reverse from 'lodash/reverse';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  STATE_SEEN,
  STATE_UNSEEN,
  TRANSITION_CUSTOMER_READ,
  TRANSITION_CUSTOMER_REPLY,
  TRANSITION_PROVIDER_READ,
  TRANSITION_PROVIDER_REPLY,
  txIsUnseen,
} from '../../util/transaction';

const { UUID } = sdkTypes;

const SINGLE_MESSAGE_PAGE_SIZE = 10;

// ================ Action types ================ //
const SET_INITIAL_VALUES = 'app/SingleMessagePage/SET_INITIAL_VALUES';

const FETCH_TRANSACTION_REQUEST = 'app/SingleMessagePage/FETCH_TRANSACTION_REQUEST';
const FETCH_TRANSACTION_SUCCESS = 'app/SingleMessagePage/FETCH_TRANSACTION_SUCCESS';
const FETCH_TRANSACTION_ERROR = 'app/SingleMessagePage/FETCH_TRANSACTION_ERROR';

const FETCH_MESSAGES_REQUEST = 'app/SingleMessagePage/FETCH_MESSAGES_REQUEST';
const FETCH_MESSAGES_SUCCESS = 'app/SingleMessagePage/FETCH_MESSAGES_SUCCESS';
const FETCH_MESSAGES_ERROR = 'app/SingleMessagePage/FETCH_MESSAGES_ERROR';

const UPDATE_MESSAGE_STATUS_REQUEST = 'app/SingleMessagePage/UPDATE_MESSAGE_STATUS_REQUEST';
const UPDATE_MESSAGE_STATUS_SUCCESS = 'app/SingleMessagePage/UPDATE_MESSAGE_STATUS_SUCCESS';
const UPDATE_MESSAGE_STATUS_ERROR = 'app/SingleMessagePage/UPDATE_MESSAGE_STATUS_ERROR';

const SEND_MESSAGE_REQUEST = 'app/SingleMessagePage/SEND_MESSAGE_REQUEST';
const SEND_MESSAGE_SUCCESS = 'app/SingleMessagePage/SEND_MESSAGE_SUCCESS';
const SEND_MESSAGE_ERROR = 'app/SingleMessagePage/SEND_MESSAGE_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  pagination: null,
  params: null,
  messages: [],

  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transaction: null,

  sendMessageInProgress: false,
  sendMessageError: null,

  updateMessageStatusInProgress: false,
  updateMessageStatusError: null,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      return { ...state, fetchTransactionInProgress: false, transaction: payload.transaction };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(payload.messages, state.messages),
        pagination: payload.pagination,
      };
    }
    case FETCH_MESSAGES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case UPDATE_MESSAGE_STATUS_REQUEST:
      return {
        ...state,
        updateMessageStatusInProgress: true,
        updateMessageStatusError: null,
      };
    case UPDATE_MESSAGE_STATUS_SUCCESS:
      return { ...state, updateMessageStatusInProgress: false };
    case UPDATE_MESSAGE_STATUS_ERROR:
      return { ...state, updateMessageStatusInProgress: false, updateMessageStatusError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const setInitialValues = () => ({ type: SET_INITIAL_VALUES });

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = transaction => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: { transaction },
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchMessagesRequest = params => ({ type: FETCH_MESSAGES_REQUEST, payload: params });
const fetchMessagesSuccess = ({ messages, pagination }) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, pagination },
});
const fetchMessagesError = e => ({
  type: FETCH_MESSAGES_ERROR,
  error: true,
  payload: e,
});

const updateMessageStatusRequest = () => ({ type: UPDATE_MESSAGE_STATUS_REQUEST });
const updateMessageStatusSuccess = messages => ({
  type: UPDATE_MESSAGE_STATUS_SUCCESS,
  payload: { messages },
});
const updateMessageStatusError = e => ({
  type: UPDATE_MESSAGE_STATUS_ERROR,
  error: true,
  payload: e,
});

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = messages => ({ type: SEND_MESSAGE_SUCCESS, payload: { messages } });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

// ================ Thunks ================ //

const checkIsUnseen = ({tx, currentUser, messages}) => {
  const isInUnseenState = txIsUnseen(tx);
  const lastMessageSender = messages[messages.length - 1].sender;
  return isInUnseenState && lastMessageSender.id.uuid !== currentUser.id.uuid;
}

const fetchTransaction = (txId) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());

  const params = {
    id: new UUID(txId),
    include: ['provider', 'provider.profileImage', 'customer', 'customer.profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  return sdk.transactions
    .show(params)
    .then(response => {
      const transaction = denormalisedResponseEntities(response)[0];
      dispatch(fetchTransactionSuccess(transaction));
      return transaction;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
    });
};

const queryMessages = async (params, sdk) => {
  const res = await sdk.messages.query(params);
  const messages = reverse(denormalisedResponseEntities(res));
  const pagination = res.data.meta;

  if (pagination.page < pagination.totalPages) {
    const nextPage = await queryMessages({ ...params, page: params.page + 1 }, sdk);
    return { messages: [...nextPage.messages, ...messages], pagination };
  }

  return { messages, pagination };
}

const fetchMessages = (txId) => (dispatch, getState, sdk) => {
  dispatch(fetchMessagesRequest());

  const params = {
    transactionId: txId,
    include: ['sender', 'sender.profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page: 1,
    per_page: SINGLE_MESSAGE_PAGE_SIZE,
  };

  return queryMessages(params, sdk)
    .then(({messages, pagination}) => {
      const { totalItems } = pagination;
      const { totalItems: currentTotalItems } = getState().SingleMessagePage.pagination || {};

      dispatch(fetchMessagesSuccess({ messages, pagination }));

      // when user haven't read messages of the other party but sending a new message
      if (currentTotalItems && totalItems - currentTotalItems > 1) {
        const transaction = getState().SingleMessagePage.transaction;
        return dispatch(updateMessageStatus(transaction, STATE_SEEN));
      }

      return messages;
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

const updateMessageStatus = (tx, updateState) => (dispatch, getState, sdk) => {
  dispatch(updateMessageStatusRequest());
  const currentUser = getState().user.currentUser;

  const isProvider = tx.provider.id.uuid === currentUser.id.uuid;
  let transition;
  if (isProvider && updateState === STATE_SEEN) transition = TRANSITION_PROVIDER_READ;
  else if (!isProvider && updateState === STATE_SEEN) transition = TRANSITION_CUSTOMER_READ;
  else if (isProvider && updateState === STATE_UNSEEN) transition = TRANSITION_PROVIDER_REPLY;
  else if (!isProvider && updateState === STATE_UNSEEN) transition = TRANSITION_CUSTOMER_REPLY;

  return sdk.transactions
    .transition({
      id: tx.id,
      transition,
      params: {},
    })
    .then(() => {
      dispatch(updateMessageStatusSuccess());
    })
    .catch((e) => {
      dispatch(updateMessageStatusError(storableError(e)));
    });
};

export const sendMessage = (tx, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: tx.id, content: message })
    .then(response => {
      const messageId = response.data.data.id;

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      return dispatch(fetchMessages(tx.id))
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .then(() => {
      if (!txIsUnseen(tx)) {
        dispatch(updateMessageStatus(tx, STATE_UNSEEN));
      }
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

export const loadData = (params) => (dispatch, getState, sdk) => {
  const { txId } = params;
  dispatch(setInitialValues());

  return Promise.all([
    fetchCurrentUser(),
    dispatch(fetchMessages(txId)),
    dispatch(fetchTransaction(txId)),
  ]).then(([fetchCurrentUserRes, messages, transaction]) => {
    const currentUser = getState().user.currentUser;

    if (messages.length > 0 && checkIsUnseen({ tx: transaction, currentUser, messages })) {
      dispatch(updateMessageStatus(transaction, STATE_SEEN));
    }
  });
};
