import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import config from '../../config';

import CityState from '../../components/CityState/CityState';
import css from './ListingPage.module.css';
import { ensureListing } from '../../util/data';
import { PICK_UP, DOMESTIC_SHIPPING, INTERNATIONAL_SHIPPING } from '../../marketplace-custom-config';
const { Money } = sdkTypes;


const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export const IconCheck = props => {
  const { classNames } = props;
  const classes = classNames;

  return (
    <svg width="9" height="9" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path
        className={css.marketplaceFill}
        d="M2.636621 7.7824771L.3573694 5.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254783L6.1721653.505847C6.565577-.0373166 7.326743-.1636902 7.8777637.227582c.5473554.3912721.6731983 1.150729.2797866 1.6951076L4.4924979 7.631801c-.2199195.306213-.5803433.5067096-.9920816.5067096-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
        fillRule="evenodd"
      />
    </svg>
  );
};

const SectionDelivery = props => {
  const { listing, intl } = props;
  const { publicData } = ensureListing(listing).attributes;
  const { location, delivery = [], internationalFee, domesticFee } = publicData;

  if (!delivery || !intl) {
    return null;
  }
  const formattedDomesticPrice = domesticFee
    ? new Money(domesticFee, config.currency)
    : null;
  const formattedInternationalPrice = internationalFee
    ? new Money(internationalFee, config.currency)
    : null;

  const { formattedPrice: domesticPrice } = formattedDomesticPrice
    ? priceData(formattedDomesticPrice, intl) : {};
  const { formattedPrice: internationalPrice } = formattedInternationalPrice
    ? priceData(formattedInternationalPrice, intl) : {};


  return (
    <div className={css.sectionDelivery}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.deliveryTitle" />
      </h2>

      {delivery.includes(PICK_UP) &&
        <div className={css.wrapIconTick}>
          <span className={css.iconWrapper}>
            <IconCheck />
          </span>
          <span className={css.itemPickUp}>
            <FormattedMessage id="ListingPage.pickUp" />
          </span>
        </div>
      }

      {location &&
        <p className={css.selectedExplanationLabel}>
          <CityState location={location} />
        </p>
      }

      {(delivery.includes(DOMESTIC_SHIPPING) || delivery.includes(INTERNATIONAL_SHIPPING)) &&
        <div className={css.shipping}>
          <div className={css.wrapIconTick}>
            <span className={css.iconWrapper}>
              <IconCheck />
            </span>
            <span className={css.itemPickUp}>
              <FormattedMessage id="ListingPage.shippingTitle" />
            </span>
          </div>

          {location &&
            <p className={classNames(css.selectedExplanationLabel, css.shippingTime)}>
              <FormattedMessage
                id="ListingPage.shippingSubTitle"
                values={{ location: <CityState location={location} /> }}
              />
            </p>
          }

          <p className={css.selectedExplanationLabel}>
            <FormattedMessage id="ListingPage.shippingPriceInfo"
              values={{
                domesticPrice: delivery.includes(INTERNATIONAL_SHIPPING)
                  ? <FormattedMessage id="ListingPage.shippingDomesticTitle"
                    values={{ price: domesticPrice }} />
                  : '',
                internationalPrice: delivery.includes(INTERNATIONAL_SHIPPING)
                  ? <FormattedMessage id="ListingPage.shippingInternationalTitle"
                    values={{ price: internationalPrice }} />
                  : '',
              }}
            />
          </p>

          <p className={classNames(css.selectedExplanationLabel, css.shippingTime)}>
            <FormattedMessage id="ListingPage.shippingTime" />
          </p>
        </div>
      }

    </div>
  );
};

export default SectionDelivery;
