import AWS from 'aws-sdk';
import config from '../config';
import {
  MADE_OFFER_EMAIL,
  OFFER_ACCEPTED_EMAIL,
  OFFER_DECLINED_EMAIL,
  OFFER_EXPIRED_EMAIL,
  TEST_ENVIRONMENT,
  PRODUCTION_ENVIRONMENT,
  TEST_SITE_URL,
  PRODUCTION_SITE_URL
} from '../util/types';
import { TITLE_PREFIX } from '../marketplace-custom-config';

const credential = new AWS.Config(
  {
    accessKeyId: `${process.env.REACT_APP_AWS_API_ACCESS_ID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_API_ACCESS_KEY}`,
    region: `${process.env.REACT_APP_AWS_API_REGION}`
  }
);

AWS.config.update(credential);

const createEmailParams = (receiver, subject, content, sender) => {
  let toAddresses = Array.isArray(receiver) ? receiver : [receiver];
  let body = {
    Html: {
      Charset: "UTF-8",
      Data: content
    }
  };
  return {
    Destination: {
      ToAddresses: toAddresses
    },
    Message: {
      Body: body,
      Subject: {
        Charset: 'UTF-8',
        Data: subject
      }
    },
    Source: sender || process.env.REACT_APP_NOTIFICATION_SENDER_EMAIL,
  };
};

const sendEmail = (params) => {
  return new AWS.SES({ apiVersion: '2010-12-01' }).sendEmail(params).promise();
};

export const sendListingAddedEmail = ({ email, listingUrl, listingTitle, userName, listingDimensions, listingPrice, adminUrl }) => {
  const content = `
    <p>Hi admin,</p>
    <p>One of our users has just published a listing:</p>
    <p><strong>Email:</strong> ${email}</p>
    <p><strong>Listing Url:</strong> ${listingUrl}</p>
    <p><strong>Listing Title:</strong> ${listingTitle}</p>
    <p><strong>Listing Dimensions:</strong> ${listingDimensions}</p>
    <p><strong>Listing Price:</strong> ${listingPrice}</p>
    <p><strong>Artist:</strong> ${userName}</p>
    <p><strong>Admin Url:</strong> ${adminUrl}</p>
  `;

  const params = createEmailParams(process.env.REACT_APP_NOTIFICATION_RECEIVER_EMAIL, `A Listing has been published by ${email}`, content);

  return sendEmail(params);
};

export const sendUserSignedUpEmail = ({ email, userUrl, userType, userName, userId }) => {
  const content = userUrl
    ? `
    <p>Hi admin,</p>
    <p>A new user has been signed up:</p>
    <p><strong>Email:</strong> ${email}</p>
    <p><strong>User Url:</strong> ${userUrl}</p>
    <p><strong>User Type:</strong> ${userType}</p>
    <p><strong>User Name:</strong> ${userName}</p>
  `
    : `
  <p>Hi admin,</p>
  <p>A new user has been signed up:</p>
  <p><strong>Email:</strong> ${email}</p>
  <p><strong>User Id:</strong> ${userId}</p>
  <p><strong>User Type:</strong> ${userType}</p>
  <p><strong>User Name:</strong> ${userName}</p>
` ;

  const params = createEmailParams(process.env.REACT_APP_NOTIFICATION_RECEIVER_EMAIL, `A new user has signed up: ${email}`, content);

  return sendEmail(params);
};

export const sendOfferEmailToAdmin = (
  {
    listingPrice,
    offerPrice,
    buyerName,
    buyerEmail,
    buyerPhone,
    artistName,
    artistPhone,
    artistEmail,
    title,
    medium,
    width,
    height,
    listingId,
    transactionId,
    artImage,
    emailType
  }) => {
  const imageUrl = artImage['scaled-medium'].url;
  const environment = config.env !== 'production' ? TEST_ENVIRONMENT : PRODUCTION_ENVIRONMENT;
  const siteUrl = config.env !== 'production' ? TEST_SITE_URL : PRODUCTION_SITE_URL;
  const listingUrl = `${siteUrl}/l/${listingId}`;
  const adminUrl = `https://flex-console.sharetribe.com/o/sugarlift/m/${environment}/transactions?id=${transactionId}`;
  const content = `
    <p>
        Listing price: ${listingPrice}<br/>
        Offer price: ${offerPrice}
    </p>
    
    <p>
        Buyer name: ${buyerName}<br/>
        Buyer email: ${buyerEmail}<br/>
        Buyer phone: ${buyerPhone}
    </p>
    
    <p>
        Artist name: ${artistName}<br/>
        Artist email: ${artistEmail}<br/>
        Artist phone: ${artistPhone}
    </p>
    
    <p>Art details:</p>
    <img src=${imageUrl} alt="art image" />
    <p>
        ${title}<br/>
        ${medium}<br/>
        ${width}" x ${height}"
    </p>
    
    <p>Listing URL: ${listingUrl}</p>
    <p>Admin URL: ${adminUrl}</p>
  `;
  const mailSubject = () => {
    switch (emailType) {
      case MADE_OFFER_EMAIL:
        return `New offer - ${buyerName} offered ${offerPrice} for ${title} by ${artistName}`;
      case OFFER_ACCEPTED_EMAIL:
        return `Offer accepted - ${artistName} accepted ${offerPrice} for ${title} by ${buyerName}`;
      case OFFER_DECLINED_EMAIL:
        return `Offer declined - ${artistName} declined ${offerPrice} for ${title} by ${buyerName}`;
      case OFFER_EXPIRED_EMAIL:
        return `Offer expired - ${artistName} did not accept or decline ${offerPrice} for ${title} by ${buyerName}`;
      default:
        return `offer mail`;
    }
  };
  const subject = mailSubject();

  const params = createEmailParams(process.env.REACT_APP_NOTIFICATION_RECEIVER_EMAIL, subject, content);

  return sendEmail(params);
};

export const sendEnquiryEmailToAdmin = ({ name, message, email, title, hyperlink, type }) => {
  const hyperlinkMessage = hyperlink ? `(${hyperlink})` : '';
  const titleMessage = `${type} - ${title}`;
  const content = `
    ${name} (${email}) inquired about ${titleMessage} ${hyperlinkMessage}
    <p>${message}</p>
  `;
  const subject = `Platform - ${name} inquired about ${titleMessage}`;

  const params = createEmailParams(
    process.env.REACT_APP_NOTIFICATION_RECEIVER_EMAIL,
    subject,
    content
  );
  return sendEmail(params);
};
