import { createArtistApplicationRecord } from '../../util/api';
import { storableError } from '../../util/errors';
import strapi from '../../util/strapi';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ArtistApplicationPage/SET_INITIAL_STATE';

export const QUERY_ARTIST_APPLICATION_REQUEST =
  'app/ArtistApplicationPage/QUERY_ARTIST_APPLICATION_REQUEST';
export const QUERY_ARTIST_APPLICATION_SUCCESS =
  'app/ArtistApplicationPage/QUERY_ARTIST_APPLICATION_SUCCESS';
export const QUERY_ARTIST_APPLICATION_ERROR =
  'app/ArtistApplicationPage/QUERY_ARTIST_APPLICATION_ERROR';

export const SUBMIT_ARTIST_APPLICATION_FORM_REQUEST =
  'app/ArtistApplicationPage/SUBMIT_ARTIST_APPLICATION_FORM_REQUEST';
export const SUBMIT_ARTIST_APPLICATION_FORM_SUCCESS =
  'app/ArtistApplicationPage/SUBMIT_ARTIST_APPLICATION_FORM_SUCCESS';
export const SUBMIT_ARTIST_APPLICATION_FORM_ERROR =
  'app/ArtistApplicationPage/SUBMIT_ARTIST_APPLICATION_FORM_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryArtistApplicationError: null,
  artistApplicationData: null,
  submitInProgress: false,
  submitError: null,
  isSubmitted: false,
};

export default function ArtistApplicationReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return initialState;

    case QUERY_ARTIST_APPLICATION_REQUEST:
      return { ...state, queryArtistApplicationError: null };
    case QUERY_ARTIST_APPLICATION_SUCCESS:
      return { ...state, artistApplicationData: payload };
    case QUERY_ARTIST_APPLICATION_ERROR:
      return { ...state, queryArtistApplicationError: payload };

    case SUBMIT_ARTIST_APPLICATION_FORM_REQUEST:
      return { ...state, submitInProgress: true };
    case SUBMIT_ARTIST_APPLICATION_FORM_SUCCESS:
      return { ...state, submitInProgress: false, submitError: null, isSubmitted: true };
    case SUBMIT_ARTIST_APPLICATION_FORM_ERROR:
      return { ...state, submitInProgress: false, submitError: payload, isSubmitted: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryArtistApplicationRequest = () => ({
  type: QUERY_ARTIST_APPLICATION_REQUEST,
});

export const queryArtistApplicationSuccess = artistApplicationData => ({
  type: QUERY_ARTIST_APPLICATION_SUCCESS,
  payload: artistApplicationData,
});

export const queryArtistApplicationError = e => ({
  type: QUERY_ARTIST_APPLICATION_ERROR,
  payload: e,
});

export const submitArtistApplicationFormRequest = () => ({
  type: SUBMIT_ARTIST_APPLICATION_FORM_REQUEST,
});

export const submitArtistApplicationFormSuccess = () => ({
  type: SUBMIT_ARTIST_APPLICATION_FORM_SUCCESS,
});

export const submitArtistApplicationFormError = e => ({
  type: SUBMIT_ARTIST_APPLICATION_FORM_ERROR,
  payload: e,
});

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  dispatch(setInitialState());
  dispatch(queryArtistApplicationRequest());

  const queryParams = {
    populate: 'heroImages',
  };

  return strapi
    .get('/api/artist-application', queryParams)
    .then(res => dispatch(queryArtistApplicationSuccess(res.data.attributes)))
    .catch(e => dispatch(queryArtistApplicationError(storableError(e.response))));
};

export const submitArtistApplicationForm = data => dispatch => {
  dispatch(submitArtistApplicationFormRequest());

  createArtistApplicationRecord(data)
    .then(() => {
      dispatch(submitArtistApplicationFormSuccess());
    })
    .catch(e => {
      dispatch(submitArtistApplicationFormError(storableError(e)));
    });
};
