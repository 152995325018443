import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCheckMark.module.css';

const SIZE_SMALL = 'small';

const IconCheckmark = props => {
  const { rootClassName, className, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  const isSmall = size === SIZE_SMALL;
  if (isSmall) {
    return (
      <svg width="9" height="9" xmlns="http://www.w3.org/2000/svg" className={classes}>
        <path
          className={css.marketplaceFill}
          d="M2.636621 7.7824771L.3573694 5.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254783L6.1721653.505847C6.565577-.0373166 7.326743-.1636902 7.8777637.227582c.5473554.3912721.6731983 1.150729.2797866 1.6951076L4.4924979 7.631801c-.2199195.306213-.5803433.5067096-.9920816.5067096-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
          fillRule="evenodd"
        />
      </svg>
    );
  }

  return (
    <svg className={classes} width="24" height="24" strokeWidth="2" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.6 1.2c-.4-.3-1-.2-1.3.2L7.8 19l-5.2-5c-.4-.4-1-.4-1.3 0-.4.3-.4.8 0 1l6 5.6.6.2s.2 0 .4-.4l14.3-18c.3-.5.2-1-.2-1" />
    </svg>
  );
};

IconCheckmark.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCheckmark.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCheckmark;
