export const loginIntercomUser = (currentUser, app_id) => {
  if (typeof window.Intercom === 'undefined') {
    return;
  }

  if (!currentUser || !currentUser.id) {
    return;
  }

  const profile = currentUser.attributes.profile;

  const intercomSettings = window.intercomSettings || {
    app_id,
  };

  intercomSettings.user_id = currentUser.id.uuid;
  intercomSettings.email = currentUser.attributes.email;
  intercomSettings.phone = profile.protectedData.phoneNumber;
  intercomSettings.name = `${profile.firstName} ${profile.lastName}`;

  window.Intercom('shutdown');
  return window.Intercom('boot', intercomSettings);
};

export const logoutIntercomUser = (app_id) => {
  if (typeof window.Intercom === 'undefined') {
    return;
  }

  const intercomSettings = {
    app_id,
    user_id: null,
    email: null,
    phone: null,
    name: null,
  };

  window.Intercom('shutdown');
  return window.Intercom('boot', intercomSettings);
};

export const trackIntercomEvent = (eventName, metadata = {}) => {
  if (typeof window.Intercom === 'undefined') {
    return;
  }

  return window.Intercom(
    'trackEvent',
    eventName,
    metadata
  )
};
